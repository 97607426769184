import {FcPrint, FcPortraitMode,FcCollaboration,FcBusinessman,FcDataSheet,FcHome,FcKindle,FcNook,FcShipped,FcShop,FcSoundRecordingCopyright, FcCollect, FcBarChart, FcInTransit, FcNeutralTrading, FcLineChart, FcLink, FcLibrary, FcPackage, FcBearish, FcBullish, FcDribbble, FcStatistics } from "react-icons/fc";

export const SIDEBAR_NAV_LINKS = [
  {
    key: "dashboard",
    label: "Dashboard",
    path: "/dashboardhome",
    icon: <FcHome />,
  },
  {
    key: "General",
    label: "General",
    path: "/General",
    icon: <FcCollect />,
    dropdown: [
      {
        key: "user",
        label: "Users",
        path: "/user",
        icon: <FcBusinessman />,
      },
      {
        key: "inventory",
        label: "Inventory",
        path: "/inventory",
        icon: <FcDataSheet />,
      },
      {
        key: "company",
        label: "Compnay",
        path: "/companies-list",
        icon: <FcDataSheet />,
      },
      {
        key: "vehicle",
        label: "Vehicle",
        path: "/vehicle-list",
        icon: <FcInTransit />,
      },
      {
        key: "rate",
        label: "Rate",
        path: "/rate-list",
        icon: <FcBarChart />,
      },
    ]
  },


  {
    key: "mushak6.1",
    label: "Mushak-6.1",
    path: "/mushak6.1",
    icon: <FcKindle />,
    dropdown: [
      {
        key: "purchases",
        label: "Purchases",
        path: "/purchases",
        icon: <FcShop />,
      },
      {
        key: "supplyChain",
        label: "Supplier",
        path: "/supplyChain",
        icon: <FcCollaboration />,
      },
      {
        key: "production",
        label: "Production",
        path: "/production-list",
        icon: <FcSoundRecordingCopyright />,
      },
      {
        key: "M-61-PDF",
        label: "M-6.1 PDF",
        path: "/mushak-6-1",
        icon: <FcPrint />,
      },
    ]
  },
  {
    key: "mushak6.2",
    label: "Mushak-6.2",
    path: "/mushak6.2",
    icon: <FcNook />,
    dropdown: [
      {
        key: "salesorder",
        label: "Sales Order",
        path: "/sales-order",
        icon: <FcShipped />,
      },
      {
        key: "customer",
        label: "Customer",
        path: "/customer-list",
        icon: <FcPortraitMode />,
      },
      {
        key: "M-62-PDF",
        label: "M-6.2 PDF",
        path: "/mushak-6-2",
        icon: <FcPrint />,
      },
      // Add more dropdown items as needed
    ]
  },
  {
    key: "mushak6.3",
    label: "Mushak-6.3",
    path: "/mushak6.3",
    icon: <FcKindle />,
    dropdown: [
      {
        key: "M-63-PDF",
        label: "M-6.3 List",
        path: "/mushak-6-3-list",
        icon: <FcBarChart />,
      },
      {
        key: "M-63-PDF",
        label: "M-6.3 PDF",
        path: "/mushak-6-3",
        icon: <FcPrint />,
      },
      // Add more dropdown items as needed
    ]
  },

  {
    key: "mushak6.4",
    label: "Mushak-6.4",
    path: "/mushak6.4",
    icon: <FcKindle />,
    dropdown: [
      {
        key: "M-64-PDF",
        label: "C.Production",
        path: "/contractual-production-list",
        icon: <FcLink />,
      },
      // Add more dropdown items as needed
    ]
  },

  {
    key: "mushak6.5",
    label: "Mushak-6.5",
    path: "/mushak6.5",
    icon: <FcKindle />,
    dropdown: [
      {
        key: "M-65-PDF",
        label: "M-6.5 List",
        path: "/mushak-6-5-list",
        icon: <FcLineChart />,
      },
      // Add more dropdown items as needed
    ]
  },

  {
    key: "mushak6.6",
    label: "Mushak-6.6",
    path: "/mushak6.6",
    icon: <FcKindle />,
    dropdown: [
      {
        key: "M-66-PDF",
        label: "M-6.6 List",
        path: "/mushak-6-6-list",
        icon: <FcLineChart />,
      },
      // Add more dropdown items as needed
    ]
  },

  {
    key: "mushak6.7",
    label: "Mushak-6.7",
    path: "/mushak6.7",
    icon: <FcKindle />,
    dropdown: [
      {
        key: "M-67-PDF",
        label: "M-6.7 List",
        path: "/mushak-6-7-list",
        icon: <FcLineChart />,
      },
      // Add more dropdown items as needed
    ]
  },

  {
    key: "mushak6.8",
    label: "Mushak-6.8",
    path: "/mushak6.8",
    icon: <FcKindle />,
    dropdown: [
      {
        key: "M-68-PDF",
        label: "M-6.8 List",
        path: "/mushak-6-8-list",
        icon: <FcLineChart />,
      },
      // Add more dropdown items as needed
    ]
  },

  {
    key: "mushak9.1",
    label: "Mushak-9-1",
    path: "/mushak9.1",
    icon: <FcKindle />,
    dropdown: [
      {
        key: "M-91-PDF",
        label: "M-9.1 List",
        path: "/mushak-9-1-list",
        icon: <FcLineChart />,
      },
      {
        key: "taxreturndeclarant",
        label: "Tax Return Declarant",
        path: "/tax-return-declarant-list",
        icon: <FcBusinessman />,
      },
      {
        key: "accountcode",
        label: "Account Code",
        path: "/account-code-list",
        icon: <FcLibrary />,
      },

      {
        key: "aoafori",
        label: "AOA-For Increasing",
        path: "/any-other-adjustments-for-increasing-list",
        icon: <FcBullish />,
      },
      
      {
        key: "aoaford",
        label: "AOA-For Decreasing",
        path: "/any-other-adjustments-for-decreasing-list",
        icon: <FcBearish />,
      },
      {
        key: "dueToVatDASFTSD",
        label: "Due To Vat DASFTSD",
        path: "/due-to-vat-deducted-at-source-from-the-supplies-delivered-list",
        icon: <FcDribbble />,
      },

      {
        key: "advancetaxpaidAIS",
        label: "Advance Tax paid AIS",
        path: "/advance-tax-paid-at-import-stage-list",
        icon: <FcStatistics />,
      },
      
    ]
  },

  {
    key: "mushak4.3",
    label: "Mushak-4.3",
    path: "/mushak4.3",
    icon: <FcKindle />,
    dropdown: [
      {
        key: "production",
        label: "Mushak 4.3 List",
        path: "/mushak-4-3-list",
        icon: <FcNeutralTrading />,
      },
    ]
  },
];
