import DashboardLayout from "../Shared/DashboardLayout";
// import CreateUserForm from "../components/CreateUserForm";
import UserListTable from "../components/UserListTable";
const Users = () => {
  return (
    <DashboardLayout>
          <UserListTable />
    </DashboardLayout>
  );
};

export default Users;
