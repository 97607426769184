import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { baseUrl } from '../../BaseUrl';

function ProductionCreateForm() {
  const navigate = useNavigate()
  const [selectedInventory, setSelectedInventory] = useState(null);
  const [productQuantities, setProductQuantities] = useState([{ productId: '', quantity: '' }]);
  const [searchedProducts, setSearchedProducts] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [rawMaterial, setRawMaterial] = useState([]);
  const [inventory, setInventory] = useState([]);
  const [quantity, setQuantity] = useState("");
  const [status, setStatus] = useState(null);

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
  });

  const statusOptions = [
    { value: 0, label: 'Ordered' },
    { value: 1, label: 'WIP' },
    { value: 2, label: 'Complete' },
  ];

  const fetchInventoryData = async () => {
    try {
      const jwtToken = localStorage.getItem("access");
      const res = await axios.get(`${baseUrl}/inventory/api/fg_list/`, {
        headers: {
          Authorization: `JWT ${jwtToken}`,
        },
      });
      if (res) {
        console.log("fetch inventory data", res.data.results);
        const finalData = res.data;
        setInventory(finalData);
      }
    } catch (error) {
      console.log("error from get inventory api", error);
    }
  };

  const fetchRawMaterialData = async () => {
    try {
      const jwtToken = localStorage.getItem("access");
      const res = await axios.get(`${baseUrl}/inventory/api/rm_list/`, {
        headers: {
          Authorization: `JWT ${jwtToken}`,
        },
      });
      if (res) {
        console.log("fetch raw material data", res.data);
        const finalData = res.data;
        setRawMaterial(finalData);
      }
    } catch (error) {
      console.log("error from get inventory api", error);
    }
  };

  useEffect(() => {
    fetchInventoryData();
    fetchRawMaterialData();
  }, []);

  
  const handleProductChange = (selectedOption, index) => {
    const updatedProductQuantities = [...productQuantities];
    updatedProductQuantities[index].productId = selectedOption.value;
    setProductQuantities(updatedProductQuantities);
  };

  const handleQuantityChange = (event, index) => {
    const updatedProductQuantities = [...productQuantities];
    updatedProductQuantities[index].quantity = event.target.value;
    setProductQuantities(updatedProductQuantities);
  };

  const handleStatusChange = (selectedOption) => {
    setStatus(selectedOption);
  };

  const handleAddMore = () => {
    setProductQuantities([...productQuantities, { productId: '', quantity: '' }]);
  };

  const shouldEnableAddMore = () => {
    const lastItem = productQuantities[productQuantities.length - 1];
    return lastItem.productId && lastItem.quantity;
  };

  const handleDelete = (index) => {
    // Prevent deleting the first raw material field (index 0)
    if (index === 0) {
      return;
    }
  
    const updatedProductQuantities = [...productQuantities];
    updatedProductQuantities.splice(index, 1);
    setProductQuantities(updatedProductQuantities);
  };

  const handleInventoryChange = (selectedOption) => {
    setSelectedInventory(selectedOption);
  };

  const handleSearchChange = (value) => {
    setSearchText(value);
  
    // Filter raw materials to exclude those that are already selected
    const filteredProducts = rawMaterial
      .filter((product) => !productQuantities.some((item) => item.productId === product.id))
      .filter((product) => product.name.toLowerCase().includes(value.toLowerCase()))
      .map((product) => ({ value: product.id, label: product.name }));
  
    setSearchedProducts(filteredProducts);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "quantity") {
      setQuantity(value);
    }
  };

  const handleSave = (event) => {
    event.preventDefault();

    const rawMaterialsData = productQuantities.reduce((acc, item) => {
      if (item.productId && item.quantity) {
        acc[item.productId] = parseInt(item.quantity); // Ensure quantity is parsed to an integer if necessary
      }
      return acc;
    }, {});
  
    const dataToSave = {
      inventory: selectedInventory.value, // Use 'value' to get the selected inventory's id
      quantity: parseInt(quantity), // Ensure quantity is parsed to an integer if necessary
      raw_materials: rawMaterialsData,
    };
  
    // You can send the dataToSave object to your backend for database storage here
    // Replace the console.log with an API call to your server
    saveDataToDatabase(dataToSave);
    setSelectedInventory(null);
    setQuantity('');
    setProductQuantities([{ productId: '', quantity: '' }]);
  };

  const saveDataToDatabase = (data) => {
    const jwtToken = localStorage.getItem("access");
    axios.post(`${baseUrl}/purchase/api/prod/`, data,{
      headers: {
        Authorization: `JWT ${jwtToken}`,
      },
    })
    .then((response) => {
      navigate("/production-list");
      Toast.fire({
        icon: 'success',
        title: 'Successfully Add',
      });
    })
    .catch((error) => {
      // console.error("Error saving data:", error);
      Toast.fire({
        icon: 'error',
        title: `Ops! ${error.message}`,
      });
    });
  };

  return (
    <div className='px-5 py-5'>
      <div className="flex min-w-xl ">
      <div className="bg-white p-8 rounded shadow-md">
        <h1 className="uppercase font-semibold text-xl mb-3">Production Create Form</h1> <hr />
        <form onSubmit={handleSave}>
        <div className='w-full max-w-lg mt-5'>
          <div class="md:flex md:items-center mb-2">
            <div class="w-[210px]">
              <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-5"  for="inline-full-name">
              Finished Good*
              </label>
            </div>
            <div class="w-[100%]">
            <Select
                value={selectedInventory} // Use the selectedInventory object directly
                options={inventory?.map(item => ({ value: item.id, label: item.name }))}
                onChange={handleInventoryChange}
                placeholder="Select inventory"
                required
              />
            </div>
          </div>
        </div>

        <div className='w-full max-w-lg mt-2'>
          <div class="md:flex md:items-center mb-2">
            <div class="w-[210px]">
              <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-5"  for="inline-full-name">
                  Quantity*
              </label>
            </div>
            <div class="w-[100%]">
              <input
                  type="number"
                  min="1"
                  name="quantity"
                  id="quantity"
                  value={quantity}
                  required
                  onChange={handleChange}
                  className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-blue-300"
                  placeholder="Enter quantity"
                />
            </div>
          </div>
        </div>

        <div className="w-full max-w-lg mt-2">
                <div className="md:flex md:items-center mb-2">
                  <div className="w-[210px]">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mt-3"
                      htmlFor="inline-full-name"
                    >
                      Status*
                    </label>
                  </div>
                  <div className="w-[100%]">
                    <Select
                      value={status}
                      options={statusOptions}
                      onChange={handleStatusChange}
                      placeholder="Select status"
                      required
                    />
                  </div>
                </div>
              </div>

        <div className='w-full max-w-xl mt-2'>
        <div className="md:flex align-top">
          <div class="w-[230px]">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mt-3" for="inline-full-name">
              Raw Material*
            </label>
          </div>
          <div class="w-[100%]">
            {productQuantities.map((item, index) => (
              <div class="flex flex-wrap -mx-3 mb-2">
                <div class="w-[38%] mb-2 mr-1 md:mb-0">
                <Select
                    value={item.productId ? { value: item.productId, label: rawMaterial.find(p => p.id === item.productId).name } : null}
                    options={searchedProducts}
                    onChange={(selectedOption) => handleProductChange(selectedOption, index)}
                    onInputChange={(value) => handleSearchChange(value)}
                    placeholder="Product"
                    required
                  />
                </div>

                <div class="w-[38%]  mb-2 mr-1 md:mb-0">
                  <input
                    className='appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-blue-300'
                    type="number"
                    min="0"
                    step="0.01"
                    value={item.quantity}
                    onChange={(e) => handleQuantityChange(e, index)}
                    placeholder="Enter quantity"
                    required
                  />
                </div>

                <div className='w-[10%]  mb-2 md:mb-0'>
                  {index === 0 ? (
                    // Render a placeholder element for the first row to prevent deletion
                    <div />
                  ) : (
                    // Render the delete button for all other rows
                    <button
                      className='border text-[#dc2626] font-bold py-2 px-4 rounded'
                      onClick={() => handleDelete(index)}
                    >
                      Delete
                    </button>
                  )}
                </div>
                
              </div>
            ))}
          </div>
        </div>
        <button
          className='bg-green-500 hover:bg-green-700 text-white p1-2 px-2 my-3 rounded'
          onClick={handleAddMore}
          disabled={!shouldEnableAddMore()} // Disable the button if no raw material field has a value
        >
          Add New
        </button>
        </div>

          <div className='w-full max-w-lg mt-5 text-right'>
            <button type='submit' className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-10 rounded'>Save</button>
          </div>
        </form>
      </div>
      </div>
    </div>
  );
}

export default ProductionCreateForm;
