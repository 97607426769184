import React from "react";
import { connect } from "react-redux";
import { checkAuthenticated,load_user } from "../redux/actions/auth";
import { Navigate, useLocation } from "react-router-dom";

const ProtectedRoutes = ({ isAuthenticated, children }) => {

const { pathname } = useLocation();
  if (isAuthenticated === false) {
      return <Navigate to="/login" state={{ path: pathname }} />;
    }
    return children;
  }

  const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
  });

export default connect(mapStateToProps, { load_user,checkAuthenticated })(ProtectedRoutes);



