import React, { useState } from 'react';
import axios from 'axios';
import Select from 'react-select';
import DashboardLayout from '../../Shared/DashboardLayout';
import { baseUrl } from '../../BaseUrl';
import { useEffect } from 'react';
import { AiFillDelete } from 'react-icons/ai';
import Swal from 'sweetalert2';
import { useNavigate, useParams } from 'react-router-dom';

const EditProduction = () => {
  const [formData, setFormData] = useState({
    inventory: null,
    fg_quantity: '',
    status: null,
    rawmaterial_set: [
      {
        raw_material: null,
        rm_quantity: '',
        amount_of_wastage: '',
      },
    ],
  });
  const { id } = useParams();
  const navigate = useNavigate()
  const [rawMaterialAddMoreRequiredMessage,setRawMaterialAddMoreRequiredMessage]= useState('')
  const [inventoryOptions, setInventoryOptions] = useState([]);
  const [selectedInventory, setSelectedInventory] = useState(null);
  const [rawMaterialOptions, setRawMaterialOptions] = useState([]);
  const [selectedRawMaterials, setSelectedRawMaterials] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [rawMaterialStock, setRawMaterialStock] = useState({});

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
  });

  const productionStatusOptions = [
    { value: 0, label: 'Ordered' },
    { value: 1, label: 'WIP' },
    { value: 2, label: 'Complete' },
  ];

  useEffect(() => {
    const fetchProductionData = async () => {
      try {
        const jwtToken = localStorage.getItem('access');
        const response = await axios.get(
          `${baseUrl}/purchase/api/production/${id}/`,
          {
            headers: {
              Authorization: `JWT ${jwtToken}`,
            },
          }
        );
        const existingData = response.data; 
        setFormData(existingData);

        // Set the selected values for Finished Good and Raw Material
        setSelectedInventory({
          value: existingData.inventory,
          label: existingData.inventory_name,
        });

        const selectedRawMaterials = existingData.rawmaterial_set.map((rawMaterialItem) => ({
          value: rawMaterialItem.raw_material,
          label: rawMaterialItem.raw_material_name,
        }));
        setSelectedRawMaterials(selectedRawMaterials);

        setSelectedStatus({
          value: existingData.status,
          label: productionStatusOptions.find((option) => option.value === existingData.status)?.label,
        });

        console.log(existingData)

      } catch (error) {
        console.error('Error fetching production data', error);
      }
    };
    fetchProductionData();
  }, [id]);

  const handleStatusChange = (selectedOption) => {
    // console.log('Status changed:', selectedOption);
    setSelectedStatus(selectedOption); 
    setFormData({ ...formData, status: selectedOption.value });
  };

  
  const handleInputChange = (e, index, type) => {
    const updatedData = { ...formData };

    if (type === 'raw_material_set') {
      updatedData.rawmaterial_set[index][e.target.name] = e.target.value;
    } else {
      updatedData[e.target.name] = e.target.value;
    }

    setFormData(updatedData);
  };

  useEffect(() => {
    const fetchInventories = async () => {
      try {
        const jwtToken = localStorage.getItem("access");
        const response = await axios.get(`${baseUrl}/inventory/api/inventory/`, {
          headers: {
            Authorization: `JWT ${jwtToken}`,
          },
        });

        // Filter items with type 'Finished Good'
        const finishedGoodInventories = response.data.results.filter(
          (item) => item.type_text === 'Finished Good'
        );

        setInventoryOptions(
          finishedGoodInventories.map((inventory) => ({
            value: inventory.id,
            label: inventory.name,
          }))
        );
      } catch (error) {
        console.error('Error fetching inventories', error);
      }
    };
    fetchInventories();
  }, []);

  const handleInventoryChange = (selectedOption) => {
    const inventoryId = selectedOption.value; // Assuming "value" holds the ID
    setSelectedInventory(selectedOption);
    setFormData({
      ...formData,
      inventory: inventoryId, // Set the ID of the selected inventory item
    });
  };

  useEffect(() => {
    const fetchRawMaterialData = async () => {
        try {
          const jwtToken = localStorage.getItem("access");
          const res = await axios.get(`${baseUrl}/inventory/api/rm_list/`, {
            headers: {
              Authorization: `JWT ${jwtToken}`,
            },
          });
    
          const rawMaterialData = res.data;
          // Filter out raw materials that are already selected
          const filteredRawMaterialData = rawMaterialData.filter((rawMaterial) => {
              const rawMaterialValue = rawMaterial.id;
              return !selectedRawMaterials.some(
              (selected) => selected.value === rawMaterialValue
              );
          });
  
          const options = filteredRawMaterialData.map((item) => ({
              value: item.id,
              label: item.name,
          }));
          setRawMaterialOptions(options);

          const stockInfo = {};
          rawMaterialData.forEach((rawMaterial) => {
            stockInfo[rawMaterial.id] = rawMaterial.current_stock;
          });
          setRawMaterialStock(stockInfo);

        } catch (error) {
          console.log("Error fetching raw material data", error);
        }
    };
    fetchRawMaterialData()   
  }, [selectedRawMaterials]);

  const handleRawMaterialChange = (selectedOption, index) => {
    const updatedFormData = { ...formData };
    updatedFormData.rawmaterial_set[index].raw_material = selectedOption.value;
    
    // Set the current stock for the selected raw material
    updatedFormData.rawmaterial_set[index].current_stock = rawMaterialStock[selectedOption.value];
    
    const updatedSelectedRawMaterials = [...selectedRawMaterials];
    updatedSelectedRawMaterials[index] = selectedOption;
  
    setSelectedRawMaterials(updatedSelectedRawMaterials);
    setFormData(updatedFormData);
  };

  const areAllRawMaterialFieldsFilled = () => {
    const newRawMaterialEntry = formData.rawmaterial_set[formData.rawmaterial_set.length - 1];
    return (
      newRawMaterialEntry.raw_material !== null &&
      newRawMaterialEntry.rm_quantity.trim() !== ''
    );
  };
  
  const handleAddRawMaterial = () => {
    if (areAllRawMaterialFieldsFilled()) {
        const newRawMaterialEntry = {
            raw_material: null,
            rm_quantity: '',
            amount_of_wastage: '',
          };
      setFormData({
        ...formData,
        rawmaterial_set: [...formData.rawmaterial_set, newRawMaterialEntry],
      });
  
      setSelectedRawMaterials([...selectedRawMaterials, formData.rawmaterial_set[formData.rawmaterial_set.length - 1].raw_material]);
      setRawMaterialAddMoreRequiredMessage('');
    } else {
        setRawMaterialAddMoreRequiredMessage("Please fill in all required raw material fields")
    }
  };

  const handleRemoveRawMaterial = (index) => {
    const rawmaterial_set = [...formData.rawmaterial_set];
    rawmaterial_set.splice(index, 1);
    setFormData({
      ...formData,
      rawmaterial_set,
    });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const jwtToken = localStorage.getItem("access");
      const response = await axios.put(
        `${baseUrl}/purchase/api/production/${id}/`,
        formData,  // Your form data
        {
          headers: {
            Authorization: `JWT ${jwtToken}`,
          },
        }
      );
        navigate("/production-list");
        Toast.fire({
          icon: 'success',
          title: 'Successfully Updated',
        });
      } catch (error) {
          if(error.response.data.detail){
              Toast.fire({
              icon: 'error',
              title: `${error.response.data.detail}`,
              });
          }else{
              console.error('Error saving data:', error);
          }

      }
  };


  return (
    <DashboardLayout>
    <div className="px-5 mt-5 mb-10 pb-10">
        <div className=" w-4/3">
        <div className="bg-white p-8 rounded shadow-xl shadow-blue-500/40 w-[90%] mx-auto">
            <form onSubmit={handleSubmit}>
            <div className="flex justify-between rounded border-b-[1px] mb-10 bg-slate-900">
                <div className="mx-5 my-auto">
                <h1 className="uppercase font-semibold text-xl text-[#fff]">Production Edit Form</h1>
                </div>
                <div className="my-2 mx-5">
                <button
                    type="submit"
                    className="w-[120px] bg-slate-700 hover:bg-slate-800 border text-white font-medium rounded-lg h-10"
                >
                    Submit
                </button>
                </div>
            </div>


              <div class="flex flex-wrap -mx-3 mb-2">

                  <div class="w-full md:w-4/4 px-3 mb-8">
                    <div className="flex  md:w-4/4 mx-5 ">
                      <div class="md:w-[15%] my-auto">
                      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-3"  for="inline-full-name">
                      Finished Good*
                      </label>
                      </div>
                      <div class="md:w-[85%]">
                      <Select
                          id="inventory"
                          name="inventory"
                          value={selectedInventory}
                          onChange={handleInventoryChange}
                          options={inventoryOptions}
                          required
                      />
                      </div>
                    </div>
                  </div>

                  <div class="w-full md:w-2/4 px-3 mb-8">
                    <div className="flex  md:w-4/4 mx-5 ">
                      <div class="md:w-[32%] my-auto">
                      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-3"  for="inline-full-name">
                          Quantity*
                      </label>
                      </div>
                      <div class="md:w-[68%]">
                      <input
                          type="number"
                          min="1"
                          name="fg_quantity"
                          id="fg_quantity"
                          value={formData.fg_quantity}
                          required
                          onChange={handleInputChange}
                          className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-blue-300"
                          placeholder="Enter quantity"
                          />
                      </div>
                    </div>
                  </div>

                  <div class="w-full md:w-2/4 px-3 mb-8">
                    <div className="flex  md:w-4/4 mx-5 ">
                      <div class="md:w-[25%] my-auto">
                      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-3"  for="inline-full-name">
                          Status*
                      </label>
                      </div>
                      <div class="md:w-[75%]">
                        <Select
                          id="status"
                          name="status"
                          value={selectedStatus}
                          onChange={handleStatusChange}
                          options={productionStatusOptions}
                        />
                      </div>
                    </div>
                  </div>
              </div>

                {formData.rawmaterial_set?.map((rawMaterial, index) => (
                <table className="table-auto w-100 sales-order-table ml-5 ">
                {index === 0 && (
                    <thead>
                    <tr>
                        <th className='inventory-th'>
                        <label
                            class="block uppercase tracking-wide  text-[12px] font-semibold"
                            for="grid-state"
                        >
                            Raw Material*
                        </label>
                        </th>
                        <th>
                        <label
                            class="block uppercase tracking-wide  text-[12px] font-semibold"
                            for="inline-full-name"
                        >
                            Quantity*
                        </label>
                        </th>
                        <th className='total-price-td'>
                        <label
                            class="block uppercase tracking-wide  text-[12px] font-semibold"
                            for="inline-full-name"
                        >
                            Amount of wastage
                        </label>
                        </th>
                        <th className="action-th"></th>
                    </tr>
                    </thead>
                )}
                <tbody>
                    <tr className="table-input-filed">
                    <td className="w-[40%] inventory-th">
                        <Select
                        id={`raw_material_${index}`}
                        name={`rawmaterial_set[${index}].raw_material`}
                        value={selectedRawMaterials[index]}
                        onChange={(selectedOption) => handleRawMaterialChange(selectedOption, index)}
                        options={rawMaterialOptions}
                        required
                    />
                    </td>
                    <td className="w-[25%]">
                        <input
                            type="number"
                            min='0'
                            step='0.01'
                            name="rm_quantity"
                            required
                            value={rawMaterial.rm_quantity}
                            onChange={(e) => handleInputChange(e, index, 'raw_material_set')}
                            placeholder="Enter quantity"
                        />
    
                    {formData.rawmaterial_set[index].current_stock && (
                      <div className="text-sm text-sky-500">
                        Current Stock: {formData.rawmaterial_set[index].current_stock}
                      </div>
                    )}
                    </td>
                    <td className="w-[25%] total-price-td">
                        <input
                            type="number"
                            min='0'
                            step='0.01'
                            name="amount_of_wastage"
                            value={rawMaterial.amount_of_wastage}
                            onChange={(e) => handleInputChange(e, index, 'raw_material_set')}
                            placeholder="Enter amount of wastage"
                        />
                    </td>

                    {index === 0 && (
                        <td className="w-[10%] action-td"></td>
                    )}
                    {index !== 0 && (
                        <td className="w-[10%] action-td">
                        {index !== 0 && (
                            <button
                            type="button"
                            onClick={() => handleRemoveRawMaterial(index)}
                            className="text-red-500"
                            >
                            <AiFillDelete className="text-xl text-red-500/100 hover:text-red-600 " />
                            </button>
                        )}
                        </td>
                    )}
                    </tr>
                </tbody>
                </table>
                ))}
            
              <div className="mb-3 ml-5">
                {rawMaterialAddMoreRequiredMessage && (
                    <span className='text-red-500 mt-2'>{rawMaterialAddMoreRequiredMessage} <br /> </span> 
                )}
                <button
                  type="button"
                  onClick={() => {
                    console.log('Add Product Button Clicked');
                    handleAddRawMaterial();
                  }}
                  className="bg-slate-800 hover:bg-slate-900 text-white p1-2 px-2 my-3 rounded"
                  title='All fields required for add more product'
                >
                  Add More
                </button>
                <hr />
              </div>        
            </form>
        </div>
        </div>
  </div>
  </DashboardLayout>
  );
};

export default EditProduction;
