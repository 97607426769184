import React, { useState } from 'react';
import axios from 'axios';
import Select from 'react-select';
import DashboardLayout from '../../Shared/DashboardLayout';
import { baseUrl } from '../../BaseUrl';
import { useEffect } from 'react';
import { AiFillDelete } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const MushakSixFiveAdd = () => {
  const [formData, setFormData] = useState({
    company:null,
    customer:null,
    challan_no:'',
    status: null,
    inventories: [
      {
        inventory_name: '',
        quantity: '',
        price_without_vat:'',
        price_with_vat:''
      },
    ],
  });

  const navigate = useNavigate()
  const [inventoryOptions, setInventoryOptions] = useState([]);
  const [selectedInventories, setSelectedInventories] = useState([]);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [unitPrice, setUnitPrice] = useState(null);
  const [inventoryRequiredMessage,setInventoryRequiredMessage]=useState('')

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
  });

  const productionStatusOptions = [
    { value: '0', label: 'Ordered' },
    { value: '1', label: 'WIP' },
    { value: '2', label: 'Complete' },
  ];

  const handleStatusChange = (selectedOption) => {
    setFormData({ ...formData, status: selectedOption.value });
  };


    const handleInputChange = (e, index, type) => {
      const updatedData = { ...formData };
    
      if (type === 'inventories') {
        updatedData.inventories[index][e.target.name] = e.target.value;
    
        if (e.target.name === 'quantity') {
          // Calculate the price_without_vat only if quantity changes
          const unitPrice = parseFloat(
            inventoryOptions.find((option) => option.value === updatedData.inventories[index].inventory_name)
              ?.unit_price
          );
          const quantity = parseFloat(e.target.value);
          if (!isNaN(unitPrice) && !isNaN(quantity)) {
            updatedData.inventories[index].price_without_vat = (unitPrice * quantity).toFixed(2);
          }
        }
      } else {
        updatedData[e.target.name] = e.target.value;
      }
    
      setFormData(updatedData);
    };
      


  useEffect(() => {
    const fetchInventories = async () => {
      try {
        const jwtToken = localStorage.getItem("access");
        const response = await axios.get(`${baseUrl}/inventory/api/inventory/`, {
          headers: {
            Authorization: `JWT ${jwtToken}`,
          },
        });

        setInventoryOptions(
          response.data.results.map((inventory) => ({
            value: inventory.name,
            label: inventory.name,
            unit_price:inventory.unit_price
          }))
        );
      } catch (error) {
        console.error('Error fetching inventories', error);
      }
    };
    fetchInventories();
  }, []);


    useEffect(() => {
      const fetchCompanies = async () => {
        try {
          const jwtToken = localStorage.getItem("access");
          const response = await axios.get(`${baseUrl}/coa/api/company/`, {
            headers: {
              Authorization: `JWT ${jwtToken}`,
            },
          });

          console.log('company',response.data.results)


          setCompanyOptions(
              response.data.results.map((company) => ({
              value: company.id,
              label: company.company_name,
            }))
          );

        } catch (error) {
          console.error('Error fetching inventories', error);
        }
      };
      fetchCompanies();
    }, []);


    useEffect(() => {
      const fetchCustomer = async () => {
        try {
          const jwtToken = localStorage.getItem("access");
          const response = await axios.get(`${baseUrl}/sales/api/customer/`, {
            headers: {
              Authorization: `JWT ${jwtToken}`,
            },
          });

          console.log('customer',response.data.results)


          setCustomerOptions(
              response.data.results.map((customer) => ({
              value: customer.id,
              label: `${customer.name} | ${customer.company_name}`,
            }))
          );

        } catch (error) {
          console.error('Error fetching inventories', error);
        }
      };
      fetchCustomer();
    }, []);


    const handleInventoryNameChange = (selectedOption, index) => {
      const updatedData = { ...formData };
      updatedData.inventories[index].inventory_name = selectedOption.value;
      updatedData.inventories[index].quantity = ''; // Reset quantity
      updatedData.inventories[index].price_without_vat = ''; // Reset price_without_vat
    
      setSelectedInventories((prevSelected) => {
        return [...prevSelected, selectedOption.value];
      });
    
      setFormData(updatedData);
    };

    const getAvailableInventoryOptions = (index) => {
      const allInventoryOptions = inventoryOptions.map(option => option.value);
      const selectedInventoryItems = formData.inventories
        .slice(0, index)
        .map(inventory => inventory.inventory_name);
      return allInventoryOptions.filter(
        item => !selectedInventoryItems.includes(item)
      ).map(item => ({
        value: item,
        label: item,
      }));
    };
  

  const handleCustomerChange = (selectedOption) => {
    const customerId = selectedOption.value; 
    setSelectedCustomer(selectedOption);
    setFormData({
      ...formData,
      customer: customerId, 
    });
  };

  const handleCompanyChange = (selectedOption) => {
    const companyId = selectedOption.value; // Assuming "value" holds the ID
    setSelectedCompany(selectedOption);
    setFormData({
      ...formData,
      company: companyId, // Set the ID of the selected company item
    });
  };


  const areAllFieldsFilled = () => {
    return formData.inventories.every((inventory) => {
      return inventory.inventory_name.trim() !== '' &&
      inventory.quantity.trim() !== ''&&
      inventory.price_without_vat.trim() !== '';
    });
  };
  
  const handleAddInventory = () => {
    if (areAllFieldsFilled()) {
      setFormData({
        ...formData,
          inventories: [
          ...formData.inventories,
          {
            inventory_name: '',
            quantity: '',
            price_without_vat:'',
            price_with_vat:''
          },
        ],
      });
      setInventoryRequiredMessage('')
    } else {
      setInventoryRequiredMessage("Please fill all required fields in the existing inventory before adding a new one.")
    }
  };
  

  const handleRemoveInventory = (index) => {
    const inventories = [...formData.inventories];
    inventories.splice(index, 1);
    setFormData({
      ...formData,
      inventories,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const jwtToken = localStorage.getItem("access");
      
      // Include unitPrice in the form data
      const formDataWithUnitPrice = {
        ...formData,
        price_without_vat: unitPrice * formData.quantity, // Calculate the total price here
      };
  
      const response = await axios.post(
        `${baseUrl}/purchase/api/goods-transfer/`,
        formDataWithUnitPrice, // Use the updated form data
        {
          headers: {
            Authorization: `JWT ${jwtToken}`,
          },
        }
      );
  
      navigate("/mushak-6-5-list");
      Toast.fire({
        icon: 'success',
        title: 'Successfully Add',
      });
    } catch (error) {
      if (error.response.data.detail) {
        Toast.fire({
          icon: 'error',
          title: `${error.response.data.detail}`,
        });
      } else {
        console.error('Error saving data:', error);
      }
    }
  };
  

console.log(unitPrice)
  return (
    <DashboardLayout>
    <div className="px-5 mt-5 mb-10 pb-10">
        <div className=" w-4/3">
        <div className="bg-white p-8 rounded shadow-xl shadow-blue-500/40 w-[90%] mx-auto">
            <form onSubmit={handleSubmit}>
            <div className="flex justify-between rounded border-b-[1px] mb-10 bg-slate-900">
                <div className="mx-5 my-auto">
                <h1 className="uppercase font-semibold text-xl text-[#fff]">transfer Goods of Central registered organization Add Form</h1>
                </div>
                <div className="my-2 mx-5">
                <button
                    type="submit"
                    className="w-[120px] bg-slate-700 hover:bg-slate-800 border text-white font-medium rounded-lg h-10"
                >
                    Submit
                </button>
                </div>
            </div>

            <div class="flex flex-wrap -mx-3 mb-2">


            <div class="w-full md:w-2/4 px-3 mb-8">
                  <div className="flex  md:w-4/4 mx-5 ">
                    <div class="md:w-[30%] my-auto">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-3"  for="inline-full-name">
                      Sender*
                    </label>
                    </div>
                    <div class="md:w-[70%]">
                    <Select
                            id="company"
                            name="company"
                            value={selectedCompany}
                            onChange={handleCompanyChange}
                            options={companyOptions}
                            required
                        />
                    </div>
                  </div>
                </div>

                <div class="w-full md:w-2/4 px-3 mb-8 border-r-[1px]">
                  <div className="flex  md:w-4/4 mx-5 ">
                    <div class="md:w-[30%] my-auto">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-3"  for="inline-full-name">
                    Recipient*
                    </label>
                    </div>
                    <div class="md:w-[70%]">
                        <Select
                            id="customer"
                            name="customer"
                            value={selectedCustomer}
                            onChange={handleCustomerChange}
                            options={customerOptions}
                            required
                        />
                    </div>
                  </div>
                </div>

                <div class="w-full md:w-4/4 px-3 mb-8">
                  <div className="flex  md:w-4/4 mx-5 ">
                    <div class="md:w-[14%] my-auto">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-3"  for="inline-full-name">
                    Challan No*
                    </label>
                    </div>
                    <div class="md:w-[86%]">
                        <input
                            type="number"
                            min="0"
                            name="challan_no"
                            id="challan_no"
                            value={formData.challan_no}
                            required
                            onChange={handleInputChange}
                            className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-blue-300"
                            placeholder="Enter challan no"
                        />
                    </div>
                  </div>
                </div>

              {/* <div class="w-full md:w-2/4 px-3 mb-8 border-r-[1px]">
                  <div className="flex  md:w-4/4 mx-5 ">
                    <div class="md:w-[30%] my-auto">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-3"  for="inline-full-name">
                        Status*
                    </label>
                    </div>
                    <div class="md:w-[70%]">
                        <Select
                            id="status"
                            name="status"
                            value={productionStatusOptions.find((option) => option.value === formData.status)}
                            onChange={handleStatusChange}
                            options={productionStatusOptions}
                            required
                        />
                    </div>
                  </div>
              </div> */}

            </div>
            
            {formData.inventories?.map((inv, index) => (
            <table className="table-auto w-100 sales-order-table ml-5 ">
            {index === 0 && (
                <thead>
                <tr>
                    <th className='inventory-th'>
                    <label
                        class="block uppercase tracking-wide  text-[12px] font-semibold"
                        for="grid-state"
                    >
                        Inventory Name*
                    </label>
                    </th>
                    <th>
                    <label
                        class="block uppercase tracking-wide  text-[12px] font-semibold"
                        for="inline-full-name"
                    >
                        Quantity*
                    </label>
                    </th>
                    <th>
                    <label
                        class="block uppercase tracking-wide  text-[12px] font-semibold"
                        for="inline-full-name"
                    >
                        Price Without Vat*
                    </label>
                    </th>

                    <th className='total-price-td'>
                    <label
                        class="block uppercase tracking-wide  text-[12px] font-semibold"
                        for="inline-full-name"
                    >
                        Price With Vat
                    </label>
                    </th>

                    <th className="action-th"></th>
                </tr>
                </thead>
            )}
            <tbody>
                <tr className="table-input-filed">
                <td className="w-[30%] inventory-th">
                <Select
                  value={inventoryOptions.find(option => option.value === inv.inventory_name)}
                  onChange={(selectedOption) => handleInventoryNameChange(selectedOption, index)}
                  options={getAvailableInventoryOptions(index)} // Use the filtered options
                  isSearchable
                  placeholder="Select Inventory"
                />

                </td>
                <td className="w-[20%]">
                  <input
                      type="number"
                      step='0.01'
                      min='0'
                      name="quantity"
                      id="quantity"
                      value={inv.quantity}
                      onChange={(e) => handleInputChange(e, index, 'inventories')}
                      required
                      className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-blue-300"
                      placeholder="Quantity"
                    />
                </td>

                <td className="w-[20%] ">
                  <input
                      type="text"
                      name="price_without_vat"
                      id="price_without_vat"
                      value={inv.price_without_vat}
                      required
                      readOnly
                      onChange={(e) => handleInputChange(e, index, 'inventories')}
                      className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-blue-300"
                      placeholder="price without VAT"
                    />
                </td>

                <td className="w-[20%] total-price-td">
                  <input
                      type="text"
                      name="price_with_vat"
                      id="price_with_vat"
                      value={inv.price_with_vat}
                      onChange={(e) => handleInputChange(e, index, 'inventories')}
                      className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-blue-300"
                      placeholder="price with VAT"
                    />
                </td>

                {index === 0 && (
                    <td className="w-[10%] action-td"></td>
                )}
                {index !== 0 && (
                    <td className="w-[10%] action-td">
                    {index !== 0 && (
                        <button
                        type="button"
                        onClick={() => handleRemoveInventory(index)}
                        className="text-red-500"
                        >
                        <AiFillDelete className="text-xl text-red-500/100 hover:text-red-600 " />
                        </button>
                    )}
                    </td>
                )}
                </tr>
            </tbody>
            </table>
            ))}
            
            <div className="mb-3 ml-5">
                {inventoryRequiredMessage && (
                    <span className='text-red-500 mt-2'>{inventoryRequiredMessage} <br /> </span> 
                )}
                <button
                  type="button"
                  onClick={() => {
                    console.log('Add Product Button Clicked');
                    handleAddInventory();
                  }}
                  className="bg-slate-800 hover:bg-slate-900 text-white p1-2 px-2 my-3 rounded"
                  title='All fields required for add more product'
                >
                  Add More
                </button>
                <hr />
            </div>
            </form>
        </div>
        </div>
    </div>
  </DashboardLayout>
  );
};

export default MushakSixFiveAdd;
