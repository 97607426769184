import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import DashboardLayout from '../../Shared/DashboardLayout'
import Swal from "sweetalert2";  
import { baseUrl } from '../../BaseUrl';

const CustomerEdit = () => {
    const navigate = useNavigate();
    const { id } = useParams();

    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })

    const [formData, setFormData] = useState({
        name: "",
        display_name: "",
        company_name: "",
        email: "",
        phone_no: "",
        address: "",
        bin_no: "",
        nid_no: ""
    });

    const [formErrors, setFormErrors] = useState({
      name: '',
      bin_no: '',
      nid_no: '',
    });

    useEffect(() => {
        // Fetch customer data based on the id and populate the form fields
        const fetchCustomerData = async () => {
            try {
                const jwtToken = localStorage.getItem("access");
                const response = await axios.get(`${baseUrl}/sales/api/customer/${id}/`, {
                    headers: {
                        Authorization: `JWT ${jwtToken}`,
                    },
                });

                const customerData = response.data; // Replace with your API response structure

                setFormData({
                    name: customerData.name || "",
                    display_name: customerData.display_name || "",
                    company_name: customerData.company_name || "",
                    email: customerData.email || "",
                    phone_no: customerData.phone_no || "",
                    address: customerData.address || "",
                    bin_no: customerData.bin_no || "",
                    nid_no: customerData.nid_no || ""
                });
            } catch (error) {
              Toast.fire({
                icon: 'error',
                title: `Ops! ${error.message}`
              })
            }
        };

        fetchCustomerData();
    }, [id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });

        // const [formErrors, setFormErrors] = useState({
        //   name: '',
        //   bin_no: '',
        //   nid_no: '',
        // });
    };

    const handleSubmit = async () => {
        try {
            const jwtToken = localStorage.getItem("access");
            
            if (!formData.name || !formData.bin_no || !formData.nid_no) {
              setFormErrors({
                name: !formData.name ? 'This field is required' : '',
                bin_no: !formData.bin_no ? 'This field is required' : '',
                nid_no: !formData.nid_no ? 'This field is required' : '',
              });
              return; // Don't submit the form
            }

            const response = await axios.put(`${baseUrl}/sales/api/customer/${id}/`, formData, {
                headers: {
                    Authorization: `JWT ${jwtToken}`,
                },
            });

            navigate("/customer-list");    
            Toast.fire({
              icon: 'success',
              title: 'Successfully Edit'
            })

        } catch (error) {     

          if(error.response.data.email){
            Toast.fire({
              icon: 'error',
              title: `Ops! ${error.response.data.email}`,
            });
           }else{
            Toast.fire({
              icon: 'error',
              title: `Ops! ${error.messages}`,
            });
           }
        }
    };

    return (
        <DashboardLayout>
        <div className="p-5">
        <div className="flex ">
        <div className="bg-white p-8 rounded shadow-xl">
        <h1 className="uppercase font-semibold text-xl mb-3">Customer Edit Form</h1> <hr />  
            
    
        <div className='w-full max-w-lg mt-2'>
          <div class="md:flex md:items-center mb-2">
            <div class="w-[250px]">
              <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-5"  for="inline-full-name">
              Name*
              </label>
            </div>
            <div class="w-[49%] mx-1">
            <input
                  type="text"
                  name="name"
                  id="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="block w-full rounded-md border-0 pl-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="Name*"
                />
                <p className="text-red-500 text-xs mt-1">{formErrors.name}</p>
            </div>
            <div class="w-[49%] mx-1">
            <input
                  type="text"
                  name="display_name"
                  id="display_name"
                  value={formData.display_name}
                  onChange={handleChange}
                  className="block w-full rounded-md border-0 pl-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="Display Name"
                />
            </div>
          </div>
        </div>
    
        <div className='w-full max-w-lg mt-2'>
          <div class="md:flex md:items-center mb-2">
            <div class="w-[250px]">
              <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-5"  for="inline-full-name">
              Company Name
              </label>
            </div>
            <div class="w-[99%] mx-1">
              <input
                  type="text"
                  name="company_name"
                  id="company_name"
                  value={formData.company_name}
                  onChange={handleChange}
                  className="block w-full rounded-md border-0 pl-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="Company Name"
                />
            </div>
          </div>
        </div>
    
        <div className='w-full max-w-lg mt-2'>
          <div class="md:flex md:items-center mb-2">
            <div class="w-[250px]">
              <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-5"  for="inline-full-name">
              Contact Information
              </label>
            </div>
            <div class="w-[49%] mx-1">
              <input
                type="email"
                name="email"
                id="email"
                value={formData.email}
                onChange={handleChange}
                className="block w-full rounded-md border-0 pl-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="Email"
              />
            </div>
            <div class="w-[49%] mx-1">
            <input
                type="text"
                onChange={handleChange}
                value={formData.phone_no}
                name="phone_no"
                id="phone_no"
                className="block w-full rounded-md border-0 pl-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="Phone number.."
              />
            </div>
          </div>
        </div>
        <div className='w-full max-w-lg mt-2'>
          <div class="md:flex md:items-center mb-2">
            <div class="w-[250px]">
              <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-5"  for="inline-full-name">
                Address
              </label>
            </div>
            <div class="w-[100%]">
            <input
                type="text"
                onChange={handleChange}
                value={formData.address}
                name="address"
                id="address"
                className="block w-full rounded-md border-0 pl-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder=" Enter address.."
              />
            </div>
          </div>
        </div>
    
        <div className='w-full max-w-lg mt-2'>
          <div class="md:flex md:items-center mb-2">
            <div class="w-[250px]">
              <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-5"  for="inline-full-name">
                BIN No*
              </label>
            </div>
            <div class="w-[100%]">
              <input
                  type="text"
                  onChange={handleChange}
                  value={formData.bin_no}
                  name="bin_no"
                  id="bin_no"
                  className="block w-full rounded-md border-0 pl-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="Enter bin no*"
                />
                <p className="text-red-500 text-xs mt-1">{formErrors.bin_no}</p>
            </div>
          </div>
        </div>
    
        <div className='w-full max-w-lg mt-2'>
          <div class="md:flex md:items-center mb-2">
            <div class="w-[250px]">
              <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-5"  for="inline-full-name">
                NID NO*
              </label>
            </div>
            <div class="w-[100%]">
            <input
                type="text"
                onChange={handleChange}
                value={formData.nid_no}
                name="nid_no"
                id="nid_no"
                className="block w-full rounded-md border-0 pl-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="Enter nid no*"
              />
              <p className="text-red-500 text-xs mt-1">{formErrors.nid_no}</p>
            </div>
          </div>
        </div>
        
        <div className="w-full max-w-lg mt-2 text-right">
          <button
            // type="submit"
            onClick={handleSubmit}
            className="w-[150px] bg-sky-500 border text-white font-medium rounded-lg h-10"
          >
            Submit
          </button>
        </div>
    
      </div>
      </div>
      </div>
      </DashboardLayout>
    )
}

export default CustomerEdit;
