import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from 'sweetalert2';
import { baseUrl } from "../BaseUrl";

const InventoryEditForm = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [total_price, setTotal_price] = useState("");
  const [inventory, setInventory] = useState({
    uom: "",
    name: "",
    unit_price: "",
    current_stock: "",
    total_price: "",
    type: "",
    product_hscode:"",
  });

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
  });

  useEffect(() => {
    async function fetchInventoryData() {
      try {
        // Replace this with your API endpoint to fetch inventory details by ID
        const jwtToken = localStorage.getItem("access");
        const response = await axios.get(
          `${baseUrl}/inventory/api/inventory/${id}/`,
          {
            headers: {
              Authorization: `JWT ${jwtToken}`,
            },
          }
        );

        if (response.data) {
          setInventory(response.data);
        }
      } catch (error) {
        console.error(error);
        // Handle error gracefully (e.g., show an error message)
      }
    }

    fetchInventoryData();
  }, [id]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInventory({
      ...inventory,
      [name]: value,
    });
  };

  // Handle UOM dropdown
  const [isUOMOpen, setIsUOMOpen] = useState(false);
  const [selectedUOMOption, setSelectedUOMOption] = useState(null);
  const togglingUOM = () => setIsUOMOpen(!isUOMOpen);

  const onOptionClickedUOM = (value) => () => {
    setSelectedUOMOption(value.name);
    setInventory({
      ...inventory,
      uom: value.id, // Assuming 'uom' is the state variable you want to update
    });
    setIsUOMOpen(false);
  };

  // Handle Type dropdown
  const [isTypeOpen, setIsTypeOpen] = useState(false);
  const [selectedTypeOption, setSelectedTypeOption] = useState(null);
  const togglingType = () => setIsTypeOpen(!isTypeOpen);

  const onOptionClickedType = (value) => () => {
    setSelectedTypeOption(value.name);
    setInventory({
      ...inventory,
      type: value.id, // Assuming 'type' is the state variable you want to update
    });
    setIsTypeOpen(false);
  };

  // Initialize selectedUOMOption and selectedTypeOption with the values from inventory
  useEffect(() => {
    setSelectedUOMOption(
      uomList.find((option) => option.id === inventory.uom)?.name || `${
        inventory.uom === 1?'Pieces':
        inventory.uom === 2?'Gram':
        inventory.uom === 3?'Meter':
        inventory.uom === 4?'Capacity':''
      }`
    );

    setSelectedTypeOption(
      typeList.find((option) => option.id === inventory.type)?.name ||
      `${
        inventory.type === 1?'Finished Good':
        inventory.type === 2?'Raw Material':''
      }`
    );
  }, [inventory.uom, inventory.type]);

  const current_stock = inventory.current_stock
  const unit_price = inventory.unit_price
  
  // create inventory price calculation....
  function calculateTotal() {
    const numberCurrentStock = parseFloat(current_stock);
    const numberUnitePrice = parseFloat(unit_price);
    const total = parseFloat(numberUnitePrice * numberCurrentStock);
    return total;
  }
  
  useEffect(() => {
    if (current_stock && unit_price) {
      const total = calculateTotal(current_stock, unit_price);
      setTotal_price(total);
    }
  }, [current_stock, unit_price]);


  const handleSubmit = async (event) => {
    event.preventDefault()
    try {
      // Replace this with your API endpoint to update the inventory item by ID
      const jwtToken = localStorage.getItem("access");
      const res = await axios.put(
        `${baseUrl}/inventory/api/inventory/${id}/`,
        inventory,
        {
          headers: {
            Authorization: `JWT ${jwtToken}`,
          },
        }
      );

      if (res) {
        navigate('/inventory');
        Toast.fire({
          icon: 'success',
          title: 'Successfully Updated',
        });
      }
    } catch (error) {
      if(error.response.data.name){
        Toast.fire({
          icon: 'error',
          title: `Ops! ${error.response.data.name}`,
        });
       }else{
        Toast.fire({
          icon: 'error',
          title: `Ops! ${error.messages}`,
        });
       }
      if (error.response.data.code === "token_not_valid") {
        alert("Token expired. Please log in again.");
        navigate("/login");
      }
    }
  };


  // Define your UOM and Type dropdown options
  const typeList = [
    { id: "1", name: "Finished Good" },
    { id: "2", name: "Raw Material" },
  ];

  const uomList = [
    { id: "1", name: "Pieces" },
    { id: "2", name: "Gram" },
    { id: "3", name: "Meter" },
    { id: "4", name: "Capacity" },
  ];

  return (
    <div className="p-5">
      <div className="w-2/4 ">
      <div className="bg-white p-8 rounded shadow-xl">
      <div className="flex justify-between rounded border-b-[1px] mb-10 bg-slate-900">
          <div className="mx-5 my-auto py-3">
          <h1 className="uppercase font-semibold text-xl text-[#fff]">Inventory Edit Form</h1>
          </div>
      </div>
      <form onSubmit={handleSubmit}>
      
      <div className='w-full max-w-lg mt-2'>
        <div class="md:flex md:items-center ">
          <div class="w-[250px]">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-5"  for="inline-full-name">
              Name*
            </label>
          </div>
            <div class="w-full mx-1">
            <input
                type="text"
                name="name"
                id="name"
                value={inventory.name}
                onChange={handleChange}
                className="block w-full rounded-md border-0 pl-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="Enter Name"
                required
              />
            </div>
        </div>
      </div>

      <div className='w-full max-w-lg mt-2'>
        <div class="md:flex md:items-center ">
          <div class="w-[250px]">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-5"  for="inline-full-name">
              Product HS Code*
            </label>
          </div>
            <div class="w-full mx-1">
            <input
                type="text"
                name="product_hscode"
                id="product_hscode"
                value={inventory.product_hscode}
                onChange={handleChange}
                className="block w-full rounded-md border-0 pl-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="Enter product hs code"
                required
              />
            </div>
        </div>
      </div>

      <div class="flex flex-wrap -mx-3 mb-2 mt-1">
        <div class="w-full md:w-2/4 px-3 md:mb-0">
        <label
            htmlFor="uom"
            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-2"
          >
            UOM*
          </label>

          <div className="relative mt-1 inline-flex w-full rounded-md border bg-white">
            <a
              onClick={togglingUOM}
              className="w-[100%] rounded-l-md px-4 py-2 text-sm text-gray-600 no-underline hover:bg-gray-50 hover:text-gray-700"
            >
              {selectedUOMOption || "Select UOM"}
            </a>
            <div className="relative">
              <button
                type="button"
                className={`button-${isUOMOpen ? "danger" : "success"} hover:text-gray-700 inline-flex h-full items-center justify-center rounded-r-md border-l border-gray-100 px-2 text-gray-600 hover:bg-gray-50`}
                onClick={togglingUOM}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className={
                    (isUOMOpen ? "content show" : "content", "h-4 w-4")
                  }
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
                
              </button>
            </div>
            {isUOMOpen && (
              <div className="absolute top-6 right-0 z-10 mt-4 w-full origin-top-right rounded-md border border-gray-100 bg-white shadow-lg">
                {uomList.map((option) => (
                  <div key={option.id}>
                    <a
                      onClick={onOptionClickedUOM(option)}
                      className="block rounded-lg px-4 py-2 text-sm text-gray-500 no-underline hover:bg-gray-50 hover:text-gray-700"
                    >
                      {option.name}
                    </a>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        <div class="w-full md:w-2/4 px-3 mb-2 md:mb-0">
        <label
            htmlFor="uom"
            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-2"
          >
            Type*
          </label>
          <div className="relative mt-1 inline-flex w-full rounded-md border bg-white">
            <a
              onClick={togglingType}
              className="w-[100%] rounded-l-md px-4 py-2 text-sm text-gray-600 no-underline hover:bg-gray-50 hover:text-gray-700"
            >
              {selectedTypeOption || "Select Type"}
            </a>
            <div className="relative">
              <button
                type="button"
                className={`button-${isTypeOpen ? "danger" : "success"} hover:text-gray-700 inline-flex h-full items-center justify-center rounded-r-md border-l border-gray-100 px-2 text-gray-600 hover:bg-gray-50`}
                onClick={togglingType}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className={
                    (isTypeOpen ? "content show" : "content", "h-4 w-4")
                  }
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
                
              </button>
            </div>
            {isTypeOpen && (
              <div className="absolute top-6 right-0 z-10 mt-4 w-full origin-top-right rounded-md border border-gray-100 bg-white shadow-lg">
                {typeList.map((option) => (
                  <div key={option.id}>
                    <a
                      onClick={onOptionClickedType(option)}
                      className="block rounded-lg px-4 py-2 text-sm text-gray-500 no-underline hover:bg-gray-50 hover:text-gray-700"
                    >
                      {option.name}
                    </a>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className='w-full max-w-lg'>
          <div class="md:flex md:items-center mb-2">
            <div class="w-[240px]">
              <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-5" for="inline-full-name">
              Unit Price*
              </label>
            </div>
            <div class="w-[100%]">
            <input
                type="number"
                step='0.01'
                min='1'
                value={inventory.unit_price}
                name="unit_price"
                id="unit_price"
                onChange={handleChange}
                className="block w-full rounded-md border-0 pl-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="Enter your Current Stock.."
                required
              />
            </div>
          </div>
        </div>

        <div className='w-full max-w-lg'>
          <div class="md:flex md:items-center mb-2">
            <div class="w-[240px]">
              <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-5" for="inline-full-name">
              Current Stock*
              </label>
            </div>
            <div class="w-[100%]">
            <input
                type="number"
                step='0.01'
                min='0'
                value={inventory.current_stock}
                name="current_stock"
                id="current_stock"
                onChange={handleChange}
                className="block w-full rounded-md border-0 pl-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="Enter your Current Stock.."
                required
              />
            </div>
          </div>
        </div>

        <div className='w-full max-w-lg'>
          <div class="md:flex md:items-center mb-2">
            <div class="w-[240px]">
              <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-5" for="inline-full-name">
              Total Price*
              </label>
            </div>
            <div class="w-[100%]">
            <input
                type="text"
                step='0.01'
                value={total_price}
                name="total_price"
                id="totalPrice"
                onChange={handleChange}
                className="block w-full rounded-md border-0 pl-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder=" Enter Total Price.."
                required
              />
            </div>
          </div>
        </div>
      
        <div className="mt-5">
          <button
            type="submit"
            className="w-[150px] bg-sky-500 border text-white font-medium rounded-lg h-10"
          >
            Submit
          </button>
        </div>
       </form>           
    </div>
    </div>
    </div>
  );
};

export default InventoryEditForm;
