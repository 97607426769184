// eslint-disable-next-line no-unused-vars
import React from "react";
import DashboardLayout from "../Shared/DashboardLayout";
import PurchaseDetailTable from "../components/PurchaseDetailTable";

const PurchasesDetailPage = () => {
  return (
    <DashboardLayout>
      <PurchaseDetailTable />
    </DashboardLayout>
  );
};

export default PurchasesDetailPage;
