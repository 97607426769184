import axios from 'axios';
import React, { useState, useEffect } from 'react';
import DashboardLayout from '../../Shared/DashboardLayout';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { baseUrl } from '../../BaseUrl';
import { AiFillDelete } from 'react-icons/ai';

const SalesOrderEdit = () => {

  const [formData, setFormData] = useState([
    {
      customer: null,
      inventory: null,
      vehicle: null,
      rate: null,
      sales_type: '',
      sales_quantity: '',
      challan: '',
      unit_price: '',
      total_price_no_vat: '',
      supplementary_duty: '',
      status: '0',
      sales_nature_type: '',
      payment_method_type: '',
    },
  ]);

  const { chalan } = useParams();
  const [customers, setCustomers] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [rates, setRates] = useState([]);
  const [inventories, setInventories] = useState([]);
  const navigate = useNavigate();
  const [selectedInventoryUnitPrice, setSelectedInventoryUnitPrice] = useState(null);
  const [max_quantity, setMaxQuantity] = useState([]);
  const [productCurrentStock, setProductCurrentStock] = useState([]);
  const [fetchedData, setFetchedData] = useState([]);
  
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
  });

  const salesTypeOptions = [
    { value: '1', label: 'LOCAL' },
    { value: '2', label: 'DIRECT EXPORT' },
    { value: '3', label: 'DEEMED EXPORT' },
  ];

  const orderStatusOptions = [
    { value: '0', label: 'Ordered' },
    { value: '1', label: 'WIP' },
    { value: '2', label: 'Complete' },
  ];

  const getStatusName = (statusValue) => {
    const statusMap = {
      '0': 'Ordered',
      '1': 'WIP',
      '2': 'Complete',
    };
    return statusMap.hasOwnProperty(statusValue) ? statusMap[statusValue] : 'Ordered';
  };
  
  const getSalesTypeName = (salesTypeValue) => {
    const salesTypeMap = {
      '1': 'LOCAL',
      '2': 'DIRECT EXPORT',
      '3': 'DEEMED EXPORT',
      // Add more values if needed
    };
    return salesTypeMap[salesTypeValue] || '';
  };

  const paymentMethodTypeOptions = [
    { value: 1, label: 'Cash' },
    { value: 2, label: 'Banking' },
  ];

  const salesNatureTypeOptions = [
    { value: 1, label: 'Zero Rated Goods/Service' },
    { value: 2, label: 'Exempted Goods/Service' },
    { value: 3, label: 'Standard Rated Goods/Service' },
    { value: 4, label: 'Goods Based on MRP' },
    { value: 5, label: 'Goods/Service Based on Specific VAT' },
    { value: 6, label: 'Goods/Service Other than Standard Rate' },
    { value: 7, label: 'Retail/Whole Sale/Trade Based Supply' },
    { value: 8, label: 'Goods/Service Not Admissible for Credit(Local Purchase)' },
    { value: 9, label: 'Goods/Service Not Admissible for Credit (Taxpayers who sell only Exempted/Specific VAT and Goods/Service Other than Standard Rate/Credits not taken' },
  ];
  

  const fetchMoshukData = async () => {
    const jwtToken = localStorage.getItem("access");
  
    try {
      const res = await axios.post(`${baseUrl}/sales/api/line_items/`, {
        challan: `${chalan}`
      }, {
        headers: {
          Authorization: `JWT ${jwtToken}`,
          'Content-Type': 'application/json'
        }
      });
  
      if (res) {
        const updatedFormData = res.data.map((orderData) => {
          // Calculate total price for each order
          const quantity = orderData.sales_quantity || 0;
          const unitPrice = orderData.unit_price || 0;
          orderData.total_price = (quantity * unitPrice).toString();
  
          // Set productCurrentStock based on fetched data
          const selectedInventoryItem = inventories.find((inventory) => {
            return inventory.id === orderData.inventory;
          });
          orderData.productCurrentStock = selectedInventoryItem
            ? selectedInventoryItem.current_stock
            : null;
  
          return orderData;
        });
  
        setFormData(updatedFormData);
        setFetchedData(updatedFormData);
        console.log(updatedFormData);
      }
    } catch (error) {
      console.log(error);
    }
  };
  

  useEffect(() => {
    fetchMoshukData();
  }, []);


  useEffect(() => {
    // Fetch customer data
    const fetchCustomers = async () => {
      try {
        const jwtToken = localStorage.getItem("access");
        const response = await axios.get(`${baseUrl}/sales/api/customer/`, {
          headers: {
            Authorization: `JWT ${jwtToken}`,
          },
        });
        setCustomers(response.data.results);
      } catch (error) {
        console.error('Error fetching customers', error);
      }
    };

    // Fetch inventory data
    const fetchInventories = async () => {
      try {
        const jwtToken = localStorage.getItem("access");
        const response = await axios.get(`${baseUrl}/inventory/api/inventory/`, {
          headers: {
            Authorization: `JWT ${jwtToken}`,
          },
        });
        const finishedGoodInventories = response.data.results.filter(
          (item) => item.type_text === 'Finished Good'
        );
        setInventories(finishedGoodInventories);
        // console.log(response.data.results);
      } catch (error) {
        console.error('Error fetching inventories', error);
      }
    };

    fetchCustomers();
    fetchInventories();
  }, []); 

  useEffect(() => {
      const fetchRateData = async () => {
          try {
              const jwtToken = localStorage.getItem("access");
              const response = await axios.get(`${baseUrl}/coa/api/rate/`, {
                  headers: {
                      Authorization: `JWT ${jwtToken}`,
                  },
              });
              setRates(response.data.results);
              // console.log(response.data.results)
          } catch (error) {
              
          }
      };

      fetchRateData();
  }, []);

  useEffect(() => {
    const fetchVehicleData = async () => {
        try {
            const jwtToken = localStorage.getItem("access");
            const response = await axios.get(`${baseUrl}/sales/api/vehicle/`, {
                headers: {
                    Authorization: `JWT ${jwtToken}`,
                },
            });
            setVehicles(response.data.results);
            console.log(response.data.results)
        } catch (error) {
        }
    };

    fetchVehicleData();
  }, []);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const updatedFormData = [...formData];
    updatedFormData[index][name] = value;
  
    // Calculate total price whenever sales_quantity or unit_price changes
    if (name === 'sales_quantity' || name === 'unit_price') {
      const quantity = updatedFormData[index].sales_quantity || 0;
      const unitPrice = updatedFormData[index].unit_price || 0;
      updatedFormData[index].total_price = (quantity * unitPrice).toString();
    }
  
    setFormData(updatedFormData);
  };

  const handleSalesTypeChange = (selectedOption, index) => {
    const updatedFormData = [...formData];
    updatedFormData.forEach((orderData) => {
      orderData['sales_type'] = selectedOption ? selectedOption.value : '';
    });
    setFormData(updatedFormData);
  };

  const handleOrderStatusChange = (selectedOption, index) => {
    const updatedFormData = [...formData];
    updatedFormData.forEach((orderData) => {
      orderData['status'] = selectedOption ? selectedOption.value : '';
    });
    setFormData(updatedFormData);
  };

  const handleCustomerChange = (selectedOption, index) => {
    const updatedFormData = [...formData];
    updatedFormData.forEach((orderData) => {
      orderData['customer'] = selectedOption ? selectedOption.value : null;
    });
    setFormData(updatedFormData);
  };

  const handleRatesChange = (selectedOption, index) => {
    const updatedFormData = [...formData];
    updatedFormData.forEach((orderData) => {
      orderData['rate'] = selectedOption ? selectedOption.value : null;
    });
    setFormData(updatedFormData);
  };

  const handleVahicleChange = (selectedOption, index) => {
    const updatedFormData = [...formData];
    updatedFormData.forEach((orderData) => {
      orderData['vehicle'] = selectedOption ? selectedOption.value : null;
    });
    setFormData(updatedFormData);
  };

  const handleInventoryChange = (selectedOption, index) => {
    const selectedInventoryItem = inventories.find(
      (inventory) => inventory.id === selectedOption.value
    );
  
    const updatedFormData = [...formData];
    updatedFormData[index] = {
      ...updatedFormData[index],
      inventory: selectedOption ? selectedOption.value : null,
      unit_price: selectedInventoryItem ? selectedInventoryItem.unit_price : null,
    };
    setFormData(updatedFormData);
    // Update the current stock for the selected product
    const updatedCurrentStock = [...productCurrentStock];
    updatedCurrentStock[index] = selectedInventoryItem ? selectedInventoryItem.current_stock : null;
    setProductCurrentStock(updatedCurrentStock);
  };
  
  const handleSalesNatureTypeChange = (selectedOption, index) => {
    const updatedFormData = [...formData];
    updatedFormData[index].sales_nature_type = selectedOption ? selectedOption.value : '';
    setFormData(updatedFormData);
  };

const handlePaymentMethodTypeChange = (selectedOption, index) => {
  const updatedFormData = [...formData];
  updatedFormData[index].payment_method_type = selectedOption ? selectedOption.value : '';
  setFormData(updatedFormData);
};


  const areAllFieldsFilled = (index) => {
    const order = formData[index];
    // console.log("Order:", order); // Log the order object
    return (
      order?.customer &&
      order?.rate &&
      order?.vehicle &&
      order?.inventory &&
      order?.sales_type &&
      order?.sales_quantity &&
      order?.challan &&
      order?.unit_price
      // order.total_price_no_vat &&
      // order.supplementary_duty &&
      // order?.status
    );
  };

  const handleAddOrder = () => {
    // Check if all fields in the last order are filled
    const lastOrder = formData[formData.length - 1];
    if (areAllFieldsFilled(formData.length - 1)) {
      const newOrder = {
        customer: lastOrder.customer || customers[0].value,
        vehicle: lastOrder.vehicle || vehicles[0].value,
        rate: lastOrder.rate || rates[0].value,
        inventory: null,
        sales_type: lastOrder.sales_type || salesTypeOptions[0].value,
        sales_quantity: '',
        challan: lastOrder.challan || '',
        unit_price: '',
        total_price_no_vat: '',
        supplementary_duty: '',
        status: lastOrder.status || orderStatusOptions[0].value,
      };
      setFormData((prevFormData) => [...prevFormData, newOrder]); // Use the previous state
    } else {
      Toast.fire({
        icon: 'info',
        title: 'Please fill in all required fields before adding a new product.',
      });
    }
  };

  const handleDeleteOrder = (indexToDelete) => {
    setFormData((prevFormData) => {
      const updatedFormData = prevFormData.filter((_, index) => index !== indexToDelete);
      return updatedFormData;
    });
  };

  const handleSubmit = async (e, index) => {
    e.preventDefault();
    try {
      const jwtToken = localStorage.getItem("access");

      // Create an array to hold promises for each order submission
      const submissionPromises = formData.map(async (orderData) => {
        const formDataSubmit = {
          ...orderData,
          unit_price: selectedInventoryUnitPrice,
          sales_nature_type: orderData.sales_nature_type,
          payment_method_type: orderData.payment_method_type,
        };

        // Use axios to make the POST request for each order
        if(orderData.id){
          await axios.put(`${baseUrl}/sales/api/sales_order/${orderData.id}/`, formDataSubmit, {
            headers: {
              Authorization: `JWT ${jwtToken}`,
            },
          });
        }else{
          await axios.post(`${baseUrl}/sales/api/sales_order/`, formDataSubmit, {
            headers: {
              Authorization: `JWT ${jwtToken}`,
            },
          });
        }
      });

      // Wait for all order submissions to complete
      await Promise.all(submissionPromises);

      const deletionPromises = fetchedData.map(async (fData) => {
        if (!formData.some((orderData) => orderData.id === fData.id)) {
          await axios.delete(`${baseUrl}/sales/api/sales_order/${fData.id}/`, {
            headers: {
              Authorization: `JWT ${jwtToken}`,
            },
          });
        }
      });
  
      // Wait for all deletions to complete
      await Promise.all(deletionPromises);

      // Optionally, reset the form fields
      // Reset the form fields after successful submission
      navigate('/sales-order');
      Toast.fire({
        icon: 'success',
        title: 'Successfully Updated',
      });
    } catch (error) {
      if (error.response.data.detail) {
        Toast.fire({
          icon: 'error',
          title: `Ops! ${error.response.data.detail}`,
        });
        setMaxQuantity(error.response.data.detail);
      } else if (error.response.data.sales_quantity) {
        Toast.fire({
          icon: 'error',
          title: `Ops! ${error.response.data.sales_quantity}`,
        });
        setMaxQuantity(error.response.data.sales_quantity);
      } else {
        Toast.fire({
          icon: 'error',
          title: `Ops! ${error.message}`,
        });
      }
    }
  };






  return (
    <DashboardLayout>
      <div className="px-5 mt-5 mb-10 ">
        <div className=" w-4/3">
          <div className="bg-white p-8 rounded shadow-xl shadow-blue-500/40 w-[90%] mx-auto">
            <form onSubmit={handleSubmit}>
              <div className="flex justify-between rounded border-b-[1px] mb-10 bg-slate-900">
                <div className="mx-5 my-auto">
                  <h1 className="uppercase font-semibold text-xl text-[#fff]">Sales Order Edit Form</h1>
                </div>
                <div className="my-2 mx-5">
                  <button
                    type="submit"
                    className="w-[120px] bg-slate-700 hover:bg-slate-800 border text-white font-medium rounded-lg h-10"
                  >
                   Submit
                  </button>
                </div>
              </div>

              <div class="flex flex-wrap -mx-3 mb-2">
                <div class="w-full md:w-4/4 px-3 mb-8">
                  <div className="flex  md:w-4/4 mx-5 ">
                    <div class="md:w-[16%] my-auto">
                      <label
                        class="block uppercase tracking-wide  text-[12px] font-semibold mb-2"
                        for="grid-city"
                      >
                        Customer*
                      </label>
                    </div>
                    <div class="md:w-[90%]">
                      <Select
                        required
                        id="customer"
                        name="customer"
                        value={
                          formData[0]?.customer
                            ? {
                                value: formData[0]?.customer,
                                label: customers.find((customer) => customer.id === formData[0]?.customer)?.name,
                              }
                            : null
                        }
                        onChange={(selectedOption) => handleCustomerChange(selectedOption, 0)}
                        options={customers.map((customer) => ({
                          value: customer.id,
                          label: customer.name,
                        }))}
                      />
                    </div>
                  </div>
                </div>
                


                <div class="w-full md:w-2/4 px-3 mb-8 border-r-[1px]">
                  <div className="flex md:w-4/4 mx-5">
                    <div class="md:w-[36%] my-auto">
                      <label
                        class="block uppercase tracking-wide  text-[12px] font-semibold mb-2"
                        for="grid-state"
                      >
                        Challan*
                      </label>
                    </div>
                    <div class="md:w-3/4">
                      <input
                        type="number"
                        name="challan"
                        id="challan"
                        value={formData[0]?.challan}
                        required
                        onChange={(e) => handleInputChange(e, 0)} // Pass index
                        className="appearance-none rounded w-full px-3  leading-tight focus:outline-none focus:shadow-outline focus:border-blue-300"
                        placeholder="Enter Chalan number"
                      />
                    </div>
                  </div>
                </div>

                <div class="w-full md:w-2/4 px-3 mb-8">
                    <div className="flex md:w-4/4 mx-5">
                      <div class="md:w-[36%] my-auto">
                        <label
                          class="block uppercase tracking-wide  text-[12px] font-semibold mb-2"
                          for="grid-city"
                        >
                          Payment Method*
                        </label>
                      </div>
                      <div class="md:w-3/4">
                        <Select
                          required
                          id="payment_method_type"
                          name="payment_method_type"
                          value={paymentMethodTypeOptions.find((option) => option.value === formData[0].payment_method_type)}
                          onChange={(selectedOption) => handlePaymentMethodTypeChange(selectedOption, 0)} // Implement this function
                          options={paymentMethodTypeOptions}
                        />
                      </div>
                    </div>
                </div>

                <div class="w-full md:w-2/4 px-3 mb-8 border-r-[1px]">
                  <div className="flex  md:w-4/4 mx-5">
                    <div class="md:w-[36%] my-auto">
                      <label
                        class="block uppercase tracking-wide  text-[12px] font-semibold mb-2"
                        for="grid-city"
                      >
                        Sales Type*
                      </label>
                    </div>
                    <div class="md:w-3/4">
                    <Select
                        required
                        id="sales_type"
                        name="sales_type"
                        value={
                          formData[0]?.sales_type
                            ? {
                                value: formData[0].sales_type,
                                label: getSalesTypeName(formData[0].sales_type),
                              }
                            : null
                        }
                        onChange={(selectedOption) => handleSalesTypeChange(selectedOption, 0)} // Pass index
                        options={salesTypeOptions}
                      />
                    </div>
                  </div>
                </div>

                <div class="w-full md:w-2/4 px-3 mb-8">
                  <div className="flex  md:w-4/4 mx-5">
                    <div class="md:w-[36%] my-auto">
                      <label
                        class="block uppercase tracking-wide  text-[12px] font-semibold mb-2"
                        for="grid-state"
                      >
                        Order Status*
                      </label>
                    </div>
                    <div class="md:w-3/4">
                    <Select
                      id="status"
                      name="status"
                      value={
                        formData[0]?.status
                          ? { value: formData[0]?.status, label: getStatusName(formData[0]?.status) }
                          : { value: '0', label: 'Ordered' } // Default to "Ordered"
                      }
                      onChange={(selectedOption) => handleOrderStatusChange(selectedOption, 0)}
                      options={orderStatusOptions}
                    />

                    </div>
                  </div>
                </div>

                <div class="w-full md:w-2/4 px-3 mb-8 border-r-[1px]">
                  <div className="flex  md:w-4/4 mx-5">
                    <div class="md:w-[36%] my-auto">
                      <label
                        class="block uppercase tracking-wide  text-[12px] font-semibold mb-2"
                        for="grid-city"
                      >
                        Rate*
                      </label>
                    </div>
                    <div class="md:w-3/4">
                      <Select
                        required
                        id="rate"
                        name="rate"
                        value={
                          formData[0]?.rate
                            ? {
                                value: formData[0].rate,
                                label: rates.find((rate) => rate.id === formData[0].rate)?.rate_name,
                              }
                            : null
                        }
                        onChange={(selectedOption) => handleRatesChange(selectedOption, 0)}
                        options={rates.map((rate) => ({
                          value: rate.id,
                          label: rate.rate_name,
                        }))}
                      />
                    </div>
                  </div>
                </div>

                <div class="w-full md:w-2/4 px-3 mb-8">
                  <div className="flex  md:w-4/4 mx-5 ">
                    <div class="md:w-[36%] my-auto">
                      <label
                        class="block uppercase tracking-wide  text-[12px] font-semibold mb-2"
                        for="grid-city"
                      >
                        Vahicle*
                      </label>
                    </div>
                    <div class="md:w-3/4">
                      <Select
                        required
                        id="vehicle"
                        name="vehicle"
                        value={
                          formData[0]?.vehicle
                            ? {
                                value: formData[0].vehicle,
                                label: vehicles.find((vehicle) => vehicle.id === formData[0].vehicle)?.vehicle_name,
                              }
                            : null
                        }
                        onChange={(selectedOption) => handleVahicleChange(selectedOption, 0)}
                        options={vehicles.map((vehicle) => ({
                          value: vehicle.id,
                          label: vehicle.vehicle_name,
                        }))}
                      />
                    </div>
                  </div>
                </div>

                <div class="w-full md:w-4/4 px-3 mb-8">
                <div className="flex md:w-4/4 mx-5">
                  <div class="md:w-[15%] my-auto">
                    <label
                      class="block uppercase tracking-wide  text-[12px] font-semibold mb-2"
                      for="grid-city"
                    >
                      Sales Nature* 
                    </label>
                  </div>
                  <div class="md:w-[85%]">
                    <Select
                      required
                      id="sales_nature_type" 
                      name="sales_nature_type" 
                      value={salesNatureTypeOptions.find((option) => option.value === formData[0].sales_nature_type)} 
                      onChange={(selectedOption) => handleSalesNatureTypeChange(selectedOption, 0)}
                      options={salesNatureTypeOptions} 
                    />
                  </div>
                </div>
                </div>


              </div>
              
              {/* <div className='ml-5 mr-5 mb-4'>
              <p className='text-[16px] font-semibold border-b-[1px]'>Product Information </p>
              </div> */}

              <hr className='mb-6 mx-5' />

              {formData.map((order, index) => (
                <table className="table-auto w-100 sales-order-table ml-5 ">
                  {index === 0 && (
                    <thead>
                      <tr>
                        <th className='inventory-th'>
                          <label
                            class="block uppercase tracking-wide  text-[12px] font-semibold"
                            for="grid-state"
                          >
                            Inventory*
                          </label>
                        </th>
                        <th>
                          <label
                            class="block uppercase tracking-wide  text-[12px] font-semibold"
                            for="inline-full-name"
                          >
                            Quantity*
                          </label>
                        </th>
                        <th>
                          <label
                            class="block uppercase tracking-wide  text-[12px] font-semibold"
                            for="inline-full-name"
                          >
                            Unit Price*
                          </label>
                        </th>
                        <th className='total-price-td' >
                          <label
                            class="block uppercase tracking-wide  text-[12px] font-semibold"
                            for="inline-full-name"
                          >
                            Total Price*
                          </label>
                        </th>
                        <th className="action-th"></th>
                      </tr>
                    </thead>
                  )}
                  <tbody>
                    <tr className="table-input-filed">
                      <td className="w-[40%] inventory-th">
                      <Select
                        required
                        id={`inventory-${index}`} // Ensure unique IDs for each Select component
                        name="inventory"
                        className="appearance-none rounded w-full px-3 leading-tight focus:outline-none focus:shadow-outline focus:border-blue-300"
                        value={
                          formData[index].inventory
                            ? {
                                value: formData[index].inventory,
                                label: inventories.find(
                                  (inventory) => inventory.id === formData[index].inventory
                                )?.name,
                              }
                            : null
                        }
                        onChange={(selectedOption) => handleInventoryChange(selectedOption, index)}
                        options={inventories
                          .filter(
                            (inventory) =>
                              !formData.some((order) => order.inventory === inventory.id)
                          )
                          .map((inventory) => ({
                            value: inventory.id,
                            label: inventory.name,
                          }))}
                      />
                      </td>
                      <td className="w-[20%]">
                        <input
                          type="number"
                          step="0.01"
                          min="0"
                          name="sales_quantity"
                          id="sales_quantity"
                          value={order?.sales_quantity}
                          required
                          onChange={(e) => handleInputChange(e, index)}
                          className="appearance-none rounded w-full px-3  leading-tight focus:outline-none focus:shadow-outline focus:border-blue-300"
                          placeholder="Enter quantity"


                        />
                        {formData[index].inventory && (
                          <>
                            {productCurrentStock[index] !== null && (
                              <span className="text-gray-500 text-[12px]">
                                Current Stock: {productCurrentStock[index]}
                                {formData[index]?.sales_quantity > productCurrentStock[index] ? (
                                  <p className="text-red-500 text-[12px]">{max_quantity}</p>
                                ) : (
                                  <></>
                                )}
                              </span>
                            )}
                          </>
                        )}
                      </td>
                      <td className="w-[15%]">
                        <input
                          type="text"
                          name="unit_price"
                          id="unit_price"
                          value={formData[index]?.unit_price || ''} // Use formData[index].unit_price
                          onChange={(e) => handleInputChange(e, index)}
                          className="appearance-none rounded w-full px-3  leading-tight focus:outline-none focus:shadow-outline focus:border-blue-300"
                          required
                          placeholder="Unit price"
                        />
                      </td>
                      <td className="w-[15%] total-price-td">
                        <input
                          type="text"
                          name="total_price"
                          id="total_price"
                          value={formData[index]?.total_price}
                          readOnly
                          className="appearance-none rounded w-full px-3  leading-tight focus:outline-none focus:shadow-outline focus:border-blue-300"
                          placeholder="Total Price"
                        />
                      </td>

                      {index !== 0 && (
                        <td className="w-[10%] action-td">
                          {index !== 0 && (
                            <button
                              type="button"
                              onClick={() => handleDeleteOrder(index)}
                              className="text-red-500"
                            >
                              <AiFillDelete className="text-xl text-red-500/100 hover:text-red-600 " />
                            </button>
                          )}
                        </td>
                      )}
                    </tr>
                  </tbody>
                </table>
              ))}

              <div className="mb-3 mt-5 ml-5">
                <button
                  type="button"
                  onClick={() => {
                    console.log('Add Product Button Clicked');
                    handleAddOrder();
                  }}
                  className="bg-slate-800 hover:bg-slate-900 text-white p1-2 px-2 my-3 rounded"
                  // disabled={!areAllFieldsFilled(0)}
                  title='All fields required for add more product'
                >
                  Add More
                </button>
                <hr />
              </div>
              
            </form>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default SalesOrderEdit;
