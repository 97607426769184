import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useReactToPrint } from "react-to-print";
import { Link, useParams } from "react-router-dom";
import { format } from "date-fns";
import { baseUrl } from "../../BaseUrl";
import { useNavigate  } from "react-router-dom";


const MushakSixFour = () => {
  const componentPDF = useRef();
  const navigate = useNavigate();

  const pdfGeneretor = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: "Mushak-6.4",
    pageStyle: `
      @page landscape {
        size: A4 landscape;
        margin: 20mm 10mm;
      }
      @page portrait {
        size: A4 portrait;
        margin: 20mm 10mm;
      }
      @page {
        margin: 4mm;
      }
      body {
        margin: 0;
      }
    `,
    contentStyle: `
      @page landscape {
        transform: rotate(0deg) translate(0, 0);
      }
      @page portrait {
        transform: rotate(90deg) translate(0, 0);
      }
    `
  });

  const { id } = useParams();
  const [data, setData] = useState(null);
  const [companyData, setCompanyData] = useState([]);
  const jwtToken = localStorage.getItem("access");
  

  useEffect(() => {
    const fetchMoshukData = async () => {
        try {
            const jwtToken = localStorage.getItem("access");
            const response = await axios.get(`${baseUrl}/purchase/api/production/${id}/`, {
                headers: {
                    Authorization: `JWT ${jwtToken}`,
                },
            });

            setData(response.data);
            console.log(response.data)
        } catch (error) {
          if(error.response.status === 401){
            navigate("/login");; 
          }
          else{
            console.log(error)
          }
        }
    };
    fetchMoshukData();
}, [id]);




    useEffect(() => {
        const fetchcompanyData = async () => {
            try {
                const jwtToken = localStorage.getItem("access");
                const response = await axios.get(`${baseUrl}/coa/api/company/`, {
                    headers: {
                        Authorization: `JWT ${jwtToken}`,
                    },
                });

                setCompanyData(response.data.results);
                console.log(response.data)
            } catch (error) {
            }
        };

        fetchcompanyData();
    }, []);



  const formatDate = (dateString) => {
    try {
      const date = new Date(dateString);
      return format(date, "dd-M-yyyy");
    } catch (error) {
      return "...";
    }
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);

    // Get the hours and minutes
    const hours = date.getHours();
    const minutes = date.getMinutes();
  
    // Determine whether it's AM or PM
    const amOrPm = hours >= 12 ? 'PM' : 'AM';
  
    // Convert hours to 12-hour format
    const formattedHours = hours % 12 || 12;
  
    // Format the time in "hh:mm AM/PM" format without seconds
    const formattedTime = `${formattedHours}:${minutes} ${amOrPm}`;
  
    return formattedTime;
  };
  
  let incrementalId = 1;

  return (
    <div className=" ">
      <div className="flex justify-between my-4 px-5 shadow-lg pb-4">
            <Link className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" to='/contractual-production-list'> Back Contractual Production List</Link>
            <button
              onClick={pdfGeneretor}
              className="bg-green-600 w-[150px] hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
            >
              Download pdf
            </button>

      </div>

      <div
        ref={componentPDF}
        style={{ width: "99%", height: "100%" }}
        className="flex flex-col mx-auto"
      >
        <div className="pdf-head">
          <div className="top-header my-3">
          <p className="text-right m-2">মূসক - ৬.৪</p>
            <p className="text-center">গণপ্রজাতন্তী বাংলাদেশ সরকার, জাতীয় রাজস্ব বোর্ড</p>
            <h3 className="text-center font-bold">চুক্তিভিত্তিক উৎপানদের চালানপত্র</h3>
            <p className="text-center">[বিধি ৪০ এর উপ-বিধি (১) এর দফা (ঘ ) দ্রষ্টব্য]</p>
            <p>
              <table className="mx-auto">
              
              {companyData?.[0] && (
                <>
                <tr >
                    <td className="pr-2 text-[14px]">নিবন্ধিত ব্যক্তির নাম</td>
                    <td>:</td>
                    <td className="pl-1 text-[14px]">{companyData[0]?.company_name}</td>
                  </tr>
                  <tr >
                    <td className="pr-2 text-[14px]">নিবন্ধিত ব্যক্তির বিআইএন</td>
                    <td>:</td>
                    <td className="pl-1 text-[14px]">{companyData[0]?.vin}</td>
                  </tr>
                  <tr >
                    <td className="pr-2 text-[14px]">চালানপত্র ইস্যুর ঠিকানা </td>
                    <td>:</td>
                    <td className="pl-1 text-[14px]">
                    {companyData[0]?.address && (
                          <div>
                            {companyData[0]?.address.split(' ').map((word, index, wordsArray) => (
                              <span key={index}>
                                {word}
                                {' '}
                                {index % 4 === 3 && index !== wordsArray.length - 1 && <br />}
                              </span>
                            ))}
                          </div>
                        )}
                    </td>
                  </tr>
                </>
              )}

              </table>
            </p>
          </div>

          <div className="mushak3-bottom-header flex justify-between mx-3 my-4">
              <div className="left-header">
              <table className="">
                  <>
                <tr >                 
                  <td className="pr-3 text-[14px]">পণ্য গ্রহীতার নাম</td>
                  <td>:</td>
                  <td className="pl-3 text-[14px]">{data?.name_of_contracting_company_text}</td>
                </tr>
                <tr >
                  <td className="pr-3 text-[14px]">গ্রহীতার বিআইএন</td>
                  <td>:</td>
                  <td className="pl-3 text-[14px]">{data?.contracting_company_bin}</td>
                </tr>
                <tr >
                  <td className="pr-3 text-[14px]">গন্তব্যস্থল</td>
                  <td>:</td>
                  <td className="pl-3 text-[14px]">{data?.contracting_company_address}</td>
                </tr>
                  </>
            </table>
              </div>
              <div className="left-header">

              <table className="">
                <>
                <tr >
                  <td className="pr-3 text-[14px]">চালানপত্র নম্বর</td>
                  <td>:</td>
                  <td className="pl-3 text-[14px]">{data?.challan_no}</td>
                </tr>
                <tr >
                  <td className="pr-3 text-[14px]">ইস্যুর তারিখ </td>
                  <td>:</td>
                  <td className="pl-3 text-[14px]">{formatDate(data?.last_updated)}</td>
                </tr>
                <tr >
                  <td className="pr-3 text-[14px]">ইস্যুর সময় </td>
                  <td>:</td>
                  <td className="pl-3 text-[14px]">{formatTime(data?.last_updated)}</td>
                </tr>
                </>
            </table>
              </div>
          </div>
          
        </div><hr />

        <div className="mushak-3-pdf">
        <table className="min-w-full">
            <thead className=" bg-gray-50 text-[12px] font-medium">
              <tr>
                <th>ক্রমিক</th>
                <th className="w-[20%]">প্রকৃতি (উপকরণ বা উৎপাদিত পণ্য)</th>
                <th>পণ্যের বিবরণ</th>
                <th>পরিমান</th>
                <th>মন্তব্য  </th>
              </tr>

          <tr>
            <th>(১)</th>
            <th>(২)</th>
            <th>(৩)</th>
            <th>(৪)</th>
            <th>(৫)</th>
          </tr>

            </thead>

            <tbody>
              {data?.rawmaterial_set?.map((rmData,index)=>(
                <tr className="text-[12px]" key={rmData.id}>             
                  <td>{incrementalId++}</td>
                  <td>{rmData.uom_text}</td>
                  <td>{rmData.raw_material_name}</td>
                  <td>{rmData.rm_quantity}</td>
                  <td></td>       
              </tr>
                ))}

            </tbody>
          </table>
        </div>
      </div>
    
    </div>
  );


};

export default MushakSixFour;
