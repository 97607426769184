import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useReactToPrint } from "react-to-print";
import { Link, useNavigate, useParams } from "react-router-dom";

import { format } from "date-fns";
import { baseUrl } from "../../BaseUrl";


const MushakSixSeven = () => {
  const componentPDF = useRef();
  const navigate = useNavigate();
  const pdfGeneretor = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: "Mushak-6.7",
    pageStyle: `
      @page landscape {
        size: A4 landscape;
        margin: 20mm 10mm;
      }
      @page portrait {
        size: A4 portrait;
        margin: 20mm 10mm;
      }
      @page {
        margin: 4mm;
      }
      body {
        margin: 0;
      }
    `,
    contentStyle: `
      @page landscape {
        transform: rotate(0deg) translate(0, 0);
      }
      @page portrait {
        transform: rotate(90deg) translate(0, 0);
      }
    `
  });

  const [moshukData, setMoshukData] = useState([]);
  const {id} =useParams()
  const [totalItemPriceWithVatSum, setTotalItemPriceWithVatSum] = useState(0);
  const [companyData, setCompanyData] = useState([]);

  const fetchMoshukData = async () => {
    const jwtToken = localStorage.getItem("access");

    try {
      const res = await axios.get(`${baseUrl}/sales/api/credit-note/${id}`, {
        headers: {
          Authorization: `JWT ${jwtToken}`,
        },
      });
      if (res) {

        setMoshukData(res.data);
        console.log(res.data)

      }
    } catch (error) {
      if(error.response.status === 401){
        navigate("/login");; 
      }
      else{
        console.log(error)
      }
    }
  };

  useEffect(() => {
    fetchMoshukData();
  }, [id]);

   
   useEffect(() => {
       const fetchcompanyData = async () => {
           try {
               const jwtToken = localStorage.getItem("access");
               const response = await axios.get(`${baseUrl}/coa/api/company/`, {
                   headers: {
                       Authorization: `JWT ${jwtToken}`,
                   },
               });
 
               setCompanyData(response.data.results);
               // console.log(response.data.results)
           } catch (error) {
           }
       };
 
       fetchcompanyData();
   }, []);


  const [totalSumWithoutVat, setTotalSumWithoutVat] = useState(0.0);
  useEffect(() => {
    // Calculate the total sum based on moshukData
    const sum = moshukData.credititem_set?.reduce((total, mData) => {
      const productValue = parseFloat(mData.sales_inventory_unit_price || 0) * parseFloat(mData.return_quantity || 0);
      return total + productValue;
    }, 0.0);
  
    // Update the total sum in the state
    setTotalSumWithoutVat(sum);
  }, [moshukData]);


  const [totalVat, setTotalVat] = useState(0.0);
  useEffect(() => {
    // Calculate the total sum based on moshukData
    const sum = moshukData.credititem_set?.reduce((total, mData) => {
      const productValue = parseFloat(mData.sales_total_vat || 0) / parseFloat(mData.sales_quantity || 0) * parseFloat(mData.return_quantity || 0);
      return total + productValue;
    }, 0.0);
  
    // Update the total sum in the state
    setTotalVat(sum);
  }, [moshukData]);

  const [totalSD, setTotalSD] = useState(0.0);
  useEffect(() => {
    // Calculate the total sum based on moshukData
    const sum = moshukData.credititem_set?.reduce((total, mData) => {
      const productValue = parseFloat(mData.sales_total_sd || 0) / parseFloat(mData.sales_quantity || 0) * parseFloat(mData.return_quantity || 0);
      return total + productValue;
    }, 0.0);
  
    // Update the total sum in the state
    setTotalSD(sum);
  }, [moshukData]);

  console.log(totalSD)
  

  const formatDate = (dateString) => {
    try {
      const date = new Date(dateString);
      return format(date, "dd-M-yyyy");
    } catch (error) {
      return "...";
    }
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);

    // Get the hours and minutes
    const hours = date.getHours();
    const minutes = date.getMinutes();
  
    // Determine whether it's AM or PM
    const amOrPm = hours >= 12 ? 'PM' : 'AM';
  
    // Convert hours to 12-hour format
    const formattedHours = hours % 12 || 12;
  
    // Format the time in "hh:mm AM/PM" format without seconds
    const formattedTime = `${formattedHours}:${minutes} ${amOrPm}`;
  
    return formattedTime;
  };


  let incrementalId = 1;

  return (
    <div className="">
      <div className="flex justify-between my-4 px-5 shadow-lg pb-4">
            <Link className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" to='/mushak-6-7-list'> Back To Mushak 6.7 List</Link>
            
            <button
              onClick={pdfGeneretor}
              className="bg-green-600 w-[150px] hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
            >
              Download pdf
            </button>

      </div>

      <div
        ref={componentPDF}
        style={{ width: "99%", height: "100%" }}
        className="flex flex-col mx-auto "
      >
        <div className="pdf-head mb-4">
          <div className="top-header my-3"> 
            <p className="text-right m-2">মূসক - ৬.৭</p>
            <p className="text-center">গণপ্রজাতন্তী বাংলাদেশ সরকার</p>
            <p className="text-center">জাতীয় রাজস্ব বোর্ড</p>
            <h3 className="text-center font-bold">ক্রেডিট নোট</h3>
            <p className="text-center">[বিধি ৪০ এর উপ-বিধি (১) এর দফা (ছ) দ্রষ্টব্য]</p>
          </div>
        </div>

        <div className="flex justify-between my-5">
          <div>
              <table>
                <tr>
                      <td className="pr-2 text-[14px]">ফেরত প্রদানকারী ব্যক্তির</td>
                </tr>
                <tr>
                      <td className="pr-2 text-[14px]">নাম</td>
                      <td>:</td>
                      <td className="pl-1 text-[14px]">{moshukData?.credititem_set?.[0]?.customer_name}</td>
                </tr>
                <tr>
                      <td className="pr-2 text-[14px]">বি আই এন</td>
                      <td>:</td>
                      <td className="pl-1 text-[14px]">{moshukData?.credititem_set?.[0]?.customer_bin}</td>
                </tr>
                <tr>
                      <td className="pr-2 text-[14px]">মূল চালানপত্র নাম্বার</td>
                      <td>:</td>
                      <td className="pl-1 text-[14px]">{moshukData?.challan}</td>
                </tr>
                <tr>
                      <td className="pr-2 text-[14px]">মূল চালানপত্র ইস্যুর তারিখ</td>
                      <td>:</td>
                      <td className="pl-1 text-[14px]">{formatDate(moshukData?.credititem_set?.[0]?.sales_date)}</td>
                </tr>
              </table>
          </div>
          <div>
            <table>
                  <tr>
                        <td className="pr-2 text-[14px]">ফেরত গ্রহনকারী ব্যক্তির :- </td>
                  </tr>
                  <tr>
                        <td className="pr-2 text-[14px]">নাম</td>
                        <td>:</td>
                        <td className="pl-1 text-[14px]">{companyData[0]?.company_name}</td>
                  </tr>
                  <tr>
                        <td className="pr-2 text-[14px]">বি আই এন</td>
                        <td>:</td>
                        <td className="pl-1 text-[14px]">{companyData[0]?.vin}</td>
                  </tr>
                  <tr>
                        <td className="pr-2 text-[14px]">ক্রেডিট নোট নাম্বার</td>
                        <td>:</td>
                        <td className="pl-1 text-[14px]">{moshukData?.id}</td>
                  </tr>
                  <tr>
                        <td className="pr-2 text-[14px]">ইস্যুর তারিখ</td>
                        <td>:</td>
                        <td className="pl-1 text-[14px]">{formatDate(moshukData?.last_updated)}</td>
                  </tr>
                  <tr>
                        <td className="pr-2 text-[14px]">ইস্যুর সময়</td>
                        <td>:</td>
                        <td className="pl-1 text-[14px]">{formatTime(moshukData?.last_updated)}</td>
                  </tr>
            </table>
          </div>
        </div>



        <div className="mb-10">
          <p className="text-center my-2">সরবরাহের বিবরণ </p>
          <table className="min-w-full mushak1-table-head">
            <thead className=" bg-gray-50 text-[12px] font-medium ">

              <tr className="">
                <td className="border-2  right-5 w-[100px]">
                  ক্রমিক নং
                </td>

                <td className="border-2 right-5">
                  ফেরতপ্রাপ্ত সরবরাহের বিবরন 
                </td>


                <td className="border-2  right-5">

                  সরবরাহের একক
                </td>

                <td className="border-2  right-5">
                  {/* Total Price No Vat */}
                  পরিমাণ
                </td>


                <td className="border-2 ">
                  {/* Total Tax */}
                  একক মূল্য
                    (টাকায়) 
                </td>

                <td className="border-2 ">
                  {/* Vat */}
                  মোট মূল্য (টাকায়)
                </td>

              </tr>

              <tr></tr>
            </thead>

            <tbody>

              {moshukData.credititem_set?.map((mData,index) =>(
                <tr key={index}>
                    <td>{incrementalId++}</td>
                    <td>{mData.sales_inventory_name}</td>
                    <td>{mData.sales_inventory_uom}</td>
                    <td>{mData.return_quantity}</td>
                    <td>{mData.sales_inventory_unit_price}</td>
                    <td>{(parseFloat(mData.sales_inventory_unit_price || 0) * parseFloat(mData.return_quantity || 0)).toFixed(2)}</td>
                </tr>
              ))}


            </tbody>
            <tfoot className="mt-1">
                <tr>
                    <td colSpan={3} className=" border-[1.5px] border-black"></td>
                    <td colSpan={2} className=" border-[1.5px] border-black">মোট মূল্য</td>
                    <td colSpan={2} className="text-right border-[1.5px] border-black">{totalSumWithoutVat?.toFixed(2)}</td>
                </tr>
                <tr>
                    <td colSpan={3}></td>
                    <td colSpan={2} className="border-[1.5px] border-black">বাদ কর্তন</td>
                    <td colSpan={2} className="text-right border-[1.5px] border-black"></td>
                </tr>
                <tr>
                    <td colSpan={3}></td>
                    <td colSpan={2} className="border-[1.5px] border-black">মূসকসহ মূল্য</td>
                    <td colSpan={2} className="text-right border-[1.5px] border-black">
                    {(parseFloat(totalSumWithoutVat || 0) + parseFloat(totalVat || 0) + parseFloat(totalSD || 0)).toFixed(2)}
                    </td>
                </tr>
                <tr>
                    <td colSpan={3}></td>
                    <td colSpan={2} className=" border-[1.5px] border-black">মূসকের পরিমাণ </td>
                    <td colSpan={2} className="text-right border-[1.5px] border-black">{totalVat?.toFixed(2)}</td>
                </tr>
                <tr>
                    <td colSpan={3}></td>
                    <td colSpan={2} className="border-[1.5px] border-black">সম্পূরক শুল্কের পরিমাণ</td>
                    <td colSpan={2} className="text-right border-[1.5px] border-black">{totalSD?.toFixed(2)}</td>
                </tr>
                <tr>
                    <td colSpan={3}></td>
                    <td colSpan={2} className=" border-[1.5px] border-black">মোট কর</td>
                    <td colSpan={2} className="text-right border-[1.5px] border-black">
                    {(parseFloat(totalVat || 0) + parseFloat(totalSD || 0)).toFixed(2)}
                    </td>
                </tr>

                <tr>
                    <td colSpan={6}>ফেরতের কারন</td>
                </tr>
                <tr className="border-[1px] border-black">
                <td className="p-4" colSpan={6}>
                {moshukData.credititem_set?.map((mData,index) =>(
                  <p >{mData.reasons_to_return}</p>
                ))}
                </td>
                </tr>
            </tfoot>
          </table>
          <table>
          <tfoot>
            <div className="mushak3-bottom-header flex justify-between my-4">
              <div className="left-header mb-3">
                <p>দায়িত্বপ্রাপ্ত ব্যাক্তির স্বাক্ষর</p>

                <br /> <br />
                  <ul>
                    <li>প্রতি একক পণ্য বা সেবার মূসক ও সম্পূরক শুল্কসহ মূল্য।</li>
                    <li>ফেরত প্রদানের জন্য কোন ধরনের কর্তন থাকিলে উহার পরিমাণ।</li>
                    <li>মূসক ও সম্পূরক শুল্কের যোগফল।</li>
                  </ul>
              </div>
              
            </div>
            </tfoot>
          </table>
        </div>
      </div>
    
    </div>
  );


};

export default MushakSixSeven;
