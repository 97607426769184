/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from "react";
import { Table } from "antd";
import axios from "axios";
import { AiFillDelete,AiFillEdit } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import { baseUrl } from "../BaseUrl";

const InventoryDetailTable = () => {
  const [inventoryData, setInventoryData] = useState([]);
  const navigate = useNavigate();

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
  });

  // fetch data function...
  const fetchInventoryData = async () => {
    try {
      const jwtToken = localStorage.getItem("access");
      console.log("jwt token from create user..", jwtToken);
      const res = await axios.get(`${baseUrl}/inventory/api/inventory/`, {
        headers: {
          Authorization: `JWT ${jwtToken}`,
        },
      });
      if (res) {
        console.log("fetch inventory data", res.data.results);
        const finalData = res.data.results;
        setInventoryData(finalData);
      }
    } catch (error) {
      console.log("error from get inventory api", error);
    }
  };

  useEffect(() => {
    fetchInventoryData();
  }, []);

  const columns = [
    {
      key: 1,
      title: "Name",
      dataIndex: "name",
    },
    {
      key: 2,
      title: "Unit Price",
      dataIndex: "unit_price",
    },
    {
      key: 3,
      title: "UOM",
      dataIndex: "uom_text",
    },
    {
      key: 4,
      title: "Current Stock",
      dataIndex: "current_stock",
    },

    {
      key: 5,
      title: "Total Price",
      dataIndex: "total_price",
    },
    {
      key: 6,
      title: "Type",
      dataIndex: "type_text",
    },
    {
      key: 7,
      title: "Created By",
      dataIndex: "created_by_text",
    },
    {
      key: 8,
      title: "Actions",
      dataIndex: "actions",
      render: (_, record) => {
        return (
          <>
            <div className="flex gap-2">
              <div className="">
              <button
                   onClick={() => navigate(`/editEnventoryPage/${record.id}`)}
                  className="bg-blue-500 hover:bg-blue-600 rounded"
                >
                  <AiFillEdit className="text-white text-xl " />
                </button>

                <button
                  onClick={() => handleDelete(record.id)}
                  className="rounded mr-1"
                >
                  <AiFillDelete className="text-xl text-red-500/100 hover:text-red-600 " />
                </button>

              </div>
            </div>
          </>
        );
      },
    },
  ];

// data delete method 
  const handleDelete = async (itemId) => {
    try {
      const jwtToken = localStorage.getItem("access");
      await axios.delete(`${baseUrl}/inventory/api/inventory/${itemId}/`, {
        headers: {
          Authorization: `JWT ${jwtToken}`,
        },
      });
      // If the deletion is successful, update the inventoryData state to reflect the change.
      setInventoryData((prevData) =>
        prevData.filter((item) => item.id !== itemId)
      );
      Toast.fire({
        icon: 'success',
        title: 'Successfully Delete',
      });

    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: `Ops! ${error.message}`,
      });
    }
  };

  return (
    <div className="px-5 ">
      <div className="flex items-center justify-between py-5">
        <h1 className=" uppercase font-semibold  text-xl">Inventory Details</h1>
        <Link to="/createInventory">
          <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
            Create New
          </button>
        </Link>
      </div>

      <div className='overflow-x-auto w-[1090px]'>
            <Table
            bordered
                columns={columns}
                dataSource={inventoryData}
                pagination={{
                  pageSize: 10, 
                  total: inventoryData.length,
                  showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                  showSizeChanger: false,
                }}
            />
      </div>

    </div>
  );
};

export default InventoryDetailTable;
