import axios from 'axios';
import React, { useState, useEffect } from 'react';
import DashboardLayout from '../../Shared/DashboardLayout';
import { Link, useNavigate } from 'react-router-dom';
import { Table } from "antd";
import { AiFillDelete,AiFillEdit, AiFillFileAdd, AiFillFilePdf } from "react-icons/ai";
import { format } from "date-fns";
import Swal from 'sweetalert2';
import { baseUrl } from '../../BaseUrl';


const MushakSixThreeList = () => {
    const [salesData, setSalesData] = useState([]);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer);
          toast.addEventListener('mouseleave', Swal.resumeTimer);
        },
      });

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return format(date, "dd-M-yyyy"); // Change the format here
      };
    
    useEffect(() => {
        // This code will run whenever salesData changes
        console.log('salesData after update:', salesData);
    }, [salesData]);

    useEffect(() => {
        const fetchSalesData = async () => {
            try {
                const jwtToken = localStorage.getItem("access");
                const response = await axios.get(`${baseUrl}/sales/api/order_list/`, {
                    headers: {
                        Authorization: `JWT ${jwtToken}`,
                    },
                });
                // Filter items with type 'Finished Good'
                const complatedData = response.data.filter(
                    (item) => item.status === "Complete"
                );
                setSalesData(complatedData);
                console.log(response.data)
            } catch (error) {
                setError(error);
                console.log(error)
            }
        };

        fetchSalesData();
    }, []); // Empty dependency array ensures this effect runs only once

    const handleDelete = async (challanNumber) => {
        try {
            const jwtToken = localStorage.getItem("access");
    
            // Fetch all sales orders
            const response = await axios.get(`${baseUrl}/sales/api/sales_order/`, {
                headers: {
                    Authorization: `JWT ${jwtToken}`,
                },
            });
    
            if (response.status !== 200) {
                // Handle the error here, e.g., show an error toast
                return;
            }
    
            const allSalesOrders = response.data.results;
    
            // Filter sales orders by challan number
            const ordersToDelete = allSalesOrders.filter(
                (item) => item.challan === challanNumber
            );
    
            // Delete each order with the same challan number
            for (const order of ordersToDelete) {
                await axios.delete(`${baseUrl}/sales/api/sales_order/${order.id}/`, {
                    headers: {
                        Authorization: `JWT ${jwtToken}`,
                    },
                });
            }
    
            // delete after update sales data 
            // console.log("ordersToDelete:", ordersToDelete);
            setSalesData((prevData) =>
                prevData.filter((item) => {
                    // console.log("item:", item);
                    if (item.chalan && ordersToDelete.some((order) => order.challan === item.chalan)) {
                        return false; // Filter out items with matching chalan number
                    }
                    return true; // Keep items with different chalan numbers or where chalan is undefined
                })
            );
            
    
            Toast.fire({
                icon: 'info',
                title: 'Successfully Deleted Orders',
            });
        } catch (error) {
            if (error.response && error.response.data.detail) {
                Toast.fire({
                    icon: 'error',
                    title: `Ops! ${error.response.data.detail}`,
                });
            } else {
                Toast.fire({
                    icon: 'error',
                    title: `Ops! ${error.message}`,
                });
            }
        }
    };

    const [columns, setColumns] = useState([
        {
            key: 1,
            title: (
                <div className="fixed-header-cell w-[100px]">Ordered Date</div>
              ),
            dataIndex: "order_date",
            render: (text) => formatDate(text),
        },
        {
            key: 2,
            title: (
                <div className="fixed-header-cell w-[120px]">Customer Name</div>
              ),
            dataIndex: "customer",
        },
        {
            key: 3,
            title: (
                <div className="fixed-header-cell w-[120px]">Chalan</div>
              ),
            dataIndex: "chalan",
        },
        {
            key: 4,
            title: (
                <div className="fixed-header-cell w-[80px]">Vehicle</div>
              ),
            dataIndex: "vehicle",
        },

        {
            key: 9,
            title: (
                <div className="fixed-header-cell w-[100px]">Order Status</div>
              ),
            dataIndex: "status",
        },
        {
            key: 11, 
            title: "Actions",
            dataIndex: "actions",
            render: (_, record) => {
                return (
                    <>
                        <div className="flex gap-2">
                        {record.status !== "Complete"?
                            <div className="">
                                <button
                                    onClick={() => navigate(`/sales-order-edit/${record.chalan}`)}
                                    className="bg-blue-500 hover:bg-blue-600 rounded"
                                >
                                    <AiFillEdit className="text-white text-xl " />
                                </button>
        
                                <button
                                    onClick={() => handleDelete(record.chalan)}
                                    className="rounded mr-1"
                                >
                                    <AiFillDelete className="text-xl text-red-500/100 hover:text-red-600 " />
                                </button>
                            </div>
                        :<div className="">
                        <button
                            onClick={() => navigate(`/mushak-6-3/${record.chalan}`)}
                            className="rounded"
                        >
                            <div className="flex items-center rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-[#000] ring-1 ring-inset ring-gray-500/10">
                                <div><AiFillFilePdf className="text-[#15803d] text-xl " /></div>
                                <div>M-6.3</div>
                            </div> 
                        </button>
                    </div>
                        }
                        </div>
                    </>
                );
            },
        },
    ]);


    return (
        <DashboardLayout>
        <div className="px-5">
            <div className="flex items-center justify-between py-5">
                <div>
                <h1 className="uppercase font-semibold  text-xl">Mushak 6.3 List</h1>

                </div>
                <div>
                <button onClick={() => navigate("/sales-order-add")} className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                    <div className="flex ">
                        <div className="mt-1">
                        <AiFillFileAdd />
                        </div>
                        <div>Add New</div>
                    </div>
                </button>
                </div>
            </div>

            <div className='overflow-x-auto w-[1090px]'>
            <Table
                bordered
                columns={columns}
                dataSource={salesData}
                // pagination={{
                //     pageSize: 10, 
                //     total: salesData.length,
                //     showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                //     showSizeChanger: false,
                // }}
            />
            </div>
            </div>
        </DashboardLayout>
    );
};


export default MushakSixThreeList;
