/* eslint-disable react/jsx-key */
import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import LoginPage from "../pages/LoginPage";
import DashboardHome from "../pages/DashboardHome";
// import Purchases from "../pages/PurchasesDetailPage";
import Users from "../pages/Users";
import InventoryPage from "../pages/InventoryPage";
import SupplyChainPage from "../pages/SupplyChainPage";
import InventoryCreatePage from "../pages/InventoryCreatePage";
import SupplyCreatePage from "../pages/SupplyCreatePage";
import PurchasesCreatePage from "../pages/PurchasesCreatePage";
import PurchasesDetailPage from "../pages/PurchasesDetailPage";
import MushokTablePage from "../pages/MushokTablePage";
import PustokTableRepost from "../pages/PustokTableRepost";
import DemoMushokTable from "../pages/DemoMushokTable";
import ProductionPage from "../pages/ProductionPage";
import ProtectedRoutes from "./ProtectedRoutes";
import UsersCreate from "../pages/UsersCreate";
import InventoryEditPage from "../pages/InventoryEditPage";
import SupplyEditPage from "../pages/SupplyEditPage";
import PurchasesEditPage from "../pages/PurchasesEditPage";
import CustomerList from "../pages/Sales/CustomerList";
import CustomerEdit from "../pages/Sales/CustomerEdit";
import CustomerAdd from "../pages/Sales/CustomerAdd";
import SalesOrder from "../pages/Sales/SalesOrder";
import SalesOrderAdd from "../pages/Sales/SalesOrderAdd";
import SalesOrderEdit from "../pages/Sales/SalesOrderEdit";
import ProductionDataEdit from "../pages/Production/ProductionDataEdit";
import ProductionDataList from "../pages/Production/ProductionDataList";
import MushakSixTwo from "../pages/MushakSixTwo";
import MushakSixThree from "../pages/MushakSixThree";
import CompanyList from "../pages/Company/CompanyList";
import CompanyAdd from "../pages/Company/CompanyAdd";
import CompanyEdit from "../pages/Company/CompanyEdit";
import RatedataList from "../pages/Rate/RatedataList";
import RateAdd from "../pages/Rate/RateAdd";
import RateDataEdit from "../pages/Rate/RateDataEdit";
import VehicleList from "../pages/Vehicle/VehicleList";
import VehicleAdd from "../pages/Vehicle/VehicleAdd";
import VehicleEdit from "../pages/Vehicle/VehicleEdit";
import ProductionAdd from "../pages/Production/AddProduction";
import EditProduction from "../pages/Production/EditProduction";
import ProductionList from "../pages/Production/ProductionList";
import MushakFourThreeList from "../pages/Mushak4-3/MushakFourThreeList";
import AddNewMushakFourThree from "../pages/Mushak4-3/AddNewMushakFourThree";
import EditExsitingMushakFourThree from "../pages/Mushak4-3/EditExsitingMushakFourThree";
import MushakFourThree from "../pages/Mushak4-3/MushakFourThree";
import MushakSixOneProduction from "../pages/Production/MushakSixOneProduction";
import MushakSixOnePuechase from "../components/MushakSixOnePuechase";
import MushakSixFive from "../pages/Mushak6-5/MushakSixFive";
import ContractualProductionList from "../pages/Mushak6-4/ContractualProductionList";
import ContractualProductionAdd from "../pages/Mushak6-4/ContractualProductionAdd";
import ContractualProductionEdit from "../pages/Mushak6-4/ContractualProductionEdit";
import MushakSixFour from "../pages/Mushak6-4/MushakSixFour";
import MushakSixThreeList from "../pages/Mushak6-3/MushakSixThreeList";
import SingleMushakSixThreePdf from "../pages/Mushak6-3/SingleMushakSixThreePdf";
import MushakSixFiveList from "../pages/Mushak6-5/MushakSixFiveList";
import MushakSixFiveAdd from "../pages/Mushak6-5/MushakSixFiveAdd";
import MushakSixFiveEdit from "../pages/Mushak6-5/MushakSixFiveEdit";
import MushakSixSixList from "../pages/Mushak6-6/MushakSixSixList";
import MushakSixSix from "../pages/Mushak6-6/MushakSixSix";
import MushakSixSixAdd from "../pages/Mushak6-6/MushakSixSixAdd";
import MushakSixSixEdit from "../pages/Mushak6-6/MushakSixSixEdit";
import MushakSixSeven from "../pages/Mushak6-7/MushakSixSeven";
import MushakSixSevenEdit from "../pages/Mushak6-7/MushakSixSevenEdit";
import MushakSixSevenAdd from "../pages/Mushak6-7/MushakSixSevenAdd";
import MushakSixSevenList from "../pages/Mushak6-7/MushakSixSevenList";
import MushakSixEightList from "../pages/Mushak6-8/MushakSixEightList";
import MushakSixEightAdd from "../pages/Mushak6-8/MushakSixEightAdd";
import MushakSixEightEdit from "../pages/Mushak6-8/MushakSixEightEdit";
import MushakSixEight from "../pages/Mushak6-8/MushakSixEight";
import MushakNineOne from "../pages/Mushak9-1/MushakNineOne";
import List from "../pages/Mushak9-1/TaxReturnDeclarant/List";
import Add from "../pages/Mushak9-1/TaxReturnDeclarant/Add";
import Edit from "../pages/Mushak9-1/TaxReturnDeclarant/Edit";
import AccountCodeList from "../pages/Mushak9-1/AccountCode/List";
import AccountCodeAdd from "../pages/Mushak9-1/AccountCode/Add";
import AccountCodeEdit from "../pages/Mushak9-1/AccountCode/Edit";

import AnyOtherAdjustmentsForINCREASINGList from "../pages/Mushak9-1/AnyOtherAdjustmentsForINCREASING/List";
import AnyOtherAdjustmentsForINCREASINGAdd from "../pages/Mushak9-1/AnyOtherAdjustmentsForINCREASING/Add";
import AnyOtherAdjustmentsForINCREASINGEdit from "../pages/Mushak9-1/AnyOtherAdjustmentsForINCREASING/Edit";

import AnyOtherAdjustmentsForDECREASINGList from "../pages/Mushak9-1/AnyOtherAdjustmentsForDECREASING/List";
import AnyOtherAdjustmentsForDECREASINGAdd from "../pages/Mushak9-1/AnyOtherAdjustmentsForDECREASING/Add";
import AnyOtherAdjustmentsForDECREASINGEdit from "../pages/Mushak9-1/AnyOtherAdjustmentsForDECREASING/Edit";

import DueToVatDeductedAtSourceFromTheSuppliesDeliveredList from "../pages/Mushak9-1/DueToVatDeductedAtSourceFromTheSuppliesDelivered/List";
import DueToVatDeductedAtSourceFromTheSuppliesDeliveredAdd from "../pages/Mushak9-1/DueToVatDeductedAtSourceFromTheSuppliesDelivered/Add";
import DueToVatDeductedAtSourceFromTheSuppliesDeliveredEdit from "../pages/Mushak9-1/DueToVatDeductedAtSourceFromTheSuppliesDelivered/Edit";

import AdvanceTaxPaidAtImportStageList from "../pages/Mushak9-1/AdvanceTaxPaidAtImportStage/List";
import AdvanceTaxPaidAtImportStageAdd from "../pages/Mushak9-1/AdvanceTaxPaidAtImportStage/Add";
import AdvanceTaxPaidAtImportStageEdit from "../pages/Mushak9-1/AdvanceTaxPaidAtImportStage/Edit";
import ReturnSubmissionList from "../pages/Mushak9-1/ReturnSubmissionList";
import ReturnSubmissionAdd from "../pages/Mushak9-1/ReturnSubmissionAdd";
import ReturnSubmissionEdit from "../pages/Mushak9-1/ReturnSubmissionEdit";
import MushakNineOnePreview from "../pages/Mushak9-1/MushakNineOnePreview";


const routes = createBrowserRouter([
  {
    path: "/",
    element: (
      <ProtectedRoutes>
        <App />
      </ProtectedRoutes>
    ),
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/dashboardhome",
    element: (
      <ProtectedRoutes>
        <DashboardHome />
      </ProtectedRoutes>
    ),
  },
  {
    path: "/dashboardhome",
    element: (
      <ProtectedRoutes>
        <DashboardHome />
      </ProtectedRoutes>
    ),
  },
  {
    path: "/purchases",
    element: (
      <ProtectedRoutes>
        <PurchasesDetailPage />
      </ProtectedRoutes>
    ),
  },
  {
    path: "/user",
    element: (
      <ProtectedRoutes>
        <Users />
      </ProtectedRoutes>
    ),
  },
  {
    path: "/user-add",
    element: (
      <ProtectedRoutes>
        <UsersCreate />
      </ProtectedRoutes>
    ),
  },
  {
    path: "/inventory",
    element: (
      <ProtectedRoutes>
        <InventoryPage />
      </ProtectedRoutes>
    ),
  },
  {
    path: "/supplyChain",
    element: (
      <ProtectedRoutes>
        <SupplyChainPage />
      </ProtectedRoutes>
    ),
  },
  {
    path: "/createInventory",
    element: (
      <ProtectedRoutes>
        <InventoryCreatePage />
      </ProtectedRoutes>
    ),
  },
  {
    path: "/createSupply",
    element: (
      <ProtectedRoutes>
        <SupplyCreatePage />
      </ProtectedRoutes>
    ),
  },
  {
    path: "/editSupply/:id",
    element: (
      <ProtectedRoutes>
        <SupplyEditPage />
      </ProtectedRoutes>
    ),
  },
  {
    path: "/createPurchases",
    element: (
      <ProtectedRoutes>
        <PurchasesCreatePage />
      </ProtectedRoutes>
    ),
  },
  {
    path: "/purchases-edit/:bill_of_entry",
    element: (
      <ProtectedRoutes>
        <PurchasesEditPage />
      </ProtectedRoutes>
    ),
  },
  {
    path: "/mushak-6-1-purchases/:id",
    element: (
      <ProtectedRoutes>
        <MushakSixOnePuechase />
      </ProtectedRoutes>
    ),
  },
  {
    path: "/mushak-6-1",
    element: (
      <ProtectedRoutes>
        <MushokTablePage />
      </ProtectedRoutes>
    ),
  },
  {
    path: "/editEnventoryPage/:id",
    element: (
      <ProtectedRoutes>
        <InventoryEditPage />
      </ProtectedRoutes>
    ),
  },
  {
    path: "/pustok",
    element: (
      <ProtectedRoutes>
        , <PustokTableRepost />,
      </ProtectedRoutes>
    ),
  },
  {
    path: "/demomushok",
    element: (
      <ProtectedRoutes>
        <DemoMushokTable />
      </ProtectedRoutes>
    ),
  },
  {
    path: "/production-list",
    element: (
      <ProtectedRoutes>
        <ProductionList />
      </ProtectedRoutes>
    ),
  },
  {
    path: "/production-add",
    element: (
      <ProtectedRoutes>
        <ProductionAdd />
      </ProtectedRoutes>
    ),
  },
  {
    path: "/production-edit/:id",
    element: (
      <ProtectedRoutes>
        <EditProduction />
      </ProtectedRoutes>
    ),
  },

  {
    path: "/mushak-6-1-production/:id",
    element: (
      <ProtectedRoutes>
        <MushakSixOneProduction />
      </ProtectedRoutes>
    ),
  },

  {
    path: "/customer-list",
    element: (
      <ProtectedRoutes>
        <CustomerList />
      </ProtectedRoutes>
    ),
  },
  {
    path: "/customer-add",
    element: (
      <ProtectedRoutes>
        <CustomerAdd />
      </ProtectedRoutes>
    ),
  },
  {
    path: "/customer-edit/:id",
    element: (
      <ProtectedRoutes>
        <CustomerEdit />
      </ProtectedRoutes>
    ),
  },
  {
    path: "/sales-order",
    element: (
      <ProtectedRoutes>
        <SalesOrder />
      </ProtectedRoutes>
    ),
  },
  {
    path: "/sales-order-add",
    element: (
      <ProtectedRoutes>
        <SalesOrderAdd />
      </ProtectedRoutes>
    ),
  },
  {
    path: "/sales-order-edit/:chalan",
    element: (
      <ProtectedRoutes>
        <SalesOrderEdit />
      </ProtectedRoutes>
    ),
  },
  {
    path: "/mushak-6-2",
    element: (
      <ProtectedRoutes>
        <MushakSixTwo />
      </ProtectedRoutes>
    ),
  },
  {
    path: "/mushak-6-3",
    element: (
      <ProtectedRoutes>
        <MushakSixThree />
      </ProtectedRoutes>
    ),
  },

  {
    path: "/companies-list",
    element: (
      <ProtectedRoutes>
        <CompanyList />
      </ProtectedRoutes>
    ),
  },
  {
    path: "/company-add",
    element: (
      <ProtectedRoutes>
        <CompanyAdd />
      </ProtectedRoutes>
    ),
  },
  {
    path: "/company-data-edit/:id",
    element: (
      <ProtectedRoutes>
        <CompanyEdit />
      </ProtectedRoutes>
    ),
  },
  {
    path: "/vehicle-list",
    element: (
      <ProtectedRoutes>
        <VehicleList />
      </ProtectedRoutes>
    ),
  },
  {
    path: "/vehicle-add",
    element: (
      <ProtectedRoutes>
        <VehicleAdd />
      </ProtectedRoutes>
    ),
  },
  {
    path: "/vehicle-edit/:id",
    element: (
      <ProtectedRoutes>
        <VehicleEdit />
      </ProtectedRoutes>
    ),
  },
  {
    path: "/rate-list",
    element: (
      <ProtectedRoutes>
        <RatedataList />
      </ProtectedRoutes>
    ),
  },
  {
    path: "/rate-add",
    element: (
      <ProtectedRoutes>
        <RateAdd />
      </ProtectedRoutes>
    ),
  },
  {
    path: "/rate-data-edit/:id",
    element: (
      <ProtectedRoutes>
        <RateDataEdit />
      </ProtectedRoutes>
    ),
  },

  {
    path: "/mushak-6-3-list",
    element: (
      <ProtectedRoutes>
        <MushakSixThreeList />
      </ProtectedRoutes>
    ),
  },
  {
    path: "/mushak-6-3/:chalan",
    element: (
      <ProtectedRoutes>
        <SingleMushakSixThreePdf />
      </ProtectedRoutes>
    ),
  },

  {
    path: "/mushak-4-3-list",
    element: (
      <ProtectedRoutes>
        <MushakFourThreeList />
      </ProtectedRoutes>
    ),
  },
  {
    path: "/mushak-4-3-add",
    element: (
      <ProtectedRoutes>
        <AddNewMushakFourThree />
      </ProtectedRoutes>
    ),
  },
  {
    path: "/mushak-4-3-edit/:id",
    element: (
      <ProtectedRoutes>
        <EditExsitingMushakFourThree />
      </ProtectedRoutes>
    ),
  },
  {
    path: "/mushak-4-3-pdf/:id",
    element: (
      <ProtectedRoutes>
        <MushakFourThree />
      </ProtectedRoutes>
    ),
  },

  
  {
    path: "/contractual-production-list",
    element: (
      <ProtectedRoutes>
        <ContractualProductionList />
      </ProtectedRoutes>
    ),
  },
  {
    path: "/contractual-production-add",
    element: (
      <ProtectedRoutes>
        <ContractualProductionAdd />
      </ProtectedRoutes>
    ),
  },
  {
    path: "/contractual-production-edit/:id",
    element: (
      <ProtectedRoutes>
        <ContractualProductionEdit/>
      </ProtectedRoutes>
    ),
  },
  {
    path: "/mushak-6-4/:id",
    element: (
      <ProtectedRoutes>
        <MushakSixFour/>
      </ProtectedRoutes>
    ),
  },

  {
    path: "/mushak-6-5-pdf/:id",
    element: (
      <ProtectedRoutes>
        <MushakSixFive />
      </ProtectedRoutes>
    ),
  },
  {
    path: "/mushak-6-5-list",
    element: (
      <ProtectedRoutes>
        <MushakSixFiveList />
      </ProtectedRoutes>
    ),
  },
  {
    path: "/mushak-6-5-add",
    element: (
      <ProtectedRoutes>
        <MushakSixFiveAdd />
      </ProtectedRoutes>
    ),
  },
  {
    path: "/mushak-6-5-edit/:id",
    element: (
      <ProtectedRoutes>
        <MushakSixFiveEdit />
      </ProtectedRoutes>
    ),
  },
  {
    path: "/mushak-6-6-list",
    element: (
      <ProtectedRoutes>
        <MushakSixSixList />
      </ProtectedRoutes>
    ),
  },
  {
    path: "/mushak-6-6-pdf/:bill_of_entry",
    element: (
      <ProtectedRoutes>
        <MushakSixSix />
      </ProtectedRoutes>
    ),
  },
  {
    path: "/mushak-6-6-add",
    element: (
      <ProtectedRoutes>
        <MushakSixSixAdd />
      </ProtectedRoutes>
    ),
  },
  {
    path: "/mushak-6-6-edit/:id",
    element: (
      <ProtectedRoutes>
        <MushakSixSixEdit />
      </ProtectedRoutes>
    ),
  },
  {
    path: "/mushak-6-7-list",
    element: (
      <ProtectedRoutes>
        <MushakSixSevenList />
      </ProtectedRoutes>
    ),
  },
  {
    path: "/credit-note-add",
    element: (
      <ProtectedRoutes>
        <MushakSixSevenAdd />
      </ProtectedRoutes>
    ),
  },

  {
    path: "/credit-note-edit/:id",
    element: (
      <ProtectedRoutes>
        <MushakSixSevenEdit />
      </ProtectedRoutes>
    ),
  },
  {
    path: "/mushak-6-7-pdf/:id",
    element: (
      <ProtectedRoutes>
        <MushakSixSeven />
      </ProtectedRoutes>
    ),
  },

  {
    path: "/mushak-6-8-list",
    element: (
      <ProtectedRoutes>
        <MushakSixEightList />
      </ProtectedRoutes>
    ),
  },
  {
    path: "/debit-note-add",
    element: (
      <ProtectedRoutes>
        <MushakSixEightAdd />
      </ProtectedRoutes>
    ),
  },

  {
    path: "/debit-note-edit/:id",
    element: (
      <ProtectedRoutes>
        <MushakSixEightEdit />
      </ProtectedRoutes>
    ),
  },
  {
    path: "/mushak-6-8-pdf/:id",
    element: (
      <ProtectedRoutes>
        <MushakSixEight />
      </ProtectedRoutes>
    ),
  },
  
  {
    path: "/mushak-9-1-pdf/:id",
    element: (
      <ProtectedRoutes>
        <MushakNineOne />
      </ProtectedRoutes>
    ),
  },

  {
    path: "/mushak-9-1-preview/:id",
    element: (
      <ProtectedRoutes>
        <MushakNineOnePreview />
      </ProtectedRoutes>
    ),
  },

  {
    path: "/mushak-9-1-list/",
    element: (
      <ProtectedRoutes>
        <ReturnSubmissionList />
      </ProtectedRoutes>
    ),
  },

  {
    path: "/mushak-9-1-add/",
    element: (
      <ProtectedRoutes>
        <ReturnSubmissionAdd />
      </ProtectedRoutes>
    ),
  },

  {
    path: "/mushak-9-1-edit/:id",
    element: (
      <ProtectedRoutes>
        <ReturnSubmissionEdit />
      </ProtectedRoutes>
    ),
  },


  {
    path: "/tax-return-declarant-list",
    element: (
      <ProtectedRoutes>
        <List />
      </ProtectedRoutes>
    ),
  },
  {
    path: "/tax-return-declarant-add",
    element: (
      <ProtectedRoutes>
        <Add />
      </ProtectedRoutes>
    ),
  },
  {
    path: "/tax-return-declarant-edit/:id",
    element: (
      <ProtectedRoutes>
        <Edit />
      </ProtectedRoutes>
    ),
  },

  {
    path: "/account-code-list",
    element: (
      <ProtectedRoutes>
        <AccountCodeList />
      </ProtectedRoutes>
    ),
  },
  {
    path: "/account-code-edit/:id",
    element: (
      <ProtectedRoutes>
        <AccountCodeEdit />
      </ProtectedRoutes>
    ),
  },
  {
    path: "/account-code-add",
    element: (
      <ProtectedRoutes>
        <AccountCodeAdd />
      </ProtectedRoutes>
    ),
  },
  
  {
    path: "/any-other-adjustments-for-increasing-list",
    element: (
      <ProtectedRoutes>
        <AnyOtherAdjustmentsForINCREASINGList />
      </ProtectedRoutes>
    ),
  },
  {
    path: "/any-other-adjustments-for-increasing-add",
    element: (
      <ProtectedRoutes>
        <AnyOtherAdjustmentsForINCREASINGAdd />
      </ProtectedRoutes>
    ),
  },
  {
    path: "/any-other-adjustments-for-increasing-edit/:id",
    element: (
      <ProtectedRoutes>
        <AnyOtherAdjustmentsForINCREASINGEdit />
      </ProtectedRoutes>
    ),
  },


  {
    path: "/any-other-adjustments-for-decreasing-list",
    element: (
      <ProtectedRoutes>
        <AnyOtherAdjustmentsForDECREASINGList />
      </ProtectedRoutes>
    ),
  },
  {
    path: "/any-other-adjustments-for-decreasing-add",
    element: (
      <ProtectedRoutes>
        <AnyOtherAdjustmentsForDECREASINGAdd />
      </ProtectedRoutes>
    ),
  },
  {
    path: "/any-other-adjustments-for-decreasing-edit/:id",
    element: (
      <ProtectedRoutes>
        <AnyOtherAdjustmentsForDECREASINGEdit />
      </ProtectedRoutes>
    ),
  },


  {
    path: "/due-to-vat-deducted-at-source-from-the-supplies-delivered-list",
    element: (
      <ProtectedRoutes>
        <DueToVatDeductedAtSourceFromTheSuppliesDeliveredList />
      </ProtectedRoutes>
    ),
  },
  {
    path: "/due-to-vat-deducted-at-source-from-the-supplies-delivered-add",
    element: (
      <ProtectedRoutes>
        <DueToVatDeductedAtSourceFromTheSuppliesDeliveredAdd />
      </ProtectedRoutes>
    ),
  },
  {
    path: "/due-to-vat-deducted-at-source-from-the-supplies-delivered-edit/:id",
    element: (
      <ProtectedRoutes>
        <DueToVatDeductedAtSourceFromTheSuppliesDeliveredEdit />
      </ProtectedRoutes>
    ),
  },

  // advance-tax-paid-at-import-stage-list

  {
    path: "/advance-tax-paid-at-import-stage-list",
    element: (
      <ProtectedRoutes>
        <AdvanceTaxPaidAtImportStageList />
      </ProtectedRoutes>
    ),
  },
  {
    path: "/advance-tax-paid-at-import-stage-add",
    element: (
      <ProtectedRoutes>
        <AdvanceTaxPaidAtImportStageAdd />
      </ProtectedRoutes>
    ),
  },
  {
    path: "/advance-tax-paid-at-import-stage-edit/:id",
    element: (
      <ProtectedRoutes>
        <AdvanceTaxPaidAtImportStageEdit />
      </ProtectedRoutes>
    ),
  },
]);

export default routes;
