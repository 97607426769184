import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useReactToPrint } from "react-to-print";
import { Link, useNavigate, useParams } from "react-router-dom";

import { format } from "date-fns";
import { baseUrl } from "../../BaseUrl";


const MushakSixSix = () => {
  const componentPDF = useRef();
  const navigate = useNavigate();
  const pdfGeneretor = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: "Mushak-6.6",
    pageStyle: `
      @page landscape {
        size: A4 landscape;
        margin: 20mm 10mm;
      }
      @page portrait {
        size: A4 portrait;
        margin: 20mm 10mm;
      }
      @page {
        margin: 4mm;
      }
      body {
        margin: 0;
      }
    `,
    contentStyle: `
      @page landscape {
        transform: rotate(0deg) translate(0, 0);
      }
      @page portrait {
        transform: rotate(90deg) translate(0, 0);
      }
    `
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [moshukData, setMoshukData] = useState([]);
  const {bill_of_entry} =useParams()
  const [totalItemPriceWithVatSum, setTotalItemPriceWithVatSum] = useState(0);
  const [companyData, setCompanyData] = useState([]);

  const fetchMoshukData = async () => {
    const jwtToken = localStorage.getItem("access");

    try {
      const res = await axios.get(`${baseUrl}/purchase/api/purchase_order_line_items/${bill_of_entry}`, {
        headers: {
          Authorization: `JWT ${jwtToken}`,
        },
      });
      if (res) {

        setMoshukData(res.data);
        console.log(res.data)

      }
    } catch (error) {
      if(error.response.status === 401){
        navigate("/login");; 
      }
      else{
        console.log(error)
      }
    }
  };

  useEffect(() => {
    fetchMoshukData();
  }, [bill_of_entry]);


  useEffect(() => {
    // Calculate the sum of total_item_price_with_vat
    const sum = moshukData.reduce(
      (total, item) => total + (parseFloat(item.total_item_price_with_vat) || 0.0),
      0.0
      );

    setTotalItemPriceWithVatSum(sum);
  }, [moshukData]);

 const [vatTotal, setVatTotal] = useState('')
  
 useEffect(() => {
    const sum = moshukData.reduce(
      (total, item) => total + (parseFloat(item.vat) || 0.0),
      0.0
      );
    setVatTotal(sum);
  }, [moshukData]);

  const [sdTotal, setSdTotal] = useState('')
  
  useEffect(() => {
    const sum = moshukData.reduce(
      (total, item) => total + (parseFloat(item.sd) || 0.0),
      0.0
      );
     setSdTotal(sum);
   }, [moshukData]);
 
   
   useEffect(() => {
       const fetchcompanyData = async () => {
           try {
               const jwtToken = localStorage.getItem("access");
               const response = await axios.get(`${baseUrl}/coa/api/company/`, {
                   headers: {
                       Authorization: `JWT ${jwtToken}`,
                   },
               });
 
               setCompanyData(response.data.results);
               // console.log(response.data.results)
           } catch (error) {
           }
       };
 
       fetchcompanyData();
   }, []);
 

   const [COfTDaTSData, setCOfTDaTSData] = useState([]);
   useEffect(() => {
       const fetchCOfTDaTS = async () => {
           try {
               const jwtToken = localStorage.getItem("access");
               const response = await axios.get(`${baseUrl}/purchase/api/certificate-of-tex-deduction-at-source/`, {
                   headers: {
                       Authorization: `JWT ${jwtToken}`,
                   },
               });
               
               const filterData = response.data.results.filter((item) => {
                return item.bill_of_entry === bill_of_entry;
               });
               setCOfTDaTSData(filterData);
               console.log(filterData)
               
           } catch (error) {

           }
       };

       fetchCOfTDaTS();
   }, [bill_of_entry]);


  const formatDate = (dateString) => {
    try {
      const date = new Date(dateString);
      return format(date, "dd-M-yyyy");
    } catch (error) {
      return "...";
    }
  };


  let incrementalId = 1;

  return (
    <div className="">
      <div className="flex justify-between my-4 px-5 shadow-lg pb-4">
            <Link className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" to='/mushak-6-6-list'> Back To Mushak 6.6 List</Link>
            
            <button
              onClick={pdfGeneretor}
              className="bg-green-600 w-[150px] hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
            >
              Download pdf
            </button>

      </div>

      <div
        ref={componentPDF}
        style={{ width: "99%", height: "100%" }}
        className="flex flex-col mx-auto "
      >
        <div className="pdf-head mb-4">
          <div className="top-header my-3"> 
            <p className="text-right m-2">মূসক - ৬.৬</p>
            <p className="text-center">গণপ্রজাতন্তী বাংলাদেশ সরকার</p>
            <p className="text-center">জাতীয় রাজস্ব বোর্ড</p>
            <h3 className="text-center font-bold">উৎসে কর কর্তন সনদপত্র</h3>
            <p className="text-center">[বিধি ৪০ এর উপ-বিধি (১) এর দফা (চ) দ্রষ্টব্য]</p>
          </div>
        </div>

        <div>
            <p className="my-1">উৎসে কর কর্তনকারী সত্তার নাম : {companyData[0]?.company_name}</p>
            <p className="my-1">উৎসে কর কর্তনকারী সত্তার ঠিকানা : {companyData[0]?.address} </p>
            <p className="my-1">উৎসে কর কর্তনকারী সত্তার বি আই এন  (প্রযোজ্য ক্ষেত্রে) : {companyData[0]?.vin}</p>
            <p className="my-1">উৎসে কর কর্তন সনদপত্র নং : {COfTDaTSData[0]?.id} </p>
            <p className="my-1">জারির তারিখ : {formatDate(COfTDaTSData[0]?.last_updated)} </p>
        </div>

        <p className="my-5">
            এই মর্মে প্রত্যয়ন করা যাইতেছে যে, আইনের ধারা ৪৯ অনুযায়ী উৎসে কর কর্তনযোগ্য সরবরাহ হইতে
            প্রযোজ্য মূল্য সংযোজন কর বাবদ উৎসে কর কর্তন করা হইল। কর্তনকৃত মূল্য সংযোজন করের অর্থ 
            বুক ট্রান্সফার / ট্রেজারি চালান / দাখিলপত্রে বৃদ্ধিকারী সমন্বয়ের মাধ্যমে সরকারি 
            কোষাগারে জমা প্রদান করা হইয়াছে। কপি এতদসংগে সংযুক্ত করা হইল (প্রযোজ্য ক্ষেত্রে)।
        </p>

        <div className="mb-10">
          <table className="min-w-full mushak1-table-head">
            <thead className=" bg-gray-50 text-[12px] font-medium ">

              <tr className="">
                <td className="border-2  right-5" rowSpan={2}>
                  {/* Quantity */}
                  ক্রমিক নং
                </td>

                <td className="border-2 right-5" colSpan={2}>
                  {/* Seller/Supplier */}
                  বিক্রেতা / সরবরাহকারী
                </td>


                <td className="border-2  right-5" colSpan={2}>
                  {/* Bill of Entry */}
                  সংশ্লিষ্ট কর চালানপত্র
                </td>

                <td className="border-2  right-5" rowSpan={2}>
                  {/* Total Price No Vat */}
                  মোট সরবরাহ মূল্য ১ (টাকায়)
                </td>


                <td className="border-2 " rowSpan={2}>
                  {/* Total Tax */}
                  মূসকের পরিমাণ (টাকা)
                </td>

                <td className="border-2 " rowSpan={3}>
                  {/* Vat */}
                  উৎসে কর্তনকৃত মূসকের পরিমাণ (টাকা)
                </td>

            
              </tr>

              <tr>
                <td className="border-2  right-5 w-[100px]" rowSpan={1}>
                  {/* Name */}
                  নাম
                </td>
                <td className="border-2  right-5" rowSpan={1}>
                  {/* NID NO */}
                  বিআইএন নম্বর
                </td>

                <td className="border-2  right-5" rowSpan={1}>
                  {/* Quantity unit */}
                  চালানপত্র/বিল অব এন্ট্রি নম্বর
                </td>
                <td className="border-2  right-5" rowSpan={1}>
                  {/* Total price no vat */}
                  ইস্যুর তারিখ
                </td>
              </tr>

              <tr></tr>

              <tr>
                <th>(১)</th>
                <th>(২)</th>
                <th>(৩)</th>
                <th>(৪)</th>
                <th>(৫)</th>
                <th>(৬)</th>
                <th>(৭)</th>
                <th>(৮)</th>
              </tr>
            </thead>

            <tbody>


                <tr>
                  <td>{incrementalId++}</td>
                  <td>{moshukData[0]?.supplier_name}</td>
                  <td>{moshukData[0]?.supplier_bin}</td>
                  <td>{moshukData[0]?.bill_of_entry}</td>
                  <td>{formatDate(moshukData[0]?.purchase_date)}</td>
                  <td>{totalItemPriceWithVatSum.toFixed(2)}</td>
                  <td>{(parseFloat(vatTotal || 0) + parseFloat(sdTotal || 0)).toFixed(2)}</td>
                  <td>{(parseFloat(vatTotal || 0) + parseFloat(sdTotal || 0)).toFixed(2)}</td>
                </tr>

            </tbody>
          </table>
          <table>
          <tfoot>
            <div className="mushak3-bottom-header flex justify-between mx-3 my-4">
              <div className="left-header">
                <p>ক্ষমতাপ্রাপ্ত কর্মকর্তার : </p>
                <p>নাম  : </p>
                <p>স্বাক্ষর : </p>
                <p>সিল : </p>
              </div>
            </div>
            </tfoot>
          </table>
        </div>
      </div>
    
    </div>
  );


};

export default MushakSixSix;
