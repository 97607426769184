import React, { useState } from 'react';
import axios from 'axios';
import Select from 'react-select';
import DashboardLayout from '../../Shared/DashboardLayout';
import { baseUrl } from '../../BaseUrl';
import { useEffect } from 'react';
import { AiFillDelete } from 'react-icons/ai';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const MushakSixSixAdd = () => {
  const navigate = useNavigate()
  
  const [formData, setFormData] = useState({
    bill_of_entry: null,
    status: null,
    supplier_name:''
  });
  
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
  });
  const [purchaseOptions, setPurchaseOptions] = useState([]);
  const [existingPurchaseId, setexistingPurchaseId] = useState([]);
  const [selectedPurchaseId, setSelectedPurchaseId] = useState(null);
  
  const purchaseStatusOptions = [
    { value: '0', label: 'Ordered' },
    { value: '1', label: 'WIP' },
    { value: '2', label: 'Complete' },
  ];


  const handleStatusChange = (selectedOption) => {
    setFormData({ ...formData, status: selectedOption.value });
  };

  const handleInputChange = (e, index, type) => {
    const updatedData = { ...formData };
    updatedData[e.target.name] = e.target.value;

    setFormData(updatedData);
  };

  useEffect(() => {
    const fetchPurchase = async () => {
      try {
        const jwtToken = localStorage.getItem("access");
        const response = await axios.get(`${baseUrl}/purchase/api/purchase_order_list/`, {
          headers: {
            Authorization: `JWT ${jwtToken}`,
          },
        });
  
        console.log(response.data)
        const completedPurchasesId = response.data.filter((item) => {
          return item.status === 'Complete' && item.bill_of_entry !== null && !existingPurchaseId.includes(item.bill_of_entry);
        });

        console.log(completedPurchasesId)

        setPurchaseOptions(
            completedPurchasesId.map((purchase) => ({
            value: purchase.bill_of_entry,
            label: `Bill Of Entry / Challan : ${purchase.bill_of_entry}`,
            supplier_name:purchase.supplier_name
          }))
        );
      } catch (error) {
        console.error('Error fetching inventories', error);
      }
    };
    fetchPurchase();
  }, [existingPurchaseId]);
  
  useEffect(() => {
    const fetchExistingData = async () => {
      try {
        const jwtToken = localStorage.getItem("access");
        const response = await axios.get(`${baseUrl}/purchase/api/certificate-of-tex-deduction-at-source/`, {
          headers: {
            Authorization: `JWT ${jwtToken}`,
          },
        });

        // Extract the existing purchase IDs from the response data
        const existingPurchaseIds = response.data.results.map(item => item.bill_of_entry);
        setexistingPurchaseId(existingPurchaseIds);
      } catch (error) {
        console.error('Error fetching existing data:', error);
      }
    };

    fetchExistingData();
  }, []);


  const handlePurchaseChange = (selectedOption) => {
    const { value, supplier_name } = selectedOption;
  
    setSelectedPurchaseId(selectedOption);
  
    setFormData({
      ...formData,
      bill_of_entry: value,
      supplier_name: supplier_name,
    });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const jwtToken = localStorage.getItem("access");
      const response = await axios.post(
        `${baseUrl}/purchase/api/certificate-of-tex-deduction-at-source/`,
        formData,  // Your form data
        {
          headers: {
            Authorization: `JWT ${jwtToken}`,
          },
        }
      );
      navigate("/mushak-6-6-list");
      Toast.fire({
        icon: 'success',
        title: 'Successfully Add',
      });
      }catch (error) {
          if(error.response.data.detail){
              Toast.fire({
              icon: 'error',
              title: `${error.response.data.detail}`,
              });
          }else{
              console.error('Error saving data:', error);
          }

      }
  };


  return (
    <DashboardLayout>
    <div className="px-5 mt-5 mb-10 pb-10">
        <div className=" w-4/4">
        <div className="bg-white p-8 rounded shadow-xl shadow-blue-500/40 w-[90%] mx-auto">
            <form onSubmit={handleSubmit}>
            <div className="flex justify-between rounded border-b-[1px] mb-10 bg-slate-900">
                <div className="mx-5 my-auto">
                <h1 className="uppercase font-semibold text-xl text-[#fff]">Certificate Of Tex Deduction At Source Add Form</h1>
                </div>
                <div className="my-2 mx-5">
                <button
                    type="submit"
                    className="w-[120px] bg-slate-700 hover:bg-slate-800 border text-white font-medium rounded-lg h-10"
                >
                    Submit
                </button>
                </div>
            </div>

            <div class="flex flex-wrap -mx-3 mb-2">

            <div class="w-full md:w-4/4 px-3 mb-8">
                <div className="flex  md:w-4/4 mx-5 ">
                <div class="md:w-[34%] my-auto">
                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-3"  for="inline-full-name">
                    Purchased Bill Of Entry / Challan No*
                </label>
                </div>
                <div class="md:w-[60%]">
                    <Select
                        id="inventory"
                        name="inventory"
                        value={selectedPurchaseId}
                        onChange={handlePurchaseChange}
                        options={purchaseOptions}
                        required
                    />
                </div>
                </div>
            </div>


                <div class="w-full md:w-4/4 px-3 mb-8">
                    <div className="flex md:w-4/4 mx-5 ">
                    <div class="md:w-[34%] my-auto">
                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-3"  for="inline-full-name">
                        Supplier Name
                        </label>
                    </div>
                    <div class="md:w-[60%]">
                       <input
                        type="text"
                        name="supplier_name"
                        value={formData.supplier_name}
                        readOnly
                        className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-blue-300"
                        placeholder="Supplier"
                    />
                    </div>
                    </div>
                </div>


            <div class="w-full md:w-4/4 px-3 mb-8">
                <div className="flex  md:w-4/4 mx-5 ">
                <div class="md:w-[34%] my-auto">
                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-3"  for="inline-full-name">
                    Status*
                </label>
                </div>
                <div class="md:w-[60%]">
                    <Select
                        id="status"
                        name="status"
                        value={purchaseStatusOptions.find((option) => option.value === formData.status)}
                        onChange={handleStatusChange}
                        options={purchaseStatusOptions}
                        required
                    />
                </div>
                </div>
            </div>
            </div>           
            </form>
        </div>
        </div>
  </div>
  </DashboardLayout>
  );
};

export default MushakSixSixAdd;