import Sidebar from "./Sidebar/Sidebar";
import DashboardHeader from "./DashboardHeader/DashboardHeader";
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { checkAuthenticated,load_user } from "../redux/actions/auth";

const DashboardLayout = ({ user,isAuthenticated,load_user,checkAuthenticated,children }) => {

  useEffect(() => {
    load_user();
  }, [load_user]);

  useEffect(() => {
    checkAuthenticated();
  }, [checkAuthenticated]);

  // console.log('layout',user)
  // console.log('checkAuthenticated',isAuthenticated)

  return (
    <div className="flex">
      {/* Sidebar */}
      <section className="w-55 bg-slate-900 h-screen fixed overflow-y-auto z-30 px-2">
        <Sidebar />
      </section>

      <div className="flex-1 flex flex-col pl-60">
        {/* Topbar */}
        <header className="bg-white shadow-lg p-4">
          <DashboardHeader />
        </header>

        {/* Main Content */}
        <main className="flex-1 overflow-y-auto my-10">
          {children}
        </main>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { load_user,checkAuthenticated })(DashboardLayout);
