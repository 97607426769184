import React, { useState } from 'react';
import axios from 'axios';
import Select from 'react-select';
import DashboardLayout from '../../Shared/DashboardLayout';
import { baseUrl } from '../../BaseUrl';
import { useEffect } from 'react';
import DatePicker from "react-datepicker";
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { format } from "date-fns";
import govLogo from "../../assets/images/gov-logo.png";


const ReturnSubmissionAdd = () => {
  const navigate = useNavigate()
  
  const [formData, setFormData] = useState({
    tax_preiod_from_date : '',
    tax_preiod_to_date : '',
    type_of_return : '',
    any_activities_in_this_tax_period: '',
    requested_amount_for_refund: '',
    tax_return_declarant : '',
    account_code : '',
    status : '0',
  });

  const [taxReturnDeclarantOptions, setTaxReturnDeclarantOptions] = useState([]);
  const [selectedTaxReturnDeclarant, setSelectedTaxReturnDeclarant] = useState(null);

  const [accountCodeOptions, setAccountCodeOptions] = useState([]);
  const [selectedAccountCode, setSelectedAccountCode] = useState(null);


  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
  });

  const statusOptions = [
    { value: '0', label: 'Ordered' },
    { value: '1', label: 'WIP' },
    { value: '2', label: 'Complete' },
  ];

  const returnTypeOptions = [
    {value:1, label: 'Main/Original Return (Section 64)'},
    {value:2, label:'Late Return (section 65)'},
    {value:3, label:'Amend Return (section 66)'} ,
    {value:4, label:'Full or Additional or Alternative Return ( Section 67)'}

  ]

  useEffect(() => {
    const fetchTaxReturnDeclarant = async () => {
      try {
        const jwtToken = localStorage.getItem("access");
        const response = await axios.get(`${baseUrl}/tax_return/api/tax-return-declarant/`, {
          headers: {
            Authorization: `JWT ${jwtToken}`,
          },
        });

        // console.log(response.data)

        setTaxReturnDeclarantOptions(
            response.data.map((trd) => ({
            value: trd.id,
            label: `${trd.name} | ${trd.mobile_number}`,
          }))
        );

      } catch (error) {
        console.error('Error fetching inventories', error);
      }
    };
    fetchTaxReturnDeclarant();
  }, []);


  useEffect(() => {
    const fetchAccountCode = async () => {
      try {
        const jwtToken = localStorage.getItem("access");
        const response = await axios.get(`${baseUrl}/tax_return/api/account-code/`, {
          headers: {
            Authorization: `JWT ${jwtToken}`,
          },
        });

        console.log(response.data)

        setAccountCodeOptions(
            response.data.map((ac) => ({
            value: ac.id,
            label: `${ac.vat_deposit_for_the_current}`,
          }))
        );

      } catch (error) {
        console.error('Error fetching inventories', error);
      }
    };
    fetchAccountCode();
  }, []);


  const handleTaxReturnDeclarantChange = (selectedOption) => {
    const tax_return_declarantId = selectedOption.value; 
    setSelectedTaxReturnDeclarant(selectedOption);
    setFormData({
      ...formData,
      tax_return_declarant: tax_return_declarantId, 
    });
  };

  const handleAccountCodeChange = (selectedOption) => {
    const accountCodeId = selectedOption.value; 
    setSelectedAccountCode(selectedOption);
    setFormData({
      ...formData,
      account_code: accountCodeId, 
    });
  };



  const handleStatusChange = (selectedOption) => {
    setFormData({ ...formData, status: selectedOption.value });
  };

  const handleReturnTypeChange = (selectedOption) => {
    setFormData({ ...formData, type_of_return: selectedOption.value });
  };

  const handleInputChange = (e, index, type) => {
    const updatedData = { ...formData };
    updatedData[e.target.name] = e.target.value;
    setFormData(updatedData);
  };

  const handleCheckboxChange = (e) => {
    const updatedData = { ...formData };
    updatedData['any_activities_in_this_tax_period'] = e.target.value;
    setFormData(updatedData);
  };
  
  const handleRequestedAmountCheckboxChange = (e) => {
    const updatedData = { ...formData };
    updatedData['requested_amount_for_refund'] = e.target.value;
    setFormData(updatedData);
  };
  
  const handleFromDateChange = (date) => {
    setFormData({
      ...formData,
      tax_preiod_from_date: date,
    });
  };
  
  const handleToDateChange = (date) => {
    setFormData({
      ...formData,
      tax_preiod_to_date: date,
    });
  };
  
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formattedFromDate = format(formData.tax_preiod_from_date, "dd-MM-yyyy");
    const formattedToDate = format(formData.tax_preiod_to_date, "dd-MM-yyyy");
  
    // Create a new object with the formatted dates and the rest of the form data
    const formDataWithFormattedDates = {
      ...formData,
      tax_preiod_from_date: formattedFromDate,
      tax_preiod_to_date: formattedToDate,
    };
  
    try {
      const jwtToken = localStorage.getItem("access");
      const response = await axios.post(
        `${baseUrl}/tax_return/api/return-submissions/`,
        formDataWithFormattedDates, // Send the formData with formatted dates
        {
          headers: {
            Authorization: `JWT ${jwtToken}`,
          },
        }
      );
      navigate("/mushak-9-1-list");
      Toast.fire({
        icon: "success",
        title: "Successfully Add",
      });
    } catch (error) {
      if (error.response.data.detail) {
        Toast.fire({
          icon: "error",
          title: `${error.response.data.detail}`,
        });
      } else {
        console.error("Error saving data:", error);
      }
    }
  };

  return (
    <DashboardLayout>
    <div className="px-5 mt-5 mb-10 pb-10">
        <div className=" w-4/4">
        <div className="bg-white p-8 rounded shadow-xl shadow-blue-500/40 w-[100%] mx-auto">
            <form onSubmit={handleSubmit}>
            <div className="flex justify-between rounded border-b-[1px] mb-10 bg-slate-900">
                <div className="mx-5 my-auto">
                <h1 className="uppercase font-semibold text-xl text-[#fff]">Return Submission Add Form</h1>
                </div>
                <div className="my-2 mx-5">
                <button
                    type="submit"
                    className="w-[120px] bg-slate-700 hover:bg-slate-800 border text-white font-medium rounded-lg h-10"
                >
                    Submit
                </button>
                </div>
            </div>

            <div class="flex flex-wrap -mx-3 mb-2">

                <div className="w-full md:w-2/4 px-3 mb-8 border-r-[1px]">
                    <div className="flex md:w-4/4 mx-5">
                        <div className="md:w-[40%] my-auto">
                            <label
                                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-3"
                                htmlFor="inline-full-name"
                            >
                                Tax Period From Date*
                            </label>
                        </div>
                        <div className="md:w-[60%]">
                            <DatePicker
                                selected={formData.tax_preiod_from_date}
                                onChange={handleFromDateChange}
                                className="block w-[250px] rounded-md border-0 pl-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                dateFormat="dd-M-yyyy"
                                placeholderText="Select from date"
                                required  
                            />
                        </div>
                    </div>
                </div>

                <div className="w-full md:w-2/4 px-3 mb-8">
                    <div className="flex md:w-4/4 mx-5">
                        <div className="md:w-[40%] my-auto">
                            <label
                                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-3"
                                htmlFor="inline-full-name"
                            >
                                Tax Period To Date*
                            </label>
                        </div>
                        <div className="md:w-[60%]">
                            <DatePicker
                                selected={formData.tax_preiod_to_date}
                                onChange={handleToDateChange}
                                className="block w-[270px] rounded-md border-0 pl-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                dateFormat="dd-M-yyyy"
                                placeholderText="Select to date"
                                required
                            />
                        </div>
                    </div>
                </div>


                <div className="w-full md:w-2/4 px-3 mb-8 border-r-[1px]">
                    <div className="flex md:w-4/4 mx-5">
                        <div className="md:w-[60%] my-auto">
                            <label
                                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-3"
                                htmlFor="any_activities_in_this_tax_period"
                            >
                                Any Activities in This Tax Period
                            </label>
                        </div>
                        <div className="md:w-[40%] mt-2">
                            <label className='mr-7'>
                                <input
                                    type="radio"
                                    name="any_activities_in_this_tax_period"
                                    value="Yes"                         
                                    checked={formData.any_activities_in_this_tax_period === 'Yes'}
                                    onChange={handleCheckboxChange}
                                    required  
                                />
                                <span className='px-2'>Yes</span>
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="any_activities_in_this_tax_period"
                                    value="No"
                                    checked={formData.any_activities_in_this_tax_period === 'No'}
                                    onChange={handleCheckboxChange}
                                    required  
                                />
                                 <span className='px-2'>No</span>
                            </label>
                        </div>
                    </div>
                </div>

                <div className="w-full md:w-2/4 px-3 mb-8">
                    <div className="flex md:w-4/4 mx-5">
                        <div className="md:w-[60%] my-auto">
                            <label
                                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-3"
                                htmlFor="requested_amount_for_refund"
                            >
                                Requested Amount for Refund
                            </label>
                        </div>
                        <div className="md:w-[40%] mt-2">
                            <label className='mr-7'>
                                <input
                                    type="radio"
                                    name="requested_amount_for_refund"
                                    value="Yes"
                                    checked={formData.requested_amount_for_refund === 'Yes'}
                                    onChange={handleRequestedAmountCheckboxChange}
                                    required
                                />
                                <span className='px-2'>Yes</span>
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="requested_amount_for_refund"
                                    value="No"
                                    checked={formData.requested_amount_for_refund === 'No'}
                                    onChange={handleRequestedAmountCheckboxChange}
                                    required
                                />
                                <span className='px-2'>No</span>
                            </label>
                        </div>
                    </div>
                </div>

                <div class="w-full md:w-4/4 px-3 mb-8">
                    <div className="flex  md:w-4/4 mx-5 ">
                        <div class="md:w-[25%] my-auto">
                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-3" for="inline-full-name">
                                Return Type*
                            </label>
                        </div>
                        <div class="md:w-[75%]">
                            <Select
                                id="type_of_return"
                                name="type_of_return"
                                value={returnTypeOptions.find((option) => option.value === formData.type_of_return)}
                                onChange={handleReturnTypeChange}
                                options={returnTypeOptions}
                                required
                            />
                        </div>
                    </div>
                </div>


                <div class="w-full md:w-4/4 px-3 mb-8">
                    <div className="flex  md:w-4/4 mx-5 ">
                        <div class="md:w-[25%] my-auto">
                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-3" for="inline-full-name">
                                tax_return_declarant*
                            </label>
                        </div>
                        <div class="md:w-[75%]">
                            <Select
                                id="tax_return_declarant"
                                name="tax_return_declarant"
                                value={selectedTaxReturnDeclarant}
                                onChange={handleTaxReturnDeclarantChange}
                                options={taxReturnDeclarantOptions}
                                required
                            />
                        </div>
                    </div>
                </div>


                <div class="w-full md:w-4/4 px-3 mb-8">
                    <div className="flex  md:w-4/4 mx-5 ">
                        <div class="md:w-[25%] my-auto">
                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-3" for="inline-full-name">
                                Account Code*
                            </label>
                        </div>
                        <div class="md:w-[75%]">
                            <Select
                                id="account_code"
                                name="tax_return_declarant"
                                value={selectedAccountCode}
                                onChange={handleAccountCodeChange}
                                options={accountCodeOptions}
                                required
                            />
                        </div>
                    </div>
                </div>

                {/* <div class="w-full md:w-4/4 px-3 mb-8">
                    <div className="flex  md:w-4/4 mx-5 ">
                        <div class="md:w-[25%] my-auto">
                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-3" for="inline-full-name">
                                status*
                            </label>
                        </div>
                        <div class="md:w-[75%]">
                            <Select
                                id="status"
                                name="status"
                                value={statusOptions.find((option) => option.value === formData.status)}
                                onChange={handleStatusChange}
                                options={statusOptions}
                                required
                            />
                        </div>
                    </div>
                </div> */}

            </div>

            </form>
        </div>
        </div>
  </div>
  </DashboardLayout>
  );
};

export default ReturnSubmissionAdd;
