import { Table } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { AiFillDelete,AiFillEdit } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import { baseUrl } from "../BaseUrl";

const SupplyDetailTable = () => {
  const [supplyData, setSupplyData] = useState([]);
  const navigate = useNavigate();
  // fetch data function...
  const getSupplierData = async () => {
    try {
      const jwtToken = localStorage.getItem("access");
      console.log("jwt token from create user..", jwtToken);
      const res = await axios.get(`${baseUrl}/purchase/api/supplier/`, {
        headers: {
          Authorization: `JWT ${jwtToken}`,
        },
      });
      if (res) {
        console.log("fetch supply data", res);
        const finalData = res.data.results;
        setSupplyData(finalData);
      }
    } catch (error) {
      console.log("error from get supply api", error);
    }
  };

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
  });


  useEffect(() => {
    getSupplierData();
  }, []);

    // data delete method 
    const handleDelete = async (itemId) => {
      try {
        const jwtToken = localStorage.getItem("access");
        await axios.delete(`${baseUrl}/purchase/api/supplier/${itemId}/`, {
          headers: {
            Authorization: `JWT ${jwtToken}`,
          },
        });
        // If the deletion is successful, update the inventoryData state to reflect the change.
        setSupplyData((prevData) =>
          prevData.filter((item) => item.id !== itemId)
        );
        Toast.fire({
          icon: 'success',
          title: 'Successfully Delete',
        });
  
      } catch (error) {
        Toast.fire({
          icon: 'error',
          title: `Ops! ${error.message}`,
        });
      }
    };

  // eslint-disable-next-line no-unused-vars
  const [columns, setColumns] = useState([
    {
      key: 1,
      title: "Company name",
      dataIndex: "company_name",
    },
    {
      key: 2,
      title: "Contact Person",
      dataIndex: "contact_person",
    },
    {
      key: 2,
      title: "Phone Number",
      dataIndex: "phone_number",
    },
    {
      key: 3,
      title: "Address",
      dataIndex: "address",
    },

    {
      key: 5,
      title: "Registration No",
      dataIndex: "registration_no",
    },
    {
      key: 6,
      title: "NID NO",
      dataIndex: "nid_no",
    },
    {
      key: 7,
      title: "Actions",
      dataIndex: "actions",
      // eslint-disable-next-line no-unused-vars
      render: (_, record) => {
        return (
          <>
            <div className="flex gap-2">
              <div className="">
                <button
                   onClick={() => navigate(`/editSupply/${record.id}`)}
                  className="bg-blue-500 hover:bg-blue-600 rounded"
                >
                  <AiFillEdit className="text-white text-xl " />
                </button>

                <button
                  onClick={() => handleDelete(record.id)}
                  className="rounded mr-1"
                >
                  <AiFillDelete className="text-xl text-red-500/100 hover:text-red-600 " />
                </button>
              </div>

            </div>
          </>
        );
      },
    },
  ]);

  return (
    <div className="px-5">
      <div className="flex items-center justify-between py-5">
        <h1 className=" uppercase font-semibold  text-xl">Supplier List</h1>
        <Link to="/createSupply">
          <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
            Create New
          </button>
        </Link>
      </div>

      <div className='overflow-x-auto w-[1090px]'>
            <Table
            bordered
                columns={columns}
                dataSource={supplyData}
                pagination={{
                  pageSize: 10, 
                  total: supplyData.length,
                  showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                  showSizeChanger: false,
                }}
            />
        </div>
    </div>
  );
};

export default SupplyDetailTable;
