import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import Swal from 'sweetalert2';
import { baseUrl } from "../BaseUrl";

const InventoryCreateForm = () => {
  const navigate = useNavigate();
  const [uom, setUom] = useState("");
  const [name, setName] = useState("");
  const [product_hscode, setProduct_hscode] = useState("");
  const [unit_price, setUnit_price] = useState("");
  const [current_stock, setCurrent_stock] = useState("");
  const [total_price, setTotal_price] = useState("");
  const [type, setType] = useState("");

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
  });

  // handle type dropdown functionality....
  const typeList = [
    { id: "1", name: "Finished Good" },
    { id: "2", name: "Raw Material" },
  ];
  // handle UOM drop down functionaliti
  const uomList = [
    { id: "1", name: "Pieces" },
    { id: "2", name: "Gram" },
    { id: "3", name: "Meter" },
    { id: "4", name: "Capacity" },
  ];
  // handle inventory type drop down
  const [isTypeOpen, setIsTypeOpen] = useState(false);
  const [selectedTypeOption, setSelectedTypeOption] = useState(null);

  const togglingType = () => setIsTypeOpen(!isTypeOpen);
  const onOptionClickedType = (value) => () => {
    setSelectedTypeOption(value.name);
    setType(value.id);
    setIsTypeOpen(false);
  };
  // handle UOM drop down
  const [isUomOpen, setIsUomOpen] = useState(false);
  const [selectedUomOption, setSelectedUomOption] = useState(null);

  const toggling = () => setIsUomOpen(!isUomOpen);

  const onOptionClicked = (value) => () => {
    // console.log("selected inventory inside", value);
    setSelectedUomOption(value.name);
    setUom(value.id);
    setIsUomOpen(false);
  };

  // all state hear .....

  // handle change function
  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "name") {
      setName(value);
    } else if (name === "unit_price") {
      setUnit_price(value);
    } else if (name === "currentStock") {
      setCurrent_stock(value);
    }else if (name === 'product_hscode'){
      setProduct_hscode(value)
    }
  };

  // create inventory calculation....
  function calculateTotal(current_stock, unit_price) {
    const numberCurrentStock = parseFloat(current_stock);
    const numberUnitPrice = parseFloat(unit_price);
    const total = numberUnitPrice * numberCurrentStock;
    return total.toFixed(2); // Round to two decimal places
  }
  
  useEffect(() => {
    if (current_stock && unit_price) {
      const total = calculateTotal(current_stock, unit_price);
      setTotal_price(total);
    }
  }, [current_stock, unit_price]);

  // handle submit function...
  const handleSubmit = async (event) => {
    event.preventDefault()
    try {
      const inventoryData = {
        uom,
        name,
        unit_price,
        current_stock,
        total_price,
        type,
        product_hscode
      };
      console.log("inventory data post ", inventoryData);
      // Get JWT token from localStorage
      const jwtToken = localStorage.getItem("access");
      const res = await axios.post(
        `${baseUrl}/inventory/api/inventory/`,
        inventoryData,
        {
          headers: {
            Authorization: `JWT ${jwtToken}`,
          },
        }
      );
      if (res) {
        navigate('/inventory');
        Toast.fire({
          icon: 'success',
          title: 'Successfully Add',
        });
      }
    } catch (error) {
      if(error.response.data.name){
        Toast.fire({
          icon: 'error',
          title: `Ops! ${error.response.data.name}`,
        });
       }else{
        Toast.fire({
          icon: 'error',
          title: `Ops! ${error.messages}`,
        });
       }
      if (error.response.data.code == "token_not_valid") {
        alert("token expired please login again..");
        navigate("/login");
      }
    }
  };


  return (
    <div className="p-5">
      <div className=" w-2/4">
        <div className="bg-white p-8 rounded shadow-xl">
        <div className="flex justify-between rounded border-b-[1px] mb-10 bg-slate-900">
                <div className="mx-5 my-auto py-3">
                <h1 className="uppercase font-semibold text-xl text-[#fff]">Inventory Add Form</h1>
                </div>
            </div>

          <form onSubmit={handleSubmit}>
            <div className="w-full max-w-lg mt-2">
              <div className="md:flex md:items-center">
                <div className="w-[200px]">
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-2" htmlFor="inline-full-name">
                    Name*
                  </label>
                </div>
                <div className="w-full mx-1">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    value={name}
                    onChange={handleChange}
                    className="block w-full rounded-md border-0 pl-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="Enter Name"
                    required
                  />
                </div>
              </div>
            </div>

            <div className="w-full max-w-lg mt-2">
              <div className="md:flex md:items-center">
                <div className="w-[200px]">
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-2" htmlFor="inline-full-name">
                    Product HS Code*
                  </label>
                </div>
                <div className="w-full mx-1">
                  <input
                    type="text"
                    name="product_hscode"
                    id="product_hscode"
                    value={product_hscode}
                    onChange={handleChange}
                    className="block w-full rounded-md border-0 pl-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="Enter product HS code"
                    required
                  />
                </div>
              </div>
            </div>

          <div className="flex flex-wrap -mx-3 mb-2 mt-1">
            <div className="w-full md:w-2/4 px-3 mb-2 md:mb-0">
              <label
                  htmlFor="uom"
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-2"
                >
                  UOM*
                </label>
                <Select
                    options={uomList.map((option) => ({
                      value: option.id,
                      label: option.name,
                    }))}
                    onChange={(selectedOption) => {
                      setSelectedUomOption(selectedOption.label);
                      setUom(selectedOption.value);
                      setIsUomOpen(false);
                    }}
                    isSearchable={false}
                    required
                  />
            </div>
            <div className="w-full md:w-2/4 px-3 mb-2 md:mb-0">
              <label htmlFor="uom"
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-2"
                >
                  Type*
                </label>
                <Select
                    options={typeList.map((option) => ({
                      value: option.id,
                      label: option.name,
                    }))}
                    onChange={(selectedOption) => {
                      setSelectedTypeOption(selectedOption.label);
                      setType(selectedOption.value);
                      setIsTypeOpen(false);
                    }}
                    isSearchable={false}
                    required
                  />
            </div>
          </div>



            <div className="w-full max-w-lg">
              <div className="md:flex md:items-center mb-2">
                <div className="w-[200px]">
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-5" htmlFor="inline-full-name">
                    Unit Price*
                  </label>
                </div>
                <div className="w-[100%]">
                  <input
                    type="number"
                    min='0'
                    step="0.01"
                    value={unit_price}
                    name="unit_price"
                    id="unit_price"
                    onChange={handleChange}
                    className="block w-full rounded-md border-0 pl-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="Unit price"
                    required
                  />
                </div>
              </div>
            </div>

            <div className="w-full max-w-lg">
              <div className="md:flex md:items-center mb-2">
                <div className="w-[200px]">
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-5" htmlFor="inline-full-name">
                    Current Stock*
                  </label>
                </div>
                <div className="w-[100%]">
                  <input
                    type="number"
                    min='0'
                    step="0.01"
                    value={current_stock}
                    name="currentStock"
                    id="currentStock"
                    onChange={handleChange}
                    className="block w-full rounded-md border-0 pl-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="Enter your Current Stock.."
                    required
                  />
                </div>
              </div>
            </div>

            <div className="w-full max-w-lg">
              <div className="md:flex md:items-center mb-2">
                <div className="w-[200px]">
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-5" htmlFor="inline-full-name">
                    Total Price*
                  </label>
                </div>
                <div className="w-[100%]">
                  <input
                    type="text"
                    step="0.01"
                    value={total_price}
                    name="totalPrice"
                    id="totalPrice"
                    className="block w-full rounded-md border-0 pl-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder=" Enter Total Price.."
                    required
                  />
                </div>
              </div>
            </div>

            <div className="mt-5">
              <button
                type="submit"
                className="w-[150px] bg-sky-500 border text-white font-medium rounded-lg h-10"
              >
                Submit
              </button>
            </div>

          </form>
        </div>
      </div>
    </div>
  );
};

export default InventoryCreateForm;
