import DashboardLayout from "../Shared/DashboardLayout";
import SupplyCreateForm from "../components/SupplyCreateForm";

const SupplyCreatePage = () => {
  return (
    <DashboardLayout>
      <SupplyCreateForm />
    </DashboardLayout>
  );
};

export default SupplyCreatePage;
