import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import mothon6icon from "../assets/images/motion6icon.png";
import { AiFillDelete, AiFillEdit,AiFillFileAdd,AiFillFilePdf, AiFillLock } from "react-icons/ai";
import { format } from "date-fns";
import { Table, Input, Button, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Swal from 'sweetalert2';
import { baseUrl } from "../BaseUrl";
const { Search } = Input;

const PurchaseDetailTable = () => {
  const navigate = useNavigate();

  const [purchasesData, setPurchasesData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const componentPDF = useRef();

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
  });

  // fetch data function...
  const fetchPurchaseData = async () => {
    try {
      const jwtToken = localStorage.getItem("access");
      const res = await axios.get(`${baseUrl}/purchase/api/purchase_order_list/`, {
        headers: {
          Authorization: `JWT ${jwtToken}`,
        },
      });
      if (res) {
        const finalData = res.data;
        console.log(finalData)
        setPurchasesData(finalData);
      }
    } catch (error) {
      console.log("error from get inventory API", error);
    }
  };

  useEffect(() => {
    fetchPurchaseData();
  }, []);

  
  const generatePdf = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: "Motion6",
    onAfterPrint: () => alert("data saved in PDF"),
  });

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, "dd-M-yyyy"); // Change the format here
  };

  // Filter function for the "INVENTORY," "ORDERED DATE," "SUPPLIER," and "BILL OF ENTRY" fields
  const onFilter = (value, record) => {
    const billOfEntryMatch =
      record.bill_of_entry &&
      record.bill_of_entry.toLowerCase().includes(value.toLowerCase());
    return (
      billOfEntryMatch
    );
  };

  const columns = [
    {
      key: 1,
      title: (
        <div className="fixed-header-cell w-[100px]">ORDERED DATE</div>
      ),
      dataIndex: "order_date",
      render: (text) => formatDate(text),
    },
    {
      key: 2,
      title: (
        <div className="fixed-header-cell w-[100px]">BILL OF ENTRY</div>
      ),
      dataIndex: "bill_of_entry",
      filterDropdown: ({ setSelectedKeys }) => (
        <div style={{ padding: 8 }}>
          <Input
            className="border rounded"
            placeholder="Search Bill Of Entry"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter,
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(
            () => document.getElementById("billOfEntrySearch")?.select(),
            100
          );
        }
      },
    },

    {
      key: 3,
      title: (
        <div className="fixed-header-cell w-[70px]">SUPPLIER</div>
      ),
      dataIndex: "supplier_name",
    },

    {
      key: 4,
      title: (
        <div className="fixed-header-cell w-[110px]">Status</div>
      ),
      dataIndex: "status",
    },
    {
      key: 5,
      title: (
        <div className="fixed-header-cell w-[180px]">ACTIONS</div>
      ),
      dataIndex: "actions",
      render: (_, record) => {
        return (
          <>
            <div className="flex">
            {record.status !== 'Complete'?
              <div className="flex gap-2">
                <button
                  onClick={() => navigate(`/purchases-edit/${record.bill_of_entry}`)}
                  className="bg-blue-500 hover:bg-blue-600 rounded"
                >
                  <AiFillEdit className="text-white text-xl " />
                </button>

                <button
                  onClick={() => handleDelete(record.bill_of_entry)}
                  className="rounded mr-1"
                >
                  <AiFillDelete className="text-xl text-red-500/100 hover:text-red-600 " />
                </button>
              </div>
              :<>

                {/* <button
                    onClick={() => navigate(`/mushak-6-1-purchases/${record.id}`)}
                      className="rounded"
                        >
                      <div className="flex items-center rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-[#000] ring-1 ring-inset ring-gray-500/10">
                        <div><AiFillFilePdf className="text-[#15803d] text-xl " /></div>
                          <div>M-6.1</div>
                      </div> 
                </button> */}
                <button
                      className="rounded"
                        >
                      <div className="flex items-center rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-[#000] ring-1 ring-inset ring-gray-500/10">
                        <div><AiFillLock className="text-[#15803d] text-xl " /></div>
                          <div>Complated</div>
                      </div> 
                </button>
                
              </>
            }
            </div>
          </>
        );
      },
    },
  ];

  const filteredData = purchasesData?.filter((record) => {
    return onFilter(searchText, record);
  });

  const handleDelete = async (billOfEntryNumber) => {
    try {
        const jwtToken = localStorage.getItem("access");
        // Fetch all sales orders
        const response = await axios.get(`${baseUrl}/purchase/api/purchase/`, {
            headers: {
                Authorization: `JWT ${jwtToken}`,
            },
        });

        if (response.status !== 200) {
            // Handle the error here, e.g., show an error toast
            return;
        }

        const allSalesOrders = response.data.results;
        console.log('allSalesOrders',allSalesOrders)
        // Filter sales orders by bill_of_entry number
        const ordersToDelete = allSalesOrders.filter(
            (item) => item.bill_of_entry === billOfEntryNumber
        );

        // Delete each order with the same bill_of_entry number
        for (const order of ordersToDelete) {
            await axios.delete(`${baseUrl}/purchase/api/purchase/${order.id}/`, {
                headers: {
                    Authorization: `JWT ${jwtToken}`,
                },
            });
        }

        // delete after update sales data 
        // console.log("ordersToDelete:", ordersToDelete);
        setPurchasesData((prevData) =>
            prevData.filter((item) => {
                // console.log("item:", item);
                if (item.bill_of_entry && ordersToDelete.some((order) => order.bill_of_entry === item.bill_of_entry)) {
                    return false; // Filter out items with matching chalan number
                }
                return true; // Keep items with different chalan numbers or where chalan is undefined
            })
        );
        

        Toast.fire({
            icon: 'info',
            title: 'Successfully Deleted',
        });
    } catch (error) {
        if (error.response && error.response.data.detail) {
            Toast.fire({
                icon: 'error',
                title: `Ops! ${error.response.data.detail}`,
            });
        } else {
            Toast.fire({
                icon: 'error',
                title: `Ops! ${error.message}`,
            });
        }
    }
};

  return (


<div className="px-5">
    <div className="flex items-center justify-between py-5">
    <h1 className=" uppercase font-semibold  text-xl">Purchase Details</h1>
            <div className="space-x-4">
              {/* <button
                onClick={generatePdf}
                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
              >
                OPEN PDF
              </button> */}

                <button onClick={() => navigate("/createPurchases")} className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                  <div className="flex ">
                    <div className="mt-1">
                    <AiFillFileAdd />
                    </div>
                    <div>Add New</div>
                  </div>
                </button>
                <button onClick={() => navigate("/mushak-6-1")} className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                  <div className="flex ">
                    <div className="mt-1">
                    <AiFillFilePdf />
                    </div>
                    <div>M-6.1</div>
                  </div>
                </button>


            </div>
    </div>

      <div className='overflow-x-auto w-[1090px]'>
            <Table
            bordered
                columns={columns}
                dataSource={searchText ? filteredData : purchasesData}
                pagination={{
                  pageSize: 9, 
                  total: purchasesData?.length,
                  showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                  showSizeChanger: false,
                }}
            />
      </div>
</div>
  );
};

export default PurchaseDetailTable;
