
import DashboardHome from "./pages/DashboardHome";

function App() {
  return (
    <>
      <div>
        <DashboardHome />
      </div>
    </>
  );
}

export default App;
