import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useReactToPrint } from "react-to-print";
import { Link, useParams } from "react-router-dom";
import { format } from "date-fns";
import { baseUrl } from "../../BaseUrl";


const MushakFourThree = () => {
  const componentPDF = useRef();

  const pdfGeneretor = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: "Mushak-4.3",
    pageStyle: `
      @page landscape {
        size: A4 landscape;
        margin: 20mm 10mm;
      }
      @page portrait {
        size: A4 portrait;
        margin: 20mm 10mm;
      }
      @page {
        margin: 4mm;
      }
      body {
        margin: 0;
      }
    `,
    contentStyle: `
      @page landscape {
        transform: rotate(0deg) translate(0, 0);
      }
      @page portrait {
        transform: rotate(90deg) translate(0, 0);
      }
    `
  });

  const {id} = useParams()
  const [data, setData] = useState(null);
  const [challan, setChallan] = useState(''); // State to store user input
  const [companyData, setCompanyData] = useState([]);
  const jwtToken = localStorage.getItem("access");

  useEffect(() => {
    const fetchDeclarationOfMpcData = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}/purchase/api/declaration-of-mpc/${id}/`,
          {
            headers: {
              Authorization: `JWT ${jwtToken}`,
            },
          }
        );
        const existingData = response.data; 
        setData(existingData);
        console.log(existingData)
      } catch (error) {
        console.error('Error fetching production data', error);
      }
    };
    fetchDeclarationOfMpcData();
  }, [id]);

  const handleInputChange = (e) => {
    setChallan(e.target.value); // Update the 'challan' state with user input
  };

  useEffect(() => {
  }, [challan]);



  useEffect(() => {
      const fetchcompanyData = async () => {
          try {
              const jwtToken = localStorage.getItem("access");
              const response = await axios.get(`${baseUrl}/coa/api/company/`, {
                  headers: {
                      Authorization: `JWT ${jwtToken}`,
                  },
              });

              setCompanyData(response.data.results);
              // console.log(response.data.results)
          } catch (error) {
          }
      };

      fetchcompanyData();
  }, []);


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, "dd-M-yyyy");
  };

  let incrementalId = 1;


  const totalRawMaterialAmount = data?.production.rawmaterial_set.reduce((total, rawMaterial) => {
    const rawMaterialAmountFloat = parseFloat(rawMaterial?.raw_material_total_amount);
    return total + (isNaN(rawMaterialAmountFloat) ? 0 : rawMaterialAmountFloat);
  }, 0);

  const pvasTotalAmount = data?.production_value_addition_sector.reduce((total, pvas) => {
    const amountFloat = parseFloat(pvas?.amount);
    return total + (isNaN(amountFloat) ? 0 : amountFloat);
  }, 0);

  const totalQuantity = data?.production.rawmaterial_set.reduce((total, rawMaterial) => {
    const quantity = parseFloat(rawMaterial?.rm_quantity);
    return total + (isNaN(quantity) ? 0 : quantity);
  }, 0);

  const totalAmountOfWastage = data?.production.rawmaterial_set.reduce((total, rawMaterial) => {
    const amountOfWastage = parseFloat(rawMaterial?.amount_of_wastage);
    return total + (isNaN(amountOfWastage) ? 0 : amountOfWastage);
  }, 0);

  return (
    <div className=" ">
      <div className="flex justify-between my-4 px-5 shadow-lg pb-4">
            <Link className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" to='/mushak-4-3-list'> Back To Mushak 4.3 List</Link>
            
            <button
              onClick={pdfGeneretor}
              className="bg-green-600 w-[150px] hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
            >
              Download pdf
            </button>

      </div>

      <div
        ref={componentPDF}
        style={{ width: "99%", height: "100%" }}
        className="flex flex-col mx-auto "
      >
        <div className="pdf-head">
          <div className="top-header my-3"> 
            <p className="text-center mb-1">গণপ্রজাতন্তী বাংলাদেশ সরকার</p>
            <p className="text-center mb-1">জাতীয় রাজস্ব বোর্ড, ঢাকা |</p>
            <p className="text-center mb-1">উপকরণ-উৎপাদ সহগ (Input-Product Coefficient) ঘোষণা</p>
            <p className="text-center mb-1">(বিধি ২১ দ্রষ্টব্য)</p>
            
            <p>
              <table className="">
              
              {companyData?.[0] && (
                <>
                  <tr >
                    <td className="pr-2 text-[15px]">প্রতিষ্টানের নাম</td>
                    <td>:</td>
                    <td className="pl-1 text-[15px]">{companyData[0]?.company_name}</td>
                  </tr>
                  <tr >
                    <td className="pr-2 text-[14px]">ঠিকানা </td>
                    <td>:</td>
                    <td className="pl-1 text-[14px]">
                    {companyData[0]?.address && (
                          <div>
                            {companyData[0]?.address.split(' ').map((word, index, wordsArray) => (
                              <span key={index}>
                                {word}
                                {' '}
                                {index % 10 === 9 && index !== wordsArray.length - 1 && <br />}
                              </span>
                            ))}
                          </div>
                        )}
                    </td>
                  </tr>
                  <tr >
                    <td className="pr-2 text-[14px]">নিবন্ধন নম্বর/বিন </td>
                    <td>:</td>
                    <td className="pl-1 text-[14px]">{companyData[0]?.vin}</td>
                  </tr>
                  <tr >
                    <td className="pr-2 text-[14px]">দাখিলের তারিখ </td>
                    <td>:</td>
                    <td className="pl-1 text-[14px]"></td>
                  </tr>
                </>
              )}

              </table>
              <tr >
                    <td className="pr-2 text-[14px]">ঘোষিত সহগ অনুযায়ী পণ্য / সেবার প্রথম সরবরাহের তারিখ</td>
                    <td>:</td>
                    <td className="pl-1 text-[14px]"></td>
                </tr>
            </p>
          </div>


        </div><hr />

        <div className="mushak-3-pdf">
        <table className="min-w-full mushak1-table-head">
            <thead className=" bg-gray-50 text-[12px] font-medium">
              <tr className="bg-gray-50">
              <td
                  className="border-2 "
                  rowSpan={3}
                >
                  ক্রমিক
                </td>
                <td
                  style={{ width: "70px" }}
                  className="border-2 "
                  rowSpan={3}
                >
                  পণ্যের এইচ এস কোড / সেবা কোড 
                </td>

                <td
                  style={{ width: "70px" }}
                  className="border-2 "
                  rowSpan={3}
                >
                  পণ্যের নাম ও বিবরণ 
                </td>

                <td
                  style={{ width: "70px" }}
                  className="border-2 "
                  rowSpan={3}
                >
                  সরবরাহের একক  
                </td>

                {/* 5-9 start */}
                <td className="border-2 " colSpan={5}>
                  {/* Initial available stock */}
                  একক পণ্য/সেবা সরবরাহে ব্যবহার্য যাবতীয় উপকরণের/কাঁচামালের ও প্যাকিং সামগ্রীর বিবরণ,পরিমান ও ক্রয়মূল্য 
                    (উপকরণভিত্তিক অপচয় শতকরা হারসহ)
                </td>
                {/* 5-9 end */}
                
                {/* 10-11 start */}
                <td className="border-2 " colSpan={2}>
                  {/* Manufacturing */}
                  মূল্য সংযোজনের বিবরণ 
                </td>
                {/* 10-11 end */}

                <td
                  style={{ width: "70px" }}
                  className="border-2 "
                  rowSpan={3}
                >
                  মন্তব্য  
                </td>
              </tr>

            {/* 5-9 start */}
              <tr className="">
                <td className="border-2  right-5" rowSpan={1}>
                  বিবরণ
                </td>
                <td className="border-2  right-5" rowSpan={1}>

                  অপচয়সহ পরিমান
                </td>
                <td className="border-2  right-5" rowSpan={1}>
                  মূল্য
                </td>
                <td className="border-2  right-5" rowSpan={2}>
                  {/* Total Price No Vat */}
                  অপচয়ের পরিমান
                </td>

                <td className="border-2  right-5" rowSpan={2}>
                  {/* Quantity */}
                  শতকরা হার
                </td>

            {/* 5-9 end */}
            
            {/* 10-11 start */}
                <td className="border-2 " rowSpan={2}>
                  {/* Total Price No Vat */}
                  মূল্য সংযোজনের খাত
                </td>

                <td className="border-2  right-5" colSpan={1}>
                  {/* Quantity */}
                  মূল্য
                </td>

            {/* 10-11 end */}

              </tr>
              <tr>

              </tr>
              <tr>
                <th>(১)</th>
                <th>(২)</th>
                <th>(৩)</th>
                <th>(৪)</th>
                <th>(৫)</th>
                <th>(৬)</th>
                <th>৭</th>
                <th>৮</th>
                <th>(৯)</th>
                <th>(১০)</th>
                <th>(১১)</th>
                <th>(১২)</th>
              </tr>
            </thead>

            <tbody>          
                <>
                  <tr className="text-[12px]">
                    <td className="py-1 border px-1">
                      {/* 1 */}
                      {incrementalId++}
                    </td>
                    {/* date  */}
                    <td className="py-1 border px-1">
                    {data?.production.inventory_hscode}
                    {data?.production.rawmaterial_set.map((rawMaterial) => (
                            <>
                            <p>{rawMaterial?.raw_material_hscode}</p>
                            </>                          
                        ))}
                    </td>

                  	{/* Initial available stock col data  */}
                    <td  className="py-1 border px-1">
                      {/* 3 */}
                      {data?.production.inventory_name}
                    </td>
                    <td className="py-1 border px-1">
                      {/* 4 */}
                      {data?.production.inventory_uom_text}
                    </td>

                    {/* Manufacturing col data */}
                    <td className="py-1 border px-1">
                      {/* 5 */}
                      {data?.production.rawmaterial_set.map((rawMaterial) => (
                            <>
                            <p>{rawMaterial?.raw_material_name}</p>
                            </>                          
                        ))}
                    </td>
                    <td className="py-1 border px-1">
                      {/* 6 */}
                      {data?.production.rawmaterial_set.map((rawMaterial) => (
                            <>
                            <p>{rawMaterial?.rm_quantity}</p>
                            </>                          
                        ))}
                    </td>
                    
                    <td className="py-1 border px-1">
                        {data?.production.rawmaterial_set.map((rawMaterial) => (
                            <>
                            <p>{rawMaterial?.raw_material_total_amount}</p>
                            </>                          
                        ))}
                    </td>
                    <td className="py-1 border px-1">
                        {data?.production.rawmaterial_set.map((rawMaterial) => (
                            <>
                            <p>{rawMaterial?.amount_of_wastage?rawMaterial?.amount_of_wastage:'...'}</p>
                            </>                          
                        ))}
                    </td>

                    <td className="py-1 border px-1">
                            {/* 9 */}
                        {data?.production.rawmaterial_set.map((rawMaterial) => (
                            <p>
                            {rawMaterial.amount_of_wastage ? ((100 / parseFloat(rawMaterial.rm_quantity)) * parseFloat(rawMaterial.amount_of_wastage)).toFixed(2) : '...'}
                            </p>
                        ))}
                    </td>
                    <td className="py-1 border px-1">
                    {data?.production_value_addition_sector.map((pvas) => (
                            <>
                            <p>{pvas?.sector_name}</p>
                            </>                          
                        ))}
                    </td>
                    <td className="py-1 border px-1">
                        {data?.production_value_addition_sector.map((pvas) => (
                            <>
                            <p>{pvas?.amount}</p>
                            </>                          
                        ))}
                    </td>


                    <td className="py-1 border px-1">

                    </td>
                  </tr>
                </>
            </tbody>
            <tfoot>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>{totalQuantity?.toFixed(2)}</td>
                <td>{totalRawMaterialAmount?.toFixed(2)}</td>
                <td>{totalAmountOfWastage?.toFixed(2)}</td>
                <td></td>
                <td></td>
                <td>{pvasTotalAmount?.toFixed(2)}</td>
                <td>{(pvasTotalAmount + totalRawMaterialAmount).toFixed(2)}</td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    
    </div>
  );


};

export default MushakFourThree;
