import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useReactToPrint } from "react-to-print";
import { Link, useNavigate, useParams } from "react-router-dom";
import { format } from "date-fns";
import { baseUrl } from "../../BaseUrl";

const SingleMushakSixThreePdf = () => {
  const componentPDF = useRef();

  const pdfGeneretor = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: "Mushak-6.3",
    pageStyle: `
      @page landscape {
        size: A4 landscape;
        margin: 20mm 10mm;
      }
      @page portrait {
        size: A4 portrait;
        margin: 20mm 10mm;
      }
      @page {
        margin: 4mm;
      }
      body {
        margin: 0;
      }
    `,
    contentStyle: `
      @page landscape {
        transform: rotate(0deg) translate(0, 0);
      }
      @page portrait {
        transform: rotate(90deg) translate(0, 0);
      }
    `
  });
  
  const navigate = useNavigate();
  const { chalan } = useParams();
  const [data, setData] = useState(null);
  const [companyData, setCompanyData] = useState([]);
  const jwtToken = localStorage.getItem("access");

  const fetchMoshukData = async () => {
    const jwtToken = localStorage.getItem("access");

    try {
      const res = await axios.post(`${baseUrl}/sales/api/line_items/`, {
        challan: `${chalan}`
      }, {
        headers: {
          Authorization: `JWT ${jwtToken}`,
          'Content-Type': 'application/json'
        }
      });
      if (res) {
        setData(res.data);
        console.log(res);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchMoshukData();
  }, []);



    useEffect(() => {
        const fetchcompanyData = async () => {
            try {
                const jwtToken = localStorage.getItem("access");
                const response = await axios.get(`${baseUrl}/coa/api/company/`, {
                    headers: {
                        Authorization: `JWT ${jwtToken}`,
                    },
                });

                setCompanyData(response.data.results);
                console.log(response.data)
            } catch (error) {
              if(error.response.status === 401){
                navigate("/login");; 
              }
              else{
                console.log(error)
              }
            }
        };

        fetchcompanyData();
    }, []);



  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, "dd-M-yyyy"); // Change the format here
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);

    // Get the hours and minutes
    const hours = date.getHours();
    const minutes = date.getMinutes();
  
    // Determine whether it's AM or PM
    const amOrPm = hours >= 12 ? 'PM' : 'AM';
  
    // Convert hours to 12-hour format
    const formattedHours = hours % 12 || 12;
  
    // Format the time in "hh:mm AM/PM" format without seconds
    const formattedTime = `${formattedHours}:${minutes} ${amOrPm}`;
  
    return formattedTime;
  };
  
  let incrementalId = 1;

  return (
    <div className=" ">
      <div className="flex justify-between my-4 px-5 shadow-lg pb-4">
            <Link className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" to='/dashboardhome'> Back To Home</Link>
            <button
              onClick={pdfGeneretor}
              className="bg-green-600 w-[150px] hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
            >
              Download pdf
            </button>

      </div>

      <div
        ref={componentPDF}
        style={{ width: "99%", height: "100%" }}
        className="flex flex-col mx-auto "
      >
        <div className="pdf-head">
          <div className="top-header my-3">
          <p className="text-right m-2">মূসক - ৬.৩</p>
            <p className="text-center">গণপ্রজাতন্তী বাংলাদেশ সরকার, জাতীয় রাজস্ব বোর্ড</p>
            <h3 className="text-center font-bold">কর চালানপত্র</h3>
            <p className="text-center">[বিধি ৪০ এর উপ-বিধি (১) এর দফা (গ ) ও দফা (চ ) দ্রষ্টব্য]</p>
            <p>
              <table className="mx-auto">
              
              {companyData?.[0] && (
                <>
                <tr >
                    <td className="pr-2 text-[14px]">নিবন্ধিত ব্যক্তির নাম</td>
                    <td>:</td>
                    <td className="pl-1 text-[14px]">{companyData[0]?.company_name}</td>
                  </tr>
                  <tr >
                    <td className="pr-2 text-[14px]">নিবন্ধিত ব্যক্তির বিআইএন</td>
                    <td>:</td>
                    <td className="pl-1 text-[14px]">{companyData[0]?.vin}</td>
                  </tr>
                  <tr >
                    <td className="pr-2 text-[14px]">চালানপত্র ইস্যুর ঠিকানা </td>
                    <td>:</td>
                    <td className="pl-1 text-[14px]">
                    {companyData[0]?.address && (
                          <div>
                            {companyData[0]?.address.split(' ').map((word, index, wordsArray) => (
                              <span key={index}>
                                {word}
                                {' '}
                                {index % 4 === 3 && index !== wordsArray.length - 1 && <br />}
                              </span>
                            ))}
                          </div>
                        )}
                    </td>
                  </tr>
                </>
              )}

              </table>
            </p>
          </div>

          <div className="mushak3-bottom-header flex justify-between mx-3 my-4">
              <div className="left-header">
              <table className="">
              {data?.[0] && (
                  <>
                <tr >                 
                  <td className="pr-3 text-[14px]">ক্রেতার নাম</td>
                  <td>:</td>
                  <td className="pl-3 text-[14px]">{data[0]?.customer_name}</td>
                </tr>
                <tr >
                  <td className="pr-3 text-[14px]">ক্রেতার বিআইএন</td>
                  <td>:</td>
                  <td className="pl-3 text-[14px]">{data[0]?.customer_bin}</td>
                </tr>
                <tr >
                  <td className="pr-3 text-[14px]">ক্রেতার ঠিকানা</td>
                  <td>:</td>
                  <td className="pl-3 text-[14px]">{data[0]?.customer_address}</td>
                </tr>
                <tr >
                  <td className="pr-3 text-[14px]">সরবরাহের গন্তব্যস্থল</td>
                  <td>:</td>
                  <td className="pl-3 text-[14px]">{data[0]?.customer_address}</td>
                </tr>
                <tr >
                  <td className="pr-3 text-[14px]">যানবাহনের প্রকৃতি ও নম্বর</td>
                  <td>:</td>
                  <td className="pl-3 text-[14px]">{data[0]?.vehicle_type} / {data[0]?.vehicle_license} </td>
                </tr>
                  </>
                )}
            </table>
              </div>
              <div className="left-header">
               

 
              <table className=" mt-[55px]">
              {data?.[0] && (
                <>
                <tr >
                  <td className="pr-3 text-[14px]">চালানপত্র নম্বর</td>
                  <td>:</td>
                  <td className="pl-3 text-[14px]">{data[0]?.challan}</td>
                </tr>
                <tr >
                  <td className="pr-3 text-[14px]">ইস্যুর তারিখ </td>
                  <td>:</td>
                  <td className="pl-3 text-[14px]">{formatDate(data[0]?.order_date)}</td>
                </tr>
                <tr >
                  <td className="pr-3 text-[14px]">ইস্যুর সময় </td>
                  <td>:</td>
                  <td className="pl-3 text-[14px]">{formatTime(data[0]?.order_date)}</td>
                </tr>
                </>
              )}
            </table>
              </div>
          </div>
          
        </div><hr />

        <div className="mushak-3-pdf">
        <table className="min-w-full">
            <thead className=" bg-gray-50 text-[12px] font-medium">
              <tr>
                <th>ক্রমিক</th>
                <th>তারিখ</th>
                <th>পণ্য বা সেবার বর্ণনা 
                  (প্রযোজ্য ক্ষেত্রে ব্র্যান্ড নামসহ)</th>
                <th>সরবরাহের একক </th>
                <th>পরিমান</th>
                <th>একক মূল্য (টাকায়)</th>
                <th>মোট মূল্য (টাকায়)</th>
                <th>সম্পূরক শুল্কের হার</th>
                <th>সম্পূরক শুল্কের পরিমান (টাকায়)</th>
                <th>মূল্য সংযোজন করের হার  /সুনির্দিষ্ট কর</th>
                <th>মূল্য সংযোজন কর /সুনির্দিষ্ট কর এর পরিমান (টাকায়)</th>
                <th>সকল প্রকার শুল্ক ও করসহ মূল্য </th>
                <th>মন্তব্য</th>
              </tr>

          <tr>
            <th>(১)</th>
            <th>(২)</th>
            <th>(৩)</th>
            <th>(৪)</th>
            <th>(৫)</th>
            <th>(৬)</th>
            <th>(৭)</th>
            <th>(৮)</th>
            <th>(৯)</th>
            <th>(১০)</th>
            <th>(১১)</th>
            <th>(১২)</th>
            <th>(১৩)</th>
          </tr>

            </thead>

            <tbody>
            {data?.map((bodyData)=>{
              return(
                <>
                <tr className="text-[12px]" key={bodyData.id}>
                  <td key={bodyData.id} >
                     {incrementalId++}
                    </td>

                    <td key={bodyData.id} >
                     {formatDate(bodyData?.order_date)}
                    </td>

                    <td key={bodyData.id} >
                      {bodyData?.inventory_name}
                    </td>
                    <td key={bodyData.id} >
                      {bodyData?.inventory_uom}
                    </td>


                    <td key={bodyData.id} >
                      {bodyData.sales_quantity}
                    </td>
                    <td key={bodyData.id} >
                      {bodyData.unit_price}
                    </td>
                    
                    <td key={bodyData.id} >
                      {bodyData?.total_price_no_vat}
                    </td>
                    <td key={bodyData.id} >
                      {bodyData?.vat_rate}
                    </td>

                    <td key={bodyData.id} >
                      {bodyData?.vat}
                    </td>
                    <td key={bodyData.id} >
                      {bodyData?.sd_rate}
                    </td>
                    <td key={bodyData.id} >
                      {bodyData?.sd}
                    </td>
                    <td key={bodyData.id} >
                      {bodyData?.total_price_with_vat}
                    </td>     
                    <td></td>
                  </tr>
                </>
              )
            })}
            </tbody>
          </table>
        </div>
      </div>
    
    </div>
  );


};

export default SingleMushakSixThreePdf;
