// import React from "react";
import LoginForm from "../components/LoginForm";
import picture from "../assets/images/vat.jpg";
const LoginPage = () => {
  return (
    <div className="relative">
    <div className="flex w-full h-screen">
      <div className="w-full flex items-center justify-center lg:w-1/2">
        <LoginForm />
      </div>
      <div className=" hidden lg:flex h-full w-1/2 items-center justify-center ">
        <img width={"600px"} src={picture} alt="" />
      </div>
    </div>
    <div class="absolute bottom-0 left-[40%] mb-3 text-center">
        <p className="font-semibold">©2023 All Rights Reserved by E-BIT VAT</p>
    </div>
    
    </div>
  );
};

export default LoginPage;
