import React, { useState } from 'react';
import axios from 'axios';
import Select from 'react-select';
import DashboardLayout from '../../../Shared/DashboardLayout';
import { baseUrl } from '../../../BaseUrl';
import { useEffect } from 'react';
import { AiFillDelete } from 'react-icons/ai';
import Swal from 'sweetalert2';
import { useNavigate, useParams } from 'react-router-dom';

const Edit = () => {
  const navigate = useNavigate()
  const {id} = useParams()

  const [formData, setFormData] = useState({
    vat_deposit_for_the_current : '',
    sd_deposit_for_the_current_tax_period : '',
    excise_duty : '',
    development_surcharge : '',
    ict_development_surcharge : '',
    health_care_surcharge : '',
    environmental_protection_surcharge: '',
    
  });
  
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
  });


  useEffect(() => {
    const AccountCodeData = async () => {
        try {
            const jwtToken = localStorage.getItem("access");
            const response = await axios.get(`${baseUrl}/tax_return/api/account-code/${id}`, {
                headers: {
                    Authorization: `JWT ${jwtToken}`,
                },
            });

            setFormData(response.data);
            console.log(response.data)
        } catch (error) {

        }
    };

    AccountCodeData();
}, [id]);

  const handleInputChange = (e, index, type) => {
    const updatedData = { ...formData };
    updatedData[e.target.name] = e.target.value;
    setFormData(updatedData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const jwtToken = localStorage.getItem("access");
      const response = await axios.put(
        `${baseUrl}/tax_return/api/account-code/${id}/`,
        formData,  // Your form data
        {
          headers: {
            Authorization: `JWT ${jwtToken}`,
          },
        }
      );
      navigate("/account-code-list");
      Toast.fire({
        icon: 'success',
        title: 'Successfully Add',
      });
      }catch (error) {
          if(error.response.data.detail){
              Toast.fire({
              icon: 'error',
              title: `${error.response.data.detail}`,
              });
          }else{
              console.error('Error saving data:', error);
          }

      }
  };


  return (
    <DashboardLayout>
    <div className="px-5 mt-5 mb-10 pb-10">
        <div className=" w-4/4">
        <div className="bg-white p-8 rounded shadow-xl shadow-blue-500/40 w-[100%] mx-auto">
            <form onSubmit={handleSubmit}>
            <div className="flex justify-between rounded border-b-[1px] mb-10 bg-slate-900">
                <div className="mx-5 my-auto">
                <h1 className="uppercase font-semibold text-xl text-[#fff]">ACCOUNTS CODE WISE PAYMENT SCHEDULE Edit Form</h1>
                </div>
                <div className="my-2 mx-5">
                <button
                    type="submit"
                    className="w-[120px] bg-slate-700 hover:bg-slate-800 border text-white font-medium rounded-lg h-10"
                >
                    Submit
                </button>
                </div>
            </div>

            <div class="flex flex-wrap -mx-3 mb-2">

            <div class="w-full md:w-4/4 px-3 mb-8 border-r-[1px]">
                <div className="flex  md:w-4/4 mx-5 ">
                    <div class="md:w-[30%] my-auto">
                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-3"  for="inline-full-name">
                        vat_deposit_for_the_current*
                        </label>
                    </div>
                    <div class="md:w-[70%]">
                        <input
                            type="text"
                            name="vat_deposit_for_the_current"
                            id="vat_deposit_for_the_current"
                            value={formData.vat_deposit_for_the_current}
                            onChange={handleInputChange}
                            className="block w-full rounded-md border-0 pl-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="Enter vat_deposit_for_the_current"
                            required
                        />   
                    </div>
                </div>
            </div>

            <div class="w-full md:w-4/4 px-3 mb-8">
                <div className="flex  md:w-4/4 mx-5 ">
                    <div class="md:w-[30%] my-auto">
                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-3"  for="inline-full-name">
                        sd_deposit_for_the_current_tax_period*
                        </label>
                    </div>
                    <div class="md:w-[70%]">
                        <input
                            type="text"
                            name="sd_deposit_for_the_current_tax_period"
                            id="sd_deposit_for_the_current_tax_period"
                            value={formData.sd_deposit_for_the_current_tax_period}
                            onChange={handleInputChange}
                            className="block w-full rounded-md border-0 pl-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="Enter sd_deposit_for_the_current_tax_period"
                            required
                        />   
                    </div>
                </div>
            </div>


            <div class="w-full md:w-4/4 px-3 mb-8 border-r-[1px]">
                <div className="flex  md:w-4/4 mx-5 ">
                    <div class="md:w-[30%] my-auto">
                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-3"  for="inline-full-name">
                        excise_duty*
                        </label>
                    </div>
                    <div class="md:w-[70%]">
                        <input
                            type="text"
                            name="excise_duty"
                            id="excise_duty"
                            value={formData.excise_duty}
                            onChange={handleInputChange}
                            className="block w-full rounded-md border-0 pl-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="Enter excise_duty"
                            required
                        />   
                    </div>
                </div>
            </div>

            <div class="w-full md:w-4/4 px-3 mb-8">
                <div className="flex  md:w-4/4 mx-5 ">
                    <div class="md:w-[30%] my-auto">
                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-3"  for="inline-full-name">
                        development_surcharge*
                        </label>
                    </div>
                    <div class="md:w-[70%]">
                        <input
                            type="text"
                            name="development_surcharge"
                            id="development_surcharge"
                            value={formData.development_surcharge}
                            onChange={handleInputChange}
                            className="block w-full rounded-md border-0 pl-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="Enter development_surcharge"
                            required
                        />   
                    </div>
                </div>
            </div>

            <div class="w-full md:w-4/4 px-3 mb-8">
                <div className="flex  md:w-4/4 mx-5 ">
                    <div class="md:w-[30%] my-auto">
                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-3"  for="inline-full-name">
                            ict_development_surcharge*
                        </label>
                    </div>
                    <div class="md:w-[70%]">
                        <input
                            type="text"
                            name="ict_development_surcharge"
                            id="ict_development_surcharge"
                            value={formData.ict_development_surcharge}
                            onChange={handleInputChange}
                            className="block w-full rounded-md border-0 pl-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="Enter ict_development_surcharge"
                            required
                        />   
                    </div>
                </div>
            </div>

            <div class="w-full md:w-4/4 px-3 mb-8">
                <div className="flex  md:w-4/4 mx-5 ">
                    <div class="md:w-[30%] my-auto">
                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-3"  for="inline-full-name">
                        health_care_surcharge*
                        </label>
                    </div>
                    <div class="md:w-[70%]">
                        <input
                            type="text"
                            name="health_care_surcharge"
                            id="health_care_surcharge"
                            value={formData.health_care_surcharge}
                            onChange={handleInputChange}
                            className="block w-full rounded-md border-0 pl-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="Enter health_care_surcharge"
                            required
                        />   
                    </div>
                </div>
            </div>

            <div class="w-full md:w-4/4 px-3 mb-8">
                <div className="flex  md:w-4/4 mx-5 ">
                    <div class="md:w-[30%] my-auto">
                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-3"  for="inline-full-name">
                        environmental_protection_surcharge*
                        </label>
                    </div>
                    <div class="md:w-[70%]">
                        <input
                            type="text"
                            name="environmental_protection_surcharge"
                            id="environmental_protection_surcharge"
                            value={formData.environmental_protection_surcharge}
                            onChange={handleInputChange}
                            className="block w-full rounded-md border-0 pl-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="Enter environmental_protection_surcharge"
                            required
                        />   
                    </div>
                </div>
            </div>

            </div>



            </form>
        </div>
        </div>
  </div>
  </DashboardLayout>
  );
};

export default Edit;
