import React, { useState } from 'react';
import axios from 'axios';
import Select from 'react-select';
import DashboardLayout from '../../../Shared/DashboardLayout';
import { baseUrl } from '../../../BaseUrl';
import { useEffect } from 'react';
import { AiFillDelete } from 'react-icons/ai';
import Swal from 'sweetalert2';
import { useNavigate, useParams } from 'react-router-dom';

const Edit = () => {
  const navigate = useNavigate()
  const {id} = useParams()
  const [formData, setFormData] = useState({
    name : '',
    amount : '',
    vat_rate : '',
    sd_rate : '',
    status : '',
  });
  
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
  });

  const statusOptions = [
    { value: 0, label: 'Ordered' },
    { value: 1, label: 'WIP' },
    { value: 2, label: 'Complete' },
  ];


  useEffect(() => {
    const anyOtherAdjustmentsForINCREASING = async () => {
        try {
            const jwtToken = localStorage.getItem("access");
            const response = await axios.get(`${baseUrl}/tax_return/api/increasing-adjustment/${id}`, {
                headers: {
                    Authorization: `JWT ${jwtToken}`,
                },
            });

            setFormData(response.data);
            console.log(response.data)
        } catch (error) {
        }
    };

    anyOtherAdjustmentsForINCREASING();
}, []); 


  const handleStatusChange = (selectedOption) => {
    setFormData({ ...formData, status: selectedOption.value });
  };

  const handleInputChange = (e, index, type) => {
    const updatedData = { ...formData };
    updatedData[e.target.name] = e.target.value;
    setFormData(updatedData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const jwtToken = localStorage.getItem("access");
      const response = await axios.put(
        `${baseUrl}/tax_return/api/increasing-adjustment/${id}/`,
        formData,  // Your form data
        {
          headers: {
            Authorization: `JWT ${jwtToken}`,
          },
        }
      );
      navigate("/any-other-adjustments-for-increasing-list");
      Toast.fire({
        icon: 'success',
        title: 'Successfully Updated',
      });
      }catch (error) {
          if(error.response.data.detail){
              Toast.fire({
              icon: 'error',
              title: `${error.response.data.detail}`,
              });
          }else{
              console.error('Error saving data:', error);
          }

      }
  };


  return (
    <DashboardLayout>
    <div className="px-5 mt-5 mb-10 pb-10">
        <div className=" w-3/4">
        <div className="bg-white p-8 rounded shadow-xl shadow-blue-500/40 w-[100%] mx-auto">
            <form onSubmit={handleSubmit}>
            <div className="flex justify-between rounded border-b-[1px] mb-10 bg-slate-900">
                <div className="mx-5 my-auto">
                <h1 className="uppercase font-semibold text-xl text-[#fff]">Any Other Adjustments For INCREASING Add Form</h1>
                </div>
                <div className="my-2 mx-5">
                <button
                    type="submit"
                    className="w-[120px] bg-slate-700 hover:bg-slate-800 border text-white font-medium rounded-lg h-10"
                >
                    Submit
                </button>
                </div>
            </div>

            <div class="flex flex-wrap -mx-3 mb-2">

            <div class="w-full md:w-2/4 px-3 mb-8 border-r-[1px]">
                <div className="flex  md:w-4/4 mx-5 ">
                    <div class="md:w-[25%] my-auto">
                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-3"  for="inline-full-name">
                            Name*
                        </label>
                    </div>
                    <div class="md:w-[75%]">
                        <input
                            type="text"
                            name="name"
                            id="name"
                            value={formData.name}
                            onChange={handleInputChange}
                            className="block w-full rounded-md border-0 pl-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="Enter Name"
                            required
                        />   
                    </div>
                </div>
            </div>

            <div class="w-full md:w-2/4 px-3 mb-8">
                <div className="flex  md:w-4/4 mx-5 ">
                    <div class="md:w-[25%] my-auto">
                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-3"  for="inline-full-name">
                        amount*
                        </label>
                    </div>
                    <div class="md:w-[75%]">
                        <input
                            type="number"
                            step='0.01'
                            min='0'
                            name="amount"
                            id="amount"
                            value={formData.amount}
                            onChange={handleInputChange}
                            className="block w-full rounded-md border-0 pl-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="Enter amount"
                            required
                        />   
                    </div>
                </div>
            </div>


            <div class="w-full md:w-2/4 px-3 mb-8 border-r-[1px]">
                <div className="flex  md:w-4/4 mx-5 ">
                    <div class="md:w-[25%] my-auto">
                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-3"  for="inline-full-name">
                        vat rate*
                        </label>
                    </div>
                    <div class="md:w-[75%]">
                        <input
                            type="number"
                            step='0.01'
                            min='0'
                            name="vat_rate"
                            id="vat_rate"
                            value={formData.vat_rate}
                            onChange={handleInputChange}
                            className="block w-full rounded-md border-0 pl-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="Enter vat rate"
                            required
                        />   
                    </div>
                </div>
            </div>

            <div class="w-full md:w-2/4 px-3 mb-8">
                <div className="flex  md:w-4/4 mx-5 ">
                    <div class="md:w-[25%] my-auto">
                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-3"  for="inline-full-name">
                        Sd Rate*
                        </label>
                    </div>
                    <div class="md:w-[75%]">
                        <input
                            type="number"
                            step='0.01'
                            min='0'
                            name="sd_rate"
                            id="sd_rate"
                            value={formData.sd_rate}
                            onChange={handleInputChange}
                            className="block w-full rounded-md border-0 pl-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="Enter sd rate"
                            required
                        />   
                    </div>
                </div>
            </div>

            <div class="w-full md:w-4/4 px-3 mb-8">
                <div className="flex  md:w-4/4 mx-5 ">
                    <div class="md:w-[12%] my-auto">
                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-3"  for="inline-full-name">
                            status*
                        </label>
                    </div>
                    <div class="md:w-[90%]">
                        <Select
                            id="status"
                            name="status"
                            value={statusOptions.find((option) => option.value === formData.status)}
                            onChange={handleStatusChange}
                            options={statusOptions}
                            required
                        /> 
                    </div>
                </div>
            </div>

            </div>



            </form>
        </div>
        </div>
  </div>
  </DashboardLayout>
  );
};

export default Edit;
