import axios from 'axios';
import React, { useState, useEffect } from 'react';
import DashboardLayout from '../../Shared/DashboardLayout';
import { Link, useNavigate } from 'react-router-dom';
import { Table } from "antd";
import { AiFillDelete,AiFillEdit, AiFillEye, AiFillFileAdd, AiFillLock } from "react-icons/ai";
import Swal from 'sweetalert2';
import { baseUrl } from '../../BaseUrl';
import { format } from "date-fns";

const ReturnSubmissionList = () => {
    const [returnSubmission, setReturnSubmission] = useState([]);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer);
          toast.addEventListener('mouseleave', Swal.resumeTimer);
        },
      });

    useEffect(() => {
        const returnSubmission = async () => {
            try {
                const jwtToken = localStorage.getItem("access");
                const response = await axios.get(`${baseUrl}/tax_return/api/return-submissions/`, {
                    headers: {
                        Authorization: `JWT ${jwtToken}`,
                    },
                });

                setReturnSubmission(response.data.results);
                // console.log(response.data.results)
            } catch (error) {
                setError(error);
            }
        };

        returnSubmission();
    }, []); 

    const handleDelete = async (itemId) => {
        try {
            const jwtToken = localStorage.getItem("access");
            await axios.delete(`${baseUrl}/tax_return/api/return-submissions/${itemId}/`, {
                headers: {
                    Authorization: `JWT ${jwtToken}`,
                },
            });

            // Update customerData state after successful deletion
            setReturnSubmission((prevData) =>
                prevData.filter((item) => item.id !== itemId)
            );
            Toast.fire({
                icon: 'info',
                title: 'Successfully Delete',
              });
        } catch (error) {
            if(error.response.data.detail){
                Toast.fire({
                    icon: 'error',
                    title: `Ops! ${error.response.data.detail}`,
                  });
            }else{
                Toast.fire({
                    icon: 'error',
                    title: `Ops! ${error.message}`,
                });
            }
            console.log(error.re)

        }
    };

    const formatDate = (dateString) => {
        try {
          const date = new Date(dateString);
          return format(date, "dd-M-yyyy");
        } catch (error) {
          return "...";
        }
      };

    const [columns, setColumns] = useState([
        {
            key: 1,
            title: "Date",
            dataIndex: "last_updated",
            render: (text) => formatDate(text),
        },
        {
            key: 2,
            title: "tax preiod from date",
            dataIndex: "tax_preiod_from_date",
        },
        {
            key: 3,
            title: "tax preiod to date",
            dataIndex: "tax_preiod_to_date",
        },
        {
            key: 4,
            title: "type of return",
            dataIndex: "type_of_return",
        },
        {
            key: 5,
            title: "status",
            dataIndex: "status_text",
        },

        {
            key: 6, 
            title: "Actions",
            dataIndex: "actions",
            render: (_, record) => {
                return (
                    <>
                        <div className="flex gap-2">
                            <div className="">
                            <>
                                {record.status !== 2?
                                    <>
                                    <button
                                    onClick={() => navigate(`/mushak-9-1-preview/${record.id}`)}
                                    className="bg-green-500 hover:bg-green-600 rounded mx-2"
                                    >
                                    <AiFillEye className="text-white text-xl" />
                                    </button>
                                    <button
                                    onClick={() => navigate(`/mushak-9-1-edit/${record.id}`)}
                                    className="bg-blue-500 hover:bg-blue-600 rounded"
                                    >
                                    <AiFillEdit className="text-white text-xl" />
                                    </button>

  
                                    <button
                                    onClick={() => handleDelete(record.id)}
                                    className="rounded mr-1"
                                    >
                                    <AiFillDelete className="text-xl text-red-500/100 hover:text-red-600" />
                                    </button>
                                    </>
                                    :
                                    <div className='flex'>
                                        <AiFillLock className="text-green text-xl" />
                                        <p>Completed</p>
                                    </div>                             
                                    }

                                </>
                            </div>
                        </div>
                    </>
                );
            },
        },
    ]);
    
    return (
        <DashboardLayout>
            <div className="px-5">
            <div className="flex items-center justify-between py-5">
                <h1 className=" uppercase font-semibold  text-xl">Return Submission List (M-9.1)</h1>
            <button onClick={() => navigate("/mushak-9-1-add")} className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
              <div className="flex ">
                <div className="mt-1">
                <AiFillFileAdd />
                </div>
                <div>Add New</div>
              </div>
            </button>
            </div>

            <div className='overflow-x-auto w-[1090px]'>
            <Table
            bordered
                columns={columns}
                dataSource={returnSubmission}
                pagination={{
                    pageSize: 10, 
                    total: returnSubmission?.length,
                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                    showSizeChanger: false,
                  }}
            />
            </div>
            </div>
        </DashboardLayout>
    );
};

export default ReturnSubmissionList;
