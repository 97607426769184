import React, { useState } from 'react';
import axios from 'axios';
import Select from 'react-select';
import DashboardLayout from '../../Shared/DashboardLayout';
import { baseUrl } from '../../BaseUrl';
import { useEffect } from 'react';
import { AiFillDelete } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const MushakSixEightAdd = () => {
  const [formData, setFormData] = useState({
    bill_of_entry: null,
    status: null,
    debititem_set: [
      {
        purchase_order: null,
        return_quantity: '',
        reasons_to_return: '',
      },
    ],
  });

  const navigate = useNavigate()
  const [billOfEntryOptions, setBillOfEntryOptions] = useState([]);
  const [selectedBillOfEntry, setSelectedBillOfEntry] = useState(null);

  const [purchaseOrderOptions, setPurchaseOrderOptions] = useState([]);
  const [selectedPurchaseOrder, setSelectedPurchaseOrder] = useState([]);
  const [selectedPurchaseOrderQuantity,setSelectedPurchaseOrderQuantity] = useState()

  const [salesOrderAddMoreRequiredMessage,setPurchaseOrderAddMoreRequiredMessage]= useState('')
  const bill_of_entry = selectedBillOfEntry?.value

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
  });

  const statusOptions = [
    { value: '0', label: 'Ordered' },
    { value: '1', label: 'WIP' },
    { value: '2', label: 'Complete' },
  ];


  const handleStatusChange = (selectedOption) => {
    setFormData({ ...formData, status: selectedOption.value });
  };

  const handleInputChange = (e, index, type) => {
    const updatedData = { ...formData };

    if (type === 'debititem_set') {
      updatedData.debititem_set[index][e.target.name] = e.target.value;
    } else {
      updatedData[e.target.name] = e.target.value;
    }

    setFormData(updatedData);
  };

  useEffect(() => {
    const fetchBillOfEntryNo = async () => {

        try {
          const jwtToken = localStorage.getItem("access");
          const response = await axios.get(`${baseUrl}/purchase/api/purchase_order_list/`, {
              headers: {
                  Authorization: `JWT ${jwtToken}`,
              },
          });
          // Filter items with type 'Finished Good'
          console.log('response.data',response.data)
          const complatedData = response.data.filter(
              (item) => item.status === "Complete"
          );
          setBillOfEntryOptions(
            complatedData.map((order) => ({
              value: order.bill_of_entry,
              label: `Bill Of Entry Numbe :- ${order.bill_of_entry}`,
            }))
          );
      } catch (error) {
          console.log(error)
      }
    };
    fetchBillOfEntryNo();
  }, []);


    // ...

    useEffect(() => {
      const fetchPurchaseOrder = async () => {
        const jwtToken = localStorage.getItem("access");
        try {

          const res = await axios.get(`${baseUrl}/purchase/api/purchase_order_line_items/${bill_of_entry}/`, {
            headers: {
              Authorization: `JWT ${jwtToken}`,
            },
          })

          console.log('res.data',res.data)
          // Filter sales orders based on the selected bill_of_entry
          const filteredSalesOrders = res.data.filter((order) => order.bill_of_entry === bill_of_entry);
          
          console.log('filteredSalesOrders',filteredSalesOrders)

            // Filter out sales inventory that are already selected
            const filteredSelectedInventoyData = filteredSalesOrders.filter((item) => {
              const InventoryValue = item.id;
              return !selectedPurchaseOrder.some(
              (selected) => selected.value === InventoryValue
              );
            });

            console.log('filteredSelectedInventoyData',filteredSelectedInventoyData)

          setPurchaseOrderOptions(
            filteredSelectedInventoyData.map((order) => ({
              value: order.id,
              label: order.inventory_name,
              bill_of_entry:order.bill_of_entry,
            }))
          );

          const stockInfo = {};
          filteredSalesOrders.forEach((salesItem) => {
            stockInfo[salesItem.id] = salesItem.quantity;
          });
          setSelectedPurchaseOrderQuantity(stockInfo);

        } catch (error) {
          console.log(error);
        }
      };
      fetchPurchaseOrder();
    }, [bill_of_entry,selectedPurchaseOrder]);

    // ...

    const handleBillOfEntryNoChange = (selectedOption) => {
      const bill_of_entryNo = selectedOption.value;
      setSelectedBillOfEntry(selectedOption);

      const newCredititemSet = [
        {
          purchase_order: null,
          return_quantity: '',
          reasons_to_return: '',
        },
      ];

      setSelectedPurchaseOrder([
        {
          value: null,
          label: 'Select..',
        }
      ]);
    
      setFormData({
        ...formData,
        bill_of_entry: bill_of_entryNo,
        debititem_set: newCredititemSet,
      });
    };
    
   

  const handleSalesOrderChange = (selectedOption, index) => {
    const updatedFormData = { ...formData };
    updatedFormData.debititem_set[index].purchase_order = selectedOption.value;
    updatedFormData.debititem_set[index].quantity = selectedPurchaseOrderQuantity[selectedOption.value];

    const updatedselectedPurchaseOrder = [...selectedPurchaseOrder];
    updatedselectedPurchaseOrder[index] = selectedOption;
    
    setSelectedPurchaseOrder(updatedselectedPurchaseOrder);
    
    setFormData(updatedFormData);
  };


  const areAllSalesOrderFieldsFilled = () => {
    const newSalesOrderEntry = formData.debititem_set[formData.debititem_set.length - 1];
    return (
      newSalesOrderEntry.purchase_order !== null &&
      newSalesOrderEntry.return_quantity.trim() !== '' &&
      newSalesOrderEntry.reasons_to_return.trim() !== ''
    );
  };
  
  const handleAddSalesOrder = () => {
    if (areAllSalesOrderFieldsFilled()) {
        const newSalesOrderEntry = {
            purchase_order: null,
            return_quantity: '',
            reasons_to_return: '',
          };
      setFormData({
        ...formData,
        debititem_set: [...formData.debititem_set, newSalesOrderEntry],
      });
  
      setSelectedPurchaseOrder([...selectedPurchaseOrder, formData.debititem_set[formData.debititem_set.length - 1].purchase_order]);
      setPurchaseOrderAddMoreRequiredMessage('');
    } else {
        setPurchaseOrderAddMoreRequiredMessage("Please fill in all required sales order fields")
    }
  };

  const handleRemoveSalesOrder = (index) => {
    const debititem_set = [...formData.debititem_set];
    debititem_set.splice(index, 1);
    setFormData({
      ...formData,
      debititem_set,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const jwtToken = localStorage.getItem("access");
      const response = await axios.post(
        `${baseUrl}/purchase/api/debit-note/`,
        formData,  // Your form data
        {
          headers: {
            Authorization: `JWT ${jwtToken}`,
          },
        }
      );
      navigate("/mushak-6-8-list");
      Toast.fire({
        icon: 'success',
        title: 'Successfully Add',
      });
    } catch (error) {
        if(error.response.data.detail){
            Toast.fire({
            icon: 'error',
            title: `${error.response.data.detail}`,
            });
        }else{
            console.error('Error saving data:', error);
        }

    }
  };

  return (
    <DashboardLayout>
    <div className="px-5 mt-5 mb-10 pb-10">
        <div className=" w-4/3">
        <div className="bg-white p-8 rounded shadow-xl shadow-blue-500/40 w-[90%] mx-auto">
            <form onSubmit={handleSubmit}>
            <div className="flex justify-between rounded border-b-[1px] mb-10 bg-slate-900">
                <div className="mx-5 my-auto">
                <h1 className="uppercase font-semibold text-xl text-[#fff]">Debit Note Add Form</h1>
                </div>
                <div className="my-2 mx-5">
                <button
                    type="submit"
                    className="w-[120px] bg-slate-700 hover:bg-slate-800 border text-white font-medium rounded-lg h-10"
                >
                    Submit
                </button>
                </div>
            </div>

            <div class="flex flex-wrap -mx-3 mb-2">

                <div class="w-full md:w-4/4 px-3 mb-8">
                  <div className="flex  md:w-4/4 mx-5 ">
                    <div class="md:w-[20%] my-auto">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-3"  for="inline-full-name">
                    Bill Of Entry Number*
                    </label>
                    </div>
                    <div class="md:w-[80%]">
                        <Select
                            id="inventory"
                            name="inventory"
                            value={selectedBillOfEntry}
                            onChange={handleBillOfEntryNoChange}
                            options={billOfEntryOptions}
                            required
                        />
                    </div>
                  </div>
                </div>

                <div class="w-full md:w-4/4 px-3 mb-8">
                  <div className="flex  md:w-4/4 mx-5 ">
                    <div class="md:w-[20%] my-auto">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-3"  for="inline-full-name">
                        Status*
                    </label>
                    </div>
                    <div class="md:w-[80%]">
                        <Select
                            id="status"
                            name="status"
                            value={statusOptions.find((option) => option.value === formData.status)}
                            onChange={handleStatusChange}
                            options={statusOptions}
                            required
                        />
                    </div>
                  </div>
                </div>
            </div>

                {formData.debititem_set?.map((purchaseInventory, index) => (
                <table className="table-auto w-100 sales-order-table ml-5 ">
                {index === 0 && (
                    <thead>
                    <tr>
                        <th className='inventory-th'>
                        <label
                            class="block uppercase tracking-wide  text-[12px] font-semibold"
                            for="grid-state"
                        >
                            Purchased Item*
                        </label>
                        </th>
                        <th>
                        <label
                            class="block uppercase tracking-wide  text-[12px] font-semibold"
                            for="inline-full-name"
                        >
                            Return Quantity*
                        </label>
                        </th>
                        <th className='total-price-td'>
                        <label
                            class="block uppercase tracking-wide  text-[12px] font-semibold"
                            for="inline-full-name"
                        >
                            Reasons to return*
                        </label>
                        </th>
                        <th className="action-th"></th>
                    </tr>
                    </thead>
                )}
                <tbody>
                    <tr className="table-input-filed">
                    <td className="w-[40%] inventory-th">
                        <Select
                            id={`purchase_order_${index}`}
                            name={`debititem_set[${index}].purchase_order`}
                            value={selectedPurchaseOrder[index]}
                            onChange={(selectedOption) => handleSalesOrderChange(selectedOption, index)}
                            options={purchaseOrderOptions}
                            required
                        />
                    </td>
                    <td className="w-[25%]">
                        <input
                            type="number"
                            min='0'
                            step='0.01'
                            // max={purchaseInventory.quantity}
                            name="return_quantity"
                            required
                            value={purchaseInventory.return_quantity}
                            onChange={(e) => handleInputChange(e, index, 'debititem_set')}
                            placeholder="Enter quantity"
                            className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-blue-300"
                        />
                        {purchaseInventory.quantity && (
                        <div className="text-sm text-sky-500">
                            Purchase Quantity: {purchaseInventory.quantity}
                        </div>
                        )}
                    </td>
                    <td className="w-[25%] total-price-td">
                        <input
                            type='text'
                            name="reasons_to_return"
                            value={purchaseInventory.reasons_to_return}
                            onChange={(e) => handleInputChange(e, index, 'debititem_set')}
                            placeholder="Enter reasons_to_return"
                            required
                            className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-blue-300"
                        />
                    </td>

                    {index === 0 && (
                        <td className="w-[10%] action-td"></td>
                    )}
                    {index !== 0 && (
                        <td className="w-[10%] action-td">
                        {index !== 0 && (
                            <button
                            type="button"
                            onClick={() => handleRemoveSalesOrder(index)}
                            className="text-red-500"
                            >
                            <AiFillDelete className="text-xl text-red-500/100 hover:text-red-600 " />
                            </button>
                        )}
                        </td>
                    )}
                    </tr>
                </tbody>
                </table>
                ))}

                <div className="mb-3 ml-5">
                {salesOrderAddMoreRequiredMessage && (
                    <span className='text-red-500 mt-2'>{salesOrderAddMoreRequiredMessage} <br /> </span> 
                )}
                <button
                  type="button"
                  onClick={() => {
                    console.log('Add Product Button Clicked');
                    handleAddSalesOrder();
                  }}
                  className="bg-slate-800 hover:bg-slate-900 text-white p1-2 px-2 my-3 rounded"
                  title='All fields required for add more product'
                >
                  Add More
                </button>
                <hr />
              </div>
            </form>
        </div>
        </div>
    </div>
  </DashboardLayout>
  );
};

export default MushakSixEightAdd;
