import axios from 'axios';
import React, { useState, useEffect } from 'react';
import DashboardLayout from '../../Shared/DashboardLayout';
import { Link, useNavigate } from 'react-router-dom';
import { Table } from "antd";
import { AiFillDelete,AiFillEdit, AiFillFileAdd, AiFillFilePdf } from "react-icons/ai";
import Swal from 'sweetalert2';
import { format } from "date-fns";
import { baseUrl } from '../../BaseUrl';

const MushakSixEightList = () => {
    const [debitNoteData, setDebitNoteData] = useState([]);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer);
          toast.addEventListener('mouseleave', Swal.resumeTimer);
        },
      });

    useEffect(() => {
        const fetchDebitNoteData = async () => {
            try {
                const jwtToken = localStorage.getItem("access");
                const response = await axios.get(`${baseUrl}/purchase/api/debit-note/`, {
                    headers: {
                        Authorization: `JWT ${jwtToken}`,
                    },
                });

                setDebitNoteData(response.data.results);
                console.log(response.data.results)
            } catch (error) {
                setError(error);
            }
        };

        fetchDebitNoteData();
    }, []); // Empty dependency array ensures this effect runs only once

    const handleDelete = async (itemId) => {
        try {
            const jwtToken = localStorage.getItem("access");
            await axios.delete(`${baseUrl}/purchase/api/debit-note/${itemId}/`, {
                headers: {
                    Authorization: `JWT ${jwtToken}`,
                },
            });

            // Update debitNoteData state after successful deletion
            setDebitNoteData((prevData) =>
                prevData.filter((item) => item.id !== itemId)
            );
            Toast.fire({
                icon: 'info',
                title: 'Successfully Delete',
              });

        } catch (error) {
            
            if (error.response.data.detail)
            Toast.fire({
                icon: 'error',
                title: `Ops! ${error.response.data.detail}`,
              });
            else{
                Toast.fire({
                    icon: 'error',
                    title: `Ops! ${error.message}`,
                  });
            }

        }
    };

    
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, "dd-M-yyyy"); // Change the format here
  };


    const [columns, setColumns] = useState([
        {
            key: 1,
            title: "Date",
            dataIndex: "last_updated",
            render: (text) => formatDate(text),
        },
        {
            key: 2,
            title: "Debit Note Id",
            dataIndex: "id",
        },
        {
            key: 3,
            title: "Challan Number",
            dataIndex: "bill_of_entry",
        },

        {
            key: 4,
            title: "STATUS",
            dataIndex: "status_text",
        },
        {
            key: 5, 
            title: "Actions",
            dataIndex: "actions",
            render: (_, record) => {
                return (
                    <>
                        <div className="flex gap-2">
                            {record.status !== 2?
                            <div className="">
                                <button
                                    onClick={() => navigate(`/debit-note-edit/${record.id}`)}
                                    className="bg-blue-500 hover:bg-blue-600 rounded"
                                >
                                    <AiFillEdit className="text-white text-xl " />
                                </button>      
                                <button
                                    onClick={() => handleDelete(record.id)}
                                    className="rounded mr-1"
                                >
                                    <AiFillDelete className="text-xl text-red-500/100 hover:text-red-600 " />
                                </button>                           
                            </div>
                            :
                            <button
                                onClick={() => navigate(`/mushak-6-8-pdf/${record.id}`)}
                                className="rounded"
                                    >
                                <div className="flex items-center rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-[#000] ring-1 ring-inset ring-gray-500/10">
                                    <div><AiFillFilePdf className="text-[#15803d] text-xl " /></div>
                                    <div>M-6.8</div>
                                </div> 
                            </button>
                            }
                        </div>
                    </>
                );
            },
        },
    ]);
    
    return (
        <DashboardLayout>
            <div className="px-5">
            <div className="flex items-center justify-between py-5">
                <h1 className=" uppercase font-semibold  text-xl">Debit Note Data List</h1>
            <button onClick={() => navigate("/debit-note-add")} className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
              <div className="flex ">
                <div className="mt-1">
                <AiFillFileAdd />
                </div>
                <div>Add New</div>
              </div>
            </button>
            </div>

            <div className='overflow-x-auto w-[1090px]'>
            <Table
                bordered
                columns={columns}
                dataSource={debitNoteData}
                pagination={{
                    pageSize: 10, 
                    total: debitNoteData.length,
                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                    showSizeChanger: false,
                  }}
            />
            </div>
            </div>
        </DashboardLayout>
    );
};

export default MushakSixEightList;
