import axios from 'axios';
import React, { useState, useEffect } from 'react';
import DashboardLayout from '../../Shared/DashboardLayout';
import { Link, useNavigate } from 'react-router-dom';
import { Table } from "antd";
import { AiFillDelete,AiFillEdit, AiFillFileAdd } from "react-icons/ai";
import Swal from 'sweetalert2';
import { baseUrl } from '../../BaseUrl';

const CompanyList = () => {
    const [companyData, setCompanyData] = useState([]);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer);
          toast.addEventListener('mouseleave', Swal.resumeTimer);
        },
      });

    useEffect(() => {
        const fetchcompanyData = async () => {
            try {
                const jwtToken = localStorage.getItem("access");
                const response = await axios.get(`${baseUrl}/coa/api/company/`, {
                    headers: {
                        Authorization: `JWT ${jwtToken}`,
                    },
                });

                setCompanyData(response.data.results);
                // console.log(response.data.results)
            } catch (error) {
                setError(error);
            }
        };

        fetchcompanyData();
    }, []); 

    const handleDelete = async (itemId) => {
        try {
            const jwtToken = localStorage.getItem("access");
            await axios.delete(`${baseUrl}/coa/api/company/${itemId}/`, {
                headers: {
                    Authorization: `JWT ${jwtToken}`,
                },
            });

            // Update companyData state after successful deletion
            setCompanyData((prevData) =>
                prevData.filter((item) => item.id !== itemId)
            );
            Toast.fire({
                icon: 'info',
                title: 'Successfully Delete',
              });
        } catch (error) {
            Toast.fire({
                icon: 'error',
                title: `Ops! ${error.message}`,
              });
        }
    };

    const [columns, setColumns] = useState([
        {
            key: 1,
            title: "Company Name",
            dataIndex: "company_name",
        },
        { 
            key: 2,
            title: "Company Code",
            dataIndex: "company_code",
        },
        {
            key: 3,
            title: "BIN Number",
            dataIndex: "vin",
        },
        {
            key: 4,  
            title: "Address",
            dataIndex: "address",
        },
        {
            key: 5, 
            title: "Actions",
            dataIndex: "actions",
            render: (_, record) => {
                return (
                    <>
                        <div className="flex gap-2">
                            <div className="">
                                <button
                                    onClick={() => navigate(`/company-data-edit/${record.id}`)}
                                    className="bg-blue-500 hover:bg-blue-600 rounded"
                                >
                                    <AiFillEdit className="text-white text-xl " />
                                </button>
        
                                <button
                                    onClick={() => handleDelete(record.id)}
                                    className="rounded mr-1"
                                >
                                    <AiFillDelete className="text-xl text-red-500/100 hover:text-red-600 " />
                                </button>
                            </div>
                        </div>
                    </>
                );
            },
        },
    ]);
    
    return (
        <DashboardLayout>
            <div className="px-5">
            <div className="flex items-center justify-between py-5">
                <h1 className=" uppercase font-semibold  text-xl">company List</h1>
            <button onClick={() => navigate("/company-add")} className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
              <div className="flex ">
                <div className="mt-1">
                <AiFillFileAdd />
                </div>
                <div>Add New</div>
              </div>
            </button>
            </div>

            <div className='overflow-x-auto w-[1090px]'>
            <Table
            bordered
                columns={columns}
                dataSource={companyData}
                pagination={{
                    pageSize: 10, 
                    total: companyData.length,
                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                    showSizeChanger: false,
                  }}
            />
            </div>
            </div>
        </DashboardLayout>
    );
};

export default CompanyList;
