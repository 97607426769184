/* eslint-disable no-unused-vars */
import React from "react";

const PustokTableRepost = () => {
  return (
    <div className=" border-2 h-[85vh] ">
      <div className="overflow-x-auto">
        <table className="min-w-full">
          <thead className="bg-gray-100 font-medium">
            <tr className="">
              <td className="py-2 px-4 border-2  right-5" colSpan={13}>
                Purchase of Equipment
              </td>
            </tr>
            <tr className="">
              <td className="py-2 px-4 border-2  right-5" colSpan={9}>
                Purchase of Equipment
              </td>
              <td className="py-2 px-4 border-2  right-5" colSpan={3}>
                Ending Stock
              </td>
              <td className="py-2 px-4 border-2 " rowSpan={4}>
                Comment
              </td>
            </tr>
            <tr className="">
              <td className="py-2 px-4 border-2 " rowSpan={3}>
                Descreption
              </td>
              <td className="py-2 px-4 border-2 " rowSpan={3}>
                Quantity
              </td>
              <td className="py-2 px-4 border-2 " rowSpan={3}>
                Total Price No Vat
              </td>
              <td className="py-2 px-4 border-2 " rowSpan={3}>
                Total Tax
              </td>
              <td className="py-2 px-4 border-2 " rowSpan={3}>
                Vat
              </td>
              <td className="py-2 px-4 border-2  right-5" colSpan={2}>
                Total Equipment Quantity
              </td>
              <td className="py-2 px-4 border-2  right-5" colSpan={2}>
                Product Redy Equipnent
              </td>
              <td className="py-2 px-4 border-2 " rowSpan={3}>
                Quantity unite
              </td>
              <td className="py-2 px-4 border-2 " rowSpan={3}>
                Total Price Without Vat
              </td>
            </tr>
            <tr className=""></tr>
            <tr>
              <td className="py-2 px-4 border-2  right-5" rowSpan={1}>
                Quantity unit
              </td>
              <td className="py-2 px-4 border-2  right-5" rowSpan={1}>
                total parice no vat
              </td>
              <td className="py-2 px-4 border-2  right-5" rowSpan={1}>
                Quantity unit
              </td>
              <td className="py-2 px-4 border-2  right-5" rowSpan={1}>
                total price no vat
              </td>
            </tr>
            <tr className=""></tr>
            <tr></tr>
            {/* <tr className="bg-gray-100">
            {tableHeader.map((header, index) => (
              <th key={index} rowSpan={2} className="py-2 px-4 border-2">
                {header}
              </th>
            ))}
          </tr> */}
            <tr></tr>
          </thead>
          {/* <thead className="bg-gray-100 font-medium">
            <tr className="">
              <td className="py-2 px-4 border-2 right-5" colSpan={12}>
                Purchase of Equipment
              </td>
            </tr>
            <tr className="">
              <td className="py-2 px-4 border-2 right-5" colSpan={9}>
                Purchase of Equipment
              </td>
              <td className="py-2 px-4 border-2 right-5" colSpan={2}>
                Purchase of Equipment
              </td>
            </tr>
            <tr className="">
              <td className="py-2 px-4 border-2" rowSpan={3}>
                Description
              </td>
              <td className="py-2 px-4 border-2" rowSpan={3}>
                Quantity
              </td>
              <td className="py-2 px-4 border-2" rowSpan={3}>
                Total Price No Vat
              </td>
              <td className="py-2 px-4 border-2" rowSpan={3}>
                Total Tax
              </td>
              <td className="py-2 px-4 border-2" rowSpan={3}>
                Vat
              </td>
              <td className="py-2 px-4 border-2 right-5" colSpan={2}>
                Total Equipment Quantity
              </td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-2 right-5" colSpan={2}>
                Product Ready Equipment
              </td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-2 right-5">Quantity unit</td>
              <td className="py-2 px-4 border-2 right-5">Total Price No Vat</td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-2 right-5" colSpan={12}>
              </td>
            </tr>
          </thead> */}

          <tbody>
            {/* {moshukData.map((bodyData) => (
            <>
              <tr className="">
                <td key={bodyData.id} className="py-2 border-2 px-4">
                  {bodyData.order_date}
                </td>
                <td key={bodyData.id} className="py-2 border-2 px-4">
                  {bodyData.quantity}
                </td>
                <td key={bodyData.id} className="py-2 border-2 px-4">
                  {bodyData.total_price_no_vat}
                </td>
                <td key={bodyData.id} className="py-2 border-2 px-4">
                  {bodyData.bill_of_entry}
                </td>
                <td key={bodyData.id} className="py-2 border-2 px-4">
                  {bodyData.supplier}
                </td>
                <td key={bodyData.id} className="py-2 border-2 px-4">
                  {bodyData.supplier_address}
                </td>
                <td key={bodyData.id} className="py-2 border-2 px-4">
                  {bodyData.supplier_nid}
                </td>
              </tr>
            </>
          ))} */}
          </tbody>
        </table>
      </div>
      <div className="flex-grow"></div>
      <div className="bg-gray-200 py-4 px-8 text-center">
        Footer content here
      </div>
    </div>
  );
};

export default PustokTableRepost;
