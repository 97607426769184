// eslint-disable-next-line no-unused-vars
import React from "react";
import DashboardLayout from "../Shared/DashboardLayout";
import EditPurchasesForm from "../components/EditPurchasesForm";
const PurchasesEditPage = () => {
  return (
    <DashboardLayout>
      <EditPurchasesForm />
    </DashboardLayout>
  );
};

export default PurchasesEditPage;
