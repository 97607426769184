// import React from 'react'

import DashboardLayout from "../Shared/DashboardLayout";
import CreatePurchasesForm from "../components/CreatePurchasesForm";

const PurchasesCreatePage = () => {
  return (
    <DashboardLayout>
      <CreatePurchasesForm />
    </DashboardLayout>
  );
};

export default PurchasesCreatePage;
