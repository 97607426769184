import axios from 'axios';
import React, { useState, useEffect } from 'react';
import DashboardLayout from '../../../Shared/DashboardLayout';
import { Link, useNavigate } from 'react-router-dom';
import { Table } from "antd";
import { AiFillDelete,AiFillEdit, AiFillFileAdd, AiFillLock } from "react-icons/ai";
import Swal from 'sweetalert2';
import { baseUrl } from '../../../BaseUrl';

const List = () => {
    const [accountCodeData, setAccountCodeData] = useState([]);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer);
          toast.addEventListener('mouseleave', Swal.resumeTimer);
        },
      });

    useEffect(() => {
        const AccountCodeData = async () => {
            try {
                const jwtToken = localStorage.getItem("access");
                const response = await axios.get(`${baseUrl}/tax_return/api/account-code/`, {
                    headers: {
                        Authorization: `JWT ${jwtToken}`,
                    },
                });

                setAccountCodeData(response.data);
                console.log(response.data)
            } catch (error) {
                setError(error);
            }
        };

        AccountCodeData();
    }, []);

    const handleDelete = async (itemId) => {
        try {
            const jwtToken = localStorage.getItem("access");
            await axios.delete(`${baseUrl}/tax_return/api/account-code/${itemId}/`, {
                headers: {
                    Authorization: `JWT ${jwtToken}`,
                },
            });

            // Update customerData state after successful deletion
            setAccountCodeData((prevData) =>
                prevData.filter((item) => item.id !== itemId)
            );
            Toast.fire({
                icon: 'info',
                title: 'Successfully Delete',
              });
        } catch (error) {
            if(error.response.data.detail){
                Toast.fire({
                    icon: 'error',
                    title: `Ops! ${error.response.data.detail}`,
                  });
            }else{
                Toast.fire({
                    icon: 'error',
                    title: `Ops! ${error.message}`,
                });
            }

        }
    };

    const [columns, setColumns] = useState([
        {
            key: 1,
            title: "vat deposit for the current",
            dataIndex: "vat_deposit_for_the_current",
        },
        {
            key: 2,
            title: "sd deposit for the current tax period",
            dataIndex: "sd_deposit_for_the_current_tax_period",
        },
        {
            key: 3,
            title: "excise duty",
            dataIndex: "excise_duty",
        },
        { 
            key: 4,
            title: "development surcharge",
            dataIndex: "development_surcharge",
        },
        {
            key: 5,
            title: "ict development surcharge",
            dataIndex: "ict_development_surcharge",
        },
        {
            key: 6,
            title: "health care surcharge",
            dataIndex: "health_care_surcharge",
        },
        {
            key: 7,
            title: "environmental protection surcharge",
            dataIndex: "environmental_protection_surcharge",
        },

        {
            key: 9, 
            title: "Actions",
            dataIndex: "actions",
            render: (_, record) => {
                return (
                    <>
                        <div className="flex gap-2">
                            <div className="">
                            <>
                            {!record.return_submissions[0]?.id?
                                    <>
                                    <button
                                    onClick={() => navigate(`/account-code-edit/${record.id}`)}
                                    className="bg-blue-500 hover:bg-blue-600 rounded"
                                    >
                                    <AiFillEdit className="text-white text-xl" />
                                    </button>
                                    <button
                                    onClick={() => handleDelete(record.id)}
                                    className="rounded mr-1"
                                    >
                                    <AiFillDelete className="text-xl text-red-500/100 hover:text-red-600" />
                                    </button>
                                    </>
                                    :
                                    <div className='flex'>
                                        <p>Used</p>
                                        <AiFillLock className="text-green text-xl" />
                                    </div>                             
                                    }

                                </>
                            </div>
                        </div>
                    </>
                );
            },
        },
    ]);
    
    return (
        <DashboardLayout>
            <div className="px-5">
            <div className="flex items-center justify-between py-5">
                <h1 className=" uppercase font-semibold  text-xl">ACCOUNTS CODE WISE PAYMENT SCHEDULE (TREASURY DEPOSIT)</h1>
            <button onClick={() => navigate("/account-code-add")} className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
              <div className="flex ">
                <div className="mt-1">
                <AiFillFileAdd />
                </div>
                <div>Add New</div>
              </div>
            </button>
            </div>

            <div className='overflow-x-auto w-[1090px]'>
            <Table
            bordered
                columns={columns}
                dataSource={accountCodeData}
                pagination={{
                    pageSize: 10, 
                    total: accountCodeData?.length,
                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                    showSizeChanger: false,
                  }}
            />
            </div>
            </div>
        </DashboardLayout>
    );
};

export default List;
