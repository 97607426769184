import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from 'sweetalert2';
import { baseUrl } from "../../BaseUrl";
import DashboardLayout from "../../Shared/DashboardLayout";

const CompanyEdit = () => {
  const navigate = useNavigate();
  const { id } = useParams(); // Assuming you have a route parameter for company ID

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
        },
    });

  const [companyData, setCompanyData] = useState({
    company_name: '',
    company_code: '',
    vin: '',
    address: '',
  });

  const [errors, setErrors] = useState({
    company_name: '',
    company_code: '',
    vin: '',
  });

  useEffect(() => {
    // Fetch company data based on companyId and populate the form fields
    const fetchCompanyData = async () => {
      try {
        const jwtToken = localStorage.getItem("access");
        const response = await axios.get(
          `${baseUrl}/coa/api/company/${id}`,
          {
            headers: {
              Authorization: `JWT ${jwtToken}`,
            },
          }
        );
        if(response.data) {
            setCompanyData(response.data);
          }
        console.log(response)
      } catch (error) {
        // Handle error (e.g., display error message)
      }
    };

    fetchCompanyData();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCompanyData({
      ...companyData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const jwtToken = localStorage.getItem("access");
      const response = await axios.put(
        `${baseUrl}/coa/api/company/${id}/`,
        companyData,
        {
          headers: {
            Authorization: `JWT ${jwtToken}`,
          },
        }
      );
      console.log(response);
      navigate('/companies-list');
      Toast.fire({
        icon: 'success',
        title: 'Successfully Updated',
      });
    } catch (error) {
      if (error.response && error.response.data) {
        const errorData = error.response.data;
        setErrors({
          company_name: errorData.company_name ? errorData.company_name[0] : '',
          company_code: errorData.company_code ? errorData.company_code[0] : '',
          vin: errorData.vin ? errorData.vin[0] : '',
        });
      }
      Toast.fire({
        icon: 'error',
        title: `Ops! ${error.message}`,
      });
      console.log(error);
    }
  };

  return (
    <DashboardLayout>
        <div className="p-5">
        <div className="flex">
            <div className="bg-white p-8 rounded shadow-xl">
            <h1 className="uppercase font-semibold text-xl mb-3">Company Data Edit Form</h1>
            <hr />

            <form onSubmit={handleSubmit}>
                <div className="w-full max-w-lg mt-2">
                <div className="md:flex md:items-center mb-4">
                    <div className="w-[200px]">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-2" htmlFor="inline-full-name">
                        Name*
                    </label>
                    </div>
                    <div className="w-full mx-1">
                    <input
                        type="text"
                        name="company_name"
                        id="company_name"
                        value={companyData.company_name}
                        onChange={handleChange}
                        className="block w-full rounded-md border-0 pl-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="Enter Name"
                        required
                    />
                        {errors.company_name && (
                            <div className="text-red-500 text-xs mt-1">{errors.company_name}</div>
                        )}
                    </div>
                </div>
                </div>

                <div className="w-full max-w-lg">
                <div className="md:flex md:items-center mb-2">
                    <div className="w-[200px]">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-5" htmlFor="inline-full-name">
                        Company Code*
                    </label>
                    </div>
                    <div className="w-[100%]">
                    <input
                        type="text"
                        value={companyData.company_code}
                        name="company_code"
                        id="company_code"
                        onChange={handleChange}
                        className="block w-full rounded-md border-0 pl-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="Company Code"
                        required
                    />
                        {errors.company_code && (
                            <div className="text-red-500 text-xs mt-1">{errors.company_code}</div>
                        )}
                    </div>
                </div>
                </div>

                <div className="w-full max-w-lg">
                <div className="md:flex md:items-center mb-2">
                    <div className="w-[200px]">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-5" htmlFor="inline-full-name">
                    BIN Number*
                    </label>
                    </div>
                    <div className="w-[100%]">
                    <input
                        type="text"
                        value={companyData.vin}
                        name="vin"
                        id="vin"
                        onChange={handleChange}
                        className="block w-full rounded-md border-0 pl-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="Enter BIN Number.."
                        required
                    />
                        {errors.vin && (
                            <div className="text-red-500 text-xs mt-1">{errors.vin}</div>
                        )}
                    </div>
                </div>
                </div>

                <div className="w-full max-w-lg">
                <div className="md:flex md:items-center mb-2">
                    <div className="w-[200px]">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-5" htmlFor="inline-full-name">
                    Address*
                    </label>
                    </div>
                    <div className="w-[100%]">
                    <input
                        type="text"
                        value={companyData.address}
                        name="address"
                        id="address"
                        onChange={handleChange}
                        className="block w-full rounded-md border-0 pl-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="Enter vin.."
                        required
                    />
                    </div>
                </div>
                </div>


                <div className="mt-5">
                <button
                    type="submit"
                    className="w-[150px] bg-sky-500 border text-white font-medium rounded-lg h-10"
                >
                    Submit
                </button>
                </div>

            </form>
            </div>
        </div>
        </div>
    </DashboardLayout>
  );
};

export default CompanyEdit;
