import axios from 'axios';
import React, { useState, useEffect } from 'react';
import DashboardLayout from '../../../Shared/DashboardLayout';
import { Link, useNavigate } from 'react-router-dom';
import { Table } from "antd";
import { AiFillDelete,AiFillEdit, AiFillFileAdd, AiFillLock } from "react-icons/ai";
import Swal from 'sweetalert2';
import { baseUrl } from '../../../BaseUrl';
import { format } from "date-fns";

const List = () => {
    const [dueToVatDASFTSD, setDueToVatDASFTSD] = useState([]);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer);
          toast.addEventListener('mouseleave', Swal.resumeTimer);
        },
      });

    useEffect(() => {
        const dueToVatDASFTSD = async () => {
            try {
                const jwtToken = localStorage.getItem("access");
                const response = await axios.get(`${baseUrl}/tax_return/api/due-to-vat-deducted-at-source-from-the-supplies-delivered/`, {
                    headers: {
                        Authorization: `JWT ${jwtToken}`,
                    },
                });

                setDueToVatDASFTSD(response.data.results);
                // console.log(response.data.results)
            } catch (error) {
                setError(error);
            }
        };

        dueToVatDASFTSD();
    }, []); 

    const handleDelete = async (itemId) => {
        try {
            const jwtToken = localStorage.getItem("access");
            await axios.delete(`${baseUrl}/tax_return/api/due-to-vat-deducted-at-source-from-the-supplies-delivered/${itemId}/`, {
                headers: {
                    Authorization: `JWT ${jwtToken}`,
                },
            });

            // Update customerData state after successful deletion
            setDueToVatDASFTSD((prevData) =>
                prevData.filter((item) => item.id !== itemId)
            );
            Toast.fire({
                icon: 'info',
                title: 'Successfully Delete',
              });
        } catch (error) {
            if(error.response.data.detail){
                Toast.fire({
                    icon: 'error',
                    title: `Ops! ${error.response.data.detail}`,
                  });
            }else{
                Toast.fire({
                    icon: 'error',
                    title: `Ops! ${error.message}`,
                });
            }
            console.log(error)

        }
    };

    const formatDate = (dateString) => {
        try {
          const date = new Date(dateString);
          return format(date, "dd-M-yyyy");
        } catch (error) {
          return "...";
        }
      };

    const [columns, setColumns] = useState([
        {
            key: 1,
            title: "Date",
            dataIndex: "last_updated",
            render: (text) => formatDate(text),
        },
        {
            key: 2,
            title: "Customer name",
            dataIndex: "customer_name",
        },
        {
            key: 3,
            title: "Challan Number",
            dataIndex: "challan_no",
        },
        {
            key: 4,
            title: "Total amount with vat",
            dataIndex: "total_amount_with_vat",
        },
        { 
            key: 5,
            title: "vat amount",
            dataIndex: "vat_amount",
        },
        {
            key: 6,
            title: "status",
            dataIndex: "status_text",
        },

        {
            key: 9, 
            title: "Actions",
            dataIndex: "actions",
            render: (_, record) => {
                return (
                    <>
                        <div className="flex gap-2">
                            <div className="">
                            <>
                                {record.status !== 2?
                                    <>
                                    <button
                                    onClick={() => navigate(`/due-to-vat-deducted-at-source-from-the-supplies-delivered-edit/${record.id}`)}
                                    className="bg-blue-500 hover:bg-blue-600 rounded"
                                    >
                                    <AiFillEdit className="text-white text-xl" />
                                    </button>
                                    <button
                                    onClick={() => handleDelete(record.id)}
                                    className="rounded mr-1"
                                    >
                                    <AiFillDelete className="text-xl text-red-500/100 hover:text-red-600" />
                                    </button>
                                    </>
                                    :
                                    <div className='flex'>
                                        <AiFillLock className="text-green text-xl" />
                                        <p>Completed</p>
                                    </div>                             
                                    }

                                </>
                            </div>
                        </div>
                    </>
                );
            },
        },
    ]);
    
    return (
        <DashboardLayout>
            <div className="px-5">
            <div className="flex items-center justify-between py-5">
                <h1 className=" uppercase font-semibold  text-xl">Due To Vat Deducted At Source From The Supplies Delivered List</h1>
            <button onClick={() => navigate("/due-to-vat-deducted-at-source-from-the-supplies-delivered-add")} className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
              <div className="flex ">
                <div className="mt-1">
                <AiFillFileAdd />
                </div>
                <div>Add New</div>
              </div>
            </button>
            </div>

            <div className='overflow-x-auto w-[1090px]'>
            <Table
            bordered
                columns={columns}
                dataSource={dueToVatDASFTSD}
                pagination={{
                    pageSize: 10, 
                    total: dueToVatDASFTSD?.length,
                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                    showSizeChanger: false,
                  }}
            />
            </div>
            </div>
        </DashboardLayout>
    );
};

export default List;
