import React, { useState } from 'react';
import axios from 'axios';
import Select from 'react-select';
import DashboardLayout from '../../Shared/DashboardLayout';
import { baseUrl } from '../../BaseUrl';
import { useEffect } from 'react';
import { AiFillDelete } from 'react-icons/ai';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

const MushakSixSevenEdit = () => {
  const [formData, setFormData] = useState({
    challan: null,
    status: null,
    credititem_set: [
      {
        sales_order: null,
        return_quantity: '',
        reasons_to_return: '',
      },
    ],
  });

  const navigate = useNavigate()
  const [salesChallanOptions, setSalesChallanOptions] = useState([]);
  const [selectedSalesChallan, setSelectedSalesChallan] = useState(null);

  const [salesOrderOptions, setSalesOrderOptions] = useState([]);
  const [selectedSalesOrder, setSelectedSalesOrder] = useState([]);
  const [selectedSalesOrderSalesQuantity,setSelectedSalesOrderSalesQuantity] = useState()

  const [salesOrderAddMoreRequiredMessage,setSalesOrderAddMoreRequiredMessage]= useState('')
  const challan = selectedSalesChallan?.value
  const {id} = useParams()
  const [selectedStatus, setSelectedStatus] = useState(null);

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
  });

  const statusOptions = [
    { value: 0, label: 'Ordered' },
    { value: 1, label: 'WIP' },
    { value: 2, label: 'Complete' },
  ];


  useEffect(() => {
    const fetchCreditNoteData = async () => {
      try {
        const jwtToken = localStorage.getItem('access');
        const response = await axios.get(
          `${baseUrl}/sales/api/credit-note/${id}/`,
          {
            headers: {
              Authorization: `JWT ${jwtToken}`,
            },
          }
        );
        const existingData = response.data; 
        setFormData(existingData);


         setSelectedSalesChallan({
              value: existingData.challan,
              label: `Challan Numbe :- ${existingData.challan}`,
            });

        
        const selectedSalesOrder = existingData.credititem_set.map((order) => ({
          value: order.id,
          label: order.sales_inventory_name,
        }));
        setSelectedSalesOrder(selectedSalesOrder);

        setSelectedStatus({
          value: existingData.status,
          label: statusOptions.find((option) => option.value === existingData.status)?.label,
        });

        console.log(existingData.challan)

      } catch (error) {
        console.error('Error credit note data', error);
      }
    };
    fetchCreditNoteData();
  }, [id]);

  const handleStatusChange = (selectedOption) => {
    setSelectedStatus(selectedOption); 
    setFormData({ ...formData, status: selectedOption.value });
  };

  const handleInputChange = (e, index, type) => {
    const updatedData = { ...formData };

    if (type === 'credititem_set') {
      updatedData.credititem_set[index][e.target.name] = e.target.value;
    } else {
      updatedData[e.target.name] = e.target.value;
    }

    setFormData(updatedData);
  };

  useEffect(() => {
    const fetchChallanNo = async () => {

        try {
          const jwtToken = localStorage.getItem("access");
          const response = await axios.get(`${baseUrl}/sales/api/order_list/`, {
              headers: {
                  Authorization: `JWT ${jwtToken}`,
              },
          });
          // Filter items with type 'Finished Good'
          const complatedData = response.data.filter(
              (item) => item.status === "Complete"
          );
          setSalesChallanOptions(
            complatedData.map((order) => ({
              value: order.chalan,
              label: `Challan Numbe :- ${order.chalan}`,
            }))
          );
      } catch (error) {
          console.log(error)
      }
    };
    fetchChallanNo();
  }, []);


    // ...

  useEffect(() => {
    const fetchSalesOrder = async () => {
      const jwtToken = localStorage.getItem("access");
      try {
        const res = await axios.post(
          `${baseUrl}/sales/api/line_items/`,
          {
            challan: `${challan}`,
          },
          {
            headers: {
              Authorization: `JWT ${jwtToken}`,
              'Content-Type': 'application/json',
            },
          }
        );

        // Filter sales orders based on the selected challan
        const filteredSalesOrders = res.data.filter((order) => order.challan === challan);

          // Filter out sales inventory that are already selected
          const filteredSelectedInventoyData = filteredSalesOrders.filter((item) => {
            const InventoryValue = item.id;
            return !selectedSalesOrder.some(
            (selected) => selected.value === InventoryValue
            );
          });


        setSalesOrderOptions(
          filteredSelectedInventoyData.map((order) => ({
            value: order.id,
            label: order.inventory_name,
            challan:order.challan,
          }))
        );

        const stockInfo = {};
        filteredSalesOrders.forEach((salesItem) => {
          stockInfo[salesItem.id] = salesItem.sales_quantity;
        });
        setSelectedSalesOrderSalesQuantity(stockInfo);

      } catch (error) {
        console.log(error);
      }
    };
    fetchSalesOrder();
  }, [challan,selectedSalesOrder]);

    // ...

    const handleChallanNoChange = (selectedOption) => {
      const challanNo = selectedOption.value;
      setSelectedSalesChallan(selectedOption);

      const newCredititemSet = [
        {
          sales_order: null,
          return_quantity: '',
          reasons_to_return: '',
        },
      ];

      setSelectedSalesOrder([
        {
          value: null,
          label: 'Select..',
        }
      ]);
    
      setFormData({
        ...formData,
        challan: challanNo,
        credititem_set: newCredititemSet,
      });
    };
    
   

  const handleSalesOrderChange = (selectedOption, index) => {
    const updatedFormData = { ...formData };
    updatedFormData.credititem_set[index].sales_order = selectedOption.value;
    updatedFormData.credititem_set[index].sales_quantity = selectedSalesOrderSalesQuantity[selectedOption.value];

    const updatedselectedSalesOrder = [...selectedSalesOrder];
    updatedselectedSalesOrder[index] = selectedOption;
    
    setSelectedSalesOrder(updatedselectedSalesOrder);
    
    setFormData(updatedFormData);
  };


  const areAllSalesOrderFieldsFilled = () => {
    const newSalesOrderEntry = formData.credititem_set[formData.credititem_set.length - 1];
    return (
      newSalesOrderEntry.sales_order !== null &&
      newSalesOrderEntry.return_quantity.trim() !== '' &&
      newSalesOrderEntry.reasons_to_return.trim() !== ''
    );
  };
  
  const handleAddSalesOrder = () => {
    if (areAllSalesOrderFieldsFilled()) {
        const newSalesOrderEntry = {
            sales_order: null,
            return_quantity: '',
            reasons_to_return: '',
          };
      setFormData({
        ...formData,
        credititem_set: [...formData.credititem_set, newSalesOrderEntry],
      });
  
      setSelectedSalesOrder([...selectedSalesOrder, formData.credititem_set[formData.credititem_set.length - 1].sales_order]);
      setSalesOrderAddMoreRequiredMessage('');
    } else {
        setSalesOrderAddMoreRequiredMessage("Please fill in all required sales order fields")
    }
  };

  const handleRemoveSalesOrder = (index) => {
    const credititem_set = [...formData.credititem_set];
    credititem_set.splice(index, 1);
    setFormData({
      ...formData,
      credititem_set,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const jwtToken = localStorage.getItem("access");
      const response = await axios.put(
        `${baseUrl}/sales/api/credit-note/${id}/`,
        formData,  // Your form data
        {
          headers: {
            Authorization: `JWT ${jwtToken}`,
          },
        }
      );
      navigate("/mushak-6-7-list");
      Toast.fire({
        icon: 'success',
        title: 'Successfully Updated',
      });
    } catch (error) {
        if(error.response.data.detail){
            Toast.fire({
            icon: 'error',
            title: `${error.response.data.detail}`,
            });
        }else{
            console.error('Error saving data:', error);
        }

    }
  };

  return (
    <DashboardLayout>
    <div className="px-5 mt-5 mb-10 pb-10">
        <div className=" w-4/3">
        <div className="bg-white p-8 rounded shadow-xl shadow-blue-500/40 w-[90%] mx-auto">
            <form onSubmit={handleSubmit}>
            <div className="flex justify-between rounded border-b-[1px] mb-10 bg-slate-900">
                <div className="mx-5 my-auto">
                <h1 className="uppercase font-semibold text-xl text-[#fff]">Credit Note Edit Form</h1>
                </div>
                <div className="my-2 mx-5">
                <button
                    type="submit"
                    className="w-[120px] bg-slate-700 hover:bg-slate-800 border text-white font-medium rounded-lg h-10"
                >
                    Submit
                </button>
                </div>
            </div>

            <div class="flex flex-wrap -mx-3 mb-2">

                <div class="w-full md:w-4/4 px-3 mb-8">
                  <div className="flex  md:w-4/4 mx-5 ">
                    <div class="md:w-[20%] my-auto">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-3"  for="inline-full-name">
                    Challan Number*
                    </label>
                    </div>
                    <div class="md:w-[80%]">
                        <Select
                            id="inventory"
                            name="inventory"
                            value={selectedSalesChallan}
                            onChange={handleChallanNoChange}
                            options={salesChallanOptions}
                            required
                        />
                    </div>
                  </div>
                </div>

                <div class="w-full md:w-4/4 px-3 mb-8">
                  <div className="flex  md:w-4/4 mx-5 ">
                    <div class="md:w-[20%] my-auto">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-3"  for="inline-full-name">
                        Status*
                    </label>
                    </div>
                    <div class="md:w-[80%]">
                        <Select
                            id="status"
                            name="status"
                            value={statusOptions.find((option) => option.value === formData.status)}
                            onChange={handleStatusChange}
                            options={statusOptions}
                            required
                        />
                    </div>
                  </div>
                </div>
            </div>

                {formData.credititem_set?.map((salesInventory, index) => (
                <table className="table-auto w-100 sales-order-table ml-5 ">
                {index === 0 && (
                    <thead>
                    <tr>
                        <th className='inventory-th'>
                        <label
                            class="block uppercase tracking-wide  text-[12px] font-semibold"
                            for="grid-state"
                        >
                            Sales Item*
                        </label>
                        </th>
                        <th>
                        <label
                            class="block uppercase tracking-wide  text-[12px] font-semibold"
                            for="inline-full-name"
                        >
                            Return Quantity*
                        </label>
                        </th>
                        <th className='total-price-td'>
                        <label
                            class="block uppercase tracking-wide  text-[12px] font-semibold"
                            for="inline-full-name"
                        >
                            Reasons to return*
                        </label>
                        </th>
                        <th className="action-th"></th>
                    </tr>
                    </thead>
                )}
                <tbody>
                    <tr className="table-input-filed">
                    <td className="w-[40%] inventory-th">
                        <Select
                            id={`sales_order_${index}`}
                            name={`credititem_set[${index}].sales_order`}
                            value={selectedSalesOrder[index]}
                            onChange={(selectedOption) => handleSalesOrderChange(selectedOption, index)}
                            options={salesOrderOptions}
                            required
                        />
                    </td>
                    <td className="w-[25%]">
                        <input
                            type="number"
                            min='0'
                            step='0.01'
                            max={salesInventory.sales_quantity}
                            name="return_quantity"
                            required
                            value={salesInventory.return_quantity}
                            onChange={(e) => handleInputChange(e, index, 'credititem_set')}
                            placeholder="Enter quantity"
                            className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-blue-300"
                        />
                        {salesInventory.sales_quantity && (
                        <div className="text-sm text-sky-500">
                            Sales Quantity: {salesInventory.sales_quantity}
                        </div>
                        )}
                    </td>
                    <td className="w-[25%] total-price-td">
                        <input
                            type='text'
                            name="reasons_to_return"
                            value={salesInventory.reasons_to_return}
                            onChange={(e) => handleInputChange(e, index, 'credititem_set')}
                            placeholder="Enter reasons_to_return"
                            required
                            className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-blue-300"
                        />
                    </td>

                    {index === 0 && (
                        <td className="w-[10%] action-td"></td>
                    )}
                    {index !== 0 && (
                        <td className="w-[10%] action-td">
                        {index !== 0 && (
                            <button
                            type="button"
                            onClick={() => handleRemoveSalesOrder(index)}
                            className="text-red-500"
                            >
                            <AiFillDelete className="text-xl text-red-500/100 hover:text-red-600 " />
                            </button>
                        )}
                        </td>
                    )}
                    </tr>
                </tbody>
                </table>
                ))}

                <div className="mb-3 ml-5">
                {salesOrderAddMoreRequiredMessage && (
                    <span className='text-red-500 mt-2'>{salesOrderAddMoreRequiredMessage} <br /> </span> 
                )}
                <button
                  type="button"
                  onClick={() => {
                    console.log('Add Product Button Clicked');
                    handleAddSalesOrder();
                  }}
                  className="bg-slate-800 hover:bg-slate-900 text-white p1-2 px-2 my-3 rounded"
                  title='All fields required for add more product'
                >
                  Add More
                </button>
                <hr />
              </div>
            </form>
        </div>
        </div>
    </div>
  </DashboardLayout>
  );
};

export default MushakSixSevenEdit;
