import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";  
import { baseUrl } from "../BaseUrl";

const SupplyCreateForm = () => {
  const navigate = useNavigate();

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  const [formData, setFormData] = useState({
    company_name: '',
    contact_person: '',
    phone_number: '',
    address: '',
    registration_no: '',
    nid_no: '',
  });

  const [formErrors, setFormErrors] = useState({
    company_name: '',
    contact_person: '',
    phone_number: '',
    registration_no: '',
    nid_no: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    // Clear the error message when the user starts typing
    setFormErrors({
      ...formErrors,
      [name]: '',
    });
  };

  const handleSubmit = async () => {
    try {
      const jwtToken = localStorage.getItem("access");
      // Check if required fields are empty before submitting
      if (!formData.company_name || !formData.contact_person || !formData.phone_number || !formData.registration_no || !formData.nid_no) {
        setFormErrors({
          company_name: !formData.company_name ? 'This field is required' : '',
          contact_person: !formData.contact_person ? 'This field is required' : '',
          phone_number: !formData.phone_number ? 'This field is required' : '',
          registration_no: !formData.registration_no ? 'This field is required' : '',
          nid_no: !formData.nid_no ? 'This field is required' : '',
        });
        return; // Don't submit the form
      }

      const response = await axios.post(
        `${baseUrl}/purchase/api/supplier/`,
        formData,
        {
          headers: {
            Authorization: `JWT ${jwtToken}`,
          },
        }
      );
      console.log(response);
      navigate('/supplyChain');
      Toast.fire({
        icon: 'success',
        title: 'Successfully Add',
      });

    } catch (error) {
      console.log(error)
      Toast.fire({
        icon: 'error',
        title: `Ops! ${error.message}`,
      });
      if (error.response.data.code == "token_not_valid") {
        alert("token expired please login again..");
        navigate("/login");
      }
    }
  };


  return (
    <div className="p-5">
      <div className="flex ">
      <div className="bg-white p-8 rounded shadow-xl">
      <h1 className="uppercase font-semibold text-xl mb-3">Supplier Add Form</h1> <hr />
      
      <div className='w-full max-w-lg mt-5'>
        <div class="md:flex md:items-center mb-2">
          <div class="w-[250px]">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-5"  for="inline-full-name">
            Company Name*
            </label>
          </div>
          <div class="w-[99%] mx-1">
            <input
                type="text"
                name="company_name"
                id="company_name"
                value={formData.company_name}
                onChange={handleChange}
                className="block w-full rounded-md border-0 pl-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="Company Name"
              />
              <p className="text-red-500 text-xs mt-1">{formErrors.company_name}</p>
          </div>
        </div>
      </div>

      <div className='w-full max-w-lg mt-2'>
        <div class="md:flex md:items-center mb-2">
          <div class="w-[250px]">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-5"  for="inline-full-name">
            Contact Person*
            </label>
          </div>
          <div class="w-[49%] mx-1">
            <input
              type="text"
              name="contact_person"
              id="contact_person"
              value={formData.contact_person}
              onChange={handleChange}
              className="block w-full rounded-md border-0 pl-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder="Contract person name*"
            />
            <p className="text-red-500 text-xs mt-1">{formErrors.contact_person}</p>
          </div>
          <div class="w-[49%] mx-1">
          <input
              type="text"
              onChange={handleChange}
              value={formData.phone_number}
              name="phone_number"
              id="phone_number"
              className="block w-full rounded-md border-0 pl-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder="Phone number*"
            />
            <p className="text-red-500 text-xs mt-1">{formErrors.phone_number}</p>
          </div>
        </div>
      </div>

      <div className='w-full max-w-lg mt-2'>
        <div class="md:flex md:items-center mb-2">
          <div class="w-[250px]">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-5"  for="inline-full-name">
              Address
            </label>
          </div>
          <div class="w-[100%]">
          <input
              type="text"
              onChange={handleChange}
              value={formData.address}
              name="address"
              id="address"
              className="block w-full rounded-md border-0 pl-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder=" Enter address.."
            />
          </div>
        </div>
      </div>

      <div className='w-full max-w-lg mt-2'>
        <div class="md:flex md:items-center mb-2">
          <div class="w-[250px]">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-5"  for="inline-full-name">
              Registration No*
            </label>
          </div>
          <div class="w-[100%]">
            <input
                type="text"
                onChange={handleChange}
                value={formData.registration_no}
                name="registration_no"
                id="registration_no"
                className="block w-full rounded-md border-0 pl-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="Enter Registration No*"
              />
              <p className="text-red-500 text-xs mt-1">{formErrors.registration_no}</p>
          </div>
        </div>
      </div>

      <div className='w-full max-w-lg mt-2'>
        <div class="md:flex md:items-center mb-2">
          <div class="w-[250px]">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-5"  for="inline-full-name">
              NID NO*
            </label>
          </div>
          <div class="w-[100%]">
          <input
              type="text"
              onChange={handleChange}
              value={formData.nid_no}
              name="nid_no"
              id="nid_no"
              className="block w-full rounded-md border-0 pl-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder="Enter nid no.."
            />
            <p className="text-red-500 text-xs mt-1">{formErrors.nid_no}</p>
          </div>
        </div>
      </div>
      
      <div className="w-full max-w-lg mt-2 text-right">
        <button
          // type="submit"
          onClick={handleSubmit}
          className="w-[150px] bg-sky-500 border text-white font-medium rounded-lg h-10"
        >
          Submit
        </button>
      </div>
    </div>
    </div>
    </div>
  );
};

export default SupplyCreateForm;
