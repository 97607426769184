import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from 'sweetalert2';
import { baseUrl } from "../../BaseUrl";
import DashboardLayout from "../../Shared/DashboardLayout";


const RateDataEdit = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
  });

  const [rateForm, setRateForm] = useState({
    rate_name: '',
    vat_rate: '',
    sd_rate: '',
  });

  useEffect(() => {
    const fetchRateData = async () => {
      try {
        const jwtToken = localStorage.getItem("access");
        const response = await axios.get(
          `${baseUrl}/coa/api/rate/${id}/`,
          {
            headers: {
              Authorization: `JWT ${jwtToken}`,
            },
          }
        );
        if(response.data) {
            setRateForm(response.data);
          }
        console.log(response)
      } catch (error) {
        // Handle error (e.g., display error message)
        console.log(error)
      }
    };

    fetchRateData();
  }, [id]);


  // handle change function
  const handleChange = (e) => {
    const { name, value } = e.target;
    setRateForm({
      ...rateForm,
      [name]: value,
    });

  };

  // handle submit function...
    const handleSubmit = async (e) => {
    e.preventDefault() 
    try {
        const jwtToken = localStorage.getItem("access");
        const response = await axios.put(
        `${baseUrl}/coa/api/rate/${id}/`,
        rateForm,
        {
            headers: {
            Authorization: `JWT ${jwtToken}`,
            },
        }
        );
        console.log(response);
        navigate('/rate-list');
        Toast.fire({
        icon: 'success',
        title: 'Successfully Add',
        });
    } catch (error) {
        Toast.fire({
        icon: 'error',
        title: `Ops! ${error.message}`,
        });
        console.log(error);
    }
    };

  return (
    <DashboardLayout>
    <div className="p-5">
      <div className="flex">
        <div className="bg-white p-8 rounded shadow-xl">
          <h1 className="uppercase font-semibold text-xl mb-3">Rate Date Edit Form</h1>
          <hr />

          <form onSubmit={handleSubmit}>
            <div className="w-full max-w-lg mt-2">
              <div className="md:flex md:items-center mb-4">
                <div className="w-[200px]">
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-2" htmlFor="inline-full-name">
                   Rate Name*
                  </label>
                </div>
                <div className="w-full mx-1">
                  <input
                    type="text"
                    name="rate_name"
                    id="rate_name"
                    value={rateForm.rate_name}
                    onChange={handleChange}
                    className="block w-full rounded-md border-0 pl-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="rate name"
                    required
                  />
                </div>
              </div>
            </div>

            <div className="w-full max-w-lg">
              <div className="md:flex md:items-center mb-2">
                <div className="w-[200px]">
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-5" htmlFor="inline-full-name">
                    Vat Rate*
                  </label>
                </div>
                <div className="w-[100%]">
                  <input
                    type="number"
                    min="0"
                    step="0.01"
                    value={rateForm.vat_rate}
                    name="vat_rate"
                    id="vat_rate"
                    onChange={handleChange}
                    className="block w-full rounded-md border-0 pl-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="vat rate"
                    required
                  />
                </div>
              </div>
            </div>

            <div className="w-full max-w-lg">
              <div className="md:flex md:items-center mb-2">
                <div className="w-[200px]">
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-5" htmlFor="inline-full-name">
                  SD rate
                  </label>
                </div>
                <div className="w-[100%]">
                  <input
                    type="number"
                    min="0"
                    step="0.01"
                    value={rateForm.sd_rate}
                    name="sd_rate"
                    id="sd_rate"
                    onChange={handleChange}
                    className="block w-full rounded-md border-0 pl-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="Enter sd rate.."
                    required
                  />
                </div>
              </div>
            </div>

            <div className="mt-5">
              <button
                type="submit"
                className="w-[150px] bg-sky-500 border text-white font-medium rounded-lg h-10"
              >
                Submit
              </button>
            </div>

          </form>
        </div>
      </div>
    </div>
    </DashboardLayout>
  );
};

export default RateDataEdit;
