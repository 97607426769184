// eslint-disable-next-line no-unused-vars
import React from "react";
import DashboardLayout from "../Shared/DashboardLayout";
import InventoryEditForm from "../components/InventoryEditForm";
const InventoryEditPage = () => {
  return (
    <DashboardLayout>
        <InventoryEditForm />
    </DashboardLayout>
  );
};

export default InventoryEditPage;
