import DashboardLayout from "../Shared/DashboardLayout";
import InventoryCreateForm from "../components/InventoryCreateForm";

const InventoryCreatePage = () => {
  return (
    <DashboardLayout>
      <InventoryCreateForm />
    </DashboardLayout>
  );
};

export default InventoryCreatePage;
