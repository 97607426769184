/* eslint-disable no-unused-vars */
import React from "react";
import munkey from "../assets/images/munkey.jpg";
import ProgressBar from "@ramonak/react-progress-bar";
import DashboardLayout from "../Shared/DashboardLayout";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {FcPrint, FcPortraitMode,FcCollaboration,FcBusinessman,FcDataSheet,FcHome,FcKindle,FcNook,FcShipped,FcShop,FcSoundRecordingCopyright, FcPackage } from "react-icons/fc";

const DashboardHome = () => {
  return (
    <DashboardLayout>
      <div className="p-5">
        <h1 className="text-[25px] font-blod mb-2 ">Mushak 6.1</h1><hr />
        <div className=" my-5 grid lg:grid-cols-4 md:grid-cols-2 gap-5 text-white">
          <Link to='/purchases'>
          <div className="bg-green-800 w-full p-5 rounded-lg">
            <div className="flex justify-between">
                <h1 className="text-5xl"><FcShop /></h1>
                <h1 className="text-3xl mt-2 ">Purchases</h1>
            </div>
            <h2 className="text-2xl mt-4">Total (....)</h2>
          </div>
          </Link>

          <Link to='/supplyChain'>
          <div className="bg-blue-900 w-full p-5 rounded-lg">
            <div className="flex justify-between">
                <h1 className="text-5xl"><FcCollaboration /></h1>
                <h1 className="text-3xl mt-2">Supplier</h1>
            </div>
            <h2 className="text-2xl mt-4">Total (....)</h2>
          </div>
          </Link>

          <Link to='/production-list'>
          <div className="bg-violet-950 w-full p-5 rounded-lg">
            <div className="flex justify-between">
                <h1 className="text-5xl"><FcSoundRecordingCopyright /></h1>
                <h1 className="text-3xl mt-2">Production</h1>
            </div>
            <h2 className="text-2xl mt-4">Total (....)</h2>
          </div>
          </Link>

          <Link to='/mushak-6-1'>
          <div className="bg-teal-900 w-full p-5 rounded-lg">
            <div className="flex justify-between">
                <h1 className="text-5xl"><FcPrint /></h1>
                <h1 className="text-3xl mt-2">M-6.1 PDF</h1>
            </div>
            <h2 className="text-2xl mt-4">Total (....)</h2>
          </div>
          </Link>
        </div>

        <h1 className="text-[25px] font-blod ">Mushak 6.2</h1><hr />
        <div className=" my-5 grid lg:grid-cols-4 md:grid-cols-2 gap-5 text-white">
          <Link to='/sales-order'>
          <div className="bg-green-800 w-full p-5 rounded-lg">
            <div className="flex justify-between">
                <h1 className="text-5xl"><FcShipped /></h1>
                <h1 className="text-3xl mt-2 ">Sales Order</h1>
            </div>
            <h2 className="text-2xl mt-4">Total (....)</h2>
          </div>
          </Link>

          <Link to='/customer-list'>
          <div className="bg-blue-900 w-full p-5 rounded-lg">
            <div className="flex justify-between">
                <h1 className="text-5xl"><FcPortraitMode /></h1>
                <h1 className="text-3xl mt-2">Customers</h1>
            </div>
            <h2 className="text-2xl mt-4">Total (....)</h2>
          </div>
          </Link>
          <Link to='/mushak-6-2'>
          <div className="bg-teal-900 w-full p-5 rounded-lg">
            <div className="flex justify-between">
                <h1 className="text-5xl"><FcPrint /></h1>
                <h1 className="text-3xl mt-2">M-6.2 PDF</h1>
            </div>
            <h2 className="text-2xl mt-4">Total (....)</h2>
          </div>
          </Link>
        </div>

        <h1 className="text-[25px] font-blod ">Mushak 6.3</h1><hr />
        <div className=" my-5 grid lg:grid-cols-4 md:grid-cols-2 gap-5 text-white">
          <Link to='/mushak-6-3'>
          <div className="bg-teal-900 w-full p-5 rounded-lg">
            <div className="flex justify-between">
                <h1 className="text-5xl"><FcPrint /></h1>
                <h1 className="text-3xl mt-2">M-6.1 PDF</h1>
            </div>
            <h2 className="text-2xl mt-4">Total (....)</h2>
          </div>
          </Link>
        </div>
        
      </div>
    </DashboardLayout>
  );
};

export default DashboardHome;
