import DashboardLayout from "../Shared/DashboardLayout";
import CreateUserAccount from "../components/CreateUserAccount";

const UsersCreate = () => {
  return (
    <DashboardLayout>
        <CreateUserAccount />
    </DashboardLayout>
  );
};

export default UsersCreate;
