import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useReactToPrint } from "react-to-print";
import { Link, useNavigate, useParams } from "react-router-dom";
import govLogo from "../../assets/images/gov-logo.png";
import { format } from "date-fns";
import { baseUrl } from "../../BaseUrl";
import DashboardLayout from "../../Shared/DashboardLayout";

const MushakNineOnePreview = () => {
  const componentPDF = useRef();
  const navigate = useNavigate();
  const {id} =useParams()
  const [companyData, setCompanyData] = useState([]);

  const [returnSubmission, setReturnSubmission] = useState([]);

  useEffect(() => {
      const returnSubmission = async () => {
          try {
              const jwtToken = localStorage.getItem("access");
              const response = await axios.get(`${baseUrl}/tax_return/api/return-submissions-pdf/${id}`, {
                  headers: {
                      Authorization: `JWT ${jwtToken}`,
                  },
              });

              setReturnSubmission(response.data);
              console.log(response.data)
          } catch (error) {
          }
      };

      returnSubmission();
  }, []); 

   
   useEffect(() => {
       const fetchcompanyData = async () => {
           try {
               const jwtToken = localStorage.getItem("access");
               const response = await axios.get(`${baseUrl}/coa/api/company/`, {
                   headers: {
                       Authorization: `JWT ${jwtToken}`,
                   },
               });
 
               setCompanyData(response.data.results);
               // console.log(response.data.results)
           } catch (error) {
           }
       };
 
       fetchcompanyData();
   }, []);

   const formatDate = (dateString) => {
    try {
      const date = new Date(dateString);
      return format(date, "dd-M-yyyy");
    } catch (error) {
      return "...";
    }
  };

  return (
    <DashboardLayout>
    <div className="">
      <div>
        <div className="mb-4 flex justify-around">
          <div>
            <p className="text-right m-2">
                <img width={"70px"} src={govLogo} alt="" />
            </p>
          </div>
          <div className="top-header my-3"> 
            <p className="text-center">GOVERNMENT OF THE PEOPLE'S REPUBLIC OF BANGLADESH</p>
            <p className="text-center">NATIONAL BOARD OF REVENUE</p>
            <h3 className="text-center font-bold">VALUE ADDED TAX RETURN FORM</h3>
            <p className="text-center">[See rule 47(1)]</p>
          </div>
          <div>
            <p className="text-right m-2">Mushak-9.1</p>
          </div>
        </div>


        {/* Part-1: TAXPAYER'S INFORMATION start */}
        <div className="mb-5">
          <p className="text-center my-2 font-semibold"> Part-1: TAXPAYER'S INFORMATION </p>
          <table className="min-w-full">
            <tbody className="mt-1">
                <tr>
                    <td className="w-[2%] border border-black text-center">1</td>
                    <td className="w-[37%] border border-black pl-1"> BIN</td>
                    <td className="w-[3%] border border-black text-center">:</td>
                    <td className="w-[60%] border border-black pl-5">{companyData[0]?.vin}</td>
                </tr>
                <tr>
                <td className="w-[2%] border border-black text-center">2</td>
                    <td className="w-[37%] border border-black pl-1">Name of Taxpayer</td>
                    <td className="w-[3%] border border-black text-center">:</td>
                    <td className="w-[60%] border border-black pl-5">{companyData[0]?.company_name}</td>
                </tr>
                <tr>
                    <td className="w-[2%] border border-black text-center">3</td>
                    <td className="w-[37%] border border-black pl-1">Address of Taxpayer</td>
                    <td className="w-[3%] border border-black text-center">:</td>
                    <td className="w-[60%] border border-black pl-5">{companyData[0]?.address}</td>
                </tr> 
                <tr>
                  <td className="w-[2%] border border-black text-center">4</td>
                    <td className="w-[37%] border border-black pl-1">Type of Ownership</td>
                    <td className="w-[3%] border border-black text-center">:</td>
                    <td className="w-[60%] border border-black pl-5"></td>
                </tr>
                <tr>
                    <td className="w-[2%] border border-black text-center">5</td>
                    <td className="w-[37%] border border-black pl-1">Economic Activity</td>
                    <td className="w-[3%] border border-black text-center">:</td>
                    <td className="w-[60%] border border-black pl-5"></td>
                </tr>
            </tbody>
          </table>
        </div>
        {/* Part-1: TAXPAYER'S INFORMATION end */}



        {/* Part-2 : RETURN SUBMISSION DATA start */}
        <div className="mb-5">
          <p className="text-center my-2 font-semibold"> Part-2 : RETURN SUBMISSION DATA </p>
          <table className="min-w-full">
            <tbody className="mt-1">
                <tr>
                  <td className="w-[2%] border border-black text-center">1</td>
                    <td className="w-[37%] border border-black pl-1">Tax Preiod</td>
                    <td className="w-[3%] border border-black text-center">:</td>
                    <td className="w-[60%] border border-black pl-5">{formatDate(returnSubmission?.serializer_data?.tax_preiod_from_date)} to {formatDate(returnSubmission?.serializer_data?.tax_preiod_to_date)} </td>
                </tr>
                <tr>
                    <td className="w-[2%] border border-black text-center">2</td>
                    <td className="w-[37%] border border-black pl-1">Type of Return <br />  <p className="py-2">[Please select your desired option]</p></td>
                    <td className="w-[3%] border border-black text-center">:</td>
                    <td className="w-[60%] border border-black pl-5">
                        <p className="py-1">(A) Main/Orginal Return (Section 64)  
                        <input type="checkbox" disabled checked={returnSubmission?.serializer_data?.type_of_return === 1}  className="text-black border border-black rounded focus:ring-0"  />
                        </p>
                        <p className="py-1">(B) Late Return (Section 65) 
                        <input type="checkbox" checked={returnSubmission?.serializer_data?.type_of_return === 2}  disabled className="text-black border border-black rounded focus:ring-0"  />
                        </p>
                        <p className="py-1">(C)Amend Return (Section 66) 
                        <input type="checkbox" disabled checked={returnSubmission?.serializer_data?.type_of_return === 3} className="text-black border border-black rounded focus:ring-0"  />
                        </p>
                        <p className="py-1">(D) Full or Additional or Alternative Return ( Section 67) 
                          <input type="checkbox" disabled checked={returnSubmission?.serializer_data?.type_of_return === 4} className="text-black border border-black rounded focus:ring-0"  />
                        </p>
                    </td>
                </tr>
                <tr>
                  <td className="w-[2%] border border-black text-center">3</td>
                    <td className="w-[37%] border border-black pl-1">
                      Any activities in this Tax Period? 
                      <br />  <p className="py-2">[If Selected “No” Please Fill Only the relevant Part]</p>
                     </td>
                    <td className="w-[3%] border border-black text-center">:</td>
                    <td className="w-[60%] border border-black pl-5">
                    <p className="py-1">
                      <span className=" px-1">
                        <input type="checkbox" checked={returnSubmission?.serializer_data?.any_activities_in_this_tax_period === true} disabled  className="text-black border border-black rounded focus:ring-0"  /> Yes </span>
                       <span className="px-1">
                        <input type="checkbox" checked={returnSubmission?.serializer_data?.any_activities_in_this_tax_period === false}  disabled  className="text-black border border-black rounded focus:ring-0"  /> No</span>
                    </p>
                    <p>[If Selected “No” Please Fill Only Section I, II & X]</p>
                    </td>
                </tr> 
                <tr>
                  <td className="w-[2%] border border-black text-center">4</td>
                    <td className="w-[37%] border border-black pl-1"> Date of Submission</td>
                    <td className="w-[3%] border border-black text-center">:</td>
                    <td className="w-[60%] border border-black pl-5">{formatDate(returnSubmission?.serializer_data?.last_updated)}</td>
                </tr>
            </tbody>
          </table>
        </div>

        {/* Part-2 : RETURN SUBMISSION DATA end */}



        {/* SECTION - 3: SUPPLY - OUTPUT TAX start */}
        <div className="mb-5">
          <p className="text-center my-2 font-semibold"> SECTION - 3: SUPPLY - OUTPUT TAX </p>
          <table className="min-w-full">
            <thead className="">
                <tr>
                  <th colSpan={2} className="w-[30%] border border-black">Nature of Supply</th>
                  <th className="w-[10%] border border-black">Note</th>
                  <th className="w-[20%] border border-black">Value (a)</th>
                  <th className="w-[20%] border border-black">SD (b)</th>
                  <th className="w-[20%] border border-black">VAT (c)</th>
                </tr>
            </thead>
            <tbody className="mt-1">
                { /* শুন্য হার বিশিষ্ট পণ্য/সেবা start */}
                  <>
                  <tr>
                  <td className="border border-black pl-1" rowSpan={2}>
                  Zero Rated Goods/Service
                  </td>
                  <td className="border border-black">Direct Export</td>
                  <td className="border border-black pl-2">1</td>
                  <td className="border border-black pl-2">{returnSubmission?.zero_rate_direct_export_sales_orders_total_price}</td>
                  <td className="border border-black pl-2">0.00</td>
                  <td className="border border-black pl-2">0.00</td>
                </tr>
                <tr>
                  <td className="border border-black pl-1">Deemed Export</td>
                    <td className="border border-black pl-2">2</td>
                    <td className="border border-black pl-2">{returnSubmission?.zero_rate_deemed_export_sales_orders_total_price}</td>
                    <td className="border border-black pl-2">0.00</td>
                    <td className="border border-black pl-2">0.00</td>
                </tr>
                  </>
                { /* শুন্য হার বিশিষ্ট পণ্য/সেবা end */}

                {/* অব্যাহতিপ্রাপ্ত পণ্য/সেবা */}
                <tr>
                  <td className="border border-black pl-2" colSpan={2}>Exempted Goods/Service </td>
                  <td className="border border-black pl-2">3</td>
                  <td className="border border-black pl-2">{returnSubmission?.exempted_sales_orders_total_price}</td>
                  <td className="border border-black pl-2">0.00</td>
                  <td className="border border-black pl-2">0.00</td>
                </tr>
                {/* অব্যাহতিপ্রাপ্ত পণ্য/সেবা */}

                
                {/* Standard Rated Goods/Service start  */}
                <tr>
                  <td className="border border-black pl-2" colSpan={2}>Standard Rated Goods/Service </td>
                  <td className="border border-black pl-2">4</td>
                  <td className="border border-black pl-2">{returnSubmission?.standard_rated_sales_orders_total_price}</td>
                  <td className="border border-black pl-2">{returnSubmission?.standard_rated_sales_orders_total_sd}</td>
                  <td className="border border-black pl-2">{returnSubmission?.standard_rated_sales_orders_total_vat}</td>
                </tr>
                {/* Standard Rated Goods/Service end  */}
                

              {/* Goods Based on MRP start  */}
               <tr>
                  <td className="border border-black pl-2" colSpan={2}>Goods Based on MRP</td>
                  <td className="border border-black pl-2">5</td>
                  <td className="border border-black pl-2">{returnSubmission?.based_on_mrp_sales_orders_total_price}</td>
                  <td className="border border-black pl-2">{returnSubmission?.based_on_mrp_sales_orders_total_sd}</td>
                  <td className="border border-black pl-2">{returnSubmission?.based_on_mrp_sales_orders_total_vat}</td>
                </tr>
                {/* Goods Based on MRP end  */}


              {/* Goods/Service Based on Specific VAT start  */}
               <tr>
                  <td className="border border-black pl-2" colSpan={2}>Goods/Service Based on Specific VAT</td>
                  <td className="border border-black pl-2">6</td>
                  <td className="border border-black pl-2">{returnSubmission?.specific_vat_sales_orders_total_price}</td>
                  <td className="border border-black pl-2">{returnSubmission?.specific_vat_sales_orders_total_sd}</td>
                  <td className="border border-black pl-2">{returnSubmission?.specific_vat_sales_orders_total_vat}</td>
                </tr>
                {/* Goods/Service Based on Specific VAT end  */}

              {/* Goods/Service Other than Standard Rat start  */}
               <tr>
                  <td className="border border-black pl-2" colSpan={2}>Goods/Service Other than Standard Rat</td>
                  <td className="border border-black pl-2">7</td>
                  <td className="border border-black pl-2">{returnSubmission?.other_than_standard_rated_sales_orders_total_price}</td>
                  <td className="border border-black pl-2">{returnSubmission?.other_than_standard_rated_sales_orders_total_sd}</td>
                  <td className="border border-black pl-2">{returnSubmission?.other_than_standard_rated_sales_orders_total_vat}</td>
                </tr>
                {/* Goods/Service Other than Standard Rat end  */}


              {/* Retail/Whole Sale/Trade Based Supply start  */}
               <tr>
                  <td className="border border-black pl-2" colSpan={2}>Retail/Whole Sale/Trade Based Supply</td>
                  <td className="border border-black pl-2">8</td>
                  <td className="border border-black pl-2">{returnSubmission?.retail_sales_orders_total_price}</td>
                  <td className="border border-black pl-2">{returnSubmission?.retail_sales_orders_total_sd}</td>
                  <td className="border border-black pl-2">{returnSubmission?.retail_sales_orders_total_vat}</td>
                </tr>
                {/* Retail/Whole Sale/Trade Based Supply end  */}

              {/* Total Sales Value &Total Payble Taxes start  */}
               <tr>
                  <td className="border border-black pl-2 font-semibold" colSpan={2}>Total Sales Value &Total Payble Taxes</td>
                  <td className="border border-black pl-2 font-semibold">9</td>
                  <td className="border border-black pl-2 font-semibold">{returnSubmission?.total_sales}</td>
                  <td className="border border-black pl-2 font-semibold">{returnSubmission?.total_sales_sd}</td>
                  <td className="border border-black pl-2 font-semibold">{returnSubmission?.total_sales_vat}</td>
                </tr>
                {/* Total Sales Value &Total Payble Taxes end  */}

            </tbody>
          </table>
        </div>
        {/* SECTION - 3: SUPPLY - OUTPUT TAX end */}



        {/* SECTION - 4: PURCHASE - INPUT TAX start */}
          <div className="mb-5">
          <p className="text-center my-2 font-semibold"> SECTION - 4: PURCHASE - INPUT TAX </p>

          <p className="mb-5">
          (1) If all the products/services you supply are standard rated, fill up note 10-20. <br />
          (2) All the products/services you supply are not standard rated or input tax credit not taken within stipulated time period under section 46, fill up note 21-22. <br />
          (3) If the products/services you supply consist of both standard rated and non-standard rated, then fill up note 10-20 for the raw materials that were used to produce/supply <br />
          standard rated goods/services and fill up note 21-22 for the raw materials that were used to produce/supply non-standard rated goods/services and show the value <br />
          proportionately in note 10-22 as applicable.

          </p>

          <table className="min-w-full">
            <thead className="">
                <tr>
                  <th colSpan={2} className="w-[45%] border border-black">Nature of Purchase</th>
                  <th className="w-[10%] border border-black">Note</th>
                  <th className="w-[15%] border border-black">Value (a)</th>
                  {/* <th className="w-[15%] border border-black">SD (b)</th> */}
                  <th className="w-[15%] border border-black">VAT (c)</th>
                </tr>
            </thead>
            <tbody className="mt-1">
                { /* Zero Rated Goods/Service start */}
                  <>
                  <tr>
                  <td className="border border-black pl-1" rowSpan={2}>
                      Zero Rated Goods/Service
                  </td>
                  <td className="border border-black">Local Purchase</td>
                  <td className="border border-black pl-2">10</td>
                  <td className="border border-black pl-2">{returnSubmission?.zero_rate_total_local_purchased}</td>
                  {/* <td className="border border-black pl-2">0.00</td> */}
                  <td className="border border-black pl-2">0.00</td>
                </tr>

                <tr>
                  <td className="border border-black ">Import</td>
                    <td className="border border-black pl-2">11</td>
                    <td className="border border-black pl-2">{returnSubmission?.zero_rate_total_foreign_purchased}</td>
                    {/* <td className="border border-black pl-2">0.00</td> */}
                    <td className="border border-black pl-2">0.00</td>
                </tr>
                  </>
                { /* Zero Rated Goods/Service end */}


                { /* Exempted Goods/Service start */}
                  <>
                  <tr>
                  <td className="border border-black pl-1" rowSpan={2}>
                  Exempted Goods/Service
                  </td>
                  <td className="border border-black">Local Purchase</td>
                  <td className="border border-black pl-2">12</td>
                  <td className="border border-black pl-2">{returnSubmission?.exempted_total_local_purchased}</td>
                  {/* <td className="border border-black pl-2">0.00</td> */}
                  <td className="border border-black pl-2">0.00</td>
                </tr>

                <tr>
                  <td className="border border-black ">Import</td>
                    <td className="border border-black pl-2">13</td>
                    <td className="border border-black pl-2">{returnSubmission?.exempted_total_foreign_purchased}</td>
                    {/* <td className="border border-black pl-2">0.00</td> */}
                    <td className="border border-black pl-2">0.00</td>
                </tr>
                  </>
                { /* Exempted Goods/Service end */}


                { /* Standard Rated Goods/Service start */}
                  <>
                  <tr>
                  <td className="border border-black pl-1" rowSpan={2}>
                  Standard Rated Goods/Service
                  </td>
                  <td className="border border-black">Local Purchase</td>
                  <td className="border border-black pl-2">14</td>
                  <td className="border border-black pl-2">{returnSubmission?.standard_rated_local_purchase_order_total_price}</td>
                  {/* <td className="border border-black pl-2">{returnSubmission?.standard_rated_local_purchase_order_total_sd}</td> */}
                  <td className="border border-black pl-2">{returnSubmission?.standard_rated_local_purchase_order_total_vat}
                   
                  </td>
                </tr>

                <tr>
                  <td className="border border-black ">Import</td>
                    <td className="border border-black pl-2">15</td>
                    <td className="border border-black pl-2">{returnSubmission?.standard_rated_foreign_purchase_order_total_price}</td>
                    {/* <td className="border border-black pl-2">{returnSubmission?.standard_rated_foreign_purchase_order_total_sd}</td> */}
                    <td className="border border-black pl-2">{returnSubmission?.standard_rated_foreign_purchase_order_total_vat}</td>
                </tr>
                  </>
                { /* Standard Rated Goods/Service end */}

                { /* Goods/Service Other than Standard Rate start */}
                  <>
                  <tr>
                  <td className="border border-black pl-1" rowSpan={2}>
                  Goods/Service Other than Standard Rate
                  </td>
                  <td className="border border-black">Local Purchase</td>
                  <td className="border border-black pl-2">16</td>
                  <td className="border border-black pl-2">{returnSubmission?.other_than_standard_rated_local_purchase_order_total_price}</td>
                  {/* <td className="border border-black pl-2">{returnSubmission?.other_than_standard_rated_local_purchase_order_total_sd}</td> */}
                  <td className="border border-black pl-2">{returnSubmission?.other_than_standard_rated_local_purchase_order_total_vat}
                   
                  </td>
                </tr>

                <tr>
                  <td className="border border-black ">Import</td>
                    <td className="border border-black pl-2">17</td>
                    <td className="border border-black pl-2">{returnSubmission?.other_than_standard_rated_foreign_purchase_order_total_price}</td>
                    {/* <td className="border border-black pl-2">{returnSubmission?.other_than_standard_rated_foreign_purchase_order_total_sd}</td> */}
                    <td className="border border-black pl-2">{returnSubmission?.other_than_standard_rated_foreign_purchase_order_total_vat}</td>
                </tr>
                  </>
                { /* Goods/Service Other than Standard Rate end */}


                { /* Goods/Service Based on Specific VAT start */}
                  <>
                  <tr>
                  <td className="border border-black pl-1">
                  Goods/Service Based on Specific VAT
                  </td>
                  <td className="border border-black">Local Purchase</td>
                  <td className="border border-black pl-2">18</td>
                  <td className="border border-black pl-2">{returnSubmission?.specific_vat_local_purchase_order_total_price}</td>
                  {/* <td className="border border-black pl-2">{returnSubmission?.}</td> */}
                  <td className="border border-black pl-2">{returnSubmission?.specific_vat_local_purchase_order_total_vat}
                   
                  </td>
                </tr>

                  </>
                { /* Goods/Service Based on Specific VAT end */}



                { /* Goods/Service Not Admissible for Credit (Local Purchase) start */}
                  <>
                  <tr>
                  <td className="border border-black pl-1" rowSpan={2}>
                  Goods/Service Not Admissible for Credit (Local Purchase)
                  </td>
                  <td className="border border-black">From Turnover Tax Units</td>
                  <td className="border border-black pl-2">19</td>
                  <td className="border border-black pl-2">{returnSubmission?.from_turnover_tax_units_local_purchase_order_total_price}</td>
                  {/* <td className="border border-black pl-2"></td> */}
                  <td className="border border-black pl-2">{returnSubmission?.from_turnover_tax_units_local_purchase_order_total_vat}
                   
                  </td>
                </tr>

                <tr>
                  <td className="border border-black ">From Unregistered Entities</td>
                    <td className="border border-black pl-2">20</td>
                    <td className="border border-black pl-2">{returnSubmission?.from_unregistered_ntities_local_purchase_order_total_price}</td>
                    {/* <td className="border border-black pl-2"></td> */}
                    <td className="border border-black pl-2"> {returnSubmission?.from_unregistered_ntities_local_purchase_order_total_vat}</td> 
                </tr>
                  </>
                { /*  Goods/Service Not Admissible for Credit (Local Purchase) end */}


                { /* Goods/Service Not Admissible for Credit (Taxpayers
                    who sell only Exempted/ Specific VAT and
                    Goods/Service Other than Standard Rate/
                    Credits not taken) start
                    */}
                  <>
                  <tr>
                  <td className="border border-black pl-1" rowSpan={2}>
                  Goods/Service Not Admissible for Credit <br />
                  (Taxpayers who sell only Exempted/ Specific VAT and Goods/Service Other than Standard Rate/Credits not taken)

                  </td>
                  <td className="border border-black">Local Purchase</td>
                  <td className="border border-black pl-2">21</td>
                  <td className="border border-black pl-2">{returnSubmission?.taxpayers_local_purchase_order_total_price}</td>
                  {/* <td className="border border-black pl-2">{returnSubmission?.}</td> */}
                  <td className="border border-black pl-2">{returnSubmission?.taxpayers_local_purchase_order_total_vat}
                   
                  </td>
                </tr>

                <tr>
                  <td className="border border-black ">Import</td>
                    <td className="border border-black pl-2">22</td>
                    <td className="border border-black pl-2">{returnSubmission?.taxpayers_foreign_purchase_order_total_price}</td>
                    {/* <td className="border border-black pl-2">{returnSubmission?.}</td> */}
                    <td className="border border-black pl-2">{returnSubmission?.taxpayers_foreign_purchase_order_total_vat}</td>
                </tr>
                  </>
                  { /* Goods/Service Not Admissible for Credit (Taxpayers
                    who sell only Exempted/ Specific VAT and
                    Goods/Service Other than Standard Rate/
                    Credits not taken) start
                    */}


              {/* Total Sales Value &Total Payble Taxes start  */}
               <tr>
                  <td className="border border-black pl-2 font-semibold" colSpan={2}>Total Input Tax Credit</td>
                  <td className="border border-black pl-2 font-semibold">23</td>
                  <td className="border border-black pl-2 font-semibold">{returnSubmission?.total_local_purchased_price}</td>
                  {/* <td className="border border-black pl-2 font-semibold"></td> */}
                  <td className="border border-black pl-2 font-semibold">{returnSubmission?.total_local_purchased_vat}</td>
                </tr>

                {/* Total Sales Value &Total Payble Taxes end  */}

            </tbody>
          </table>
        </div>
        {/*SECTION - 4: PURCHASE - INPUT TAX end */}



        {/* SECTION - 5: INCREASING ADJUSTMENTS (VAT) start */}      
        <div className="mb-5">
          <p className="text-center my-2 font-semibold"> SECTION - 5: INCREASING ADJUSTMENTS (VAT) </p>
          <table className="min-w-full">
            <thead className="">
                <tr>
                  <th colSpan={2} className="w-[40%] border border-black">Adjustment Details</th>
                  <th className="w-[10%] border border-black">Note</th>
                  <th className="w-[40%] border border-black">VAT Amount</th>
                </tr>
            </thead>
            <tbody className="mt-1">
                { /* Due to VAT Deducted at Source by the supply start */}
                  <>
                  <tr>
                  <td className="border border-black pl-1" colSpan={2}>
                  Due to VAT Deducted at Source by the supply
                  </td>
                  <td className="border border-black pl-2">24</td>
                  <td className="border border-black pl-2">{returnSubmission?.total_due_to_vat_deducted_at_source_by_the_supply}</td>
                </tr>
                  </>
                { /* Due to VAT Deducted at Source by the supply end */}


                { /* Payment Not Made Through Banking Channel start */}
                  <>
                  <tr>
                  <td className="border border-black pl-1" colSpan={2}>
                  Payment Not Made Through Banking Channel
                  </td>
                  <td className="border border-black pl-2">25</td>
                  <td className="border border-black pl-2"></td>
                </tr>
                  </>
                { /* Payment Not Made Through Banking Channel end */}

                { /* Issuance of Debit Note start */}
                  <>
                  <tr>
                  <td className="border border-black pl-1" colSpan={2}>
                  Issuance of Debit Note
                  </td>
                  <td className="border border-black pl-2">26</td>
                  <td className="border border-black pl-2">{returnSubmission?.debit_note_total_vat}</td>
                </tr>
                  </>
                { /* Issuance of Debit Note end */}


                { /* Any Other Adjustments (please specify below) start */}
                  <>
                  <tr>
                  <td className="border border-black pl-1" colSpan={2}>
                  Any Other Adjustments (please specify below)
                  </td>
                  <td className="border border-black pl-2">27</td>
                  <td className="border border-black pl-2">{returnSubmission?.aoa_for_increasing_total_vat}</td>
                </tr>
                  </>
                { /* Any Other Adjustments (please specify below) end */}

              {/* Total Increasing Adjustment start  */}
               <tr>
                  <td className="border border-black pl-2 font-semibold" colSpan={2}>Total Increasing Adjustment</td>
                  <td className="border border-black pl-2 font-semibold">28</td>
                  <td className="border border-black pl-2 font-semibold">{returnSubmission?.total_increasing_adjustment_vat}</td>
                </tr>
                {/* Total Increasing Adjustment end  */}

            </tbody>
          </table>
        </div>
        {/* SECTION - 5: INCREASING ADJUSTMENTS (VAT) end */}


        {/* SECTION - 6: DECREASING ADJUSTMENTS (VAT) start */}      
          <div className="mb-5">
            <p className="text-center my-2 font-semibold"> SECTION - 6: DECREASING ADJUSTMENTS (VAT) </p>
            <table className="min-w-full">
              <thead className="">
                  <tr>
                    <th colSpan={2} className="w-[40%] border border-black">Adjustment Details</th>
                    <th className="w-[10%] border border-black">Note</th>
                    <th className="w-[40%] border border-black">VAT Amount</th>
                  </tr>
              </thead>
              <tbody className="mt-1">
                  { /* Due to VAT Deducted at Source from the supplies delivered start */}
                    <>
                    <tr>
                    <td className="border border-black pl-1" colSpan={2}>
                    Due to VAT Deducted at Source from the supplies delivered
                    </td>
                    <td className="border border-black pl-2">29</td>
                    <td className="border border-black pl-2">{returnSubmission?.total_d_to_vat_dasftsds_vat_amount}</td>
                  </tr>
                    </>
                  { /* Due to VAT Deducted at Source from the supplies delivered end */}


                  { /* Advance Tax Paid at Import Stage start */}
                    <>
                    <tr>
                    <td className="border border-black pl-1" colSpan={2}>
                    Advance Tax Paid at Import Stage
                    </td>
                    <td className="border border-black pl-2">30</td>
                    <td className="border border-black pl-2">{returnSubmission?.total_advance_tax_paid_amount}</td>
                  </tr>
                    </>
                  { /* Advance Tax Paid at Import Stage end */}


                  { /* Issuance of Credit Note start */}
                    <>
                    <tr>
                    <td className="border border-black pl-1" colSpan={2}>
                    Issuance of Credit Note
                    </td>
                    <td className="border border-black pl-2">31</td>
                    <td className="border border-black pl-2">{returnSubmission?.credit_note_total_vat}</td>
                  </tr>
                    </>
                  { /* Issuance of Credit Note end */}


                  { /* Any Other Adjustments (please specify below) start */}
                    <>
                    <tr>
                    <td className="border border-black pl-1" colSpan={2}>
                    Any Other Adjustments (please specify below)
                    </td>
                    <td className="border border-black pl-2">32</td>
                    <td className="border border-black pl-2">{returnSubmission?.aoa_for_decreasing_total_vat}</td>
                  </tr>
                    </>
                  { /* Any Other Adjustments (please specify below) end */}

                  

                {/* Total Decreasing Adjustment start  */}
                <tr>
                    <td className="border border-black pl-2 font-semibold" colSpan={2}>Total Decreasing Adjustment</td>
                    <td className="border border-black pl-2 font-semibold">33</td>
                    <td className="border border-black pl-2 font-semibold">{returnSubmission?.total_decreasing_adjustment_vat}</td>
                  </tr>
                  {/* Total Decreasing Adjustment end  */}

              </tbody>
            </table>
          </div>
        {/* SECTION - 6: DECREASING ADJUSTMENTS (VAT) end */}


          {/* SECTION - 7: NET TAX CALCULATION start */}      
          <div className="mb-5">
            <p className="text-center my-2 font-semibold"> SECTION - 7: NET TAX CALCULATION </p>
              <table className="min-w-full">
                <thead className="">
                    <tr>
                      <th colSpan={2} className="w-[67%] border border-black">Items</th>
                      <th className="w-[5%] border border-black">Note</th>
                      <th className="w-[28%] border border-black">Amount</th>
                    </tr>
                </thead>
                <tbody className="mt-1">
                    { /* Net Payable VAT for the Tax Period (Section- 45) ( 9C-23B+28-33) start */}
                      <>
                      <tr>
                      <td className="border border-black pl-1" colSpan={2}>
                      Net Payable VAT for the Tax Period (Section- 45) ( 9C-23B+28-33)
                      </td>
                      <td className="border border-black pl-2">34</td>
                      <td className="border border-black pl-2"></td>
                    </tr>
                      </>
                    { /* Net Payable VAT for the Tax Period (Section- 45) ( 9C-23B+28-33) end */}


                    { /* Net Payable VAT for the Tax Period after Adjustment with Closing Balance and balance of form 18.6 [34-(52+56)] start */}
                      <>
                      <tr>
                      <td className="border border-black pl-1" colSpan={2}>
                      Net Payable VAT for the Tax Period after Adjustment with Closing Balance and balance of form 18.6 [34-(52+56)]
                      </td>
                      <td className="border border-black pl-2">35</td>
                      <td className="border border-black pl-2"></td>
                    </tr>
                      </>
                    { /* Net Payable VAT for the Tax Period after Adjustment with Closing Balance and balance of form 18.6 [34-(52+56)] end */}


                    { /* Net Payable Supplementary Duty for the Tax Period (Before adjustment with Closing Balance) [9B+38-(39+40)] start */}
                      <>
                      <tr>
                      <td className="border border-black pl-1" colSpan={2}>
                      Net Payable Supplementary Duty for the Tax Period (Before adjustment with Closing Balance) [9B+38-(39+40)]
                      </td>
                      <td className="border border-black pl-2">36</td>
                      <td className="border border-black pl-2"></td>
                    </tr>
                      </>
                    { /* Net Payable Supplementary Duty for the Tax Period (Before adjustment with Closing Balance) [9B+38-(39+40)] end */}


                    { /* Net Payable Supplementary Duty for the Tax Period after Adjusted with Closing Balance and balance of form 18.6[36-(53+57)] start */}

                      <>
                      <tr>
                      <td className="border border-black pl-1" colSpan={2}>
                      Net Payable Supplementary Duty for the Tax Period after Adjusted with Closing Balance and balance of form 18.6[36-(53+57)]

                      </td>
                      <td className="border border-black pl-2">37</td>
                      <td className="border border-black pl-2"></td>
                    </tr>
                      </>
                    { /* Net Payable Supplementary Duty for the Tax Period after Adjusted with Closing Balance and balance of form 18.6[36-(53+57)] end */}

                    
                    { /*Supplementary Duty Against Issuance of Debit Note) start */}
                      <>
                      <tr>
                      <td className="border border-black pl-1" colSpan={2}>
                      Supplementary Duty Against Issuance of Debit Note
                      </td>
                      <td className="border border-black pl-2">38</td>
                      <td className="border border-black pl-2"></td>
                    </tr>
                      </>
                    { /* Supplementary Duty Against Issuance of Debit Note end */}


                    { /* Supplementary Duty Against Issuance of Credit Note start */}
                      <>
                      <tr>
                      <td className="border border-black pl-1" colSpan={2}>
                      Supplementary Duty Against Issuance of Credit Note
                      </td>
                      <td className="border border-black pl-2">39</td>
                      <td className="border border-black pl-2"></td>
                    </tr>
                      </>
                    { /* Supplementary Duty Against Issuance of Credit Note end */}


                    { /* Supplementary Duty Paid on Inputs Against Exports start */}
                      <>
                      <tr>
                      <td className="border border-black pl-1" colSpan={2}>
                        Supplementary Duty Paid on Inputs Against Exports
                      </td>
                      <td className="border border-black pl-2">40</td>
                      <td className="border border-black pl-2"></td>
                    </tr>
                      </>
                    { /* Supplementary Duty Paid on Inputs Against Exports end */}


                    { /* Interest on Overdue VAT (Based on note 35) start */}
                      <>
                      <tr>
                      <td className="border border-black pl-1" colSpan={2}>
                          Interest on Overdue VAT (Based on note 35)
                      </td>
                      <td className="border border-black pl-2">41</td>
                      <td className="border border-black pl-2"></td>
                    </tr>
                      </>
                    { /* Interest on Overdue VAT (Based on note 35) end */}


                    { /* Interest on Overdue SD (Based on note 37) start */}
                      <>
                      <tr>
                      <td className="border border-black pl-1" colSpan={2}>
                        Interest on Overdue SD (Based on note 37)
                      </td>
                      <td className="border border-black pl-2">42</td>
                      <td className="border border-black pl-2"></td>
                    </tr>
                      </>
                    { /* Interest on Overdue SD (Based on note 37) end */}


                    { /* Fine/Penalty for Non-submission of Return start */}
                      <>
                      <tr>
                      <td className="border border-black pl-1" colSpan={2}>
                      Fine/Penalty for Non-submission of Return
                      </td>
                      <td className="border border-black pl-2">43</td>
                      <td className="border border-black pl-2"></td>
                    </tr>
                      </>
                    { /* Fine/Penalty for Non-submission of Return end */}


                    { /* Other Fine/Penalty/Interest start */}
                      <>
                      <tr>
                      <td className="border border-black pl-1" colSpan={2}>
                      Other Fine/Penalty/Interest
                      </td>
                      <td className="border border-black pl-2">44</td>
                      <td className="border border-black pl-2"></td>
                    </tr>
                      </>
                    { /* Other Fine/Penalty/Interest end */}


                    { /* Payable Excise Duty start */}
                      <>
                      <tr>
                      <td className="border border-black pl-1" colSpan={2}>
                      Payable Excise Duty
                      </td>
                      <td className="border border-black pl-2">45</td>
                      <td className="border border-black pl-2"></td>
                    </tr>
                      </>
                    { /* Payable Excise Duty end */}


                    { /* Payable Development Surcharge start */}
                      <>
                      <tr>
                      <td className="border border-black pl-1" colSpan={2}>
                          Payable Development Surcharge
                      </td>
                      <td className="border border-black pl-2">46</td>
                      <td className="border border-black pl-2"></td>
                    </tr>
                      </>
                    { /* Payable Development Surcharge end */}


                    { /* Payable ICT Development Surcharge start */}
                      <>
                      <tr>
                      <td className="border border-black pl-1" colSpan={2}>
                      Payable ICT Development Surcharge
                      </td>
                      <td className="border border-black pl-2">47</td>
                      <td className="border border-black pl-2"></td>
                    </tr>
                      </>
                    { /* Payable ICT Development Surcharge end */}


                    { /* Payable Health Care Surcharge start */}
                      <>
                      <tr>
                      <td className="border border-black pl-1" colSpan={2}>
                          Payable Health Care Surcharge
                      </td>
                      <td className="border border-black pl-2">48</td>
                      <td className="border border-black pl-2"></td>
                    </tr>
                      </>
                    { /* Payable Health Care Surcharge end */}


                    { /* Payable Environmental Protection Surcharge start */}
                      <>
                      <tr>
                        <td className="border border-black pl-1" colSpan={2}>
                        Payable Environmental Protection Surcharge
                        </td>
                        <td className="border border-black pl-2">49</td>
                        <td className="border border-black pl-2"></td>
                      </tr>
                      </>
                    { /* Payable Environmental Protection Surcharge end */}


                    { /* Net payable VAT for treasury deposit ( 35+41+43+44) start */}
                      <>
                      <tr>
                        <td className="border border-black pl-1" colSpan={2}>
                        Net payable VAT for treasury deposit ( 35+41+43+44)
                        </td>
                        <td className="border border-black pl-2">50</td>
                        <td className="border border-black pl-2"></td>
                      </tr>
                      </>
                    { /* Net payable VAT for treasury deposit ( 35+41+43+44) end */}


                    { /* Net payable SD for treasury deposit ( 37+42)  start */}
                      <>
                      <tr>
                        <td className="border border-black pl-1" colSpan={2}>
                          Net payable SD for treasury deposit ( 37+42) 
                        </td>
                        <td className="border border-black pl-2">51</td>
                        <td className="border border-black pl-2"></td>
                      </tr>
                      </>
                    { /* Net payable SD for treasury deposit ( 37+42)  end */}


                    { /* Closing Balance of Last Tax Period (VAT) start */}
                      <>
                      <tr>
                        <td className="border border-black pl-1" colSpan={2}>
                          Closing Balance of Last Tax Period (VAT)
                        </td>
                        <td className="border border-black pl-2">52</td>
                        <td className="border border-black pl-2"></td>
                      </tr>
                      </>
                    { /* Closing Balance of Last Tax Period (VAT) end */}

                    { /* Closing Balance of Last Tax Period (SD) start */}
                      <>
                      <tr>
                        <td className="border border-black pl-1" colSpan={2}>
                        Closing Balance of Last Tax Period (SD)
                        </td>
                        <td className="border border-black pl-2">53</td>
                        <td className="border border-black pl-2"></td>
                      </tr>
                      </>
                    { /* Closing Balance of Last Tax Period (SD) end */}

                </tbody>
              </table>
          </div>
        {/* SECTION - 7: NET TAX CALCULATION end */}


        {/* SECTION - 8: ADJUSTMENT FOR OLD ACCOUNT CURRENT BALANCE start */}      
          <div className="mb-5">
            <p className="text-center my-2 font-semibold"> SECTION - 8: ADJUSTMENT FOR OLD ACCOUNT CURRENT BALANCE </p>
              <table className="min-w-full">
                <thead className="">
                    <tr>
                      <th colSpan={2} className="w-[67%] border border-black">Items</th>
                      <th className="w-[5%] border border-black">Note</th>
                      <th className="w-[28%] border border-black">Amount</th>
                    </tr>
                </thead>
                <tbody className="mt-1">
                    { /* Remaining Balance (VAT) from Mushak-18.6,[ Rule 118(5)] start */}
                      <>
                      <tr>
                      <td className="border border-black pl-1" colSpan={2}>
                          Remaining Balance (VAT) from Mushak-18.6,[ Rule 118(5)]
                      </td>
                      <td className="border border-black pl-2">54</td>
                      <td className="border border-black pl-2"></td>
                    </tr>
                      </>
                    { /* Remaining Balance (VAT) from Mushak-18.6,[ Rule 118(5)] end */}



                    { /* Remaining Balance (SD) from Mushak-18.6,[ Rule 118(5)] start */}
                      <>
                      <tr>
                      <td className="border border-black pl-1" colSpan={2}>
                          Remaining Balance (SD) from Mushak-18.6,[ Rule 118(5)]
                      </td>
                      <td className="border border-black pl-2">55</td>
                      <td className="border border-black pl-2"></td>
                    </tr>
                      </>
                    { /* Remaining Balance (SD) from Mushak-18.6,[ Rule 118(5)] end */}


                    { /* Decreasing Adjustment for Note 54 (up to 30% of Note 34) start */}
                      <>
                      <tr>
                      <td className="border border-black pl-1" colSpan={2}>
                      Decreasing Adjustment for Note 54 (up to 30% of Note 34)
                      </td>
                      <td className="border border-black pl-2">56</td>
                      <td className="border border-black pl-2"></td>
                    </tr>
                      </>
                    { /* Decreasing Adjustment for Note 54 (up to 30% of Note 34) end */}


                    { /* Decreasing Adjustment for Note 55 (up to 30% of Note 36) start */}

                      <>
                      <tr>
                      <td className="border border-black pl-1" colSpan={2}>
                        Decreasing Adjustment for Note 55 (up to 30% of Note 36)

                      </td>
                      <td className="border border-black pl-2">57</td>
                      <td className="border border-black pl-2"></td>
                    </tr>
                      </>
                    { /* Decreasing Adjustment for Note 55 (up to 30% of Note 36) end */}

                </tbody>
              </table>
          </div>
        {/* SECTION - 8: ADJUSTMENT FOR OLD ACCOUNT CURRENT BALANCE end */}


        {/* SECTION - 9: ACCOUNTS CODE WISE PAYMENT SCHEDULE (TREASURY DEPOSIT) start */}      
          <div className="mb-5">
            <p className="text-center my-2 font-semibold"> SECTION - 9: ACCOUNTS CODE WISE PAYMENT SCHEDULE (TREASURY DEPOSIT) </p>
              <table className="min-w-full">
                <thead className="">
                    <tr>
                      <th colSpan={2} className="w-[37%] border border-black">Items</th>
                      <th className="w-[5%] border border-black">Note</th>
                      <th className="w-[30%] border border-black">Account Code</th>
                      <th className="w-[28%] border border-black">Amount</th>
                    </tr>
                </thead>

                <tbody className="mt-1">
                    { /* VAT Deposit for the Current start */}
                      <>
                      <tr>
                      <td className="border border-black pl-1" colSpan={2}>
                          VAT Deposit for the Current
                      </td>
                      <td className="border border-black pl-2">58</td>
                      <td className="border border-black pl-2">{returnSubmission?.serializer_data?.account_code_vat_deposit_for_the_current}</td>
                      <td className="border border-black pl-2"></td>
                    </tr>
                      </>
                    { /* VAT Deposit for the Current end */}



                    { /* SD Deposit for the Current Tax Period start */}
                      <>
                      <tr>
                      <td className="border border-black pl-1" colSpan={2}>
                      SD Deposit for the Current Tax Period
                      </td>
                      <td className="border border-black pl-2">59</td>
                      <td className="border border-black pl-2">{returnSubmission?.serializer_data?.account_code_sd_deposit_for_the_current_tax_period}</td>
                      <td className="border border-black pl-2"></td>
                    </tr>
                      </>
                    { /* SD Deposit for the Current Tax Period end */}


                    { /*Excise Duty start */}
                      <>
                      <tr>
                      <td className="border border-black pl-1" colSpan={2}>
                        Excise Duty
                      </td>
                      <td className="border border-black pl-2">60</td>
                      <td className="border border-black pl-2">{returnSubmission?.serializer_data?.account_code_excise_duty}</td>
                      <td className="border border-black pl-2"></td>
                    </tr>
                      </>
                    { /* Excise Duty end */}


                    { /* Development Surcharge start */}

                      <>
                      <tr>
                      <td className="border border-black pl-1" colSpan={2}>
                          Development Surcharge
                      </td>
                      <td className="border border-black pl-2">61</td>
                      <td className="border border-black pl-2">{returnSubmission?.serializer_data?.account_code_development_surcharge}</td>
                      <td className="border border-black pl-2"></td>
                    </tr>
                      </>
                    { /* Development Surcharge end */}

                    { /* ICT Development Surcharge start */}
                    <>
                    <tr>
                    <td className="border border-black pl-1" colSpan={2}>
                        ICT Development Surcharge
                    </td>
                    <td className="border border-black pl-2">62</td>
                    <td className="border border-black pl-2">{returnSubmission?.serializer_data?.account_code_ict_development_surcharge}</td>
                    <td className="border border-black pl-2"></td>
                    </tr>
                    </>
                    { /* ICT Development Surcharge end */}

                    { /* Health Care Surcharge start */}
                    <>
                    <tr>
                    <td className="border border-black pl-1" colSpan={2}>
                      Health Care Surcharge
                    </td>
                    <td className="border border-black pl-2">63</td>
                    <td className="border border-black pl-2">{returnSubmission?.serializer_data?.account_code_health_care_surcharge}</td>
                    <td className="border border-black pl-2"></td>
                    </tr>
                    </>
                    { /* Health Care Surcharge end */}

                    { /* Environmental Protection Surcharge start */}
                    <>
                    <tr>
                    <td className="border border-black pl-1" colSpan={2}>
                    Environmental Protection Surcharge
                    </td>
                    <td className="border border-black pl-2">64</td>
                    <td className="border border-black pl-2">{returnSubmission?.serializer_data?.account_code_environmental_protection_surcharge}</td>
                    <td className="border border-black pl-2"></td>
                    </tr>
                    </>
                    { /* Environmental Protection Surcharge end */}
                    

                </tbody>
              </table>
          </div>
        {/* SECTION - 9: ACCOUNTS CODE WISE PAYMENT SCHEDULE (TREASURY DEPOSIT) end */}


          {/* SECTION - 10: ACCOUNTS CODE WISE PAYMENT SCHEDULE (TREASURY DEPOSIT) start */}      
          <div className="mb-5">
            <p className="text-center my-2 font-semibold"> SECTION - 10: ACCOUNTS CODE WISE PAYMENT SCHEDULE (TREASURY DEPOSIT) </p>
              <table className="min-w-full">
                <thead className="">
                    <tr>
                      <th colSpan={2} className="w-[60%] border border-black">Items</th>
                      <th className="w-[10%] border border-black">Note</th>
                      <th className="w-[30%] border border-black">Amount</th>
                    </tr>
                </thead>

                <tbody className="mt-1">
                    { /* Closing Balance (VAT) [58 - (50 + 67) + The refund amount not approved] start */}
                      <>
                      <tr>
                      <td className="border border-black pl-1" colSpan={2}>
                      Closing Balance (VAT) [58 - (50 + 67) + The refund amount not approved]
                      </td>
                      <td className="border border-black pl-2">65</td>
                      <td className="border border-black pl-2"></td>
                    </tr>
                      </>
                    { /* Closing Balance (VAT) [58 - (50 + 67) + The refund amount not approved] end */}



                    { /* SClosing Balance (SD) [59 - (51 + 68) + The refund amount not approved] start */}
                      <>
                      <tr>
                      <td className="border border-black pl-1" colSpan={2}>
                      Closing Balance (SD) [59 - (51 + 68) + The refund amount not approved]
                      </td>
                      <td className="border border-black pl-2">66</td>
                      <td className="border border-black pl-2"></td>
                    </tr>
                      </>
                    { /* Closing Balance (SD) [59 - (51 + 68) + The refund amount not approved] end */}


                </tbody>
              </table>
          </div>
        {/* SECTION - 10: ACCOUNTS CODE WISE PAYMENT SCHEDULE (TREASURY DEPOSIT) end */}


          {/* SECTION - 11:REFUND start */}
          <div className="mb-5">
            <p className="text-center my-2 font-semibold"> SECTION - 11:REFUND </p>
            <table className="min-w-full">
              <tbody className="mt-1">
                  { /* শুন্য হার বিশিষ্ট পণ্য/সেবা start */}
                    <>
                    <tr>
                    <td className="border border-black pl-1 w-[40%]" rowSpan={3}>
                    I am interested to get refund of my Closing Yes No Balance
                    </td>
                    <td className="border border-black font-bold text-center">Items</td>
                    <td className="border border-black  text-center font-bold">Note</td>
                    <td className="border border-black  text-center font-bold">
                    <p className="py-1">
                      <span className=" px-1"><input type="checkbox" checked={returnSubmission?.serializer_data?.requested_amount_for_refund === true} disabled  className="text-black border border-black rounded focus:ring-0"  /> Yes </span>
                       <span className="px-1"><input type="checkbox"  disabled checked={returnSubmission?.serializer_data?.requested_amount_for_refund === false}  className="text-black border border-black rounded focus:ring-0"  /> No</span>
                    </p>
                    </td>

                  </tr>
                  <tr>
                    <td className="border border-black pl-1">Requested Amount for Refund(VAT)</td>
                      <td className="border border-black pl-2">67</td>
                      <td className="border border-black pl-2"></td>
                  </tr>
                  <tr>
                    <td className="border border-black pl-1">Requested Amount for Refund(SD)</td>
                      <td className="border border-black pl-2">68</td>
                      <td className="border border-black pl-2"></td>
                  </tr>
                    </>
                  { /* শুন্য হার বিশিষ্ট পণ্য/সেবা end */}

              </tbody>
            </table>
          </div>
        {/* SECTION - 11:REFUND end */}


        {/* SECTION - 12: DECLARATION start */}
        <div className="mb-5">
          <p className="text-center my-2 font-semibold"> SECTION - 12: DECLARATION </p>

          <p className="mb-2">I hereby declare that all information provided in this Return Form are complete, true & accurate. In case of any
            untrue/incomplete statement, I may be subjected to penal action under The Value Added Tax and Supplementary
            Duty Act, 2012 or any other applicable Act prevailing at present.
          </p>
          <table className="min-w-full">
            <tbody className="mt-1">
                <tr>
                    <td className="w-[37%] border border-black pl-1"> Name</td>
                    <td className="w-[3%] border border-black text-center">:</td>
                    <td className="w-[60%] border border-black pl-5">{returnSubmission?.serializer_data?.tax_return_declarant_name}</td>
                </tr>
                <tr>
                    <td className="w-[37%] border border-black pl-1">Designation</td>
                    <td className="w-[3%] border border-black text-center">:</td>
                    <td className="w-[60%] border border-black pl-5">{returnSubmission?.serializer_data?.tax_return_declarant_designation}</td>
                </tr>
                <tr>
                    <td className="w-[37%] border border-black pl-1">Mobile Number</td>
                    <td className="w-[3%] border border-black text-center">:</td>
                    <td className="w-[60%] border border-black pl-5">{returnSubmission?.serializer_data?.tax_return_declarant_mobile_number}</td>
                </tr> 
                <tr>
                    <td className="w-[37%] border border-black pl-1">National ID/Passport Number</td>
                    <td className="w-[3%] border border-black text-center">:</td>
                    <td className="w-[60%] border border-black pl-5">{returnSubmission?.serializer_data?.tax_return_declarant_national_id_or_passport_number}</td>
                </tr>
                <tr>
                    <td className="w-[37%] border border-black pl-1">Email</td>
                    <td className="w-[3%] border border-black text-center">:</td>
                    <td className="w-[60%] border border-black pl-5">{returnSubmission?.serializer_data?.tax_return_declarant_email}</td>
                </tr>
                <tr>
                    <td className="w-[37%] border border-black pl-1">Signature [Not required for electronic submission]</td>
                    <td className="w-[3%] border border-black text-center">:</td>
                    <td className="w-[60%] border border-black pl-5"></td>
                </tr>
            </tbody>
          </table>
        </div>
        {/* SECTION - 12: DECLARATION end */}

      </div>
    </div>
    </DashboardLayout>
  );

};

export default MushakNineOnePreview;
