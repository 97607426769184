import axios from 'axios';
import React, { useState, useEffect } from 'react';
import DashboardLayout from '../../Shared/DashboardLayout';
import { Link, useNavigate } from 'react-router-dom';
import { Table } from "antd";
import { AiFillDelete,AiFillEdit, AiFillFileAdd, AiFillFilePdf } from "react-icons/ai";
import Swal from 'sweetalert2';
import { format } from "date-fns";
import { baseUrl } from '../../BaseUrl';

const MushakSixFiveList = () => {
    const [goodsTransferData, setGoodsTransferData] = useState([]);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer);
          toast.addEventListener('mouseleave', Swal.resumeTimer);
        },
      });

    useEffect(() => {
        const fetchGoodsTransfer = async () => {
            try {
                const jwtToken = localStorage.getItem("access");
                const response = await axios.get(`${baseUrl}/purchase/api/goods-transfer/`, {
                    headers: {
                        Authorization: `JWT ${jwtToken}`,
                    },
                });
                setGoodsTransferData(response.data.results);
                console.log(response.data)
                
            } catch (error) {
                setError(error);
            }
        };

        fetchGoodsTransfer();
    }, []);

    const handleDelete = async (itemId) => {
        try {
            const jwtToken = localStorage.getItem("access");
            await axios.delete(`${baseUrl}/purchase/api/goods-transfer/${itemId}/`, {
                headers: {
                    Authorization: `JWT ${jwtToken}`,
                },
            });

            // Update goodsTransferData state after successful deletion
            setGoodsTransferData((prevData) =>
                prevData.filter((item) => item.id !== itemId)
            );
            Toast.fire({
                icon: 'info',
                title: 'Successfully Delete',
              });

        } catch (error) {
            console.log(error)
            Toast.fire({
                icon: 'error',
                title: `Ops! ${error.message}`,
              });
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return format(date, "dd-M-yyyy"); // Change the format here
    };

    const [columns, setColumns] = useState([
        {
            key: 1,
            title: "Date",
            dataIndex: "last_updated",
            render: (text) => formatDate(text),
        },
        {
            key: 2,
            title: "SENDER",
            dataIndex: "company_name",
        },
        {
            key: 3,
            title: "RECIPIENT",
            dataIndex: "customer_name",
        },
        {
            key: 4,
            title: "Challan No",
            dataIndex: "challan_no",
        },
        {
            key: 5,
            title: "Inventory",
            render: (Inventories) => (
                <ul>
                  {Inventories.inventories?.map((inventory, index) => (
                      <li key={index}>
                        <p>{inventory.inventory_name}</p>
                      </li>
                    ))
                  }
                </ul>
              ),
        },
        {
            key: 6,
            title: "Quantity",
            render: (Inventories) => (
            <ul>
                  {Inventories.inventories?.map((inventory, index) => (
                      <li key={index}>
                        <p>{inventory.quantity}</p>
                      </li>
                    ))
                  }
                </ul>
              ),
        },
        {
            key:7,
            title: 'price without vat',
            render: (Inventories) => (
                <ul>
                  {Inventories.inventories?.map((inventory, index) => (
                      <li key={index}>
                        <p>{inventory.price_without_vat}</p>
                      </li>
                    ))
                  }
                </ul>
              ),

        },
          {
            key:8,
            title: 'price with vat',
            render: (Inventories) => (
                <ul>
                  {Inventories.inventories?.map((inventory, index) => (
                      <li key={index}>
                        <p>{inventory.price_with_vat}</p>
                      </li>
                    ))
                  }
                </ul>
              ),
          },
        {
            key: 9, 
            title: "Actions",
            dataIndex: "actions",
            render: (_, record) => {
                return (
                    <>
                        <div className="flex gap-2">
                            {record.status !== 2?
                            <div className="">
                                <button
                                    onClick={() => navigate(`/mushak-6-5-edit/${record.id}`)}
                                    className="bg-blue-500 hover:bg-blue-600 rounded"
                                >
                                    <AiFillEdit className="text-white text-xl " />
                                </button> 

                                <button
                                    onClick={() => handleDelete(record.id)}
                                    className="rounded mr-1"
                                >
                                    <AiFillDelete className="text-xl text-red-500/100 hover:text-red-600 " />
                                </button>

                                <button
                                onClick={() => navigate(`/mushak-6-5-pdf/${record.id}`)}
                                className="rounded"
                                    >
                                <div className="flex items-center rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-[#000] ring-1 ring-inset ring-gray-500/10">
                                    <div><AiFillFilePdf className="text-[#15803d] text-xl " /></div>
                                    <div>M-6.5</div>
                                </div> 
                            </button>                       
                            </div>
                            :
                            <>
                            <button
                                onClick={() => navigate(`/mushak-6-4/${record.id}`)}
                                className="rounded"
                                    >
                                <div className="flex items-center rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-[#000] ring-1 ring-inset ring-gray-500/10">
                                    <div><AiFillFilePdf className="text-[#15803d] text-xl " /></div>
                                    <div>M-6.4</div>
                                </div> 
                            </button>
                            </>
                            }
                        </div>
                    </>
                );
            },
        },
    ]);
    
    return (
        <DashboardLayout>
            <div className="px-5">
            <div className="flex items-center justify-between py-5">
                <h1 className=" uppercase font-semibold  text-xl">Mushak 6.5 List</h1>
            <button onClick={() => navigate("/mushak-6-5-add")} className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
              <div className="flex ">
                <div className="mt-1">
                <AiFillFileAdd />
                </div>
                <div>Add New</div>
              </div>
            </button>
            </div>

            <div className='overflow-x-auto w-[1090px]'>
            <Table
                bordered
                columns={columns}
                dataSource={goodsTransferData}
                pagination={{
                    pageSize: 10, 
                    total: goodsTransferData.length,
                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                    showSizeChanger: false,
                  }}
            />
            </div>
            </div>
        </DashboardLayout>
    );
};

export default MushakSixFiveList;
