import React, { useState } from 'react';
import axios from 'axios';
import Select from 'react-select';
import DashboardLayout from '../../Shared/DashboardLayout';
import { baseUrl } from '../../BaseUrl';
import { useEffect } from 'react';
import { AiFillDelete } from 'react-icons/ai';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const AddNewMushakFourThree = () => {
  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    production: null,
    status: null,
    production_value_addition_sector: [
      {
        sector_name: '',
        amount: '',
      },
    ],
  });
  
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
  });
  const [productionOptions, setProductionOptions] = useState([]);
  const [existingProductionId, setexistingProductionId] = useState([]);
  const [selectedProduction, setSelectedProduction] = useState(null);
  const [productionValueAdditionSectorFiledsRequiredMessage,setProductionValueAdditionSectorFiledsRequiredMessage]=useState('')
  const productionStatusOptions = [
    { value: '0', label: 'Ordered' },
    { value: '1', label: 'WIP' },
    { value: '2', label: 'Complete' },
  ];

  const productionValueAdditionSectorNameOptions = [
    { value: 'Electicity', label: 'Electicity' },
    { value: 'W', label: 'W' },
    { value: 'Another Amount', label: 'Another Amount' },
  ];

  const handleStatusChange = (selectedOption) => {
    setFormData({ ...formData, status: selectedOption.value });
  };

  const handleInputChange = (e, index, type) => {
    const updatedData = { ...formData };
    if (type === 'production_value_addition_sector') {
      updatedData.production_value_addition_sector[index][e.target.name] = e.target.value;
    } else {
      updatedData[e.target.name] = e.target.value;
    }
    setFormData(updatedData);
  };

  useEffect(() => {
    const fetchProduction = async () => {
      try {
        const jwtToken = localStorage.getItem("access");
        const response = await axios.get(`${baseUrl}/purchase/api/production/`, {
          headers: {
            Authorization: `JWT ${jwtToken}`,
          },
        });
  
        const finishedGoodInventories = response.data.results.filter((item) => {
          return item.status_text === 'Complete' && !existingProductionId.includes(item.id);
        });

        setProductionOptions(
          finishedGoodInventories.map((production) => ({
            value: production.id,
            label: `ProductionID: ${production.id} | FinishedGood: ${production.inventory_name}`,
          }))
        );
      } catch (error) {
        console.error('Error fetching inventories', error);
      }
    };
    fetchProduction();
  }, [existingProductionId]);
  
  useEffect(() => {
    const fetchExistingProductionData = async () => {
      try {
        const jwtToken = localStorage.getItem("access");
        const response = await axios.get(`${baseUrl}/purchase/api/declaration-of-mpc/`, {
          headers: {
            Authorization: `JWT ${jwtToken}`,
          },
        });

        // Extract the existing production IDs from the response data
        const existingProductionIds = response.data.results.map(item => item.production.id);
        setexistingProductionId(existingProductionIds);

      } catch (error) {
        console.error('Error fetching existing data:', error);
      }
    };

    fetchExistingProductionData();
  }, []);


  const handleProductionChange = (selectedOption) => {
    const productionId = selectedOption.value; // Assuming "value" holds the ID
    
    setSelectedProduction(selectedOption);
    setFormData({
      ...formData,
      production: productionId,
    });
  };

  const areAllFieldsFilled = () => {
    return formData.production_value_addition_sector.every((sector) => {
      return sector.sector_name.trim() !== '' && sector.amount.trim() !== '';
    });
  };
  
  const handleAddProductionSector = () => {
    if (areAllFieldsFilled()) {
      setFormData({
        ...formData,
        production_value_addition_sector: [
          ...formData.production_value_addition_sector,
          {
            sector_name: '',
            amount: '',
          },
        ],
      });
      setProductionValueAdditionSectorFiledsRequiredMessage('')
    } else {
      setProductionValueAdditionSectorFiledsRequiredMessage("Please fill all fields in the existing sectors before adding a new one.")
    }
  };
  

  const handleRemoveProductionSector = (index) => {
    const production_value_addition_sector = [...formData.production_value_addition_sector];
    production_value_addition_sector.splice(index, 1);
    setFormData({
      ...formData,
      production_value_addition_sector,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const jwtToken = localStorage.getItem("access");
      const response = await axios.post(
        `${baseUrl}/purchase/api/declaration-of-mpc/`,
        formData,  // Your form data
        {
          headers: {
            Authorization: `JWT ${jwtToken}`,
          },
        }
      );
      navigate("/mushak-4-3-list");
      Toast.fire({
        icon: 'success',
        title: 'Successfully Add',
      });
      }catch (error) {
          if(error.response.data.detail){
              Toast.fire({
              icon: 'error',
              title: `${error.response.data.detail}`,
              });
          }else{
              console.error('Error saving data:', error);
          }

      }
  };


  return (
    <DashboardLayout>
    <div className="px-5 mt-5 mb-10 pb-10">
        <div className=" w-3/4">
        <div className="bg-white p-8 rounded shadow-xl shadow-blue-500/40 w-[90%] mx-auto">
            <form onSubmit={handleSubmit}>
            <div className="flex justify-between rounded border-b-[1px] mb-10 bg-slate-900">
                <div className="mx-5 my-auto">
                <h1 className="uppercase font-semibold text-xl text-[#fff]">Mushak 4.3 Add Form</h1>
                </div>
                <div className="my-2 mx-5">
                <button
                    type="submit"
                    className="w-[120px] bg-slate-700 hover:bg-slate-800 border text-white font-medium rounded-lg h-10"
                >
                    Submit
                </button>
                </div>
            </div>

            <div class="flex flex-wrap -mx-3 mb-2">

            <div class="w-full md:w-4/4 px-3 mb-8">
                  <div className="flex  md:w-4/4 mx-5 ">
                    <div class="md:w-[20%] my-auto">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-3"  for="inline-full-name">
                    Finished Good*
                    </label>
                    </div>
                    <div class="md:w-[80%]">
                        <Select
                            id="inventory"
                            name="inventory"
                            value={selectedProduction}
                            onChange={handleProductionChange}
                            options={productionOptions}
                            required
                        />
                    </div>
                  </div>
                </div>
                <div class="w-full md:w-4/4 px-3 mb-8">
                  <div className="flex  md:w-4/4 mx-5 ">
                    <div class="md:w-[20%] my-auto">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-3"  for="inline-full-name">
                        Status*
                    </label>
                    </div>
                    <div class="md:w-[80%]">
                        <Select
                            id="status"
                            name="status"
                            value={productionStatusOptions.find((option) => option.value === formData.status)}
                            onChange={handleStatusChange}
                            options={productionStatusOptions}
                            required
                        />
                    </div>
                  </div>
                </div>
                </div>


            <div className='mx-5 mb-2'>
                <p>Production value addition sector</p> <hr />
            </div>
            {/* Production Value Addition Sector Fields */}
            {formData.production_value_addition_sector?.map((sector, index) => (
            <table className="table-auto w-100 sales-order-table ml-5 ">
            {index === 0 && (
                <thead>
                <tr>
                    <th className='inventory-th'>
                    <label
                        class="block uppercase tracking-wide  text-[12px] font-semibold"
                        for="grid-state"
                    >
                        Sector Name*
                    </label>
                    </th>
                    <th className='total-price-td'>
                    <label
                        class="block uppercase tracking-wide  text-[12px] font-semibold"
                        for="inline-full-name"
                    >
                        Amount*
                    </label>
                    </th>
                    <th className="action-th"></th>
                </tr>
                </thead>
            )}
            <tbody>
                <tr className="table-input-filed">
                <td className="w-[40%] inventory-th">
                <Select
                    id={`sector_name_${index}`}
                    name="sector_name"
                    value={productionValueAdditionSectorNameOptions.find(option => option.value === sector.sector_name)}
                    onChange={selectedOption => handleInputChange({ target: { name: 'sector_name', value: selectedOption.value } }, index, 'production_value_addition_sector')}
                    options={productionValueAdditionSectorNameOptions.filter(option => 
                      !formData.production_value_addition_sector.some(existingSector => existingSector.sector_name === option.value)
                    )}
                    required
                  />
                </td>
                <td className="w-[30%] total-price-td">
                  <input
                      type="text"
                      name="amount"
                      value={sector.amount}
                      onChange={(e) => handleInputChange(e, index, 'production_value_addition_sector')}
                      placeholder='Enter amount'
                  />

                </td>

                {index === 0 && (
                    <td className="w-[10%] action-td"></td>
                )}
                {index !== 0 && (
                    <td className="w-[10%] action-td">
                    {index !== 0 && (
                        <button
                        type="button"
                        onClick={() => handleRemoveProductionSector(index)}
                        className="text-red-500"
                        >
                        <AiFillDelete className="text-xl text-red-500/100 hover:text-red-600 " />
                        </button>
                    )}
                    </td>
                )}
                </tr>
            </tbody>
            </table>
            ))}
            

            <div className="mb-3 ml-5">
                {productionValueAdditionSectorFiledsRequiredMessage && (
                    <span className='text-red-500 mt-2'>{productionValueAdditionSectorFiledsRequiredMessage} <br /> </span> 
                )}
                <button
                  type="button"
                  onClick={() => {
                    console.log('Add Product Button Clicked');
                    handleAddProductionSector();
                  }}
                  className="bg-slate-800 hover:bg-slate-900 text-white p1-2 px-2 my-3 rounded"
                  title='All fields required for add more product'
                >
                  Add More
                </button>
                <hr />
              </div>

            
            </form>
        </div>
        </div>
  </div>
  </DashboardLayout>
  );
};

export default AddNewMushakFourThree;
