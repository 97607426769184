import React, { useState, useEffect } from "react";
import { SIDEBAR_NAV_LINKS } from "./NavItem";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";
import { FcInternal } from "react-icons/fc";

const linkClassess =
  "flex items-center gap-2 pl-2 py-2 hover:bg-blue-700 hover:no-underline hover:text-white active:bg-blue-600 active:text-white rounded-sm text-base border-b-[1px] border-white-300";

function Sidebar() {
  const { pathname } = useLocation();
  const [activeMain, setActiveMain] = useState(null);

  useEffect(() => {
    // Find the active main menu based on the current pathname
    const activeMainFound = SIDEBAR_NAV_LINKS.find((item) => {
      if (item.path === pathname) {
        return true;
      }
      if (item.dropdown) {
        return item.dropdown.some((subItem) => subItem.path === pathname);
      }
      return false;
    });

    if (activeMainFound) {
      setActiveMain(activeMainFound.key);
    }
  }, [pathname]);

  return (
    <div className="w-52 h-screen flex flex-col text-black mb-[60px]">
      <div className="flex-1 flex flex-col gap-0.5 scroll-auto mt-[80px]">
        {SIDEBAR_NAV_LINKS.map((item) => (
          <SidebarLink
            key={item.key}
            item={item}
            activeMain={activeMain}
            setActiveMain={setActiveMain}
            pathname={pathname}
          />
        ))}
      </div>
      <div className=""></div>
    </div>
  );
}

function SidebarLink({ item, activeMain, setActiveMain, pathname }) {
  if (item.dropdown && item.dropdown.length > 0) {
    const isDropdownActive = item.dropdown.some(
      (subItem) => pathname === subItem.path
    );

    return (
      <div>
        <div
          onClick={() => {
            if (activeMain !== item.key) {
              setActiveMain(item.key);
            } else {
              setActiveMain(null);
            }
          }}
          className={classNames(
            "cursor-pointer",
            pathname === item.path || isDropdownActive
              ? "bg-blue-700 text-white"
              : "text-white",
            linkClassess
          )}
        >
          <div className="flex">
            <div className="w-[180px] flex">
              <span className="mt-1 mr-1 text-xl">{item.icon}</span>
              {item.label}
            </div>
            <div className="">
              <FcInternal />
            </div>
          </div>
        </div>

        {activeMain === item.key && (
          <div className="ml-4">
            {item.dropdown.map((subItem) => (
              <Link
                key={subItem.key}
                to={subItem.path}
                className={classNames(
                  pathname === subItem.path
                    ? "bg-blue-700 text-white"
                    : "text-white",
                  linkClassess
                )}
              >
                <span className="text-xl">{subItem.icon}</span>
                {subItem.label}
              </Link>
            ))}
          </div>
        )}
      </div>
    );
  } else {
    return (
      <Link
        to={item.path}
        className={classNames(
          pathname === item.path ? "bg-blue-700 text-white text-[18px]" : "text-white ",
          linkClassess
        )}
      >
        <span className="text-xl">{item.icon}</span>
        {item.label}
      </Link>
    );
  }
}

export default Sidebar;
