import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useReactToPrint } from "react-to-print";
import { Link, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import { baseUrl } from "../BaseUrl";

const MushokTablePage = () => {
  const componentPDF = useRef();

  const pdfGeneretor = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: "Mushak-6.1",
    pageStyle: `
      @page landscape {
        size: A4 landscape;
        margin: 20mm 10mm;
      }
      @page portrait {
        size: A4 portrait;
        margin: 20mm 10mm;
      }
      @page {
        margin: 4mm;
      }
      body {
        margin: 0;
      }
    `,
    contentStyle: `
      @page landscape {
        transform: rotate(0deg) translate(0, 0);
      }
      @page portrait {
        transform: rotate(90deg) translate(0, 0);
      }
    `
  });
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [toDate, setToDate] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [isDateSelected, setIsDateSelected] = useState(false);
  const [inventoryName, setInventoryName] = useState("");

  const [moshukData, setMoshukData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const fetchMoshukData = async () => {
    const jwtToken = localStorage.getItem("access");

    try {
      const res = await axios.get(`${baseUrl}/purchase/api/purchase/`, {
        headers: {
          Authorization: `JWT ${jwtToken}`,
        },
      });
      if (res) {
        setMoshukData(res.data.results);
        console.log(res)
      }
    } catch (error) {
      if(error.response.status === 401){
        navigate("/login");; 
      }
      else{
        console.log(error)
      }
    }
  };

  useEffect(() => {
    fetchMoshukData();
  }, []);

  const handleSearchChange = (e) => {
    const { name, value } = e.target;
    if (name === "toDate") {
      setToDate(new Date(value));
    } else if (name === "fromDate") {
      setFromDate(new Date(value));
    } else if (name === "inventoryName") {
      setInventoryName(value);
    }
  };



  useEffect(() => {
    const filtered = moshukData.filter((data) => {
      const inventoryNameLowerCase = data.inventory_name?.toLowerCase() || "";
      const supplierNameLowerCase = data.customer_name?.toLowerCase() || "";
      const orderDate = new Date(data.date_created);
  
      const matchesToDate = !toDate || orderDate <= toDate;
      const matchesFromDate = !fromDate || orderDate >= fromDate;
      const matchesSalesInventoryName = !inventoryName || inventoryNameLowerCase.includes(inventoryName.toLowerCase());
      
  
      // Modify this condition to check if the order date is within the selected date range
      const isWithinDateRange = (!toDate || !fromDate) || (orderDate >= fromDate && orderDate <= toDate);
  
      return (
        matchesToDate &&
        matchesFromDate &&
        (matchesSalesInventoryName) && // Modify this line to consider both sales and prod inventory names
        isWithinDateRange &&
        (inventoryNameLowerCase.includes(searchQuery.toLowerCase()) ||
          supplierNameLowerCase.includes(searchQuery.toLowerCase()) ||
          format(orderDate, "dd-M-yyyy").includes(searchQuery.toLowerCase()))
      );
    });
  
    setFilteredData(filtered);
  }, [searchQuery, toDate, fromDate, inventoryName, moshukData]);
  

  const handleDateInputChange = (date, inputName) => {
    if (inputName === "toDate") {
      setToDate(date);
    } else if (inputName === "fromDate") {
      setFromDate(date);
    }
    setIsDateSelected(true);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, "dd-M-yyyy"); // Change the format here
  };

  const filteredCompleteData = filteredData.filter(bodyData => bodyData.status_text === 'Complete');
  let incrementalId = 1;

  return (
    <div className=" ">
      <div className="flex justify-between my-4 px-5 shadow-lg pb-4">
            <Link className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" to='/dashboardhome'> Back To Home</Link>
            
            <div className="flex">
              <div className="flex">
                  <label htmlFor="fromDate" className="p-2 bg-black text-white font-blod">
                    Search
                  </label>
                  <DatePicker
                    selected={fromDate}
                    onChange={(date) => handleDateInputChange(date, "fromDate")}
                    dateFormat="dd-M-yyyy"
                    placeholderText="Select from date"
                  />
                </div>

                <div className="flex mx-1">
                  <DatePicker
                    selected={toDate}
                    onChange={(date) => handleDateInputChange(date, "toDate")}
                    dateFormat="dd-M-yyyy"
                    placeholderText="Select to date"
                  />
                </div>

                <div className="flex mx-1">
                  <input
                    type="text"
                    name="inventoryName"
                    placeholder="Enter Inventory Name"
                    value={inventoryName}
                    onChange={handleSearchChange}
                    className="shadow appearance-none border-white-20 w-[200px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
            </div>

            <button
              onClick={pdfGeneretor}
              className="bg-green-600 w-[150px] hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
            >
              Download pdf
            </button>

      </div>

      <div
        ref={componentPDF}
        style={{ width: "100%", height: "100%" }}
        className="flex flex-col mx-auto my-5"
      >
        <div className="pdf-head mb-4">
          <div className="top-header my-3"> 
            <p className="text-right mr-2">মূসক - ৬.১</p>
            <p className="text-center">গণপ্রজাতন্তী বাংলাদেশ সরকার, জাতীয় রাজস্ব বোর্ড</p>
            <h3 className="text-center font-bold">ক্রয় হিসাব পুস্তুক</h3>
            <p className="text-center">[বিধি ৪০ এর উপ-বিধি (১) এর দফা (ক) দ্রষ্টব্য]</p>

          </div>
        </div>

        <div className="">
        <table className="min-w-full mushak1-table-head">
            <thead className=" bg-gray-50 text-[12px] font-medium ">
              <tr className="">
                <td className="border-2  right-5 text-[15px]" colSpan={21}>
                  {/* Purchase of Equipment */}
                  পন্য / সেবা উপকরণ ক্রয়
                </td>
              </tr>

              <tr className="bg-gray-50">
              <td rowSpan={3}>
                  {/* Date */}
                  ক্রমিক 
                </td>
                <td style={{ width: "70px" }} className="border-2 " rowSpan={3}>
                  {/* Date */}
                  তারিখ
                </td>

                <td className="border-2 " colSpan={2}>
                  {/* Initial Available Stock */}
                  মজুদ উপকরণের প্রারম্ভিক জের
                </td>

                <td className="border-2  right-5" colSpan={14}>
                  {/* Purchase of Equipment */}
                  ক্রয়কৃত উপকরণ
                </td>
                <td className="border-2  right-5" colSpan={2}>
                  {/* Ending Stock */}
                  উপকরণের প্রান্তিক জের
                </td>
                <td rowSpan={3}>
                  {/* Date */}
                  মন্তব্য 
                </td>
              </tr>
              
              <tr className="">
                <td className="border-2  right-5" rowSpan={2}>
                  {/* Quantity */}
                  পরিমাণ (একক)
                </td>
                <td className="border-2  right-5" rowSpan={2}>
                  {/* Total Price No Vat */}
                  মূল্য (সকল প্রকার কর ব্যতীত)
                </td>
                <td className="border-2  right-5" rowSpan={2}>
                  {/* Bill of Entry */}
                  চালানপত্র/বিল অব এন্ট্রি নম্বর
                </td>
                <td style={{ width: "70px" }} className="border-2 " rowSpan={3}>
                  {/* Date */}
                  তারিখ
                </td>
                <td className="border-2 right-5" colSpan={3}>
                  {/* Seller/Supplier */}
                  বিক্রেতা / সরবরাহকারী
                </td>
                <td className="border-2 w-[100px]" rowSpan={3}>
                  {/* Descreption */}
                  বিবরণ
                </td>
                <td className="border-2 " rowSpan={3}>
                  {/* Quantity */}
                  পরিমাণ
                </td>
                <td className="border-2 " rowSpan={3}>
                  {/* Total Price No Vat */}
                  মূল্য (সকল প্রকার কর ব্যতীত)
                </td>
                <td className="border-2 " rowSpan={3}>
                  {/* Total Tax */}
                  সম্পূরক শুল্ক (যদি থাকে )
                </td>
                <td className="border-2 " rowSpan={3}>
                  {/* Vat */}
                  মূসক
                </td>
                <td className="border-2  right-5" colSpan={2}>
                  {/* Total Equipment Quantity */}
                  মোট উপকরণের পরিমাণ
                </td>
                <td className="border-2  right-5" colSpan={2}>
                  {/* Product Redy Equipnent */}
                  পণ্য প্রস্তুত/প্রক্রিয়া করনে উপকরণের ব্যবহার 
                </td>
                <td className="border-2 " rowSpan={3}>
                  {/* Quantity unite */}
                  পরিমাণ (একক)
                </td>
                <td className="border-2 " rowSpan={3}>
                  {/* Total Price Without Vat */}
                  মূল্য (সকল প্রকার কর ব্যতীত)
                </td>
              </tr>

              <tr>
                <td className="border-2  right-5 w-[100px]" rowSpan={1}>
                  {/* Name */}
                  নাম
                </td>
                <td className="border-2  right-5 w-[100px]" rowSpan={1}>
                  {/* Address */}
                  ঠিকানা
                </td>
                <td className="border-2  right-5" rowSpan={1}>
                  {/* NID NO */}
                  জাতীয় পরিচয় পত্র নং
                </td>
                <td className="border-2  right-5" rowSpan={1}>
                  {/* Quantity unit */}
                  পরিমাণ (একক)
                </td>
                <td className="border-2  right-5" rowSpan={1}>
                  {/* Total price no vat */}
                  মূল্য (সকল প্রকার কর ব্যতীত)
                </td>
                <td className="border-2  right-5" rowSpan={1}>
                  {/* Quantity unit */}
                  পরিমাণ (একক)
                </td>
                <td className="border-2  right-5" rowSpan={1}>
                  {/* total price no vat */}
                  মূল্য (সকল প্রকার কর ব্যতীত)
                </td>
              </tr>

              <tr></tr>

              <tr>
                <th>(১)</th>
                <th>(২)</th>
                <th>(৩)</th>
                <th>(৪)</th>
                <th>(৫)</th>
                <th>(৬)</th>
                <th>(৭)</th>
                <th>(৮)</th>
                <th>(৯)</th>
                <th>(১০)</th>
                <th>(১১)</th>
                <th>(১২)</th>
                <th>(১৩)</th>
                <th>(১৪)</th>
                <th>১৫=(৩+১১)</th>
                <th>১৬=(৪+১২)</th>
                <th>(১৭)</th>
                <th>(১৮)</th>
                <th>(১৯)</th>
                <th>(২০)</th>
                <th>(২১)</th>
              </tr>
            </thead>

            <tbody>

              {filteredCompleteData.map((bodyData) => (
                <>
                  <tr className="text-[12px]" key={bodyData.id}>

                  <td key={bodyData.id} className="py-1 border px-1">
                     {/* 1 */}
                     {incrementalId++}
                  </td>

                    <td key={bodyData.id} className="py-1 border px-1">
                      {/* 2 */}
                     {formatDate(bodyData.order_date)}
                    </td>

                    <td key={bodyData.id} className="py-1 border px-1">
                      {/* 3 */}
                      {bodyData.initial_stock}
                    </td>

                    <td key={bodyData.id} className="py-1 border px-1">
                      {/* 4 */}
                      {bodyData.initial_total_amount_no_vat}
                    </td>

                    <td key={bodyData.id} className="py-1 border px-1">
                      {/* 5 */}
                      {bodyData.bill_of_entry}
                    </td>
                    <td key={bodyData.id} className="py-1 border px-1">
                      {/* 6 */}
                      {formatDate(bodyData?.order_date)}
                    </td>
                    <td key={bodyData.id} className="py-1 border px-1">
                      {/* 7 */}
                      {bodyData?.supplier_name}
                    </td>
                    <td key={bodyData.id} className="py-1 border px-1">
                      {/* 8 */}
                      {bodyData?.supplier_address}
                    </td>
                    <td key={bodyData.id} className="py-1 border px-1">
                      {/* 9 */}
                      {bodyData?.supplier_nid}
                    </td>
                    <td key={bodyData.id} className="py-1 border px-1">
                      {/* 10 */}
                      {bodyData?.inventory_name}
                    </td>
                    <td key={bodyData.id} className="py-1 border px-1">
                      {/* 11 */}
                      {bodyData?.quantity}
                    </td>
                    <td key={bodyData.id} className="py-1 border px-1">
                      {/* 12 */}
                      {bodyData?.total_price_no_vat}
                    </td>

                    <td key={bodyData.id} className="py-1 border px-1">
                      {/* 13 */}
                      {bodyData?.supplimentary_duty}
                    </td>

                    <td key={bodyData.id} className="py-1 border px-1">
                      {/* 14 */}
                      {bodyData?.vat}
                    </td>

                    <td key={bodyData.id} className="py-1 border px-1">
                      {/* 15 */}
                      {bodyData?.quantity? 
                      (parseFloat(bodyData.initial_stock) + parseFloat(bodyData?.quantity || 0)).toFixed(2)
                      :
                      ''
                      }
                      </td>

                    <td key={bodyData.id} className="py-1 border px-1">
                      {/* 16 */}
                      {bodyData?.total_price_no_vat?
                      (parseFloat(bodyData.initial_total_amount_no_vat) + parseFloat(bodyData?.total_price_no_vat || 0)).toFixed(2)
                      :''
                      }
                    </td>
                    <td key={bodyData.id} className="py-1 border px-1">
                      {/* 17 */}
                      {bodyData?.production_usage_qty >0 ?bodyData?.production_usage_qty:''}
                    </td>

                    <td key={bodyData.id} className="py-1 border px-1">
                      {/* 18 */}
                      {bodyData?.production_usage_price}
                    </td>

                    <td key={bodyData.id} className="py-1 border px-1">
                      {/* 19 */}
                      {bodyData?.current_stock_qty}
                    </td>
                    <td key={bodyData.id} className="py-1 border px-1">
                      {/* 20 */}
                      {bodyData?.current_total_amount_no_vat}
                    </td>
                    <td>
                      {bodyData?.debit_note?
                        <> Debit Note {bodyData?.debit_note}</>
                        :<></>
                      }
                      </td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
        </div>

      </div>
    
    </div>
  );


};

export default MushokTablePage;
