import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useReactToPrint } from "react-to-print";
import { Link, useNavigate, useParams } from "react-router-dom";
import { format } from "date-fns";
import { baseUrl } from "../BaseUrl";


const MushakSixOnePuechase = () => {
  const componentPDF = useRef();

  const pdfGeneretor = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: "Mushak-6.1",
    pageStyle: `
      @page landscape {
        size: A4 landscape;
        margin: 20mm 10mm;
      }
      @page portrait {
        size: A4 portrait;
        margin: 20mm 10mm;
      }
      @page {
        margin: 4mm;
      }
      body {
        margin: 0;
      }
    `,
    contentStyle: `
      @page landscape {
        transform: rotate(0deg) translate(0, 0);
      }
      @page portrait {
        transform: rotate(90deg) translate(0, 0);
      }
    `
  });

  const navigate = useNavigate();

  const [moshukData, setMoshukData] = useState([]);
  const {id} =useParams()
  const fetchMoshukData = async () => {
    const jwtToken = localStorage.getItem("access");

    try {
      const res = await axios.get(`${baseUrl}/purchase/api/purchase/${id}`, {
        headers: {
          Authorization: `JWT ${jwtToken}`,
        },
      });
      if (res) {
        setMoshukData(res.data);
      }
    } catch (error) {
      if(error.response.status === 401){
        navigate("/login");; 
      }
      else{
        console.log(error)
      }
    }
  };

  useEffect(() => {
    fetchMoshukData();
  }, [id]);




  const formatDate = (dateString) => {
    if (dateString) {
      const date = new Date(dateString);
      return format(date, "dd-M-yyyy"); // Change the format here
    }
    return "";
  };

  let incrementalId = 1;

  return (
    <div className=" ">
      <div className="flex justify-between my-4 px-5 shadow-lg pb-4">
            <Link className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" to='/purchases'> Back To Purchases List</Link>

            <button
              onClick={pdfGeneretor}
              className="bg-green-600 w-[150px] hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
            >
              Download pdf
            </button>

      </div>

      <div
        ref={componentPDF}
        style={{ width: "99%", height: "100%" }}
        className="flex flex-col mx-auto"
      >
      <div className="pdf-head mb-4">
          <div className="top-header my-3"> 
            <p className="text-right mr-2">মূসক - ৬.১</p>
            <p className="text-center">গণপ্রজাতন্তী বাংলাদেশ সরকার, জাতীয় রাজস্ব বোর্ড</p>
            <h3 className="text-center font-bold">ক্রয় হিসাব পুস্তুক</h3>
            <p className="text-center">[বিধি ৪০ এর উপ-বিধি (১) এর দফা (ক) দ্রষ্টব্য]</p>

          </div>
        </div>

        <div className="">
        <table className="min-w-full mushak1-table-head">
            <thead className=" bg-gray-50 text-[12px] font-medium ">
              <tr className="">
                <td className="border-2  right-5 text-[15px]" colSpan={21}>
                  {/* Purchase of Equipment */}
                  পন্য / সেবা উপকরণ ক্রয়
                </td>
              </tr>

              <tr className="bg-gray-50">
              <td rowSpan={3}>
                  {/* Date */}
                  ক্রমিক 
                </td>
                <td style={{ width: "70px" }} className="border-2 " rowSpan={3}>
                  {/* Date */}
                  তারিখ
                </td>

                <td className="border-2 " colSpan={2}>
                  {/* Initial Available Stock */}
                  মজুদ উপকরণের প্রারম্ভিক জের
                </td>
                <td className="border-2  right-5" colSpan={14}>
                  {/* Purchase of Equipment */}
                  ক্রয়কৃত উপকরণ
                </td>
                <td className="border-2  right-5" colSpan={2}>
                  {/* Ending Stock */}
                  উপকরণের প্রান্তিক জের
                </td>
                <td rowSpan={3}>
                  {/* Date */}
                  মন্তব্য 
                </td>
              </tr>
              
              <tr className="">
                <td className="border-2  right-5" rowSpan={2}>
                  {/* Quantity */}
                  পরিমাণ (একক)
                </td>
                <td className="border-2  right-5" rowSpan={2}>
                  {/* Total Price No Vat */}
                  মূল্য (সকল প্রকার কর ব্যতীত)
                </td>
                <td className="border-2  right-5" rowSpan={2}>
                  {/* Bill of Entry */}
                  চালানপত্র/বিল অব এন্ট্রি নম্বর
                </td>
                <td style={{ width: "70px" }} className="border-2 " rowSpan={3}>
                  {/* Date */}
                  তারিখ
                </td>
                <td className="border-2 right-5" colSpan={3}>
                  {/* Seller/Supplier */}
                  বিক্রেতা / সরবরাহকারী
                </td>
                <td className="border-2 w-[100px]" rowSpan={3}>
                  {/* Descreption */}
                  বিবরণ
                </td>
                <td className="border-2 " rowSpan={3}>
                  {/* Quantity */}
                  পরিমাণ
                </td>
                <td className="border-2 " rowSpan={3}>
                  {/* Total Price No Vat */}
                  মূল্য (সকল প্রকার কর ব্যতীত)
                </td>
                <td className="border-2 " rowSpan={3}>
                  {/* Total Tax */}
                  সম্পূরক শুল্ক (যদি থাকে )
                </td>
                <td className="border-2 " rowSpan={3}>
                  {/* Vat */}
                  মূসক
                </td>
                <td className="border-2  right-5" colSpan={2}>
                  {/* Total Equipment Quantity */}
                  মোট উপকরণের পরিমাণ
                </td>
                <td className="border-2  right-5" colSpan={2}>
                  {/* Product Redy Equipnent */}
                  পণ্য প্রস্তুত/প্রক্রিয়া করনে উপকরণের ব্যবহার 
                </td>
                <td className="border-2 " rowSpan={3}>
                  {/* Quantity unite */}
                  পরিমাণ (একক)
                </td>
                <td className="border-2 " rowSpan={3}>
                  {/* Total Price Without Vat */}
                  মূল্য (সকল প্রকার কর ব্যতীত)
                </td>
              </tr>

              <tr>
                <td className="border-2  right-5 w-[100px]" rowSpan={1}>
                  {/* Name */}
                  নাম
                </td>
                <td className="border-2  right-5 w-[100px]" rowSpan={1}>
                  {/* Address */}
                  ঠিকানা
                </td>
                <td className="border-2  right-5" rowSpan={1}>
                  {/* NID NO */}
                  জাতীয় পরিচয় পত্র নং
                </td>
                <td className="border-2  right-5" rowSpan={1}>
                  {/* Quantity unit */}
                  পরিমাণ (একক)
                </td>
                <td className="border-2  right-5" rowSpan={1}>
                  {/* Total price no vat */}
                  মূল্য (সকল প্রকার কর ব্যতীত)
                </td>
                <td className="border-2  right-5" rowSpan={1}>
                  {/* Quantity unit */}
                  পরিমাণ (একক)
                </td>
                <td className="border-2  right-5" rowSpan={1}>
                  {/* total price no vat */}
                  মূল্য (সকল প্রকার কর ব্যতীত)
                </td>
              </tr>

              <tr></tr>

              <tr>
                <th>(১)</th>
                <th>(২)</th>
                <th>(৩)</th>
                <th>(৪)</th>
                <th>(৫)</th>
                <th>(৬)</th>
                <th>(৭)</th>
                <th>(৮)</th>
                <th>(৯)</th>
                <th>(১০)</th>
                <th>(১১)</th>
                <th>(১২)</th>
                <th>(১৩)</th>
                <th>(১৪)</th>
                <th>১৫=(৩+১১)</th>
                <th>১৬=(৪+১২)</th>
                <th>(১৭)</th>
                <th>(১৮)</th>
                <th>(১৯)</th>
                <th>(২০)</th>
                <th>(২১)</th>
              </tr>
            </thead>

            <tbody>

                <tr className="text-[12px]" >

                <td  className="py-1 border px-1">
                    {/* 1 */}
                    {incrementalId++}
                </td>

                  <td  className="py-1 border px-1">
                    {/* 2 */}
                    {formatDate(moshukData?.order_date)}
                  </td>

                  <td  className="py-1 border px-1">
                    {/* 3 */}
                    {moshukData?.initial_stock}
                  </td>

                  <td  className="py-1 border px-1">
                    {/* 4 */}
                    {moshukData?.initial_total_amount_no_vat}
                  </td>

                  <td  className="py-1 border px-1">
                    {/* 5 */}
                    {moshukData?.bill_of_entry}
                  </td>
                  <td  className="py-1 border px-1">
                    {/* 6 */}
                    {formatDate(moshukData?.order_date)}
                  </td>
                  <td  className="py-1 border px-1">
                    {/* 7 */}
                    {moshukData?.supplier_name}
                  </td>
                  <td  className="py-1 border px-1">
                    {/* 8 */}
                    {moshukData?.supplier_address}
                  </td>
                  <td  className="py-1 border px-1">
                    {/* 9 */}
                    {moshukData?.supplier_nid}
                  </td>
                  <td  className="py-1 border px-1">
                    {/* 10 */}
                    {moshukData?.inventory_name}
                  </td>
                  <td  className="py-1 border px-1">
                    {/* 11 */}
                    {moshukData?.quantity}
                  </td>
                  <td  className="py-1 border px-1">
                    {/* 12 */}
                    {moshukData?.total_price_no_vat}
                  </td>

                  <td  className="py-1 border px-1">
                    {/* 13 */}
                    {moshukData?.supplimentary_duty}
                  </td>

                  <td  className="py-1 border px-1">
                    {/* 14 */}
                    {moshukData?.vat}
                  </td>

                  <td  className="py-1 border px-1">
                    {/* 15 */}
                    {moshukData?.current_stock_qty}
                  </td>

                  <td  className="py-1 border px-1">
                    {/* 16 */}
                    {(parseFloat(moshukData?.initial_total_amount_no_vat) + parseFloat(moshukData?.total_price_no_vat || 0)).toFixed(2)}
                  </td>
                  <td  className="py-1 border px-1">
                    {/* 17 */}
                    {/* {moshukData?.production_usage_qty} */}
                  </td>

                  <td  className="py-1 border px-1">
                    {/* 18 */}
                    {/* {moshukData?.production_usage_price} */}
                  </td>

                  <td  className="py-1 border px-1">
                    {/* 19 */}
                    {moshukData?.current_stock_qty}
                  </td>
                  <td  className="py-1 border px-1">
                    {/* 20 */}
                    {moshukData?.current_total_amount_no_vat}
                  </td>
                  <td></td>
                </tr>
            </tbody>
          </table>
        </div>
        <div className="flex-grow"></div>

      </div>
    
    </div>
  );


};

export default MushakSixOnePuechase;
