// eslint-disable-next-line no-unused-vars
import React from "react";
import DashboardLayout from "../Shared/DashboardLayout";
import SupplyEditForm from "../components/SupplyEditForm";
const SupplyEditPage = () => {
  return (
    <DashboardLayout>
      <SupplyEditForm />
    </DashboardLayout>
  );
};

export default SupplyEditPage;
