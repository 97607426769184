// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from "react";
import { Table } from "antd";
import axios from "axios";
import { AiFillDelete } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { baseUrl } from "../BaseUrl";

const UserListTable = () => {
  const [userData, setUserData] = useState([]);
  useEffect(()=>{
    async function getUserData(){
        try {
          const jwtToken = localStorage.getItem("access");
          console.log("jwt token from create user..", jwtToken);
          const res = await axios.get(`${baseUrl}/user/api/users/`, {
            headers: {
              Authorization: `JWT ${jwtToken}`,
            },
          });
          if (res) {
            console.log("fetch user data", res.data);
            const finalData = res.data;
            setUserData(finalData);
          }
        } catch (error) {
            console.log(error)
        }
    }getUserData()
},[])


  const columns = [
    {
      key: 1,
      title: "ID",
      dataIndex: "id",
    },
    {
      key: 2,
      title: "First Name",
      dataIndex: "first_name",
    },
    {
      key: 3,
      title: "Last Name",
      dataIndex: "last_name",
    },

    {
      key: 4,
      title: "Phone",
      dataIndex: "phone",
    },

    {
      key: 4,
      title: "Email",
      dataIndex: "email",
    },
  ];

  return (
    <div>
      <div className="px-5 ">
      <div className="flex items-center justify-between py-5">
        <h1 className=" uppercase font-semibold  text-xl">User List</h1>
        <Link to="/user-add">
          <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
            Create New
          </button>
        </Link>
      </div>
      
      <Table
        columns={columns}
        dataSource={userData}
        selectableRows
        fixedHeader
        pagination={{
          pageSize: 10, 
          total: userData.length,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
          showSizeChanger: false,
        }}
      />

    </div>
    </div>
  );
};

export default UserListTable;
