import React, { useState } from 'react';
import axios from 'axios';
import Select from 'react-select';
import DashboardLayout from '../../Shared/DashboardLayout';
import { baseUrl } from '../../BaseUrl';
import { useEffect } from 'react';
import { AiFillDelete } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const ContractualProductionAdd = () => {
  const [formData, setFormData] = useState({
    inventory: null,
    fg_quantity: '',
    name_of_contracting_company:null,
    production_cost_per_unit:'',
    challan_no:'',
    status: null,
    rawmaterial_set: [
      {
        raw_material: null,
        rm_quantity: '',
        amount_of_wastage: '',
      },
    ],
  });
  const navigate = useNavigate()
  const [inventoryOptions, setInventoryOptions] = useState([]);
  const [selectedInventory, setSelectedInventory] = useState(null);
  const [rawMaterialOptions, setRawMaterialOptions] = useState([]);
  const [selectedRawMaterials, setSelectedRawMaterials] = useState([]);
  const [rawMaterialAddMoreRequiredMessage,setRawMaterialAddMoreRequiredMessage]= useState('')
  const [rawMaterialStock, setRawMaterialStock] = useState({});

  const [companyOptions, setCompanyOptions] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
  });

  const productionStatusOptions = [
    { value: '0', label: 'Ordered' },
    { value: '1', label: 'WIP' },
    { value: '2', label: 'Complete' },
  ];

  const handleStatusChange = (selectedOption) => {
    setFormData({ ...formData, status: selectedOption.value });
  };

  const handleInputChange = (e, index, type) => {
    const updatedData = { ...formData };

    if (type === 'raw_material_set') {
      updatedData.rawmaterial_set[index][e.target.name] = e.target.value;
    } else {
      updatedData[e.target.name] = e.target.value;
    }

    setFormData(updatedData);
  };


  useEffect(() => {
    const fetchInventories = async () => {
      try {
        const jwtToken = localStorage.getItem("access");
        const response = await axios.get(`${baseUrl}/inventory/api/inventory/`, {
          headers: {
            Authorization: `JWT ${jwtToken}`,
          },
        });

        // Filter items with type 'Finished Good'
        const finishedGoodInventories = response.data.results.filter(
          (item) => item.type_text === 'Finished Good'
        );

        setInventoryOptions(
          finishedGoodInventories.map((inventory) => ({
            value: inventory.id,
            label: inventory.name,
          }))
        );
      } catch (error) {
        console.error('Error fetching inventories', error);
      }
    };
    fetchInventories();
  }, []);


  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const jwtToken = localStorage.getItem("access");
        const response = await axios.get(`${baseUrl}/coa/api/company/`, {
          headers: {
            Authorization: `JWT ${jwtToken}`,
          },
        });

        console.log('company',response.data.results)


        setCompanyOptions(
            response.data.results.map((company) => ({
            value: company.id,
            label: company.company_name,
          }))
        );

      } catch (error) {
        console.error('Error fetching inventories', error);
      }
    };
    fetchCompanies();
  }, []);


  const handleInventoryChange = (selectedOption) => {
    const inventoryId = selectedOption.value; // Assuming "value" holds the ID
    setSelectedInventory(selectedOption);
    setFormData({
      ...formData,
      inventory: inventoryId, // Set the ID of the selected inventory item
    });
  };

  const handleCompanyChange = (selectedOption) => {
    const companyId = selectedOption.value; // Assuming "value" holds the ID
    setSelectedCompany(selectedOption);
    setFormData({
      ...formData,
      name_of_contracting_company: companyId, // Set the ID of the selected company item
    });
  };

  useEffect(() => {
    const fetchRawMaterialData = async () => {
        try {
          const jwtToken = localStorage.getItem("access");
          const res = await axios.get(`${baseUrl}/inventory/api/rm_list/`, {
            headers: {
              Authorization: `JWT ${jwtToken}`,
            },
          });
    
          if (res) {
            const rawMaterialData = res.data;

            // Filter out raw materials that are already selected
            const filteredRawMaterialData = rawMaterialData.filter((rawMaterial) => {
                const rawMaterialValue = rawMaterial.id;
                return !selectedRawMaterials.some(
                (selected) => selected.value === rawMaterialValue
                );
            });
    
            const options = filteredRawMaterialData.map((item) => ({
                value: item.id,
                label: item.name,
            }));
            setRawMaterialOptions(options);

            const stockInfo = {};
            rawMaterialData.forEach((rawMaterial) => {
              stockInfo[rawMaterial.id] = rawMaterial.current_stock;
            });
            setRawMaterialStock(stockInfo);
          }
        } catch (error) {
          console.log("Error fetching raw material data", error);
        }
    };
    fetchRawMaterialData()   
  }, [selectedRawMaterials]);

  const handleRawMaterialChange = (selectedOption, index) => {
    const updatedFormData = { ...formData };
    updatedFormData.rawmaterial_set[index].raw_material = selectedOption.value;
    
    // Set the current stock for the selected raw material
    updatedFormData.rawmaterial_set[index].current_stock = rawMaterialStock[selectedOption.value];
    
    const updatedSelectedRawMaterials = [...selectedRawMaterials];
    updatedSelectedRawMaterials[index] = selectedOption;
  
    setSelectedRawMaterials(updatedSelectedRawMaterials);
    setFormData(updatedFormData);
  };

  const areAllRawMaterialFieldsFilled = () => {
    const newRawMaterialEntry = formData.rawmaterial_set[formData.rawmaterial_set.length - 1];
    return (
      newRawMaterialEntry.raw_material !== null &&
      newRawMaterialEntry.rm_quantity.trim() !== ''
    );
  };
  
  const handleAddRawMaterial = () => {
    if (areAllRawMaterialFieldsFilled()) {
        const newRawMaterialEntry = {
            raw_material: null,
            rm_quantity: '',
            amount_of_wastage: '',
          };
      setFormData({
        ...formData,
        rawmaterial_set: [...formData.rawmaterial_set, newRawMaterialEntry],
      });
  
      setSelectedRawMaterials([...selectedRawMaterials, formData.rawmaterial_set[formData.rawmaterial_set.length - 1].raw_material]);
      setRawMaterialAddMoreRequiredMessage('');
    } else {
        setRawMaterialAddMoreRequiredMessage("Please fill in all required raw material fields")
    }
  };

  const handleRemoveRawMaterial = (index) => {
    const rawmaterial_set = [...formData.rawmaterial_set];
    rawmaterial_set.splice(index, 1);
    setFormData({
      ...formData,
      rawmaterial_set,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const jwtToken = localStorage.getItem("access");
      const response = await axios.post(
        `${baseUrl}/purchase/api/production/`,
        formData,  // Your form data
        {
          headers: {
            Authorization: `JWT ${jwtToken}`,
          },
        }
      );
      navigate("/contractual-production-list");
      Toast.fire({
        icon: 'success',
        title: 'Successfully Add',
      });
    } catch (error) {
        if(error.response.data.detail){
            Toast.fire({
            icon: 'error',
            title: `${error.response.data.detail}`,
            });
        }else{
            console.error('Error saving data:', error);
        }

    }
  };


  return (
    <DashboardLayout>
    <div className="px-5 mt-5 mb-10 pb-10">
        <div className=" w-4/3">
        <div className="bg-white p-8 rounded shadow-xl shadow-blue-500/40 w-[90%] mx-auto">
            <form onSubmit={handleSubmit}>
            <div className="flex justify-between rounded border-b-[1px] mb-10 bg-slate-900">
                <div className="mx-5 my-auto">
                <h1 className="uppercase font-semibold text-xl text-[#fff]">Contractual Production Order Edit Form</h1>
                </div>
                <div className="my-2 mx-5">
                <button
                    type="submit"
                    className="w-[120px] bg-slate-700 hover:bg-slate-800 border text-white font-medium rounded-lg h-10"
                >
                    Submit
                </button>
                </div>
            </div>

            <div class="flex flex-wrap -mx-3 mb-2">

                <div class="w-full md:w-2/4 px-3 mb-8 border-r-[1px]">
                  <div className="flex  md:w-4/4 mx-5 ">
                    <div class="md:w-[30%] my-auto">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-3"  for="inline-full-name">
                    Finished Good*
                    </label>
                    </div>
                    <div class="md:w-[70%]">
                        <Select
                            id="inventory"
                            name="inventory"
                            value={selectedInventory}
                            onChange={handleInventoryChange}
                            options={inventoryOptions}
                            required
                        />
                    </div>
                  </div>
                </div>

                <div class="w-full md:w-2/4 px-3 mb-8">
                  <div className="flex  md:w-4/4 mx-5 ">
                    <div class="md:w-[32%] my-auto">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-3"  for="inline-full-name">
                        Quantity*
                    </label>
                    </div>
                    <div class="md:w-[68%]">
                        <input
                            type="number"
                            min="1"
                            name="fg_quantity"
                            id="fg_quantity"
                            value={formData.fg_quantity}
                            required
                            onChange={handleInputChange}
                            className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-blue-300"
                            placeholder="Enter quantity"
                        />
                    </div>
                  </div>
                </div>

                <div class="w-full md:w-2/4 px-3 mb-8 border-r-[1px]">
                  <div className="flex  md:w-4/4 mx-5 ">
                    <div class="md:w-[30%] my-auto">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-3"  for="inline-full-name">
                        Status*
                    </label>
                    </div>
                    <div class="md:w-[70%]">
                        <Select
                            id="status"
                            name="status"
                            value={productionStatusOptions.find((option) => option.value === formData.status)}
                            onChange={handleStatusChange}
                            options={productionStatusOptions}
                            required
                        />
                    </div>
                  </div>
                </div>

                <div class="w-full md:w-2/4 px-3 mb-8">
                  <div className="flex  md:w-4/4 mx-5 ">
                    <div class="md:w-[32%] my-auto">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-3"  for="inline-full-name">
                    Challan No*
                    </label>
                    </div>
                    <div class="md:w-[68%]">
                        <input
                            type="number"
                            min="0"
                            name="challan_no"
                            id="challan_no"
                            value={formData.challan_no}
                            required
                            onChange={handleInputChange}
                            className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-blue-300"
                            placeholder="Enter challan no"
                        />
                    </div>
                  </div>
                </div>


                <div class="w-full md:w-4/4 px-3 mb-8">
                  <div className="flex  md:w-4/4 mx-5 ">
                    <div class="md:w-[23%] my-auto">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-3"  for="inline-full-name">
                    Contracting company*
                    </label>
                    </div>
                    <div class="md:w-[77%]">
                    <Select
                            id="name_of_contracting_company"
                            name="name_of_contracting_company"
                            value={selectedCompany}
                            onChange={handleCompanyChange}
                            options={companyOptions}
                            required
                        />
                    </div>
                  </div>
                </div>

                <div class="w-full md:w-4/4 px-3 mb-8">
                  <div className="flex  md:w-4/4 mx-5 ">
                    <div class="md:w-[23%] my-auto">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-3"  for="inline-full-name">
                    production cost per unit*
                    </label>
                    </div>
                    <div class="md:w-[77%]">
                        <input
                            type="number"
                            min="1"
                            step='0.01'
                            name="production_cost_per_unit"
                            id="production_cost_per_unit"
                            value={formData.production_cost_per_unit}
                            required
                            onChange={handleInputChange}
                            className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-blue-300"
                            placeholder="Enter production_cost_per_unit"
                        />
                    </div>
                  </div>
                </div>
            </div>

                {formData.rawmaterial_set?.map((rawMaterial, index) => (
                <table className="table-auto w-100 sales-order-table ml-5 ">
                {index === 0 && (
                    <thead>
                    <tr>
                        <th className='inventory-th'>
                        <label
                            class="block uppercase tracking-wide  text-[12px] font-semibold"
                            for="grid-state"
                        >
                            Raw Material*
                        </label>
                        </th>
                        <th>
                        <label
                            class="block uppercase tracking-wide  text-[12px] font-semibold"
                            for="inline-full-name"
                        >
                            Quantity*
                        </label>
                        </th>
                        <th className='total-price-td'>
                        <label
                            class="block uppercase tracking-wide  text-[12px] font-semibold"
                            for="inline-full-name"
                        >
                            Amount of wastage
                        </label>
                        </th>
                        <th className="action-th"></th>
                    </tr>
                    </thead>
                )}
                <tbody>
                    <tr className="table-input-filed">
                    <td className="w-[40%] inventory-th">
                        <Select
                            id={`raw_material_${index}`}
                            name={`rawmaterial_set[${index}].raw_material`}
                            value={selectedRawMaterials[index]}
                            onChange={(selectedOption) => handleRawMaterialChange(selectedOption, index)}
                            options={rawMaterialOptions}
                            required
                        />
                    </td>
                    <td className="w-[25%]">
                        <input
                            type="number"
                            min='0'
                            step='0.01'
                            name="rm_quantity"
                            required
                            value={rawMaterial.rm_quantity}
                            onChange={(e) => handleInputChange(e, index, 'raw_material_set')}
                            placeholder="Enter quantity"
                        />
                        {rawMaterial.current_stock && (
                        <div className="text-sm text-sky-500">
                            Current Stock: {rawMaterial.current_stock}
                        </div>
                        )}


                    </td>
                    <td className="w-[25%] total-price-td">
                        <input
                            type="number"
                            min='0'
                            step='0.01'
                            name="amount_of_wastage"
                            value={rawMaterial.amount_of_wastage}
                            onChange={(e) => handleInputChange(e, index, 'raw_material_set')}
                            placeholder="Enter amount of wastage"
                        />
                    </td>

                    {index === 0 && (
                        <td className="w-[10%] action-td"></td>
                    )}
                    {index !== 0 && (
                        <td className="w-[10%] action-td">
                        {index !== 0 && (
                            <button
                            type="button"
                            onClick={() => handleRemoveRawMaterial(index)}
                            className="text-red-500"
                            >
                            <AiFillDelete className="text-xl text-red-500/100 hover:text-red-600 " />
                            </button>
                        )}
                        </td>
                    )}
                    </tr>
                </tbody>
                </table>
                ))}

                <div className="mb-3 ml-5">
                {rawMaterialAddMoreRequiredMessage && (
                    <span className='text-red-500 mt-2'>{rawMaterialAddMoreRequiredMessage} <br /> </span> 
                )}
                <button
                  type="button"
                  onClick={() => {
                    console.log('Add Product Button Clicked');
                    handleAddRawMaterial();
                  }}
                  className="bg-slate-800 hover:bg-slate-900 text-white p1-2 px-2 my-3 rounded"
                  title='All fields required for add more product'
                >
                  Add More
                </button>
                <hr />
              </div>
            </form>
        </div>
        </div>
    </div>
  </DashboardLayout>
  );
};

export default ContractualProductionAdd;
