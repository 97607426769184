import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useReactToPrint } from "react-to-print";
import { Link, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import { baseUrl } from "../BaseUrl";

const MushakSixTwo = () => {
  const componentPDF = useRef();

  const pdfGeneretor = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: "Mushak-6.2",
    pageStyle: `
      @page landscape {
        size: A4 landscape;
        margin: 20mm 10mm;
      }
      @page portrait {
        size: A4 portrait;
        margin: 20mm 10mm;
      }
      @page {
        margin: 4mm;
      }
      body {
        margin: 0;
      }
    `,
    contentStyle: `
      @page landscape {
        transform: rotate(0deg) translate(0, 0);
      }
      @page portrait {
        transform: rotate(90deg) translate(0, 0);
      }
    `
  });
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [toDate, setToDate] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [isDateSelected, setIsDateSelected] = useState(false);
  const [inventoryName, setInventoryName] = useState("");

  const [moshukData, setMoshukData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const fetchMoshukData = async () => {
    const jwtToken = localStorage.getItem("access");

    try {
      const res = await axios.get(`${baseUrl}/sales/api/mushak_6_2/`, {
        headers: {
          Authorization: `JWT ${jwtToken}`,
        },
      });
      if (res) {
        // Filter the data here
        const filteredData = res.data.results.filter(
          (bodyData) =>
            bodyData.sales_status_text === "Complete" ||
            bodyData.prod_status_text === "Complete" ||
            bodyData.credit_note !== null
        );
        setMoshukData(filteredData);
        console.log("Filtered Data:", res.data.results);
      }
    } catch (error) {
      if(error.response.status === 401){
        navigate("/login");; 
      }
      else{
        console.log(error)
      }
    }
  };

  useEffect(() => {
    fetchMoshukData();
  }, []);

  const handleSearchChange = (e) => {
    const { name, value } = e.target;
    if (name === "toDate") {
      setToDate(new Date(value));
    } else if (name === "fromDate") {
      setFromDate(new Date(value));
    } else if (name === "inventoryName") {
      setInventoryName(value);
    }
  };

  useEffect(() => {
    const filtered = moshukData.filter((data) => {
      const salesInventoryNameLowerCase = data.sales_inventory_name?.toLowerCase() || "";
      const prodInventoryNameLowerCase = data.prod_inventory_name?.toLowerCase() || ""; // Add this line
      const supplierNameLowerCase = data.customer_name?.toLowerCase() || "";
      const orderDate = new Date(data.date_created);
  
      
      const matchesToDate = !toDate || orderDate <= toDate;
      const matchesFromDate = !fromDate || orderDate >= fromDate;
      const matchesSalesInventoryName = !inventoryName || salesInventoryNameLowerCase.includes(inventoryName.toLowerCase());
      const matchesProdInventoryName = !inventoryName || prodInventoryNameLowerCase.includes(inventoryName.toLowerCase()); // Add this line
  
      // Modify this condition to check if the order date is within the selected date range
      const isWithinDateRange = (!toDate || !fromDate) || (orderDate >= fromDate && orderDate <= toDate);
  
      return (
        matchesToDate &&
        matchesFromDate &&
        (matchesSalesInventoryName || matchesProdInventoryName) && // Modify this line to consider both sales and prod inventory names
        isWithinDateRange &&
        (salesInventoryNameLowerCase.includes(searchQuery.toLowerCase()) ||
          prodInventoryNameLowerCase.includes(searchQuery.toLowerCase()) || // Modify this line to consider both sales and prod inventory names
          supplierNameLowerCase.includes(searchQuery.toLowerCase()) ||
          format(orderDate, "dd-M-yyyy").includes(searchQuery.toLowerCase()))
      );
    });
  
    setFilteredData(filtered);
  }, [searchQuery, toDate, fromDate, inventoryName, moshukData]);
  
  
  const formatDate = (dateString) => {
    try {
      const date = new Date(dateString);
      return format(date, "dd-M-yyyy");
    } catch (error) {
      return "...";
    }
  };

  const handleDateInputChange = (date, inputName) => {
    if (inputName === "toDate") {
      setToDate(date);
    } else if (inputName === "fromDate") {
      setFromDate(date);
    }
    setIsDateSelected(true);
  };



  let incrementalId = 1;

  return (
    <div className=" ">
      <div className="flex justify-between my-4 px-5 shadow-lg pb-4">
        <Link className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" to="/dashboardhome">
          Back To Home
        </Link>
      <div className="flex">
      <div className="flex">
          <label htmlFor="fromDate" className="p-2 bg-black text-white font-blod">
            Search
          </label>
          <DatePicker
            selected={fromDate}
            onChange={(date) => handleDateInputChange(date, "fromDate")}
            dateFormat="dd-M-yyyy"
            placeholderText="Select from date"
          />
        </div>

        <div className="flex mx-1">
          <DatePicker
            selected={toDate}
            onChange={(date) => handleDateInputChange(date, "toDate")}
            dateFormat="dd-M-yyyy"
            placeholderText="Select to date"
          />
        </div>

        <div className="flex mx-1">
          <input
            type="text"
            name="inventoryName"
            placeholder="Enter Inventory Name"
            value={inventoryName}
            onChange={handleSearchChange}
            className="shadow appearance-none border-white-20 w-[200px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
      </div>
        <button
          onClick={pdfGeneretor}
          className="bg-green-600 w-[150px] hover-bg-green-700 text-white font-bold py-2 px-4 rounded"
        >
          Download pdf
        </button>
      </div>


      <div
        ref={componentPDF}
        style={{ width: "100%", height: "100%" }}
        className="flex flex-col mx-auto "
      >
        <div className="pdf-head">
          <div className="top-header my-3">  
            <p className="text-right mr-2">মূসক - ৬.২</p>
            <p className="text-center">গণপ্রজাতন্তী বাংলাদেশ সরকার, জাতীয় রাজস্ব বোর্ড</p>
            <h3 className="text-center font-bold">বিক্রয় হিসাব পুস্তুক</h3>
            <p className="text-center">[বিধি ৪০ এর উপ-বিধি (১) এর দফা (খ) এবং বিধি ৪১ এর দফা (ক) দ্রষ্টব্য]</p>

          </div>

        </div>
        <div className=" my-[30px]">
        <table className="min-w-full mushak1-table-head">
            <thead className=" bg-gray-50 text-[12px] font-medium">
              <tr className="">
                <td className="border-2  right-5 text-[15px]" colSpan={21}>
                  {/* Sales */}
                  পন্য/সেবা বিক্রয় 
                </td>
              </tr>
              <tr className="bg-gray-50">
              <td
                  className="border-2 "
                  rowSpan={3}
                >
                  {/* Date */}
                  ক্রমিক
                </td>
                <td
                  style={{ width: "70px" }}
                  className="border-2 "
                  rowSpan={3}
                >
                  {/* Date */}
                  তারিখ
                </td>
                <td className="border-2 " colSpan={2}>
                  {/* Initial available stock */}
                  উৎপাদিত পণ্য/সেবার প্রারম্ভিক জের
                </td>
                <td className="border-2 " colSpan={2}>
                  {/* Manufacturing */}
                  উৎপাদন 
                </td>
                <td className="border-2  right-5" colSpan={2}>
                  {/* Total goods/services produced */}
                  মোট উৎপাদিত পণ্য/সেবা
                </td>
                <td className="border-2  right-5" colSpan={3}>
                  {/* Buyer/Supplier */}
                  ক্রেতা/সরবারহ গ্রহীতা
                </td>
                <td className="border-2  right-5" colSpan={2}>
                  {/* Invoice Details */}
                  চালানপত্রের বিবরণ
                </td>
                <td className="border-2  right-5" colSpan={5}>
                    {/* Use of goods sold/supplied */}
                    বিক্রিত/সরবারাহকৃত পণ্যের ব্যবহার
                </td>
                <td className="border-2  right-5" colSpan={2}>
                    {/* Ending Stock */}
                    পণ্যের প্রান্তিক জের
                </td>
                <td
                  className="border-2 "
                  rowSpan={3}
                >
                  {/* মন্তব্য */}
                  মন্তব্য
                </td>
              </tr>

              <tr className="">
                <td className="border-2  right-5" rowSpan={2}>
                  {/* Quantity */}
                  পরিমাণ (একক)
                </td>
                <td className="border-2  right-5" rowSpan={2}>
                  {/* Total Price No Vat */}
                  মূল্য (সকল প্রকার কর ব্যতীত)
                </td>

                <td className="border-2  right-5" rowSpan={2}>
                  {/* Quantity */}
                  পরিমাণ (একক)
                </td>
                <td className="border-2 " rowSpan={2}>
                  {/* Total Price No Vat */}
                  মূল্য (সকল প্রকার কর ব্যতীত)
                </td>

                <td className="border-2  right-5" colSpan={1}>
                  {/* Quantity */}
                  পরিমাণ (একক)
                </td>
                <td className="border-2  right-5" colSpan={1}>
                  {/* Total Price No Vat */}
                  মূল্য (সকল প্রকার কর ব্যতীত)
                </td>
       
                <td className="border-2  right-5 w-[100px]" colSpan={1}>
                  {/* Nmae */}
                  নাম
                </td>
                <td className="border-2  right-5 w-[100px]" colSpan={1}>
                  {/* Address */}
                  ঠিকানা
                </td>
                <td className="border-2  right-5" colSpan={1}>
                  {/* NID No */}
                  জাতীয় পরিচয় পত্র নং
                </td>


                <td className="border-2  right-5" colSpan={1}>
                  {/* Number */}
                  নম্বর 
                </td>
                <td className="border-2 w-[70px]  right-5" colSpan={1} >
                  {/* Date */}
                  তারিখ 
                </td>



                <td className="border-2  right-5" colSpan={1}>
                  {/* Details */}
                  বিবরণ 
                </td>

                <td className="border-2  right-5" colSpan={1}>
                  {/* Quantity */}
                  পরিমাণ
                </td>

                <td className="border-2  right-5" colSpan={1}>
                {/* Taxable value */}
                  করযোগ্য মূল্য 
                </td>
                <td className="border-2  right-5" colSpan={1}>
                  {/* Supplementary duty */}
                  সম্পূরক শুল্ক (যদি থাকে)
                </td>
                <td className="border-2  right-5" colSpan={1}>
                  {/* Vat */}
                  মূসক 
                </td>


                <td className="border-2  right-5" colSpan={1}>
                  {/* Quantity */}
                  পরিমাণ
                </td>

                <td className="border-2  right-5" colSpan={1}>
                    মূল্য (সকল প্রকার কর ব্যতীত)
                </td>
              </tr>
              <tr>

              </tr>
              <tr>
                <th>(১)</th>
                <th>(২)</th>
                <th>(৩)</th>
                <th>(৪)</th>
                <th>(৫)</th>
                <th>(৬)</th>
                <th>৭=(৩+৫)</th>
                <th>৮=(৪+৬)</th>
                <th>(৯)</th>
                <th>(১০)</th>
                <th>(১১)</th>
                <th>(১২)</th>
                <th>(১৩)</th>
                <th>(১৪)</th>
                <th>(১৫)</th>
                <th>(১৬)</th>
                <th>(১৭)</th>
                <th>(১৮)</th>
                <th>১৯=(৭-১৫)</th>
                <th>২০=(৮-১৬)</th>
                <th>২১</th>
              </tr>
            </thead>

            <tbody>          

              {filteredData.map((bodyData) => (
                <>
                  <tr className="text-[12px]" key={bodyData.id}>
                    <td key={bodyData.id} className="py-1 border px-1">
                      {/* 1 */}
                      {incrementalId++}
                    </td>
                    {/* date  */}
                    <td key={bodyData.id} className="py-1 border px-1">
                      {/* 2 */}
                     {formatDate(bodyData?.date_created)}
                    </td>

                  	{/* Initial available stock col data  */}
                    <td key={bodyData.id} className="py-1 border px-1">
                      {/* 3 */}
                      {bodyData.initial_stock}
                    </td>
                    <td key={bodyData.id} className="py-1 border px-1">
                      {/* 4 */}
                      {bodyData.initial_total_amount_no_vat}
                    </td>

                    {/* Manufacturing col data */}
                    <td key={bodyData.id} className="py-1 border px-1">
                      {/* 5 */}
                      {bodyData.prod_qty}
                    </td>
                    <td key={bodyData.id} className="py-1 border px-1">
                      {/* 6 */}
                      {bodyData?.prod_qty?
                      (parseFloat(bodyData?.prod_qty) * parseFloat(bodyData?.prod_inventory_unit_price || 0)).toFixed(2)
                      :''
                      }
                    </td>
                    
        {/* Total goods/services produced */}
                    <td key={bodyData.id} className="py-1 border px-1">
                      {/* 7 */}
                      {(parseFloat(bodyData.initial_stock) + parseFloat(bodyData?.prod_qty || 0)).toFixed(2)}
                    </td>
                    <td key={bodyData.id} className="py-1 border px-1">
                      {/* 8 */}
                      {
                      (parseFloat(bodyData.initial_total_amount_no_vat) + (parseFloat(bodyData.prod_qty || 0) * parseFloat(bodyData?.prod_inventory_unit_price || 0))).toFixed(2)
                      }
                    </td>

        {/* Buyer/Supplier col data */}
                    <td key={bodyData.id} className="py-1 border px-1">
                      {/* 9 */}
                      {bodyData?.customer_name}
                    </td>
                    <td key={bodyData.id} className="py-1 border px-1">
                      {/* 10 */}
                      {bodyData?.customer_address}
                    </td>
                    <td key={bodyData.id} className="py-1 border px-1">
                      {/* 11 */}
                      {bodyData?.customer_nid}
                    </td>

        {/* Invoice Details col data */}
                    <td key={bodyData.id} className="py-1 border px-1">
                      {/* 12 */}
                      {bodyData?.challan}
                    </td>
                    <td key={bodyData.id} className="py-1 border px-1">
                      {/* 13 */}                
                      {formatDate(bodyData?.date)}              
                    </td>

        {/* Use of goods sold/supplied col key={bodyData.id} data */}
                    <td key={bodyData.id} className="py-1 border px-1">
                      {/* 14 */}
                      {bodyData?.sales_inventory_name} {bodyData?.prod_inventory_name}
                    </td>
                    <td key={bodyData.id} className="py-1 border px-1">
                      {/* 15 */}
                      {bodyData?.credit_note?
                      <></>
                      :<>{bodyData?.sales_qty}</>
                      }
                    </td>
                    <td key={bodyData.id} className="py-1 border px-1">
                      {/* 16 */}


                      {bodyData?.credit_note?
                      <></>
                      :<>
                          {bodyData?.sales_inventory_unit_price?
                          (parseFloat(bodyData?.sales_inventory_unit_price || 0) * parseFloat(bodyData?.sales_qty || 0)).toFixed(2)
                          :''
                          }
                      </>
                      }
                      
                    </td>
                    <td key={bodyData.id} className="py-1 border px-1">
                      {/* 17 */}
                      

                      {bodyData?.credit_note?
                      <></>
                      :<>
                        {bodyData?.sd}
                      </>
                      }
                    </td>
                    <td key={bodyData.id} className="py-1 border px-1">
                      {/* 18 */}
                      
                      {bodyData?.credit_note?
                      <></>
                      :<>
                        {bodyData?.vat}
                      </>
                      }
                    </td>


                    <td key={bodyData.id} className="py-1 border px-1">
                      {/* 19 */}
                      {bodyData?.current_stock_qty}
                    </td>
                    <td key={bodyData.id} className="py-1 border px-1">
                      {/* 20 */}
                      {bodyData?.current_total_amount_no_vat}
                    </td>

                    <td>
                      {/* 21 */}
                      {bodyData?.credit_note?
                      <>Credit Note {bodyData?.credit_note}</>
                      :<></>
                      }
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default MushakSixTwo;