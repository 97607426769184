import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import Swal from "sweetalert2";
import { login,checkAuthenticated } from '../redux/actions/auth';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';

const Login = ({ login , isAuthenticated }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)
    
    const [formData, setFormData] = useState({
        email: '',
        password: '' 
    });

    const { email, password } = formData;

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });
    
    // const [error,setError]=useState();
    const onSubmit = async (e) => {
      e.preventDefault();
      setLoading(true); // Set loading to true when the form is submitted
    
      try {
        await login(email, password);
    
        if (!isAuthenticated) {
          setErrorMessage('Invalid email or password'); // Set the error message
        }
      } catch (err) {
        console.error(err); // Log any unexpected errors
        setErrorMessage('An error occurred during login'); // Set a generic error message
      }
    
      setLoading(false); // Set loading back to false after the request is completed
    };
    // this state is to make the password hide and show
      const [showPass, setShowPass] = useState(false);

      // handle the toggle show pass and hide pass button..
      const toggle = () => {
        setShowPass(!showPass);
      };


    if(isAuthenticated) {        
      return navigate('/dashboardhome');
    }


  return (
    <div>
      <div className="bg-white rounded px-10 py-10 shadow-xl shadow shadow-blue-500">
      <h1 className="text-3xl font-semibold mb-2">Login</h1>
      {errorMessage && (
        <div className="text-red-500 mb-2">{errorMessage}</div>
      )}
      <hr />
      <form onSubmit={e => onSubmit(e)}>
      <div className='mt-3'>
          <label className="text-lg font-medium" htmlFor="">
            Email
          </label>
          <input
            className="w-full border-2 font-bold border-gray-100 focus:outline-none rounded-xl p-3 bg-blue-50"
            type="email" 
            name='email' 
            value={email} 
            onChange={e => onChange(e)} 
            required 
            placeholder="Enter email or email" 
            />
        </div>

        <div>
          <div className="mt-4 flex justify-between">
            <label className="text-lg font-medium" htmlFor="">
              Password
            </label>
          </div>
          <div className=" relative">
            <div className=" w-full">
              <input 
              className="w-full border-2 border-gray-100 font-bold focus:outline-none rounded-xl p-3 bg-blue-50"
                type={showPass === false ? "password" : "text"} 
                name='password' 
                value={password} 
                onChange={e => onChange(e)} 
                required 
                placeholder="Password" 
              />
            </div>
            <div className=" text-xl absolute top-4 right-5">
              {showPass === false ? (
                <AiFillEye onClick={toggle} />
              ) : (
                <AiFillEyeInvisible onClick={toggle} />
              )}
            </div>
          </div>
        </div>

        
      <button type='submit'  className="w-[100%] bg-sky-500 border text-white font-medium rounded-lg mt-4 py-1">
        {loading ? (
          <>Loading...</>
        ):<>Login</>
        }
      </button>
      
      </form>

    </div>
    </div>
  )
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { login,checkAuthenticated })(Login);

