/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../BaseUrl";
import Swal from 'sweetalert2';
// form validation scheema

const CreateUserAccount = () => {
  const navigate = useNavigate();
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
  });
  const [errorMessage, setErrorMessage] = useState('')


  const formRegisterSchema = Yup.object().shape({
    password: Yup.string("Password should be a string")
      .required("Password is mendatory")
      .min(3, "Password must be at 3 char long"),
    confirmPassword: Yup.string("Confirm Password should be a string").oneOf(
      [Yup.ref("password")],
      "Passwords does not match"
    ),
    phone: Yup.string()
    .matches(/^\d+$/, "Phone number must contain only digits")
    .min(11, "Phone number must be at least 11 digits long")
    .max(11, "Phone number must not exceed 11 digits")
    .required("Phone number is mandatory"),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formRegisterSchema),
  });
  
  const jwtToken = localStorage.getItem("access");

  async function onSubmit(data) {
    try {
      const res = await axios.post(`${baseUrl}/user/api/user-signup/`, data, {
        headers: {
          Authorization: `JWT ${jwtToken}`,
        },
      });
      // if (res.data.statusText == "OK")
      if (res) {
        navigate("/user");
        Toast.fire({
          icon: 'success',
          title: 'Successfully Add',
        });
        reset();
      }
    } catch (error) {
      console.log(error);
      if (error.response.data.detail) {
        Toast.fire({
          icon: 'error',
          title: 'user with this email already exists',
        });
        setErrorMessage('User with this email already exists')
      }
      if (error.response.data.code == "token_not_valid") {
        alert("token expired please login again..");
        navigate("/login");
      }
    }
  }

  return (
    <div className="bg-white w-[600px] px-10 my-10 py-6 mx-auto mt-10 shadow-lg">
      <h1 className="text-4xl font-semibold mb-2">Create User</h1> <hr />
      <form form onSubmit={handleSubmit(onSubmit)} className="mt-4">
        <div className="grid grid-cols-2 gap-5">
          <div className=" w-full">
            <label className=" font-medium" htmlFor="">
              First Name
            </label>
            <input
              className="w-full border-1 border-teal-300 focus:outline-none rounded"
              type="text"
              name="firstName"
              // value={first_name}
              {...register("first_name")}
              id="firstName"
              placeholder=" First Name"
              // onChange={handleChange}
            />
          </div>
          <div className=" w-full">
            <label className=" font-medium" htmlFor="">
              Last Name
            </label>
            <input
              className="w-full border-1 border-teal-300 focus:outline-none rounded"
              type="text"
              name="lastName"
              // value={last_name}
              {...register("last_name")}
              id="lastName"
              placeholder=" Last Name"
              // onChange={handleChange}
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-5 mt-4">
        <div>
          <label className="font-medium" htmlFor="">
            Phone
          </label>
          <input
            className="w-full border-1 border-teal-300 focus:outline-none rounded"
            type="text"
            name="phone"
            {...register("phone")}
            id="phone"
            //   value={phone}
            placeholder="Enter your Phone"
            //   onChange={handleChange}
          />
            {errors.phone ? (
              <span className="text-red-600">{errors.phone.message}</span>
            ) : (
              <></>
            )}
        </div>
        <div>
          <label className=" font-medium" htmlFor="">
            Email
          </label>
          <input
            className="w-full border-1 border-teal-300 focus:outline-none rounded"
            type="email"
            //   value={email}
            {...register("email")}
            name="email"
            id="email"
            placeholder="Enter your Email"
            //   onChange={handleChange}
          />
          {errorMessage &&(
            <span className="text-red-600">{errorMessage}</span>
          )}
        </div>
        </div>
        <div className="grid grid-cols-2 gap-5">
        <div>
          <div className="mt-4 flex justify-between">
            <label className=" font-medium" htmlFor="">
              Password
            </label>
            {/* <p className=" text-blue-500 font-medium">Forgot password?</p> */}
          </div>
          <div className=" relative">
            <div className=" w-full">
              <input
                className="w-full border-1 border-teal-300 focus:outline-none rounded"
                {...register("password")}
                // type={showPass === false ? "password" : "text"}
                type="password"
                name="password"
                id="password"
                //   value={password}
                placeholder="Enter your password"
                //   onChange={handleChange}
              />
            </div>

            {errors.password ? (
              <span className="text-red-600">{errors.password.message}</span>
            ) : (
              <></>
            )}
            <div className=" text-xl absolute top-4 right-5"></div>
          </div>
        </div>
        <div>
          <div className="mt-4 flex justify-between">
            <label className=" font-medium" htmlFor="">
              Confirm Password
            </label>
            {/* <p className=" text-blue-500 font-medium">Forgot password?</p> */}
          </div>
          <div className=" relative">
            <div className=" w-full">
              <input
                className="w-full border-1 border-teal-300 focus:outline-none rounded"
                {...register("confirmPassword")}
                // type={showPass === false ? "password" : "text"}
                type="password"
                name="confirmPassword"
                id="confirmPassword"
                //   value={confirmPassword}
                placeholder="Enter your password"
                //   onChange={handleChange}
              />
            </div>
            {errors.confirmPassword ? (
              <span className="text-red-600">
                {errors.confirmPassword.message}
              </span>
            ) : (
              <></>
            )}

            <div className=" text-xl absolute top-4 right-5"></div>
          </div>
        </div>
        </div>
        <div>
          <div className="mt-4">
            <button
              className="w-[150px] bg-sky-500 border text-white font-medium rounded-lg h-10"
              type="submit"
              // onClick={handleSubmit}
            >
              CREATE USER
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateUserAccount;
