import React, {useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Swal from "sweetalert2";
import { logout,checkAuthenticated,load_user } from '../../redux/actions/auth';
import { FaPowerOff } from "react-icons/fa6";
import picture from "../../assets/images/ebit-logo.jpg";


const DashboardHeader = ({ userData,logout, isAuthenticated }) => {
      const [redirect, setRedirect] = useState(false);

      const logout_user = () => {
          logout();
          setRedirect(true);
         
          const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
          })
          
          Toast.fire({
            icon: 'success',
            title: 'Successfully Logout'
          })
          
      };

      // console.log('header',userData)
      // console.log('isAuthenticated',isAuthenticated)

  return (
    <>
    <header className="fixed top-0 left-0 w-full bg-white shadow-lg p-4 flex justify-between items-center z-50">
      {/* Brand Name (Left) */}
      <div className="flex items-center">
        <Link to="/dashboardhome">
          <h1 className="text-2xl font-semibold text-gray-800 cursor-pointer">
          <img width={"100px"} src={picture} alt="" />
          </h1>
        </Link>
      </div>
      {/* Search Bar (Center) */}
      <div className="relative hidden md:block">
          {/* <label htmlFor="" className="p-[9px] bg-black text-white font-blod ">Search</label>
              <input
                  type="text"
                  placeholder="Seacrh..."
                  value=''
                  onChange=''
                  className="shadow appearance-none border-white-20 w-[300px] py-[9pxpx] px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              /> */}
      </div>

      <div>
        {isAuthenticated ?
      <>
      <div className='flex'>
      <p className='font-bold mr-2'>{userData?.user.email}</p>
        <button onClick={logout_user} className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-1 rounded">
        <FaPowerOff />
        </button>
      </div>
      </> 
      :
       <>
        <Link to="/login">
            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
              Login
            </button>
          </Link>
       </>
       }
        </div>

    </header>
    </>
  )
}


const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  userData: state.auth.user,
});

export default connect(mapStateToProps, { logout,load_user,checkAuthenticated })(DashboardHeader);
