import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import DashboardLayout from '../../Shared/DashboardLayout';
import { baseUrl } from '../../BaseUrl';

function ProductionDataEdit() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [formData, setFormData] = useState({
    inventory: null,
    quantity: '',
    status: '',
    raw_material: '',
  });

  const [productQuantities, setProductQuantities] = useState([{ productId: '', quantity: '' }]);
  const [searchedProducts, setSearchedProducts] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [rawMaterial, setRawMaterial] = useState([]);
  const [inventory, setInventory] = useState([]);
  const [existingProductionData, setExistingProductionData] = useState(null);

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
  });

  // Add this function to safely get product names
  function getProductName(productId, rawMaterial) {
    const product = rawMaterial.find((p) => p.id == productId);
    console.log('Found Product:', product);

    return product ? product.name : ''; // Return an empty string if the product is not found
  }

  useEffect(() => {
    fetchInventoryData();
    fetchRawMaterialData();
    fetchProductionData();
  }, []);

  const fetchInventoryData = async () => {
    try {
      const jwtToken = localStorage.getItem("access");
      const res = await axios.get(`${baseUrl}/inventory/api/fg_list/`, {
        headers: {
          Authorization: `JWT ${jwtToken}`,
        },
      });
      if (res) {
        console.log('fetch inventory data', res.data);
        const finalData = res.data;
        setInventory(finalData);
      }
    } catch (error) {
      console.log('error from get inventory api', error);
    }
  };

  const fetchRawMaterialData = async () => {
    try {
      const jwtToken = localStorage.getItem("access");
      const res = await axios.get(`${baseUrl}/inventory/api/rm_list/`, {
        headers: {
          Authorization: `JWT ${jwtToken}`,
        },
      });
      if (res) {
        const finalData = res.data;
        console.log('Raw Material Data:', finalData);
        setRawMaterial(finalData);
      }
    } catch (error) {
      console.log('error from get raw material api', error);
    }
  };

  const fetchProductionData = async () => {
    try {
      const jwtToken = localStorage.getItem("access");
      const res = await axios.get(`${baseUrl}/purchase/api/prod/${id}/`, {
        headers: {
          Authorization: `JWT ${jwtToken}`,
        },
      });
      if (res && res.data !== null) {
        console.log('fetch production data', res.data);
        const existingData = res.data;
        setExistingProductionData(existingData);
        setFormData({
          inventory: { value: existingData.inventory, label: existingData.inventory_name },
          quantity: existingData.quantity.toString(),
          status: existingData.status,
          raw_material: '', // You can set a default value here if needed
        });

        // Extract product IDs and quantities from raw_materials
        const productQuantitiesData = Object.entries(existingData.raw_materials).map(
          ([productId, quantity]) => ({
            productId,
            quantity: quantity.toString(),
          })
        );

        setProductQuantities(productQuantitiesData);
      } else {
        console.log('No data found for the specified production ID.');
      }
    } catch (error) {
      console.log('Error from get production api', error);
    }
  };

  const handleQuantityChange = (event, index) => {
    const updatedProductQuantities = [...productQuantities];
    updatedProductQuantities[index].quantity = event.target.value;
    setProductQuantities(updatedProductQuantities);
  };

  const handleProductChange = (selectedOption, index) => {
    const updatedProductQuantities = [...productQuantities];
    updatedProductQuantities[index].productId = selectedOption.value;
    setProductQuantities(updatedProductQuantities);
  };

  const statusOptions = [
    { value: 0, label: 'Ordered' },
    { value: 1, label: 'WIP' },
    { value: 2, label: 'Complete' },
  ];

  const handleStatusChange = (selectedOption) => {
    setFormData({ ...formData, status: selectedOption.value });
  };

  const handleAddMore = () => {
    setProductQuantities([...productQuantities, { productId: '', quantity: '' }]);
  };

const shouldEnableAddMore = () => {
  const lastItem = productQuantities[productQuantities.length - 1];
  return lastItem && lastItem.productId && lastItem.quantity;
};

  const handleDelete = (index) => {
    if (index === 0) {
      return;
    }
    const updatedProductQuantities = [...productQuantities];
    updatedProductQuantities.splice(index, 1);
    setProductQuantities(updatedProductQuantities);
  };

  const handleInventoryChange = (selectedOption) => {
    setFormData({ ...formData, inventory: selectedOption });
  };

  const handleSearchChange = (value) => {
    setSearchText(value);
    const filteredProducts = rawMaterial
      .filter((product) => {
        // Check if the product is not already selected in productQuantities
        return (
          product.name.toLowerCase().includes(value.toLowerCase()) &&
          !productQuantities.some((item) => item.productId === product.id)
        );
      })
      .map((product) => ({ value: product.id, label: product.name }));
    
    // If the current search text matches a selected raw material, add it to the options
    if (productQuantities.some((item) => item.productId === value)) {
      filteredProducts.push({ value, label: value });
    }
  
    setSearchedProducts(filteredProducts);
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSave = (event) => {
    event.preventDefault();

    const rawMaterialsData = productQuantities.reduce((acc, item) => {
      if (item.productId && item.quantity) {
        acc[item.productId] = parseInt(item.quantity);
      }
      return acc;
    }, {});

    const dataToUpdate = {
      inventory: formData.inventory.value,
      quantity: parseInt(formData.quantity),
      raw_materials: JSON.stringify(rawMaterialsData), // Convert to JSON string
      status: formData.status,
    };

    const jwtToken = localStorage.getItem("access");
    axios
      .put(`${baseUrl}/purchase/api/prod/${id}/`, dataToUpdate, {
        headers: {
          Authorization: `JWT ${jwtToken}`,
        },
      })
      .then((response) => {
        navigate('/production-list');
        Toast.fire({
          icon: 'success',
          title: 'Successfully Updated',
        });
      })
      .catch((error) => {
        Toast.fire({
          icon: 'error',
          title: `Ops! ${error.message}`,
        });
      });
  };

  return (
    <DashboardLayout>
    <div className="px-5 py-5">
      <div className="flex min-w-xl">
        <div className="bg-white p-8 rounded shadow-md">
          <h1 className="uppercase font-semibold text-xl mb-3">Production Edit Form</h1>
          <hr />

          <form onSubmit={handleSave}>
            <div className="w-full max-w-lg mt-5">
              <div className="md:flex md:items-center mb-2">
                <div className="w-[210px]">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-5"
                    htmlFor="inline-full-name"
                  >
                    Finished Good**
                  </label>
                </div>
                <div className="w-[100%]">
                  <Select
                    value={formData.inventory}
                    options={inventory?.map((item) => ({ value: item.id, label: item.name }))}
                    onChange={handleInventoryChange}
                    placeholder="Select inventory"
                    required
                  />
                </div>
              </div>
            </div>

            <div className="w-full max-w-lg mt-2">
              <div className="md:flex md:items-center mb-2">
                <div className="w-[210px]">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-5"
                    htmlFor="inline-full-name"
                  >
                    Quantity*
                  </label>
                </div>
                <div className="w-[100%]">
                  <input
                    type="text"
                    name="quantity"
                    id="quantity"
                    value={formData.quantity}
                    required
                    onChange={handleChange}
                    className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-blue-300"
                    placeholder="Enter quantity"
                  />
                </div>
              </div>
            </div>

            <div className="w-full max-w-lg mt-2">
              <div className="md:flex md:items-center mb-2">
                <div className="w-[210px]">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mt-3"
                    htmlFor="inline-full-name"
                  >
                    Status*
                  </label>
                </div>
                <div className="w-[100%]">
                  <Select
                    value={statusOptions.find((option) => option.value === formData.status)}
                    options={statusOptions}
                    onChange={handleStatusChange}
                    placeholder="Select status"
                    required
                  />
                </div>
              </div>
            </div>

            <div className="w-full max-w-xl mt-2">
              <div className="md:flex align-top">
                <div className="w-[230px]">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mt-3"
                    htmlFor="inline-full-name"
                  >
                    Raw Material*
                  </label>
                </div>
                <div className="w-[100%]">
                  {productQuantities.map((item, index) => (
                    <div className="flex flex-wrap -mx-3 mb-2" key={index}>
                      <div className="w-[38%] mb-2 mr-1 md:mb-0">
                      <Select
                          value={
                            item.productId
                              ? { value: item.productId, label: getProductName(item.productId, rawMaterial) }
                              : null
                          }
                          options={searchedProducts}
                          onChange={(selectedOption) => handleProductChange(selectedOption, index)}
                          onInputChange={(value) => handleSearchChange(value)}
                          placeholder="Product"
                          required
                        />
                      </div>

                      <div className="w-[38%]  mb-2 mr-1 md:mb-0">
                        <input
                          className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-blue-300"
                          type="number"
                          min="0"
                          step="0.01"
                          value={item.quantity}
                          onChange={(e) => handleQuantityChange(e, index)}
                          placeholder="Enter quantity"
                          required
                        />
                      </div>

                      <div className="w-[10%]  mb-2 md:mb-0">
                        {index === 0 ? (
                          <div />
                        ) : (
                          <button
                            className="border text-[#dc2626] font-bold py-2 px-4 rounded"
                            onClick={() => handleDelete(index)}
                          >
                            Delete
                          </button>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <button
                className="bg-green-500 hover:bg-green-700 text-white p-1-2 px-2 my-3 rounded"
                onClick={handleAddMore}
                disabled={!shouldEnableAddMore()}
              >
                Add New
              </button>
            </div>

            <div className="w-full max-w-lg mt-5 text-right">
              <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-10 rounded">
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    </DashboardLayout>
  );
}

export default ProductionDataEdit;
