// eslint-disable-next-line no-unused-vars
import React from "react";
import DashboardLayout from "../Shared/DashboardLayout";
import SupplyDetailTable from "../components/SupplyDetailTable";

const SupplyChainPage = () => {
  return (
    <DashboardLayout>
      <SupplyDetailTable />
    </DashboardLayout>
  );
};

export default SupplyChainPage;
