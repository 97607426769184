const DemoMushokTable = () => {
  return (
    <div className=" ">
      <div className="flex justify-between  my-5">
        <p></p>
        <button className="bg-green-500 w-[150px] hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
          Download pdf
        </button>
      </div>

      <div className="flex flex-col max-w-full  h-[85vh]">
        <div className="">
          <table className="min-w-full">
            <thead className=" bg-gray-100">
              <tr className="">
                <td className="py-2 px-2 border-2  right-5" colSpan={9}>
                  Product/Service
                </td>
                <td className="py-2 px-2 border-2  right-5" colSpan={12}>
                  Purchase of Equipment
                </td>
              </tr>
              <tr className="bg-gray-100">
                <td className="py-2 px-2 border-2 " rowSpan={3}>
                  Order data
                </td>
                <td className="py-2 px-2 border-2 " colSpan={6}>
                  Initial Available Stock
                </td>
                <td className="py-2 px-2 border-2  right-5" colSpan={9}>
                  Purchase of Equipment
                </td>
                <td className="py-2 px-2 border-2  right-5" colSpan={3}>
                  Ending Stock
                </td>
                {/* <td className="py-2 px-4 border-2 " rowSpan={4}>
                  Comment
                </td> */}
              </tr>
              <tr className="">
                <td className="py-2 px-2 border-2  right-5" rowSpan={2}>
                  Quantity
                </td>
                <td className="py-2 px-2 border-2  right-5" rowSpan={2}>
                  Total Price No Vat
                </td>
                <td className="py-2 px-2 border-2  right-5" rowSpan={2}>
                  Bill of Entry
                </td>
                <td className="py-2 px-2 border-2 right-5" colSpan={3}>
                  Seller/Supplier
                </td>
                <td className="py-2 px-2 border-2 " rowSpan={3}>
                  Descreption
                </td>
                <td className="py-2 px-2 border-2 " rowSpan={3}>
                  Quantity
                </td>
                <td className="py-2 px-2 border-2 " rowSpan={3}>
                  Total Price No Vat
                </td>
                <td className="py-2 px-2 border-2 " rowSpan={3}>
                  Total Tax
                </td>
                <td className="py-2 px-2 border-2 " rowSpan={3}>
                  Vat
                </td>
                <td className="py-2 px-2 border-2  right-5" colSpan={2}>
                  Total Equipment Quantity
                </td>
                <td className="py-2 px-2 border-2  right-5" colSpan={2}>
                  Product Redy Equipnent
                </td>
                <td className="py-2 px-2 border-2 " rowSpan={3}>
                  Quantity unite
                </td>
                <td className="py-2 px-2 border-2 " rowSpan={3}>
                  Total Price Without Vat
                </td>
              </tr>
              <tr>
                <td className="py-2 px-2 border-2  right-5" rowSpan={1}>
                  Name
                </td>
                <td className="py-2 px-2 border-2  right-5" rowSpan={1}>
                  Address
                </td>
                <td className="py-2 px-2 border-2  right-5" rowSpan={1}>
                  NID NO
                </td>
                <td className="py-2 px-2 border-2  right-5" rowSpan={1}>
                  Quantity unit
                </td>
                <td className="py-2 px-2 border-2  right-5" rowSpan={1}>
                  total parice no vat
                </td>
                <td className="py-2 px-2 border-2  right-5" rowSpan={1}>
                  Quantity unit
                </td>
                <td className="py-2 px-2 border-2  right-5" rowSpan={1}>
                  total price no vat
                </td>
              </tr>

              <tr></tr>
            </thead>
            <tbody></tbody>
          </table>
        </div>
        <div className="flex-grow"></div>
        <div className="bg-gray-200 py-4 px-8 text-center">
          Footer content here
        </div>
      </div>
    </div>
  );
};

export default DemoMushokTable;
